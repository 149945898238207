<div class="addDetail_card_con">
  <div class="address_card" id="card-info">
    <div class="row" *ngIf="skeletonAddress">
      <div class="col-md-4 cus_md5"
        *ngIf="userDetail && userDetail?.phyState && userDetail?.phyCity && userDetail?.phyZip">
        <div class="bill_info" [ngClass]="currentAddressActive === 'physical' ? 'active_card_child' : ''">
          <div class="pointer bill_info_head"
            (click)="selectAddress(userDetail?.phyAddressLine1,userDetail?.phyAddressLine2,userDetail?.phyState,userDetail?.phyCity,userDetail?.phyCountry,userDetail?.phyZip,'physical')">
            <p class=" mb-2 fs_14 fsb_6 txt_lb f_clash">Physical Address</p>
            <p class=" mb-2 fs_15 fsn_4 txt_lb txt_break">
              {{userDetail?.phyAddressLine1 ? userDetail?.phyAddressLine1 +"," : ""}}
              {{userDetail?.phyAddressLine2 ? userDetail?.phyAddressLine2+",": ""}}
              {{userDetail?.phyCity ? userDetail?.phyCity +"," : ""}} {{userDetail?.phyState ? userDetail?.phyState+",":
              ""}} {{userDetail?.phyCountry ? userDetail?.phyCountry+"," : ""}} {{userDetail?.phyZip ?
              userDetail?.phyZip : ""}}
            </p>
          </div>
          <p class="mb-0 fs_14 fsb_6 txt_p pointer bill_info_footer"
            (click)="userEditAdd(userDetail?.phyAddressLine1,userDetail?.phyAddressLine2,userDetail?.phyCity,userDetail?.phyState,userDetail?.phyZip,userDetail?.phyCountry,'Physical Address')">
            <span class="pointer f_clash">Edit details</span></p>
        </div>
      </div>
      <div class="col-md-4 cus_md5"
        *ngIf="userDetail && userDetail?.mailingAddressLine1 && userDetail?.mailingCity && userDetail?.mailingZip">
        <div class="bill_info" [ngClass]="currentAddressActive === 'mailing' ? 'active_card_child' : ''">
          <div class="pointer bill_info_head"
            (click)="selectAddress(userDetail?.mailingAddressLine1,userDetail?.mailingAddressLine2,userDetail?.mailingState,userDetail?.mailingCity,userDetail?.mailingCountry,userDetail?.mailingZip,'mailing')">
            <p class=" mb-2 fs_14 fsb_6 txt_lb f_clash">Mailing Address</p>
            <p class=" mb-2 fs_15 fsn_4 txt_lb txt_break">
              {{userDetail?.mailingAddressLine1 ? userDetail?.mailingAddressLine1 +"," : ""}}
              {{userDetail?.mailingAddressLine2 ? userDetail?.mailingAddressLine2 +"," : ""}}
              {{userDetail?.mailingCity ? userDetail?.mailingCity +"," : ""}} {{userDetail?.mailingState ?
              userDetail?.mailingState+",": ""}} {{userDetail?.mailingCountry ? userDetail?.mailingCountry+"," : ""}}
              {{userDetail?.mailingZip ? userDetail?.mailingZip : ""}}
            </p>
          </div>
          <p class="mb-0 fs_14 fsb_6 txt_p bill_info_footer"
            (click)="userEditAdd(userDetail?.mailingAddressLine1,userDetail?.mailingAddressLine2,userDetail?.mailingCity,userDetail?.mailingState,userDetail?.mailingZip,userDetail?.mailingCountry,'Mailing Address')">
            <span class="pointer f_clash">Edit details</span></p>
        </div>
      </div>
      <div class="col-md-4 cus_md5" *ngIf="!addresBuDisabled">
        <div class="add_bill_info pointer"
          *ngIf="(!userDetail?.mailingAddressLine1 || !userDetail?.mailingCity || !userDetail?.mailingZip ) && (!userDetail?.phyState || !userDetail?.phyCity || !userDetail?.phyZip) && userType!='STORAGE'"
          (click)="addAddress('Both Address')">
          <span class="fs_30 txt_p icon-ic-plus-in-circle mb-1"></span>
          <p class=" mb-0 fs_14 fsb_6 txt_p f_clash">Add Address</p>
        </div>
        <div class="add_bill_info pointer"
          *ngIf="(!userDetail?.mailingAddressLine1 || !userDetail?.mailingCity || !userDetail?.mailingZip) && (userDetail?.phyState && userDetail?.phyCity && userDetail?.phyZip)&& userType!='STORAGE'"
          (click)="addAddress('Mailing Address')">
          <span class="fs_30 txt_p icon-ic-plus-in-circle mb-1"></span>
          <p class=" mb-0 fs_14 fsb_6 txt_p f_clash">Add Address</p>
        </div>
        <div class="add_bill_info pointer"
          *ngIf="(userDetail?.mailingAddressLine1 && userDetail?.mailingCity && userDetail?.mailingZip) && (!userDetail?.phyState || !userDetail?.phyCity || !userDetail?.phyZip)"
          (click)="addAddress('Physical Address')">
          <span class="fs_30 txt_p icon-ic-plus-in-circle mb-1"></span>
          <p class=" mb-0 fs_14 fsb_6 txt_p f_clash">Add Address</p>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="!skeletonAddress">
      <div class="col-md-4 cus_md5" *ngFor="let fake of generateFake(2)">
        <div class="bill_info cark_skelaton" style="height: 113px; padding: 1rem">
          <ngx-skeleton-loader count="1" [theme]="{ width: '80%', height: '12px'}" class="loader_mb_5">
          </ngx-skeleton-loader>
          <ngx-skeleton-loader count="2" [theme]="{ width: '100%', height: '12px'}" class="loader_mb_5">
          </ngx-skeleton-loader>
          <ngx-skeleton-loader count="1" [theme]="{ width: '45%', height: '12px'}" class="loader_mb_5">
          </ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div>
</div>