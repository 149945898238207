import { Component, OnInit,   } from '@angular/core';
import { SharedService } from '../../commons/service/shared.service';
import { Router,  } from '@angular/router';
@Component({
  selector: 'app-connect-account-success',
  templateUrl: './connect-account-success.component.html',
  styleUrls: ['./connect-account-success.component.css'],
})
export class ConnectAccountSuccessComponent implements OnInit  {
  public showCurrentSection:any;
  
  constructor(
    private sharedService: SharedService,
    private router: Router,
    ) {
      if (window.addEventListener) {
        window.addEventListener("message", this.receiveMessage.bind(this), false);
      } else {
         (<any>window).attachEvent("onmessage", this.receiveMessage.bind(this));
      }
      
    }
  
  receiveMessage(evt:any){
  }  

  ngOnInit() {
    if(this.router.url==='/carrier/stripe/connect-account-success'){
      this.showCurrentSection = "connect_account";
    }else if(this.router.url==='/shipper/stripe/ach-success'){
      this.showCurrentSection="ach_account";
    }else if(this.router.url==='/configure-ach-account'){
      this.showCurrentSection="configure-ach-account";
    }
    this.sharedService.ACHService.next();
  }
}
