import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LocationStrategy, Location, PathLocationStrategy} from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app.routing';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AppComponent } from './app.component';
import { RefreshPopupComponent } from './commons/refresh-popup/refresh-popup.component';
import { FeedbackComponent } from './commons/feedback/feedback.component';
import { TokenInterceptorService } from './commons/service/token-interceptor.service';
import { DatePipe } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SharedService } from './commons/service/shared.service';
import { TopNavbarComponent } from './top-navbar/top-navbar.component';
import { AngularFireModule } from '@angular/fire/compat';
// import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
// import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
// import { AngularFireAuthModule } from '@angular/fire/compat/auth';
// import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { MessagingService } from './commons/service/messaging.service';
import { environment } from '../environments/environment';
import { CoreModule } from './core/core.module';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MaterialModule,
    // AngularFireDatabaseModule,
    // AngularFireAuthModule,
    // AngularFireMessagingModule,
    // AngularFirestoreModule,
    AngularFireModule.initializeApp(environment.firebase),
    NgxSkeletonLoaderModule.forRoot(),
    HttpClientModule,
    AppRoutingModule,
    InfiniteScrollModule,
    CoreModule
  ],
  declarations: [
    AppComponent,
    RefreshPopupComponent,
    TopNavbarComponent,
    FeedbackComponent,
  ],
  entryComponents: [RefreshPopupComponent, FeedbackComponent],
  providers: [
    MessagingService,
    SharedService,
    Location,
    DatePipe,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
