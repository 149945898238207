<div class="htg_popup"  *ngIf="data.openPop=='user-guide-popup'"> 
    <span class="material-icons-outlined close_icon pointer" (click)="closePoup()"> close </span>
    <div class="popup_heading">video tutorial</div>
    <div class="row">
        <iframe width="560" height="400" [src]="data?.videoUrl" frameborder="0" allowfullscreen></iframe>
    </div>
</div>

 <div *ngIf="data.openPop=='dissMissConfirmation'">
    <div class="header_popup">
        <p class="fs_20 fsb_6 txt_b line_before f_clash">Confirmation</p>
        <span class="close_popup_Icon" (click)="closePoup()">
           <span class="icon-ic-cross close_Icon"></span>
        </span>
     </div>
     <div class="text-center">
        <p class="fs_16 fsn_4 txt_lb mb_24">Do you want to remove header permanently?</p>
        <div class="btn_action">
           <button type="button" class="btn custum_button button_primary btn_primary mb_16"
              (click)="successClose()">OK</button>
           <span class="btn_trans txt_lb pointer f_clash" (click)="closePoup()">Cancel</span>
        </div>
     </div>
 </div>    
