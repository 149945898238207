import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from '../../commons/service/common.service';
import { Router, ActivatedRoute } from '@angular/router';

export interface DialogData {
  openPop: string;
  subscriptionData: any;
  type: any;
}

@Component({
  selector: 'app-subscription-popup',
  templateUrl: './subscription-popup.component.html',
  styleUrls: ['./subscription-popup.component.scss']
})
export class SubscriptionPopupComponent implements OnInit {
  public loading=false;
  public subscriptionData: any;

  constructor(
    private commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<SubscriptionPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {
  }

  
  gotoPriemum(){
    this.dialogRef.close({ event: 'goCheck' });
    this.router.navigate(['/shipper/premium-plan']);
  }
  gotoPriemumAdditional(){
    this.dialogRef.close({ event: 'goCheck' });
    this.router.navigate(['/shipper/subscription-overview/additional-credits']);
  }

  onNoClick(): void {
    this.dialogRef.close({ event: 'fail' });
  }

}
