import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./core/core.module').then((mod) => mod.CoreModule),
  },
  {
    path: 'tutorial',
    loadChildren: () =>
      import('./user-guide/guide.module').then((mod) => mod.GuideModule),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((mod) => mod.DashboardModule),
  },
  {
    path: 'network',
    loadChildren: () =>
      import('./network/network.module').then((mod) => mod.NetworkModule),
  },
  {
    path: 'booking',
    loadChildren: () =>
      import('./bidding/bidding.module').then((mod) => mod.BiddingModule),
  },
  {
    path: 'messages',
    loadChildren: () =>
      import('./message/message.module').then((mod) => mod.MessageModule),
  },
  {
    path: 'carrier',
    loadChildren: () =>
      import('./carrier/carrier.module').then((mod) => mod.CarrierModule),
  },
  {
    path: 'shipper',
    loadChildren: () =>
      import('./shipper/shipper.module').then((mod) => mod.ShipperModule),
  },
  {
    path: 'shipment',
    loadChildren: () =>
      import('./shipment/shipment.module').then((mod) => mod.ShipmentModule),
  },
  {
    path: 'bid-quote-assistant',
    loadChildren: () =>
      import('./bid-quote-assistant/bid-quote-assistant.module').then((mod) => mod.BidQuoteAssistantModule),
  },
  {
    path: 'driver',
    loadChildren: () =>
      import('./driver/driver.module').then((mod) => mod.DriverModule),
  },
  {
    path: 'payment',
    loadChildren: () =>
      import('./payment/payment.module').then((mod) => mod.PaymentModule),
  },
  {
    path: 'billing',
    loadChildren: () =>
      import('./payment/payment.module').then((mod) => mod.PaymentModule),
  },
  {
    path: 'storage',
    loadChildren: () =>
      import('./storage/storage.module').then((mod) => mod.StorageModule),
  },
  {
    path: 'storage-order',
    loadChildren: () =>
      import('./order-management/order-management.module').then(
        (mod) => mod.OrderManagementModule
      ),
  },
    {
    path: '**', 
    redirectTo: '/'
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
