import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedService } from '../../commons/service/shared.service';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/commons/service/common.service';
import { AlertService } from 'src/app/commons/service/alert.service';
import { AppSettings } from 'src/app/commons/setting/app_setting';
import * as moment from 'moment';
import { NetworkPopupComponent } from 'src/app/network-shared/network-popup/network-popup.component';
import { StatusSetting } from 'src/app/commons/setting/status_setting';
import { SubscriptionPopupComponent } from '../subscription-popup/subscription-popup.component';
import { ActivatedRoute, Router } from '@angular/router';
import { windowTime } from 'rxjs-compat/operator/windowTime';
@Component({
  selector: 'app-carrier-side-panel',
  templateUrl: './carrier-side-panel.component.html',
  styleUrls: ['./carrier-side-panel.component.css']
})
export class CarrierSidePanelComponent implements OnInit {
  @Input() userType: any;
  @Input() findUserType: any;
  @Input() sidePanelProfileData: any;
  @Input() sidePanelProfileDataId: any
  @Input() currentUrlTab: any;
  @Output() closeSidePanel = new EventEmitter();
  @Input() getUseProfileNewTab: any;
  public loading = false;
  public driverSidePanelData: any;
  public ratingOwnerDetail: {};
  public regionSelectedData: any;
  public profileSidePanelData: any;
  public eCarrierCheckRisk: any;
  public subscriptionData: any;
  public eCarrierCheckData: any
  public currentUrlTabType: any
  convertedRevenue: any;

  constructor(
    private sharedService: SharedService,
    public dialog: MatDialog,
    public commonService: CommonService,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    console.log(this.sidePanelProfileData, 'sidePanelProfileData')
    this.eCarrierCheckRisk = StatusSetting.eCarrierCheckToolTip;

    if (this.userType === 'CARRIER' && this.sidePanelProfileData) {
      this.ratingOwnerDetail = {
        key: 'shipperId',
        id: this.sidePanelProfileData?.shippers?.userId,
        ratingPlace: 'network'
      }
    }
    if ((this.userType === 'SHIPPER' && this.sidePanelProfileData?.carriers?.userId) || this.sidePanelProfileData && this.sidePanelProfileData?.userId) {
      this.ratingOwnerDetail = {
        key: 'carrierId',
        id: this.sidePanelProfileData?.carriers?.userId ? this.sidePanelProfileData?.carriers?.userId : this.sidePanelProfileData?.userId,
        ratingPlace: 'network'
      }
    }
    if (this.userType === 'CARRIER' && this.sidePanelProfileData?.shipper?.userId) {
      this.ratingOwnerDetail = {
        key: 'shipperId',
        id: this.sidePanelProfileData?.shipper?.userId,
        ratingPlace: 'network'
      }
    }

    if ((this.userType === 'SHIPPER' && this.sidePanelProfileData?.carrier?.userId)) {
      this.ratingOwnerDetail = {
        key: 'carrierId',
        id: this.sidePanelProfileData?.carrier?.userId,
        ratingPlace: 'network'
      }
    }

    if (this.sidePanelProfileData?.revenueUsd) {
      this.convertedRevenue = this.convertMilionBillon(this.sidePanelProfileData?.revenueUsd);
    }

    if (this.userType === 'SHIPPER') {
      this.getSubscriptionAlert();
    }

    if (this.sidePanelProfileDataId) {
      this.getApi();
    }


  }

  ngAfterViewInit() {
    this.currentUrlTabType = this.route.snapshot.params['type']
    console.log(this.route.snapshot, 'consoleSnapSHot')
    console.log(this.currentUrlTab, 'this.currentUrlTab')
  }
  eqipmentSpace(equipmentType) {

    return equipmentType.join(', ');
  }

  convertMilionBillon(revenueUsd: any) {
    let million
    if (revenueUsd >= 1000) {
        million = (revenueUsd / 1000)
        million =Number(million.toString().match(/^\d+(?:\.\d{0,2})?/)) + 'B';
    } else if (revenueUsd != null) {
        million = revenueUsd + 'M'
    } else {
        million = null
    }
    return million
  }

  closePanel(value: any, type: any) {
    console.log(value, 'valuee-->')
    if (this.eCarrierCheckData) {
      this.closeSidePanel.emit({ value, type });
    }
    else if (this.currentUrlTabType == 'history' && !this.sidePanelProfileData?.carriers?.carrierSafetyScore) {
      this.closeSidePanel.emit({ value, type: 'history' });
    }
    else if (this.currentUrlTabType == 'bidding') {
      this.closeSidePanel.emit({ value, type: 'bidding' });
    }
    else if (this.currentUrlTabType == 'booked') {
      this.closeSidePanel.emit({ value, type: 'booked' });
    }
    else {
      this.closeSidePanel.emit({ value, type });

    }

  }
  userLoginPopup(UserType: any) {
    const dialogRef = this.dialog.open(NetworkPopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      data: { openPop: 'required_Login', type: UserType },
    });
  }
  getApi() {
    this.loading = true;
    let uri = null;
    let newParams = {
      id: this.sidePanelProfileDataId
    };
    if (newParams) uri = this.commonService.getAPIUriFromParams(newParams);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.MANAGE_CONNECTION.GETPROFILE,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe(
      (ServerRes) => {
        this.profileSidePanelData = ServerRes.response;
        this.loading = false;

      })
  }

  getApiDriver() {
    this.loading = true;
    let uri = null;
    let now = new Date();
    const currentPickDate = moment(now).format('MM/DD/YYYY');
    let newParams = {
      driverId: this.sidePanelProfileDataId,
      availabilityDate: currentPickDate,
      isLastShipment: 1,
      isTotalShipments: 1,
    };
    if (newParams) uri = this.commonService.getAPIUriFromParams(newParams);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.DRIVER.GET,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe(
      (ServerRes) => {
        this.driverSidePanelData = ServerRes.response;
        this.loading = false;
      })
  }
  checkEquipmentType(equipment: any) {
    if (equipment && equipment.length > 0 && Array.isArray(equipment)) {
      return equipment.toString()
    }
    else if (equipment && equipment.length > 0) {
      return equipment
    }
    else {
      return '-'
    }
  }
  ecarriercheck(sidePanelProfileData) {
    console.log(sidePanelProfileData, 'sidePanelProfileData-E-Carrier-Check')
    console.log('e-carrieer')
    const dialogRef = this.dialog.open(NetworkPopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: '1000px',
      height: 'calc(100vh - 100px)',
      data: { openPop: 'ecarriercheck', dotNumber: this.profileSidePanelData?.dotNumber
    },
    });
    dialogRef.afterClosed().subscribe((result) => {

    });
  }

  // Functions to get subscription alert popup
  getSubscriptionAlert() {
    this.commonService.getSubscriptionAlert().subscribe((serverRes) => {
      this.subscriptionData = serverRes.response;
    });
  }

  getSubscriptionAlertPopup(type) {
    const dialogRef = this.dialog.open(SubscriptionPopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: type == 'isUnlockSafetyScore' ? '500px' : AppSettings.popWidth,
      data: {
        openPop: 'subscriptionAlert',
        subscriptionData: this.subscriptionData,
        type: type
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.loading = false;

    });
    this.loading = false;

  }

  unlockSafetyScore(sidePanelProfileData, type) {
    this.loading = true;
    if (this.userType == 'SHIPPER' && (((this.subscriptionData?.subscriptionCreditHistory?.defaultCredit + this.subscriptionData?.subscriptionCreditHistory?.additionalCredit) == 0) && this.subscriptionData?.isPremium != true && this.subscriptionData?.subscriptionStatus == 'canceled')
      || (this.subscriptionData?.isPremium == true && this.subscriptionData?.subscriptionStatus == 'active' && ((this.subscriptionData?.subscriptionCreditHistory?.defaultCredit + this.subscriptionData?.subscriptionCreditHistory?.additionalCredit) == 0))) {
      this.getSubscriptionAlertPopup('isUnlockSafetyScore');
    } else {
      // this.closeSidePanel.emit({ dotNumber, type });
      let uri = null;
      let newParams = {
        dotNumber: this.profileSidePanelData?.dotNumber

      };
      if (newParams) uri = this.commonService.getAPIUriFromParams(newParams);
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.CARRIER.ECARRIERCHECK,
        uri: uri,
      };
      this.commonService.getList(APIparams).subscribe(
        (ServerRes) => {
          if (ServerRes?.success == true) {
            this.eCarrierCheckData = ServerRes?.response;
            if (this.sidePanelProfileData.carrier) {
              if (this.eCarrierCheckData?.profile?.equipmentType) {
                this.sidePanelProfileData.carrier.equipmentType = ServerRes?.response?.profile?.equipmentType
              }
              this.sidePanelProfileData.carrier.shipperCarrierScoreMapping = true;
              this.sidePanelProfileData.carrier.carrierSafetyScore = {
                id: 71,
                authority: ServerRes.response.authority,
                riskLevel: ServerRes.response.riskLevel,
                riskLabel: ServerRes.response.riskLabel,
                recentCrashes: ServerRes.response.recentCrashesCount,
                dotNumber: ServerRes.response.profile?.dotNumber,
                recentInspections: ServerRes.response.recentInspectionsCount
              };
              this.loading = false;
            }
            else if (this.sidePanelProfileData) {
              if (this.eCarrierCheckData?.profile?.equipmentType) {
                this.sidePanelProfileData.equipmentType = ServerRes?.response?.profile?.equipmentType
              }
              this.sidePanelProfileData.shipperCarrierScoreMapping = true;
              this.sidePanelProfileData.carrierSafetyScore = {
                id: 71,
                authority: ServerRes.response.authority,
                riskLevel: ServerRes.response.riskLevel,
                riskLabel: ServerRes.response.riskLabel,
                recentCrashes: ServerRes.response.recentCrashesCount,
                dotNumber: ServerRes.response.profile?.dotNumber,
                recentInspections: ServerRes.response.recentInspectionsCount
              };
              this.loading = false;
            }
            else if (this.sidePanelProfileData?.carriers) {
              if (this.eCarrierCheckData?.profile?.equipmentType) {
                this.sidePanelProfileData.equipmentType = ServerRes?.response?.profile?.equipmentType
              }
              this.sidePanelProfileData.shipperCarrierScoreMapping = true;
              this.sidePanelProfileData.carrierSafetyScore = {
                id: 71,
                authority: ServerRes.response.authority,
                riskLevel: ServerRes.response.riskLevel,
                riskLabel: ServerRes.response.riskLabel,
                recentCrashes: ServerRes.response.recentCrashesCount,
                dotNumber: ServerRes.response.profile?.dotNumber,
                recentInspections: ServerRes.response.recentInspectionsCount
              };
              this.loading = false;
            }

          }
          else if (ServerRes.isCreditAvailable == false && ServerRes?.success == false) {
            const dialogRef = this.dialog.open(NetworkPopupComponent, {
              disableClose: true,
              backdropClass: AppSettings.backdropClass,
              width: '500px',
              data: { openPop: 'eCarreirCreadits', },

            });
            dialogRef.afterClosed().subscribe((result) => {
              console.log(result, 'resuly')
              this.loading = false;
            });
            this.loading = false;

          }
          else if (ServerRes?.success == false) {
            this.alertService.showNotificationMessage(
              'danger',
              'bottom',
              'right',
              'txt_d',
              'check_circle',
              'Outside Carrier',
              ServerRes?.message
            );
            this.loading = false;
          }

        },
        (error) => {      
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_d',
            'check_circle',
            'Outside Carrier',
            error?.error?.message
          );
    
        this.loading = false;
      });
    }
  }
}


