import {Component,OnInit,Input,ElementRef,ViewChild,OnDestroy,AfterViewInit,Renderer2} from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FeedbackComponent } from '../commons/feedback/feedback.component';
import { CommonService } from '../commons/service/common.service';
import { AppSettings } from '../commons/setting/app_setting';
import { SharedService } from '../commons/service/shared.service';
import { AlertService } from '../commons/service/alert.service';
import 'rxjs/BehaviorSubject';
import { environment } from '../../environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ScriptService } from '../commons/service/script.service';
import { FacebookPixelService } from '../commons/service/facebook-pixel.service';
import { ActivatedRoute } from "@angular/router";
import * as moment from 'moment';
import { EnterpricePopupComponent } from '../shipper/enterprice-popup/enterprice-popup.component';

declare const $: any;
declare let fbq: Function; // fbq function declaration
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  icon_selected: string;
  class: string;
  name: string;
  subMenu: any[];
  id: string;
  count: string
}
// export let profileHighlight = 'profile';


@Component({
  selector: 'app-top-navbar',
  templateUrl: './top-navbar.component.html',
  styleUrls: ['./top-navbar.component.css'],
})
export class TopNavbarComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('toggleButton1', { static: false }) toggleButton1: ElementRef;
  @ViewChild('menu', { static: false }) menu: ElementRef;
  public imgURL =
    'https://staticprod.laneaxis.com/portal/images/after-login/Profile-squre.png';
  public proImage: any;
  location: Location;
  mobile_menu_visible: any = 0;
  public sidebutton: boolean;
  menuItems: any[];
  public chIcon: any;
  public sortReverse = false;
  public menI: any;
  public userName: any;
  public params: any;
  public newparams: any;
  public recordGet: any;
  public currentRoute: any;
  public userDataForBanner: any;
  public userType: any;
  public emailVerified: any;
  public mxPhone: any;
  public phoneVerified: any;
  public userDetailForRedirect: any;
  public notificationList: any;
  public message: any;
  public labelNameValue: any;
  public totalNotificationCount: any;
  public notifcionsklitor = false;
  public isActive = false;
  public pageNo = 2;
  public page: any;
  public totalPages: any;
  public focus = 0;
  public totalUnreadTabs=0;
  sidebarVisible:any;
  public loading = false;
  public scrollvariable: any;
  public spinnderLoader = false;
  public newnotificationList = [];
  public selectedTab = "bookings";
  public newStatusscroll = false;
  public routeSub: any;
  isMenuOpen = false;
  public subscriptionId = null;
  public skeletonLoad = false;
  public currentDate: any;
  @Input() userNameUpdate: string;
  @Input() userProfileImageUpdate: string;
  public serverPathForUrl: any;
  public cancelRequestCheck: any = null;
  public cancelRequestProfile: any = null;
  public selectedIndex = 'Booking';
  isAllBookingsCounts: boolean = false;
  isAllBookingsCountsForC: boolean = false;
  isAllShipmentCounts: boolean = false;
  isAllShipmentCountsForC: boolean = false;
  isAllBookingCounts: boolean = false;
  public allUnreadNotification=false;
  isAllBookingCountsForC: boolean = false;
  public allShipmentCounts: any;
  public allShipmentCountsForC: any;
  public allBookingCounts: any;
  public allBookingCountsForC: any;
  public userInfo: any;
  public logo:any;
  subscriptionData: any;
  userDetails: any;
  constructor(
    public dialog: MatDialog,
    location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private alertService: AlertService,
    private renderer: Renderer2,
    public sharedService: SharedService,
    public facebookPixelService: FacebookPixelService,
    private deviceService: DeviceDetectorService,
    private scriptService: ScriptService,
  ) {
    this.location = location;
    this.sidebarVisible = false;
    this.sidebutton = false;
    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.menu !== undefined && this.toggleButton1 !== undefined) {
        if (
          !this.toggleButton1.nativeElement.contains(e.target) &&
          !this.menu.nativeElement.contains(e.target)
        ) {
          this.isMenuOpen = false;
        }
      }
    });
  }

  ngOnInit() {
    this.logo = AppSettings.logo;
    this.markReadAlertNotification('');
    if(localStorage.getItem('user_type')){
      this.userType = localStorage.getItem('user_type');
    }else{
      if (this.deviceService.isMobile()){
        if(this.route && this.route.snapshot['_urlSegment']['children']['primary']['segments'][1]["path"]){
          let getUserType = this.route.snapshot['_urlSegment']['children']['primary']['segments'][1]["path"]
          if(getUserType=='carrier' || getUserType=='shipper'){
            this.userType = getUserType.toUpperCase()
            this.userType = localStorage.setItem('user_type',this.userType);
          }
        }
      } 
    }
    this.emailVerified = localStorage.getItem('email_verified');
    this.phoneVerified = localStorage.getItem('phone_verified');
      const isMobile = this.deviceService.isMobile();
        if (isMobile) {
        this.commonService.userData.subscribe((userData) => {
          this.userInfo = userData;
          if(localStorage.getItem('user_type') == 'CARRIER' && this.userInfo.subscription == true) {
            if(this.route && this.route.snapshot['_urlSegment']['children']['primary']['segments'][0]["path"]=='tutorial'){
              this.userType = localStorage.setItem('user_type','CARRIER');
            }else{
              this.router.navigate(['/dashboard/limit-mobile-view']);
            }
          } 
          if(localStorage.getItem('user_type') == 'SHIPPER'){
            if(this.route && this.route.snapshot['_urlSegment']['children']['primary']['segments'][0]["path"]=='tutorial'){
              this.userType = localStorage.setItem('user_type','SHIPPER');
              this.userType = localStorage.setItem('user_type','SHIPPER');
            }else{
              this.router.navigate(['/dashboard/limit-mobile-view']);
            }
          }
          if(localStorage.getItem('user_type') == 'STORAGE'){
            this.router.navigate(['/dashboard/limit-mobile-view']);
          }
       });
    }

    if (this.userType === 'SHIPPER') {
      this.getSubscriptionAlert();

     let userDetail = this.sharedService.getAddress();
      if (userDetail !== undefined) {
        this.userInfoData(userDetail);
      }
    this.commonService.userData.subscribe((userData) => {
       this.userInfoData(userData);
     });
    }

    this.serverPathForUrl = environment.serverPathForUrl;
    this.newparams = {
      limit: 10,
    };
    this.commonService.callToLogout.subscribe((userData) => {
      this.logoutUser();
    });
    this.sharedService.notifyEmailVerification.subscribe(() => {
      this.emailVerified = true;
    });
    this.sharedService.AlertNotifyService.subscribe((userData) => {
      if (userData.name === 'shipment') {
        this.menuItems.forEach((n) => {
          if (n.path === '/shipment/list/inProgress') {
            n.count = userData.counts.toString();
          }
        });
      }
      if (userData.name === 'network') {
        this.menuItems.forEach((n) => {
          if (n.path === '/network/my-network/connected') {
            n.count = userData.counts.toString();
          }
        });
      }
      if (userData.name === 'bidding') {
        this.menuItems.forEach((n) => {
          if (n.path === '/booking/currentBids' || n.path === '/booking/availableToBid' || n.path === '/booking/lane/availableLanes' || n.path === '/booking/lane/postedLanes') {
            n.count = userData.counts.toString();
          }
        });
      }
    });

    this.commonService.allMarkRedBellnotiion.subscribe((userData) => {
      this.totalNotificationCount = '';
    });
    this.commonService.sendTokanUpdate.subscribe((userData) => {
      this.userType = localStorage.getItem('user_type');
      this.menuItems = ROUTES.filter((menuItem) => menuItem);
      this.userName = null;
      this.proImage = null;

      if (localStorage.getItem('user_type') === 'CARRIER') {
      
        this.recordGet = AppSettings.APIsNameArray.CARRIER.GET;
      }
      if (localStorage.getItem('user_type') === 'SHIPPER') {
        this.recordGet = AppSettings.APIsNameArray.SHIPPER.GET;
      }
      if (localStorage.getItem('user_type') === 'STORAGE') {
        this.recordGet = AppSettings.APIsNameArray.COLDSTORAGE.GET;
      }
      this.getProfileCheck();
    });
    this.commonService.profileUpdate.subscribe((userData) => {
      this.getProfileCheck();

    });
    this.commonService.bellNotificationUpdate.subscribe((userData) => {
      this.notifictionList(this.selectedTab);
      this.topBell();
    });
    this.topBell();
    if (localStorage.getItem('access_token') && localStorage.getItem('stripeConenct') !== 'active' && (localStorage.getItem('user_type') === 'CARRIER' || localStorage.getItem('user_type') === 'STORAGE')) {
      this.checkSubStatusConnect();
    } else if (localStorage.getItem('tag') === 'GTFO' && localStorage.getItem('user_type') === 'SHIPPER') {
      this.checkSubStatusConnect();
    }
    this.checkUserMenuProfile();
    if (this.router) {
      this.getProfileCheck();
      let route = this.router.url.split('/');
    
      let newRoute;
      if (this.userType === 'STORAGE') {
        if (route[2]) {
          newRoute = route[2].split('?');
          this.currentRoute = newRoute[0];
        } else {
          newRoute = route[1].split('?');
          this.currentRoute = newRoute[0];
        }
      } else {
        if (route[1]) {
          newRoute = route[1].split('?');
          this.currentRoute = newRoute[0];
        }
      }
      localStorage.setItem('currentRouteUrl', this.router.url);
      if (localStorage.getItem('stripeConenct') !== 'active' && localStorage.getItem('access_token') && (localStorage.getItem('user_type') === 'CARRIER' || localStorage.getItem('user_type') === 'STORAGE')) {
        this.checkSubStatusConnect();
      } else if (localStorage.getItem('tag') === 'GTFO' && localStorage.getItem('user_type') === 'SHIPPER') {
        this.checkSubStatusConnect();
      }
    }
    let that = this;
    this.routeSub = this.router.events.subscribe((event) => {
      if (event) {
        if (event instanceof NavigationEnd) {
          let route = event.url.split('/');
          let newRoute;
          if (this.userType === 'STORAGE') {
            if (route[1] == 'storage-order') {
              newRoute = route[1];
              this.currentRoute = newRoute;
            } else if (route[2]) {
              newRoute = route[2].split('?');
              this.currentRoute = newRoute[0];
            } else {
              newRoute = route[1].split('?');
              this.currentRoute = newRoute[0];
            }
          } else {
            if(route[2]=='profile'){
              newRoute = route[2].split('?');
              this.currentRoute = newRoute[0];
            }else{
              newRoute = route[1].split('?');
              this.currentRoute = newRoute[0];

            }
          }
          this.isActive = false;
          localStorage.setItem('currentRouteUrl', this.router.url);
          if (localStorage.getItem('stripeConenct') !== 'active' && localStorage.getItem('access_token') && (localStorage.getItem('user_type') === 'CARRIER' || localStorage.getItem('user_type') === 'STORAGE')) {
            this.checkSubStatusConnect();
          } else if (localStorage.getItem('tag') === 'GTFO' && localStorage.getItem('user_type') === 'SHIPPER') {
            this.checkSubStatusConnect();
          }
        }
      }
    });
    this.menuItems = ROUTES.filter((menuItem) => menuItem);
  }

  ngAfterViewInit() {
    let notification = {};
    let count = 0;
    this.notifictionList(this.selectedTab);
  }
  changeFirebaseToken() {
    this.sharedService.changeFirebaseToken.next();
  }

    // Function to get user details 
    userInfoData(userDtail) {
      this.userDetails = userDtail;
    }
   

  settingPage(type: any) {
    if (this.userType === 'CARRIER') {
      if (type === 'setting') {
        this.router.navigateByUrl('/carrier/setting/change-password');
      } else {
        this.router.navigateByUrl('/carrier/profile');
      }
    } else if (this.userType === 'SHIPPER') {
      if (type === 'setting') {
        this.router.navigateByUrl('/shipper/setting/change-password');
      } else if (type === 'profile') {
        this.router.navigateByUrl('/shipper/profile');
      }
    } else if (this.userType === 'STORAGE') {
      if (type === 'setting' && localStorage.getItem('locationChek') === '1') {
        this.router.navigateByUrl('/storage/setting/change-password');
      } else if (
        type === 'profile' &&
        localStorage.getItem('locationChek') === '1'
      ) {
        this.router.navigateByUrl('/storage/profile');
      } else if (
        (type === 'profile' || type === 'setting') &&
        localStorage.getItem('locationChek') === '0'
      ) {
        this.router.navigateByUrl('/storage/location/list');
      }
    }
  }
  toggleMenu() {
    this.userDetailForRedirect = this.sharedService.getAddress();
    if ((localStorage.getItem('user_type') === 'SHIPPER') && (this.userDetailForRedirect?.phyAddressLine1 && this.userDetailForRedirect?.phyCity && this.userDetailForRedirect?.phyState && this.userDetailForRedirect?.phyZip && this.userDetailForRedirect?.equipmentType
      && this.userDetailForRedirect?.preferredRegions && this.userDetailForRedirect?.signatureFont && this.userDetailForRedirect?.profileImage && this.userDetailForRedirect?.contractDocument)) 
    {
       this.isMenuOpen = !this.isMenuOpen;
       this.selectedTab = "bookings";
       if (this.isMenuOpen) {
         this.notifictionList(this.selectedTab);
       }
    } 

    if ((localStorage.getItem('user_type') === 'CARRIER') && (this.userDetailForRedirect?.phyAddressLine1 && this.userDetailForRedirect?.phyCity && this.userDetailForRedirect?.phyState && this.userDetailForRedirect?.phyZip && this.userDetailForRedirect?.equipmentType
      && this.userDetailForRedirect?.preferredRegions && this.userDetailForRedirect?.signatureFont && this.userDetailForRedirect?.profileImage && this.userDetailForRedirect?.coverImage && this.userDetailForRedirect?.insuranceAmount && this.userDetailForRedirect?.insuranceCompanyName && this.userDetailForRedirect?.insuranceCompanyEmail))
    {
      this.isMenuOpen = !this.isMenuOpen;
      this.selectedTab = "bookings";
      if (this.isMenuOpen) {
        this.notifictionList(this.selectedTab);
      }
    } 

    if ((localStorage.getItem('user_type') === 'CARRIER') && this.userDetailForRedirect?.subscription === null){
      this.isMenuOpen = false;
    }

    if ((localStorage.getItem('user_type') === 'SHIPPER') && this.userDetailForRedirect?.subscription === null){
      this.isMenuOpen = false;
    }
    
    if((localStorage.getItem('user_type') === 'STORAGE') ){
      this.isMenuOpen = !this.isMenuOpen;
      this.selectedTab = "bookings";
      if(this.isMenuOpen) {
      this.notifictionList(this.selectedTab);
       }
    }
  }

  generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }
  timeout: any;
  ngOnDestroy() {
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
    clearTimeout(this.timeout);
  }
  checkUserMenuProfile() {
    this.userType = localStorage.getItem('user_type');
    this.menuItems = ROUTES.filter((menuItem) => menuItem);

    if (localStorage.getItem('user_type') === 'CARRIER') {
      this.recordGet = AppSettings.APIsNameArray.CARRIER_REGISTRATION.GET;
      if (!localStorage.getItem('userName')) {
        this.getProfileCheck();
      } else {
        this.userName = localStorage.getItem('userName');
        this.proImage = localStorage.getItem('userProfileImage');
      }
    } else if (localStorage.getItem('user_type') === 'SHIPPER') {
      this.recordGet = AppSettings.APIsNameArray.SHIPPER.GET;
      if (!localStorage.getItem('userName')) {
        this.getProfileCheck();
      } else {
        this.userName = localStorage.getItem('userName');
        this.proImage = localStorage.getItem('userProfileImage');
      }
      
    } else if (localStorage.getItem('user_type') === 'STORAGE') {
      this.recordGet = AppSettings.APIsNameArray.COLDSTORAGE.GET;
      if (!localStorage.getItem('userName')) {
        this.getProfileCheck();
      } else {
        this.userName = localStorage.getItem('userName');
        this.proImage = localStorage.getItem('userProfileImage');
      }
    }
  }
  redirectMainPage() {

    this.userDetailForRedirect = this.sharedService.getAddress();
    if (localStorage.getItem('user_type') === 'SHIPPER' && this.userDetailForRedirect?.subscription == null) {
      this.router.navigateByUrl('shipper/subscription-plan');
    } else if (localStorage.getItem('user_type') === 'SHIPPER') {
      this.router.navigateByUrl('/shipper/profile');
    } else if (localStorage.getItem('user_type') === 'CARRIER' && this.userDetailForRedirect?.subscription == null) {
      this.router.navigateByUrl('carrier/subscription-plan');
    } else if (localStorage.getItem('user_type') === 'CARRIER') {
      this.router.navigateByUrl('/carrier/profile');
    } else if (localStorage.getItem('user_type') === 'STORAGE') {
      this.router.navigateByUrl('/storage/location/list');
    }

    if (localStorage.getItem('user_type') === 'before_login') {
      this.sharedService.configLoginCallUrl.next();
    }
  }

  setActiveMenu(name: any) {
    this.userDetailForRedirect = this.sharedService.getAddress();
    if (
      name === 'storage-order' &&
      this.userType === 'STORAGE' &&
      localStorage.getItem('locationChek') === '0'
    ) {
      this.currentRoute = 'location';
      this.router.navigateByUrl('/storage/location/list');
      this.markReadAlertNotification('');
    }
    if (localStorage.getItem('user_type') == 'CARRIER') {
      if ((!this.userDetailForRedirect?.phyAddressLine1 || !this.userDetailForRedirect?.phyCity || !this.userDetailForRedirect?.phyState || !this.userDetailForRedirect?.phyZip || !this.userDetailForRedirect?.equipmentType
          || !this.userDetailForRedirect?.preferredRegions || !this.userDetailForRedirect?.signatureFont || !this.userDetailForRedirect?.profileImage || !this.userDetailForRedirect?.coverImage || !this.userDetailForRedirect?.insuranceAmount || !this.userDetailForRedirect?.insuranceCompanyName
         || !this.userDetailForRedirect?.insuranceCompanyEmail) && this.userDetailForRedirect?.subscription === null)
      {
         this.router.navigateByUrl('carrier/subscription-plan');
         this.markReadAlertNotification('');
      } 
      if ((!this.userDetailForRedirect?.phyAddressLine1 || !this.userDetailForRedirect?.phyCity || !this.userDetailForRedirect?.phyState || !this.userDetailForRedirect?.phyZip || !this.userDetailForRedirect?.equipmentType
            || !this.userDetailForRedirect?.preferredRegions || !this.userDetailForRedirect?.signatureFont || !this.userDetailForRedirect?.profileImage || !this.userDetailForRedirect?.coverImage || !this.userDetailForRedirect?.insuranceAmount || !this.userDetailForRedirect?.insuranceCompanyName
           || !this.userDetailForRedirect?.insuranceCompanyEmail) && this.userDetailForRedirect?.subscription != null)
      {
         this.router.navigateByUrl('/carrier/onboarding');
         this.markReadAlertNotification('');
      } 

      else if (name === 'network') {
        this.markReadAlertNotification('connected');
      }
      else if (name === 'booking') {
        this.markReadAlertNotification('newPrivateShipment');
      } else if (name === 'shipment') {
        this.markReadAlertNotification('inProgress');
      } 
      else {
        this.markReadAlertNotification('');
      }
    } else if (localStorage.getItem('user_type') == 'SHIPPER') {
      if (this.userDetailForRedirect?.subscription === null)
       {
         this.router.navigateByUrl('shipper/subscription-plan');
       } 

      if ((!this.userDetailForRedirect?.phyAddressLine1 || !this.userDetailForRedirect?.phyCity || !this.userDetailForRedirect?.phyState || !this.userDetailForRedirect?.phyZip || !this.userDetailForRedirect?.equipmentType
        || !this.userDetailForRedirect?.preferredRegions || !this.userDetailForRedirect?.signatureFont || !this.userDetailForRedirect?.profileImage 
        || !this.userDetailForRedirect?.contractDocument) && this.userDetailForRedirect?.subscription != null)
      {
          this.router.navigateByUrl('/shipper/onboarding');
      } 
      if (name === 'network') {
        this.markReadAlertNotification('connected');
      }else if (name === 'booking') {
        this.markReadAlertNotification('currentBids');
      } else if (name === 'shipment') {
        this.markReadAlertNotification('inProgress');
      }
      else if ( name === 'profile') {
        this.markReadAlertNotification('');
      }else {
        this.markReadAlertNotification('');
      }
      
    } else {
      this.checkUserMenuProfile();
      this.currentRoute = name;
      if (localStorage.getItem('user_type') === 'before_login') {
        this.sharedService.configLoginCallUrl.next();
      }
      this.checkUserMenuProfile();
      this.currentRoute = name;
      if (localStorage.getItem('user_type') === 'before_login') {
        this.sharedService.configLoginCallUrl.next();
      }
    }
  }
  markReadAlertNotification(value: any) {
    let carrier = localStorage.getItem('user_type') === 'CARRIER';
    let shipper = localStorage.getItem('user_type') === 'SHIPPER';
    if(localStorage.getItem('user_type')=='CARRIER' || localStorage.getItem('user_type')=='SHIPPER'){
    this.commonService.putAlertNotificationOnRead(value).subscribe((data) => {
      if (data.response) {
        let allNetworkCount = data.response.connected + data.response.invite; 
        this.sharedService.AlertNotifyService.next({ name: 'network', counts: allNetworkCount });
        if (shipper) {
          this.allBookingCounts = data.response.currentBid + data.response.confirmedBids + data.response.offerRateConfirmed;
          this.allShipmentCounts = data.response.assign + data.response.assignToCarrier + data.response.create + data.response.accept + data.response.navigateToPickup + data.response.delivered + data.response.cancel + data.response.dispute +  data.response.expired;
          this.sharedService.AlertNotifyService.next({ name: 'bidding', counts: this.allBookingCounts });
          this.sharedService.AlertNotifyService.next({ name: 'shipment', counts: this.allShipmentCounts });
        } else if (carrier) {
          this.allBookingCountsForC = data.response.currentBid + data.response.awardedBids + data.response.offerRateConfirmed + data.response.newPrivateShipment + data.response.newOfferRate;
          this.allShipmentCountsForC = data.response.assign + data.response.assignToCarrier + data.response.create + data.response.accept + data.response.navigateToPickup + data.response.delivered + data.response.cancel + data.response.dispute + data.response.expired;
          this.sharedService.AlertNotifyService.next({ name: 'bidding', counts: this.allBookingCountsForC });
          this.sharedService.AlertNotifyService.next({ name: 'shipment', counts: this.allShipmentCountsForC });
        }
      } 
    });
  }
  }

  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    var tik = titlee.slice(1);
    var newStr = tik.replace('-', ' ');
    if (newStr) {
      return newStr.slice(0).toUpperCase();
    }
    return 'Dashboard';
  }

  sideMenuClick() {
    this.userDetailForRedirect = this.sharedService.getAddress();
    if ((localStorage.getItem('user_type') === 'SHIPPER') && this.userDetailForRedirect?.subscription === null)
     {
       this.router.navigateByUrl('shipper/subscription-plan');
     } 

    if ((localStorage.getItem('user_type') === 'SHIPPER') && (!this.userDetailForRedirect?.phyAddressLine1 || !this.userDetailForRedirect?.phyCity || !this.userDetailForRedirect?.phyState || !this.userDetailForRedirect?.phyZip || !this.userDetailForRedirect?.equipmentType
      || !this.userDetailForRedirect?.preferredRegions || !this.userDetailForRedirect?.signatureFont || !this.userDetailForRedirect?.profileImage 
      || !this.userDetailForRedirect?.contractDocument) && this.userDetailForRedirect?.subscription != null) 
    {
       this.router.navigateByUrl('/shipper/onboarding');
    } 

    if ((localStorage.getItem('user_type') === 'CARRIER') && (!this.userDetailForRedirect?.phyAddressLine1 || !this.userDetailForRedirect?.phyCity || !this.userDetailForRedirect?.phyState || !this.userDetailForRedirect?.phyZip || !this.userDetailForRedirect?.equipmentType
      || !this.userDetailForRedirect?.preferredRegions || !this.userDetailForRedirect?.signatureFont || !this.userDetailForRedirect?.profileImage ||  !this.userDetailForRedirect?.coverImage ||  !this.userDetailForRedirect?.insuranceAmount || !this.userDetailForRedirect?.insuranceCompanyName 
       || !this.userDetailForRedirect?.insuranceCompanyEmail) && this.userDetailForRedirect?.subscription === null)
    {
      this.router.navigateByUrl('carrier/subscription-plan');
    } 

    if ((localStorage.getItem('user_type') === 'CARRIER') && (!this.userDetailForRedirect?.phyAddressLine1 || !this.userDetailForRedirect?.phyCity || !this.userDetailForRedirect?.phyState || !this.userDetailForRedirect?.phyZip || !this.userDetailForRedirect?.equipmentType
      || !this.userDetailForRedirect?.preferredRegions || !this.userDetailForRedirect?.signatureFont || !this.userDetailForRedirect?.profileImage ||  !this.userDetailForRedirect?.coverImage ||  !this.userDetailForRedirect?.insuranceAmount || !this.userDetailForRedirect?.insuranceCompanyName 
       || !this.userDetailForRedirect?.insuranceCompanyEmail) && this.userDetailForRedirect?.subscription != null)
    {
      this.router.navigateByUrl('/carrier/onboarding');
    } 
  }

  logoutUser() {
    // if (localStorage.getItem('user_type') == 'CARRIER') {
    //   (window as any).fbq.disablePushState = true; //not recommended, but can be done
    // }

    if (localStorage.getItem('uniqueId') !== null && localStorage.getItem('fcmToken') !== null) {
      this.deleteDeviceTokan();
    }

    this.userNameUpdate = '';
    this.userName = '';
    this.userType = '';
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigateByUrl('/');
    this.sharedService.configLoginCallUrl.next();
    this.sharedService.setAddress(null);
  }

  deleteDeviceTokan() {
    let token;
    if (localStorage.getItem('lastfcmToken')) {
      token = localStorage.getItem('lastfcmToken');
    } else {
      token = localStorage.getItem('fcmToken');
    }
    if (token) {
      let value = {
        deviceId: localStorage.getItem('uniqueId'),
        token: localStorage.getItem('fcmToken'),
      };
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.NOTIFICATION.DEVICETOKENDELETE,
        uri: '',
        postBody: value,
      };
      this.commonService.post(APIparams).subscribe(
        (success) => {
          if (success.success === true) {
          } else if (success.success === false) {
            this.alertService.showNotificationMessage(
              'danger',
              'bottom',
              'right',
              'txt_d',
              'cancel',
              'Remove Tokan',
              success.message
            );
          }
        },
        (error) => {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_g',
            'error',
            'Unexpected Error',
            AppSettings.ERROR
          );
        }
      );

    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(FeedbackComponent, {
      width: '500px',
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
    });
    dialogRef.afterClosed().subscribe((result) => {

    });
  }

  /*get Driver name */
  checkSubStatusConnect() {
    if (this.cancelRequestCheck) {
      this.cancelRequestCheck.unsubscribe();
    }
    this.checkSubStatus();
  }

  checkSubStatus(params = {}) {
    let uri = null;
    uri = this.commonService.getAPIUriFromParams({});
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.NEWPAYMENT.CHECKSTATUS,
      uri: uri,
    };
    this.cancelRequestCheck = this.commonService
      .getList(APIparams)
      .subscribe((ServerRes) => {
        if (ServerRes.success === true) {
          this.cancelRequestCheck = null;
          if (localStorage.getItem('user_type') === 'CARRIER' || localStorage.getItem('user_type') === 'STORAGE') {
            if (ServerRes.response.connectAccountStatus === true) {
              localStorage.setItem('stripeConenct', 'active');
            } else if (ServerRes.response.connectAccountStatus === false) {
              localStorage.setItem('stripeConenct', 'Pending');
            } else {
              localStorage.setItem('stripeConenct', '');
            }
          } else if (localStorage.getItem('user_type') === 'SHIPPER' && localStorage.getItem('tag') === 'GTFO') {
            let numberOfDay: any = moment.unix(ServerRes.response.current_period_end);
            let getNewDate = moment(new Date(numberOfDay)).format('YYYY-MM-DD HH:mm');
            localStorage.setItem('checkDate', getNewDate);
            if (ServerRes.response.subscriptionStatus === 'active') {
              localStorage.setItem('premium', '1');
            } else {
              localStorage.setItem('premium', '0');
              this.logoutUser();
            }
          }
          this.commonService.updateConnectAccount.next(ServerRes.response);

        }
      });
  }
  getProfileCheck() {
    if (this.cancelRequestProfile) {
      this.cancelRequestProfile.unsubscribe();
    }
    this.getProfile();
  }

  redirectToSubscription(){
    if (localStorage.getItem('user_type') === 'CARRIER') {
      this.router.navigate(['/carrier/subscription-overview'])
    }
    if (localStorage.getItem('user_type') === 'SHIPPER') {
      this.router.navigate(['/shipper/subscription-overview'])
    }
  }

  /*get Driver name */
  getProfile(params = {}) {
    this.sharedService.setAddress(null);
    let uri = '';
    if (this.params) uri = this.commonService.getAPIUriFromParams(this.params);
    let APIparams = {
      apiKey: this.recordGet,
      uri: uri,
    };
    this.cancelRequestProfile = this.commonService
      .getList(APIparams)
      .subscribe((ServerRes) => {
        this.sharedService.setAddress(null);
        this.commonService.afteLoginUpdateLoader.next();
        if (ServerRes.success === true) {
          this.cancelRequestProfile = null;
          this.userDataForBanner = ServerRes.response;
          this.sharedService.setAddress(ServerRes.response);
          if (
            ServerRes.response &&
            ServerRes.response.phyCity &&
            ServerRes.response.phyState
          ) {
            const SourceLocation =
              ServerRes.response.phyCity + ',' + ServerRes.response.phyState;
            this.sharedService.setUserLocationType(SourceLocation);
          }
          this.commonService.userData.next(ServerRes.response);
          if (
            localStorage.getItem('user_type') === 'CARRIER' ||
            localStorage.getItem('user_type') === 'STORAGE'
          ) {
            localStorage.setItem('email', ServerRes.response.email);
            localStorage.setItem('isMLWallet', ServerRes.response.isMLWallet);
            localStorage.setItem('lane', ServerRes.response.lane);
            localStorage.setItem(
              'equipmentType',
              ServerRes.response.equipmentType
            );
            localStorage.setItem('industries', ServerRes.response.industries);
          }

          if (localStorage.getItem('user_type') === 'CARRIER') {
            localStorage.setItem('userName', ServerRes.response.legalName);
            if (ServerRes.response.coiDoc && !ServerRes.response.coiExpired) {
              localStorage.setItem(
                'certificateOfInsurance',
                ServerRes.response.coiDoc
              );
            } else if (
              (ServerRes.response.coiDoc && ServerRes.response.coiExpired) ||
              (!ServerRes.response.coiDoc && ServerRes.response.coiExpired)
            ) {
              localStorage.setItem('certificateOfInsurance', 'null');
            }
            this.userName = localStorage.getItem('userName');
          }
          if (localStorage.getItem('user_type') === 'SHIPPER') {
            localStorage.setItem('userName', ServerRes.response.companyName);
            localStorage.setItem(
              'contractDocument',
              ServerRes.response.contractDocument
            );
            this.userName = localStorage.getItem('userName');
          }
          if (localStorage.getItem('user_type') === 'STORAGE') {
            localStorage.setItem('userName', ServerRes.response.companyName);
            this.userName = localStorage.getItem('userName');
          }
          if (ServerRes.response.profileImage) {
            localStorage.setItem(
              'userProfileImage',
              ServerRes.response.profileImage
            );
            this.proImage = localStorage.getItem('userProfileImage');
          }
        }
      });
  }
  getConfig() {
    let uri = null;
    let newParams = {};
    if (newParams) uri = this.commonService.getAPIUriFromParams(newParams);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.OTHER.CONFIG,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      if (ServerRes.success === true) {
        this.sharedService.setConfig(ServerRes.response);
        this.commonService.configData.next(ServerRes.response);
      }
    });
  }

  clickRefresh() {
    this.notifictionList(this.selectedTab);
  }
  onScroll() {
    if (this.page !== this.totalPages) {
      this.page = this.page + 1;
      this.spinnderLoader = true;
      this.getMoreNotification();
    }
  }

  getAPIParamNoti() {
    let APIparams, params;
    params = { limit: 10, page: this.page, tabType: this.selectedTab };
    APIparams = {
      apiKey: AppSettings.APIsNameArray.NOTIFICATION.BEllNOTIFICATION,
      uri: this.commonService.getAPIUriFromParams(params),
    };
    return APIparams;
  }

  getAPIParamNotify() {
    let APIparams, params;
    params = { limit: 10, page: this.page };
    APIparams = {
      apiKey: AppSettings.APIsNameArray.NOTIFICATION.BEllNOTIFICATION,
      uri: this.commonService.getAPIUriFromParams(params),
    };
    return APIparams;
  }

  topBell() {
    this.notifcionsklitor = false;
    this.page = 1;
    var APIparams = this.getAPIParamNotify();
    this.newnotificationList = []
    this.commonService.getList(APIparams).subscribe(
      (ServerRes) => {
        this.notifcionsklitor = true;
        if (
          ServerRes.response?.bellNotifications &&
          ServerRes.response?.bellNotifications?.length > 0
        ) {
          for (let notiIndex in ServerRes.response.bellNotifications) {
            ServerRes.response.bellNotifications[notiIndex].createdAt =
              new Date(
                ServerRes.response.bellNotifications[notiIndex].createdAt +
                ' ' +
                'UTC'
              );
          }
          this.newnotificationList = ServerRes.response.bellNotifications;
          this.totalNotificationCount = ServerRes.response.unreadNotifications;
          this.totalUnreadTabs=ServerRes.response.unreadTabs
          this.allUnreadNotification =  Object.values(ServerRes.response.unreadTabs).includes(1)
          this.totalPages = ServerRes.response.totalPages;
        } else {
          this.notificationList = [];
          this.totalNotificationCount = 0;
          this.notifcionsklitor = true;
        }
      },
      (error) => {
        this.notificationList = [];
        this.totalNotificationCount = 0;
        this.notifcionsklitor = true;
      }
    );
  };

   notifictionList(tabType: any) {
    if (tabType.length < 10) {
      this.selectedTab = tabType.toLowerCase();
      this.notifcionsklitor = false;
      this.page = 1;
      var APIparams = this.getAPIParamNoti();
      this.newnotificationList = []
      this.commonService.getList(APIparams).subscribe(
        (ServerRes) => {
          this.allUnreadNotification =  Object.values(ServerRes.response.unreadTabs).includes(1)
          this.totalUnreadTabs=ServerRes.response.unreadTabs
          this.notifcionsklitor = true;
          if (
            ServerRes.response?.bellNotifications &&
            ServerRes.response?.bellNotifications?.length > 0
          ) {
            for (let notiIndex in ServerRes.response.bellNotifications) {
              ServerRes.response.bellNotifications[notiIndex].createdAt =
                new Date(
                  ServerRes.response.bellNotifications[notiIndex].createdAt +
                  ' ' +
                  'UTC'
                );
            }
            this.newnotificationList = ServerRes.response.bellNotifications;
            this.totalPages = ServerRes.response.totalPages;
            this.totalNotificationCount = ServerRes.response.unreadNotifications;

            var obj = {
              eventName: 'refresh_notification',
              event_category: 'bellNotification',
              userType: localStorage.getItem('user_type'),
              EmailID: localStorage.getItem('email'),
            };
              this.sharedService.track(obj);
          } else {
            this.notificationList = [];
            this.totalNotificationCount = 0;
            this.notifcionsklitor = true;
          }
        },
        (error) => {
          this.notificationList = [];
          this.totalNotificationCount = 0;
          this.notifcionsklitor = true;
        }
      );
    }
   
  }

  getMoreNotification() {
    var APIparams = this.getAPIParamNoti();
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      if (
        ServerRes.response.bellNotifications &&
        ServerRes.response.bellNotifications.length > 0
      ) {
        this.spinnderLoader = false;
        for (let v = 0; v < ServerRes.response.bellNotifications.length; v++) {
          if (ServerRes.response.bellNotifications[v])
            this.newnotificationList.push(
              ServerRes.response.bellNotifications[v]
            );
        }
      }
    });
  }

  notificationAction(id, userId, status) {
    if (this.userType == 'SHIPPER' && status == 'CONNECT' && this.subscriptionData.subscriptionCreditHistory.shipperConnection == 0) {
      this.enterPricePlan();
    } else {
    var APIparams = {
      apiKey: AppSettings.APIsNameArray.MANAGE_CONNECTION[status],
      uri: '',
      postBody: {
        userPkId: userId,
        bellId: id,
      },
    };
    this.commonService.post(APIparams).subscribe((ServerRes) => {
      if (ServerRes.success === true) {
        for (let notiIndex in this.newnotificationList) {
          if (
            this.newnotificationList[notiIndex].id &&
            this.newnotificationList[notiIndex].id === id
          ) {
            this.newnotificationList[notiIndex].data.actionTaken = 0;
          }
        }
        this.markReadAlertNotification('');
        if (status === 'CONNECT') {
          this.callGoogleAnalytics(userId);
          this.sharedService.NetworkNotifyService.next("Accepted");
          this.clickRefresh();
          this.alertService.showNotificationMessage(
            'success',
            'bottom',
            'right',
            'txt_s',
            'check_circle',
            'Notification',
            ServerRes.message,
            ServerRes.isInvitationReverted
          );
        } else {
          this.alertService.showNotificationMessage(
            'success',
            'bottom',
            'right',
            'txt_s',
            'check_circle',
            'Notification',
            ServerRes.message,
            ServerRes.isInvitationReverted
          );
        }
      } else {
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_d',
          'cancel',
          'Notification',
          ServerRes.message,
          ServerRes.isInvitationReverted
        );
      }
    });
  }
  }

  enterPricePlan(){
    if (this.userDetails && this.userDetails.isEnterprisePlanRequested) {
      const dialogRef = this.dialog.open(EnterpricePopupComponent, {
        disableClose: true,
        backdropClass: AppSettings.backdropClass,
        width: AppSettings.popWidth,
        data: { openPop: 'enterprisePlanRequestedPopup'},
      });
      dialogRef.afterClosed().subscribe((result) => {
        if(result?.event=='Ok'){
        }
      });
    } 
    else { 
    if(this.userDetails.phone?.slice(0,3)=='+52' || this.userDetails.phone?.slice(0,3)=='+91'){
      this.mxPhone=this.userDetails.phone?.slice(4,14)
    }
    else if(this.userDetails.phone?.slice(0,2)=='+1'){
      this.mxPhone=this.userDetails.phone?.slice(3,13)
    }
    const dialogRef = this.dialog.open(EnterpricePopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width:'500px',
      height:'calc(100vh - 100px)',
      data: { openPop: 'enterPrice', userData:this.userDetails,fullName:this.userDetails.firstName + ' ' + this.userDetails.lastName,companyName:this.userDetails.companyName,email:this.userDetails.email,phone:this.mxPhone,countryCode:this.userDetails.countryCode,ftlVolume:this.userDetails.ftlVolume },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if(result?.event=='Ok'){
        this.getShipperRecord();
      }
    });
   }

  }

  // Functions to get subscription data
   getSubscriptionAlert() {
     this.commonService.getSubscriptionAlert().subscribe((serverRes) => {
       this.subscriptionData =  serverRes.response;
     });
   }

  // Get shipper details from API
   getShipperRecord() {
     let uri = null;
     let newParams = {};
     //get dynamic URI for APIs
     if (newParams) uri = this.commonService.getAPIUriFromParams(newParams);
     let APIparams = {
       apiKey: AppSettings.APIsNameArray.SHIPPER.GET,
       uri: uri,
     };
     this.commonService.getList(APIparams).subscribe((ServerRes) => {
       if (ServerRes.success === true) {
         this.userDetails = ServerRes.response;
       } 
     });
   }

  markAllRed() {
    this.notifcionsklitor = false;
    let uri = null;
    uri = this.commonService.getAPIUriFromParams({});
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.NOTIFICATION.ALLMARKREAD,
      postBody: { 'tabType': this.selectedTab.toLowerCase() },
      uri: uri,
    };
    this.commonService.put(APIparams).subscribe(
      (ServerRes) => {
        if (ServerRes.success === true) {
          this.totalNotificationCount = '';
          for (let notiIndex in this.newnotificationList) {
            this.newnotificationList[notiIndex].isRead = 1;
          }
          this.totalNotificationCount = '';
          this.notifcionsklitor = true;
          var obj = {
            eventName: 'mark_all_read',
            event_category: 'bellNotification',
            userType: localStorage.getItem('user_type'),
            EmailID: localStorage.getItem('email'),
          };
          this.sharedService.track(obj);
          this.notifictionList(this.selectedTab);
        } else {
          this.notifcionsklitor = true;
        }
      },
      (error) => {
        this.notifcionsklitor = true;
      }
    );
    // this.notifictionList(this.selectedTab);
  }

  readFun(Id: any, isRead: any, urlRedirect: any) {
    if (urlRedirect === '/carrier/transaction/shipments') {
      this.router.navigate(['/carrier/transaction/shipments'], {queryParams: {
        tabIndex: "0",
        orderDir: "DESC"
      }});
    } else if (urlRedirect === '/carrier/transaction/buy-loads') {
      this.router.navigate(['/carrier/transaction/buy-loads'], {queryParams: {
        orderDir: "DESC",
        tabIndex: "1"
      }});
    } else if (urlRedirect === '/carrier/transaction/subscription') {
      this.router.navigate(['/carrier/transaction/subscription'], {queryParams: {
        orderDir: "DESC",
        tabIndex: "2"
      }});
    } else if (urlRedirect === '/carrier/transaction/additional-credit') {
      this.router.navigate(['/carrier/transaction/additional-credit'], {queryParams: {
        orderDir: "DESC",
        tabIndex: "3"
      }});
    } else {
       this.router.navigate([urlRedirect]);
    }

    this.isMenuOpen = false;
    // this.router.navigate([urlRedirect]);
    if (isRead === 0) {
      this.isActive = false;
      let postBody = {
        notiId: Id,
      };
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.NOTIFICATION.NOTIREAD,
        uri: '',
        postBody: postBody,
      };
      this.commonService.put(APIparams).subscribe((success) => {
        if (success.success === true) {
          this.notifictionList(this.selectedTab);
        } else if (success.success === false) {
        }
      });
    }
  }

  async getNotifiction(params = {}) {
    this.notifcionsklitor = false;
    let uri = null;
     this.newparams ={
     limit :10,
    }
      uri = this.commonService.getAPIUriFromParams(this.newparams);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.NOTIFICATION.BEllNOTIFICATION,
      uri: uri,
    };
     this.commonService.getList(APIparams).subscribe(
      (ServerRes) => {
        if (ServerRes.success === true) {
          this.notificationList = ServerRes.response.bellNotifications;
          for (let notiIndex in this.notificationList) {
            this.notificationList[notiIndex].createdAt = new Date(
              this.notificationList[notiIndex].createdAt + ' ' + 'UTC'
            );
          }
         
          this.totalNotificationCount = ServerRes.response.unreadTabs;
          if (ServerRes.response.bellNotifications.length > 0) {
            this.scrollvariable = false;
          } else {
            this.scrollvariable = true;
          }
          if (this.newparams.page > 1) {
            this.newnotificationList = this.newnotificationList.concat(
              this.notificationList
            );
            this.pageNo += 1;
            this.focus += 7;
            this.spinnderLoader = false;
            setTimeout(() => this.setScrollview(this.focus), 100);
          } else {
            this.spinnderLoader = false;
            this.newnotificationList = this.notificationList;
            this.notifcionsklitor = true;
          }
        } else {
          this.newnotificationList = [];
          this.totalNotificationCount = '';
          this.notifcionsklitor = true;
          this.scrollvariable = false;
        }
      },
      (error) => {
        this.newnotificationList = [];
        this.totalNotificationCount = '';
        this.notifcionsklitor = true;
        this.scrollvariable = false;
      }
    );
  
  }
  setScrollview(focus : any) {
    if (focus !== null) {
      var elmnt = document.getElementById('setnotification_' + this.focus);
      if (elmnt !== null) {
        elmnt.scrollIntoView();
      }
    }
  }
  getInitials(name) {
    let acronym;
    let specialChar;
    let countLength;
    if (name) {
      acronym = name
        .split(/\s/)
        .reduce((response, word) => (response += word.slice(0, 1)), '');
      countLength = acronym.length;
      if (countLength === 1) {
        acronym = name.substr(0, 2);
      } else if (countLength >= 2) {
        specialChar = acronym.substr(2, 1);
        acronym = acronym.substr(0, 2).replace(/[^\w\s]/gi, specialChar);
      }
    } else acronym = '';
    return acronym;
  }

  callGoogleAnalytics(userId) {
    this.params = {
      id: userId,
    };
    const uri = this.commonService.getAPIUriFromParams(this.params);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.MANAGE_CONNECTION.GETPROFILE,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      if (ServerRes.success === true) {
        let targetLocation = '-';
        if (
          ServerRes.response &&
          ServerRes.response.phyCity &&
          ServerRes.response.phyState
        ) {
          targetLocation =
            ServerRes.response.phyCity + ',' + ServerRes.response.phyState;
        }
        let name;
        if (ServerRes.response.companyName) {
          name = ServerRes.response.companyName;
        }
        if (ServerRes.response.legalName) {
          name = ServerRes.response.legalName;
        }
        var obj = {
          eventName: 'network_accept_request',
          event_category: 'Network',
          Occurence: 'bell_notification',
          userType: localStorage.getItem('user_type'),
          EmailID: localStorage.getItem('email'),
          dotNumber: ServerRes.response.dotNumber
            ? ServerRes.response.dotNumber
            : '-',
          TargetID: name,
          TargetLocation: targetLocation,
          SourceLocation: this.sharedService.getUserLocationType(),
        };
        this.sharedService.track(obj);
      }
    });
  }
}

export const ROUTES: RouteInfo[] = [
  {
    path: 'tutorial/carrier/booking/bid-on-lane',
    title: 'How-To Guides',
    name: 'CARRIER',
    icon: '',
    icon_selected: '',
    class: 'tutorial',
    id: 'tutorial',
    subMenu: [],
    count: ''
  },

  {
    path: '/network/my-network/connected',
    title: 'Network',
    name: 'CARRIER',
    icon: '',
    icon_selected: '',
    class: 'network',
    id: 'network',
    subMenu: [],
    count: ''
  },
  {
    path: '/booking/lane/availableLanes',
    title: 'Bookings',
    name: 'CARRIER',
    icon: '',
    icon_selected: '',
    class: 'Booking',
    id: 'booking',
    subMenu: [],
    count: ''
  },
  {
    path: '/shipment/list/inProgress',
    title: 'Shipments',
    name: 'CARRIER',
    icon: '',
    icon_selected: '',
    class: 'Shipments',
    id: 'shipment',
    subMenu: [],
    count: ''
  },
  {
    path: ('/bid-quote-assistant'),
    title: 'Bid/Quote Assistant',
    name: 'CARRIER',
    icon: '',
    icon_selected: '',
    class: 'bid-quote-assistant',
    id: 'bid-quote-assistant',
    subMenu: [],
    count: ''
  },
  {
    path: '/driver/list/All',
    title: 'Drivers',
    name: 'CARRIER',
    icon: '',
    icon_selected: '',
    class: 'driver',
    id: 'driver',
    subMenu: [],
    count: ''
  },

  {
    path: '/messages/active_shipment/All',
    title: 'Messages',
    name: 'CARRIER',
    icon: '',
    icon_selected: '',
    class: 'messages',
    id: 'messages',
    subMenu: [],
    count: ''
  },
  {
    path: '/carrier/profile',
    title: 'Profile',
    name: 'CARRIER',
    icon: '',
    icon_selected: '',
    class : 'profile',
    id : 'profile',
    subMenu: [],
    count: ''
  },
  {
    path: 'tutorial/carrier/booking/create-a-lane',
    title: 'How-To Guides',
    name: 'SHIPPER',
    icon: '',
    icon_selected: '',
    class: 'tutorial',
    id: 'tutorial',
    subMenu: [],
    count: ''
  },
  {
    path: '/network/my-network/connected',
    title: 'Network',
    name: 'SHIPPER',
    icon: '',
    icon_selected: '',
    class: 'network',
    id: 'network',
    subMenu: [],
    count: ''
  },

  {
    path: '/booking/lane/postedLanes',
    title: 'Bookings',
    name: 'SHIPPER',
    icon: '',
    icon_selected: '',
    class: 'Booking',
    id: 'booking',
    subMenu: [],
    count: ''
  },
  {
    path: '/shipment/list/inProgress',
    title: 'Shipments',
    name: 'SHIPPER',
    icon: '',
    icon_selected: '',
    class: 'Shipments',
    id: 'shipment',
    subMenu: [],
    count: ''
  },
  {
    path: '/messages/active_shipment/All',
    title: 'Messages',
    name: 'SHIPPER',
    icon: '',
    icon_selected: '',
    class: 'messages',
    id: 'messages',
    subMenu: [],
    count: '0'
  },
  {
    path: '/shipper/profile',
    title: 'Profile',
    name: 'SHIPPER',
    icon: '',
    icon_selected: '',
    class : 'profile',
    id : 'profile',
    subMenu: [],
    count: ''
  },
  {
    path: '/storage-order/list/orders',
    title: 'Storage Orders',
    name: 'STORAGE',
    icon: '',
    icon_selected: '',
    class: 'storage-order',
    id: 'storage-order',
    subMenu: [],
    count: '0'
  },
  {
    path: '/storage/location/list',
    title: 'Location',
    name: 'STORAGE',
    icon: '',
    icon_selected: '',
    class: 'location',
    id: 'location',
    subMenu: [],
    count: '0'
  },
  {
    path: '/storage/profile',
    title: 'Profile',
    name: 'STORAGE',
    icon: '',
    icon_selected: '',
    class: 'profile',
    id: 'profile',
    subMenu: [],
    count: '0'
  },

  // {
  //   path: '/storage-order/list/orders',
  //   title: 'Storage Orders',
  //   name: 'SHIPPER',
  //   icon: '',
  //   icon_selected: '',
  //   class: 'storage-order',
  //   id: 'storage-order',
  //   subMenu: [],
  //   count: '0'
  // },
  
];
