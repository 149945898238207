import { Component, OnInit ,Input,EventEmitter, Output} from '@angular/core';
import { CommonService } from '../../commons/service/common.service';
import { AlertService } from '../../commons/service/alert.service';
import { AppSettings } from '../../commons/setting/app_setting';
import { SharedService } from '../../commons/service/shared.service';
import * as moment from 'moment';
@Component({
  selector: 'app-assign-driver',
  templateUrl: './assign-driver.component.html',
  styleUrls: ['./assign-driver.component.css']
})

export class AssignDriverComponent implements OnInit {
  public driverList=[];
  public totalDriver:any;
  public driverSkeletonLoader =false;
  public userDetails:any;
  public checkOccuiped:any;
  @Output() closePopup = new EventEmitter();
  @Output() driverConfirmToAssign = new EventEmitter();
  @Input() shipmentRecord:any;

  constructor(private commonService: CommonService, 
    public alertService: AlertService, 
    private sharedService:SharedService,
   ){ }

  ngOnInit(){
  	//Get user information; 
    let userDetail = this.sharedService.getAddress();    
    if(userDetail !==undefined){
      this.userInfo(userDetail);
    }
    this.commonService.userData.subscribe((userData) => {
      this.userInfo(userData);
    });
    this.getDriver(); 
  }

  generateFake(count: number): Array < number > {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }
  userInfo(userDtail){
    this.userDetails=userDtail;
  }

  onNoClick(){
    this.closePopup.emit(event);
  }

  getDriver(params = {}){

    this.shipmentRecord[0].date = this.shipmentRecord[0].date.replace(/\-/g, '/');
    let pickupDateConvent = moment(this.shipmentRecord[0].date.toString()).format('MM/DD/YYYY');
    this.shipmentRecord[this.shipmentRecord.length - 1].date = this.shipmentRecord[this.shipmentRecord.length - 1].date.replace(/\-/g, '/');
    let dropDateConvent = moment(this.shipmentRecord[this.shipmentRecord.length - 1].date).format('MM/DD/YYYY');

      this.driverSkeletonLoader=true;
      let uri = null;

      let newparams = {
        'limit':100,
        "pickupDate": pickupDateConvent,
        "dropDate": dropDateConvent,
        "status" : "message",
      }
      if(params)
        uri = this.commonService.getAPIUriFromParams(newparams);
        let APIparams = {
          apiKey: AppSettings.APIsNameArray.SHIPMENT.LIST,
          uri: uri,
        };
      this.commonService.getList(APIparams).subscribe((ServerRes) => {
        if(ServerRes.success === true){
          this.checkOccuiped = ServerRes.response.isCarrierOccupied;
          this.totalDriver=   ServerRes.response.totalDriver;
          this.driverList=ServerRes.response.drivers;
           if(ServerRes.response.totalDriver > 0){ 
          }else{

          this.alertService.showNotificationMessage("danger","bottom","right",'txt_d','cancel','No Drivers Available',"You have either not added any drivers or all added drivers are currently assigned to loads. If you are also a driver, You have the option of assigning the load to yourself.");
          }
          this.driverSkeletonLoader=false;  
        } else{
          this.driverList = [];  
          this.driverSkeletonLoader=false;  
          this.totalDriver = 0;
        }
      },
      error => {
        this.totalDriver = 0;
        this.driverList=[]; 
        this.alertService.showNotificationMessage("danger","bottom","right",'txt_g','error','Unexpected Error',AppSettings.ERROR);
        this.driverSkeletonLoader=true;    
      },
      );
  }

 assignToConfirm(driverDetail){
    this.driverConfirmToAssign.emit(driverDetail);
 }
  
}
