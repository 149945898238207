import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import { AppSettings } from '../setting/app_setting';
import { environment } from '../../../environments/environment';
import { DOCUMENT } from '@angular/common';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  public sendTokanUpdate = new Subject<string>();
  public profileUpdate = new Subject<string>();
  public bellNotificationUpdate = new Subject<string>();
  public configData = new Subject<string>();
  public userData = new Subject<string>();
  public configApiCall = new Subject<string>();
  public configLoginCall = new Subject<string>();
  public callToLogout = new Subject<string>();
  public buyUsdcPassData = new Subject<string>();
  public updateConnectAccount = new Subject<string>();
  public afteLoginUpdateLoader = new Subject<string>();
  public callFcmTokenDelete = new Subject<string>();
  public allMarkRedBellnotiion = new Subject<string>();
  public messageClearData = new Subject<string>();
  public mapClearApiCalling = new Subject<string>();
  public bidQuotesAssistanceUpdate = new Subject<any>();
  public bidQuotesAssistanceUpdateBidPopup = new Subject<any>();
  public bidQuestPopupUpdateSingleValue = new Subject<string>();
  public isDetailComponent: boolean = false;
  
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private http: HttpClient,

  ) { }

  checkIfInDetail() {
    return this.isDetailComponent;
  }

  updateDetail(detail) {
    this.isDetailComponent = detail;
  }

  getList(APIparams): Observable<any> {
    //this variable will be used to hold the absoulate URL of API
    let apiUrl = '';

    // If the API slug passed in service then, we'll get the full API URL by this function
    if (APIparams.apiKey) apiUrl = this.getAPIUrl(APIparams.apiKey);

    // Appending The URI in APIs URL
    if (APIparams.uri) apiUrl = apiUrl + '?' + APIparams.uri;

    return this.http.get(apiUrl).map((res) => {
      return res;
    });
  }

  putAlertNotificationOnRead(APIparams: any): Observable<any> {
    let myApi = '/api/badge/notification/read';
    if(APIparams)
    myApi = `/api/badge/notification/read?tabType=${APIparams}`;
    let apiUrl = environment.apiEndpoint + myApi;
    let checkKeyvalue = this.checkBlankValue(APIparams);
    return this.http.put(apiUrl, checkKeyvalue).map((res) => {
      return res;
    });
  }

  getContractFees(): Observable<any> {
    let myApi = '/contracts/fees';
    let apiUrl = environment.paymentApiEndpoint + myApi;
    return this.http.get(apiUrl).map((res) => {
      return res;
    });
  }

  getStateList(): Observable<any> {
    let myApi = '/api/state/list';
    let apiUrl = environment.apiEndpoint + myApi;
    return this.http.get(apiUrl).map((res) => {
      return res;
    });
  }

  getSubscriptionAlert(): Observable<any> {
    let userType = localStorage.getItem('user_type');
    let myApi;
    if (userType === 'SHIPPER') {
      myApi = '/subscription/active-plan';
    }
    let apiUrl = environment.paymentApiEndpoint + myApi;
    return this.http.get(apiUrl).map((res) => {
      return res;
    });
  }

  getSubscriptionPlanList(): Observable<any> {
    let userType = localStorage.getItem('user_type');
    let myApi;
    if (userType === 'CARRIER') {
      myApi = '/subscription/plans';
    }
    if (userType === 'SHIPPER') {
      myApi = '/subscription/shipper/plans';
    }
    let apiUrl = environment.paymentApiEndpoint + myApi;
    return this.http.get(apiUrl).map((res) => {
      return res;
    });
  }

  post(APIparams): Observable<any> {
    //this variable will be used to hold the absoulate URL of API
    let apiUrl = '';
    // If the API slug passed in service then, we'll get the full API URL by this function
    if (APIparams.apiKey) apiUrl = this.getAPIUrl(APIparams.apiKey);
    // Appending The URI in APIs URL
    if (APIparams.uri) apiUrl = apiUrl + '?' + APIparams.uri;

    // Validating an HTTP POST BODY
    if (!APIparams.postBody) APIparams.postBody = {};
    /*  //get headers 
        let headers = this.getHeaders();
    */
    let checkKeyvalue = this.checkBlankValue(APIparams.postBody);

    return this.http.post(apiUrl, checkKeyvalue /*,headers*/).map((res) => {
      return res;
    });
  }

  put(APIparams): Observable<any> {
    //this variable will be used to hold the absoulate URL of API
    let apiUrl = '';
    // If the API slug passed in service then, we'll get the full API URL by this function
    if (APIparams.apiKey) apiUrl = this.getAPIUrl(APIparams.apiKey);
    // Appending The URI in APIs URL
    if (APIparams.uri) apiUrl = apiUrl + '?' + APIparams.uri;

    // Validating an HTTP POST BODY
    if (!APIparams.postBody) APIparams.postBody = {};
    /*  //get headers 
        let headers = this.getHeaders();
    */
    let checkKeyvalue = this.checkBlankValue(APIparams.postBody);

    return this.http.put(apiUrl, checkKeyvalue /*,headers*/).map((res) => {
      return res;
    });
  }

  putAllValue(APIparams): Observable<any> {
    //this variable will be used to hold the absoulate URL of API
    let apiUrl = '';
    // If the API slug passed in service then, we'll get the full API URL by this function
    if (APIparams.apiKey) apiUrl = this.getAPIUrl(APIparams.apiKey);
    // Appending The URI in APIs URL
    if (APIparams.uri) apiUrl = apiUrl + '?' + APIparams.uri;

    // Validating an HTTP POST BODY
    if (!APIparams.postBody) APIparams.postBody = {};
    /* //get headers 
          let headers = this.getHeaders();
      */
    /*	         let checkKeyvalue=this.checkBlankValue(APIparams.postBody);*/

    return this.http.put(apiUrl, APIparams.postBody).map((res) => {
      return res;
    });
  }
  postAllValue(APIparams): Observable<any> {
    //this variable will be used to hold the absoulate URL of API
    let apiUrl = '';
    // let checkKeyvalue = this.checkBlankValueFormdata(APIparams.postBody);
    // If the API slug passed in service then, we'll get the full API URL by this function
    if (APIparams.apiKey) apiUrl = this.getAPIUrl(APIparams.apiKey);

    // Appending The URI in APIs URL
    if (APIparams.uri) apiUrl = apiUrl + '?' + APIparams.uri;

    // Validating an HTTP POST BODY
    if (!APIparams.postBody) APIparams.postBody = [];

    return this.http.post(apiUrl, APIparams.postBody).map((res) => {
      return res;
    });
  }

  postMediaMethod(APIparams): Observable<any> {
    //this variable will be used to hold the absoulate URL of API
    let apiUrl = '';
    let checkKeyvalue = this.checkBlankValueFormdata(APIparams.postBody);
    // If the API slug passed in service then, we'll get the full API URL by this function
    if (APIparams.apiKey) apiUrl = this.getAPIUrl(APIparams.apiKey);

    // Appending The URI in APIs URL
    if (APIparams.uri) apiUrl = apiUrl + '?' + APIparams.uri;

    // Validating an HTTP POST BODY
    if (!APIparams.postBody) APIparams.postBody = [];

    return this.http.post(apiUrl, checkKeyvalue).map((res) => {
      return res;
    });
  }

  postMediaMethodAllValue(APIparams): Observable<any> {
    //this variable will be used to hold the absoulate URL of API
    let apiUrl = '';
    //let checkKeyvalue=this.checkBlankValueFormdata(APIparams.postBody);
    // If the API slug passed in service then, we'll get the full API URL by this function
    if (APIparams.apiKey) apiUrl = this.getAPIUrl(APIparams.apiKey);

    // Appending The URI in APIs URL
    if (APIparams.uri) apiUrl = apiUrl + '?' + APIparams.uri;

    // Validating an HTTP POST BODY
    if (!APIparams.postBody) APIparams.postBody = [];

    return this.http.post(apiUrl, APIparams.postBody).map((res) => {
      return res;
    });
  }

  postMedia(APIparams): Observable<any> {
    let apiUrl = '';

    if (APIparams.apiKey) apiUrl = this.getAPIUrl(APIparams.apiKey);

    if (APIparams.uri) apiUrl = apiUrl + '?' + APIparams.uri;

    if (!APIparams.postBody) APIparams.postBody = [];

    const checkKeyvalue = this.checkBlankValueFormdata(APIparams.postBody);

    return this.http.put(apiUrl, checkKeyvalue).map((res) => {
      return res;
    });
  }

  delete(APIparams): Observable<any> {
    let apiUrl = '';

    if (APIparams.apiKey) apiUrl = this.getAPIUrl(APIparams.apiKey);

    if (APIparams.uri) apiUrl = apiUrl + '?' + APIparams.uri;

    if (!APIparams.postBody) APIparams.postBody = {};

    return this.http.delete(apiUrl /*,headers*/).map((res) => {
      return res;
    });
  }

  getAPIUrl(apiSlug: string) {
    let checkAPI = apiSlug.split('/');
    let checkAPINew = apiSlug.substr(apiSlug.indexOf('/')).replace('/', '');
    if (checkAPI[0] === 'NEWPAYMENT') {
      let apiUrl = '';
      if (apiSlug) apiUrl = environment.paymentApiEndpoint + '/' + checkAPINew;
      return apiUrl;
    } else if (checkAPI[0] === 'PAYAPI') {
      let apiUrl = '';
      if (apiSlug)
        apiUrl =
          environment.axisTokanApiEndpoint +
          '/' +
          AppSettings.API_GATEWAY +
          '/' +
          checkAPINew;
      return apiUrl;
    } else {
      let apiUrl = '';
      if (apiSlug)
        apiUrl =
          environment.apiEndpoint +
          '/' +
          AppSettings.API_GATEWAY +
          '/' +
          apiSlug;
      return apiUrl;
    }
  }

  getAPIUriFromParams(params) {
    if (!params) return '';

    let url = new URLSearchParams();

    for (let key in params) if (params[key]) url.set(key, params[key]);
    return url.toString();
  }
  checkBlankValuePut(recordForm) {
    for (var propName in recordForm) {
      if (recordForm[propName] === undefined || recordForm[propName] === '') {
        delete recordForm[propName];
      }
    }
    return recordForm;
  }

  checkBlankValue(recordForm) {
    for (var propName in recordForm) {
      if (
        recordForm[propName] === null ||
        recordForm[propName] === undefined ||
        recordForm[propName] === ''
      ) {
        delete recordForm[propName];
      }
    }
    return recordForm;
  }

  checkBlankValueFormdata(recordForm) {
    const tempPostBody = new FormData();
    recordForm.forEach((V, K) => {
      if (
        V !== '' &&
        V !== null &&
        V !== 'null' &&
        V !== 'Invalid Da' &&
        typeof V !== undefined
      ) {
        tempPostBody.append(K, V);
      }
    });
    return tempPostBody;
  }
  getRole(type) {
    let chatUserType = '';
    if (type === 'shipper') {
      chatUserType = 'SHIPPER';
    }
    if (type === 'active_shipment') {
      chatUserType = 'ACTIVE';
    }
    if (type === 'shipment') {
      chatUserType = 'PAST';
    }
    if (type === 'carrier') {
      chatUserType = 'CARRIER';
    }
    if (type === 'driver') {
      chatUserType = 'DRIVER';
    }
    if (type === 'order') {
      chatUserType = 'ORDER';
    }
    return chatUserType;
  }
}
