
export class StatusSetting {
  public countryList = ['US', 'CA', 'MX'];
  public static statusList = [
    {
      id: 3,
      name: 'Available',
      type: 'Inactive',
      colorClass: 'stats_available',
    },
    { id: 6, name: 'Active', type: 'Inactive', colorClass: 'stats_inTransit' },
    { id: 1, name: 'Invited', type: 'Inactive', colorClass: 'stats_invited' },
    { id: 2, name: 'Suspended', type: 'Inactive', colorClass: 'stats_suspend' },
  ];
  public static shipmentStatusList = [
    { id: 1, name: 'In Progress', slugName: 'inProgress', type: 'active' },
    { id: 2, name: 'Upcoming', slugName: 'upcoming', type: 'Inactive' },
    { id: 3, name: 'Past', slugName: 'past', type: 'Inactive' },
    { id: 4, name: 'Pending', slugName: 'pending', type: 'Inactive' },
    {
      id: 5,
      name: 'Shipment & Bids',
      slugName: 'shipmentAndBids',
      type: 'Inactive',
    },
  ];
  public static shipmentDriverStatus = ['accept', 'navigateToPickup'];
  public static shipmentPickupStatus = [
    'atPickupLocation',
    'navigateToPickup',
    'shipmentLoading',
    'shipmentLoaded',
  ];
  public static shipmentDropOffStatus = [
    'atDropOffLocation',
    'navigateToDropOff',
    'shipmentUnloading',
    'shipmentUnloaded',
    'delivered',
  ];

  public static emptyScreenStatusCarrier = [
    {
      slug: 'inProgress',
      title: "No In-Transit <span class='txt_p f_clash'>Shipments</span>",
      description: 'You can view all in-transit shipments here.',
      image: 'portal/images/after-login/Empty_State_icons/gr-no-shipment.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/home',
      status: '1',
    },
    {
      slug: 'upcoming',
      title: "No Confirmed <span class='txt_p f_clash'>Shipments</span>",
      description: 'You can view all your confirmed shipments here.',
      image: 'portal/images/after-login/Empty_State_icons/gr-no-shipment.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/home',
      status: '1',
    },
    {
      slug: 'pending',
      title: "No Awaiting <span class='txt_p f_clash'>Shipments</span>",
      description: 'You can view all your awaiting shipments here.',
      image: 'portal/images/after-login/Empty_State_icons/gr-no-shipment.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/home',
      status: '1',
    },
    {
      slug: 'past',
      title: "No Completed <span class='txt_p f_clash'>Shipments</span>",
      description: 'You can view all your completed shipments here.',
      image: 'portal/images/after-login/Empty_State_icons/gr-no-shipment.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/home',
      status: '1',
    },
    {
      slug: 'expired',
      title: "No Expired <span class='txt_p f_clash'>Shipments</span>",
      description: 'You can view all your expired shipments here.',
      image: 'portal/images/after-login/Empty_State_icons/gr-no-shipment.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/home',
      status: '1',
    },
    {
      slug: 'availableToBid',
      title: "No Loads <span class='txt_p f_clash'>Available</span>",
      description: 'All Shipments available for bidding will appear here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/home',
      status: '1',
    },
    {
      slug: 'currentBids',
      title: "No Current <span class='txt_p f_clash'>Quotes/Bids</span>",
      description: 'You can view all current bidding activity here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/home',
      status: '1',
    },
    {
      slug: 'awardedBids',
      title: "No Awarded <span class='txt_p f_clash'>Bid</span>",
      description: 'You can view all awarded bidding activity here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/home',
      status: '1',
    },
    {
      slug: 'nonAwardedBids',
      title: "No Non-Awarded <span class='txt_p f_clash'>Bidding</span>",
      description: 'You can view all non-awarded bidding activity here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/home',
      status: '1',
    },
    {
      slug: 'confirmedBids',
      title: "Confirm <span class='txt_p f_clash'>Quotes/Bids</span>",
      description:
        'When any carrier invites you to quote/bid on a load, you can view all confirmed Carrier quotes/bids and load details here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/home',
      status: '1',
    },
    {
      slug: 'offerRate',
      title: "Shipper Offer <span class='txt_p f_clash'>Price</span>",
      description:
        'When any carrier invites you to quote/bid on a load, you can view all offer rate Carrier quotes/bids and load details here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/home',
      status: '1',
    },
    {
      slug: 'offerRateConfirmed',
      title: "Offer Rate <span class='txt_p f_clash'>Confirmed</span>",
      description:
        'When any carrier invites you to quote/bid on a load, you can view all offer rate Carrier quotes/bids and load details here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/home',
      status: '1',
    },
    {
      slug: 'dispute',
      title: "No More <span class='txt_p f_clash'>Dispute</span>",
      description:
        'When a dispute is raised on any shipment by you or shipper, it will appear here.',
      image: 'portal/images/after-login/Empty_State_icons/gr-no-dispute.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/home',
      status: '1',
    },
    {
      slug: 'newDispute',
      title: "No New <span class='txt_p f_clash'>Disputes</span>",
      description:
        'When a dispute is raised on any shipment by you or shipper, it will appear here.',
      image: 'portal/images/after-login/Empty_State_icons/gr-no-dispute.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/home',
      status: '1',
    },
    {
      slug: 'ongoingDispute',
      title: "No Active <span class='txt_p f_clash'>Disputes</span>",
      description:
        'If you have any active disputes regarding an existing shipment you can view the details here.',
      image: 'portal/images/after-login/Empty_State_icons/gr-no-dispute.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/home',
      status: '1',
    },
    {
      slug: 'resolvedDispute',
      title: "No Resolved <span class='txt_p f_clash'>Disputes</span>",
      description: 'All past disputes and their outcomes can be viewed here.',
      image: 'portal/images/after-login/Empty_State_icons/gr-no-dispute.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/home',
      status: '1',
    },
    {
      slug: 'shipmentAndBids',
      title: "No Active <span class='txt_p f_clash'>Bidding</span>",
      description:
        'When bidding is ongoing on any shipment, it will appear here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-shipment-bidding.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/home',
      status: '1',
    },
    {
      slug: 'postedLanes',
      title: "No Current <span class='txt_p f_clash'>Posted Lanes</span>",
      description:
        'When any carrier invites you to quote/bid on a lane, you can view all current Carrier quotes/bids and lane details here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-shipment-bidding.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/home',
      status: '1',
    },
    {
      slug: 'availableLanes',
      title: "No Lanes <span class='txt_p f_clash'>Available</span>",
      description: 'All Lanes available for bidding will appear here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/home',
      status: '1',
    },
    {
      slug: 'confirmedLanes',
      title: "No Confirmed <span class='txt_p f_clash'>Quotes/Bids</span>",
      description:
        'When any carrier invites you to quote/bid on a load, you can view all confirmed Carrier quotes/bids and load details here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/home',
      status: '1',
    },
    {
      slug: 'search',
      title: 'No results found…',
      description:
        'Your search returns no results. Please update your search criteria.',
      image: 'portal/images/after-login/cl-icons/gr-no-search-results.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/home',
      status: '1',
    },
  ];

  public static emptyScreenStatusShipper = [
    {
      slug: 'inProgress',
      title: "No In-Transit <span class='txt_p f_clash'>Shipments</span>",
      description: 'You can view all in-transit shipments here.',
      image: 'portal/images/after-login/Empty_State_icons/gr-no-shipment.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/home',
      status: '1',
    },
    {
      slug: 'upcoming',
      title: "No Confirmed <span class='txt_p f_clash'>Shipments</span>",
      description: 'You can view all your confirmed shipments here.',
      image: 'portal/images/after-login/Empty_State_icons/gr-no-shipment.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/home',
      status: '1',
    },
    {
      slug: 'pending',
      title: "No Awaiting <span class='txt_p f_clash'>Shipments</span>",
      description: 'You can view all your awaiting shipments here.',
      image: 'portal/images/after-login/Empty_State_icons/gr-no-shipment.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/home',
      status: '1',
    },
    {
      slug: 'past',
      title: "No Completed <span class='txt_p f_clash'>Shipments</span>",
      description: 'You can view all your completed shipments here.',
      image: 'portal/images/after-login/Empty_State_icons/gr-no-shipment.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/home',
      status: '1',
    },
    {
      slug: 'expired',
      title: "No Expired <span class='txt_p f_clash'>Shipments</span>",
      description: 'You can view all your expired shipments here.',
      image: 'portal/images/after-login/Empty_State_icons/gr-no-shipment.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/home',
      status: '1',
    },
    {
      slug: 'currentBids',
      title: "No Current <span class='txt_p f_clash'>Quotes/Bids</span>",
      description:
        'When any carrier invites you to quote/bid on a load, you can view all current Carrier quotes/bids and load details here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/home',
      status: '1',
    },
    {
      slug: 'confirmedBids',
      title: "No Confirmed <span class='txt_p f_clash'>Quotes/Bids</span>",
      description:
        'When any carrier invites you to quote/bid on a load, you can view all confirmed Carrier quotes/bids and load details here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/home',
      status: '1',
    },

    {
      slug: 'offerRate',
      title: "Offer <span class='txt_p f_clash'>Rate</span>",
      description:
        'When any carrier invites you to quote/bid on a load, you can view all offer rate Carrier quotes/bids and load details here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/home',
      status: '1',
    },
    {
      slug: 'offerRateConfirmed',
      title: "Offer Rate <span class='txt_p f_clash'>Confirmed</span>",
      description:
        'When any carrier invites you to quote/bid on a load, you can view all offer rate Carrier quotes/bids and load details here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/home',
      status: '1',
    },

    {
      slug: 'dispute',
      title: "No More <span class='txt_p f_clash'>Dispute</span>",
      description:
        'When a dispute is raised on any shipment by you or carrier, it will appear here.',
      image: 'portal/images/after-login/Empty_State_icons/gr-no-dispute.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/home',
      status: '1',
    },
    {
      slug: 'newDispute',
      title: "No New <span class='txt_p f_clash'>Disputes</span>",
      description:
        'When a dispute is raised on any shipment by you or carrier, it will appear here.',
      image: 'portal/images/after-login/Empty_State_icons/gr-no-dispute.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/home',
      status: '1',
    },
    {
      slug: 'ongoingDispute',
      title: "No Active <span class='txt_p f_clash'>Disputes</span>",
      description:
        'If you have any active disputes regarding an existing shipment you can view the details here.',
      image: 'portal/images/after-login/Empty_State_icons/gr-no-dispute.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/home',
      status: '1',
    },
    {
      slug: 'resolvedDispute',
      title: "No Resolved <span class='txt_p f_clash'>Disputes</span>",
      description: 'All past disputes and their outcomes can be viewed here.',
      image: 'portal/images/after-login/Empty_State_icons/gr-no-dispute.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/home',
      status: '1',
    },
    {
      slug: 'shipmentAndBids',
      title: "No Active <span class='txt_p f_clash'>Bidding</span>",
      description: 'You can view all active bidding activity here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-shipment-bidding.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/home',
      status: '1',
    },

    {
      slug: 'postedLanes',
      title: "No Current <span class='txt_p f_clash'>Posted Lanes</span>",
      description:
        'When any carrier invites you to quote/bid on a lane, you can view all current Carrier quotes/bids and lane details here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-shipment-bidding.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/home',
      status: '1',
    },
    {
      slug: 'confirmedLanes',
      title: "No Confirmed <span class='txt_p f_clash'>Quotes/Bids</span>",
      description:
        'When any carrier invites you to quote/bid on a load, you can view all confirmed Carrier quotes/bids and load details here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/home',
      status: '1',
    },
    {
      slug: 'archivedLanes',
      title: "No <span class='txt_p f_clash'>Archived Lanes</span>",
      description:
        'No Archived Lanes.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-shipment-bidding.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/home',
      status: '1',
    },
    {
      slug: 'confirmedLaneList',
      title: "No Confirm <span class='txt_p f_clash'> Lane</span>",
      description: 'You can view all confirm lane here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-shipment-bidding.svg',
      buttonText: 'Visit Confirmed Lanes',
      buttonURL: '/booking/lane/confirmedLanes',
      status: '1',
    },
    {
      slug: 'bidding',
      title: "No Active <span class='txt_p f_clash'>Bidding</span>",
      description:
        'You can view all active bidding activity here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-shipment-bidding.svg',
      buttonText: 'Visit Posted Lanes',
      buttonURL: '/booking/lane/postedLanes',
      status: '1',
    },
    {
      slug: 'booked',
      title: "Confirm <span class='txt_p f_clash'>Quotes/Bids</span>",
      description:
        'When any carrier invites you to quote/bid on a load, you can view all confirmed Carrier quotes/bids and load details here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit Posted Lanes',
      buttonURL: '/booking/lane/postedLanes',
      status: '1',
    },
    {
      slug: 'search',
      title: 'No results found…',
      description:
        'Your search returns no results. Please update your search criteria.',
      image: 'portal/images/after-login/cl-icons/gr-no-search-results.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/home',
      status: '1',
    },
  ];

  public static emptyScreenStatusOrderShipper = [
    {
      slug: 'orders',
      title: "No Posted <span class='txt_p f_clash'> Orders</span>",
      description: 'You can view all Posted Orders here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-shipment-bidding.svg',
      buttonText: 'Visit the Storage Network',
      buttonURL: '/storage/shipper',
      status: '1',
    },
    {
      slug: 'bidding',
      title: "No Active <span class='txt_p f_clash'> Bidding</span>",
      description: 'You can view all active bidding activity here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-shipment-bidding.svg',
      buttonText: 'Visit the Storage Network',
      buttonURL: '/storage/shipper',
      status: '1',
    },
    {
      slug: 'confirmed',
      title: "No Confirmed <span class='txt_p f_clash'> Orders</span>",
      description: 'You can view all Confirmed Orders here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-shipment-bidding.svg',
      buttonText: 'Visit the Storage Network',
      buttonURL: '/storage/shipper',
      status: '1',
    },

    {
      slug: 'search',
      title: 'No results found…',
      description:
        'Your search returns no results. Please update your search criteria.',
      image: 'portal/images/after-login/cl-icons/gr-no-search-results.svg',
      buttonText: 'Visit the Storage Network',
      buttonURL: '/storage/shipper',
      status: '1',
    },
  ];

  public static emptyScreenStatusStorage = [
    {
      slug: 'orders',
      title: "No Available <span class='txt_p f_clash'> Orders</span>",
      description: 'You can view all Available Orders here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-shipment-bidding.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/home',
      status: '1',
    },
    {
      slug: 'bidding',
      title: "No Active <span class='txt_p f_clash'>Bidding</span>",
      description: 'You can view all active bidding activity here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-shipment-bidding.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/home',
      status: '1',
    },
    {
      slug: 'confirmed',
      title: "No Assigned <span class='txt_p f_clash'> Orders</span>",
      description: 'You can view all Assigned Orders here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-shipment-bidding.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/home',
      status: '1',
    },

    {
      slug: 'search',
      title: 'No results found…',
      description:
        'Your search returns no results. Please update your search criteria.',
      image: 'portal/images/after-login/cl-icons/gr-no-search-results.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/home',
      status: '1',
    },
  ];

  public static sliderList = [
    {
      id: 1,
      status: 'selectedSlide',
      name: 'Direct Connection',
      description:
        'Send, receive and accept unlimited Carrier connection requests for free',
      image:
        'https://staticprod.laneaxis.com/portal/images/before-login/direct-connections-slide-min.png?v=1',
      subImage:
        'https://staticprod.laneaxis.com/portal/images/before-login/ic-direct-connections-min.png?v=1',
    },

    {
      id: 2,
      status: 'hide',
      name: 'Contractual Control',
      description:
        'Carrier must accept Shipper’s contract, including option to list Shipper on Carrier’s Insurance certificate.',
      image:
        'https://staticprod.laneaxis.com/portal/images/before-login/direct-connections-slide-2-min.png?v=1',
      subImage:
        'https://staticprod.laneaxis.com/portal/images/before-login/ic-contractual-control-min.png?v=1',
    },

    {
      id: 3,
      status: 'hide',
      name: 'Rate Negotiations',
      description:
        'Negotiate with specific Carriers or broadcast a shipment to Network for bidding. Shippers have the final say and control over their freight',
      image:
        'https://staticprod.laneaxis.com/portal/images/before-login/direct-connections-slide-3-min.png?v=1',
      subImage:
        'https://staticprod.laneaxis.com/portal/images/before-login/ic-rate-negotiation-min.png?v=1',
    },

    {
      id: 4,
      status: 'hide',
      name: 'Load Management',
      description:
        'LaneAxis Mobile Application provides real-time tracking beginning prior-to-pickup, e-docs, in-app messaging, instantly archived load history',
      image:
        'https://staticprod.laneaxis.com/portal/images/before-login/direct-connections-slide-4-min.png?v=1',
      subImage:
        'https://staticprod.laneaxis.com/portal/images/before-login/ic-load-management-min.png?v=1',
    },

    {
      id: 5,
      status: 'hide',
      name: 'Delivery and Payment',
      description:
        'Shipper payment to Carrier held in escrow and not released until completion of 24-hour dispute window following Proof of Delivery.',
      image:
        'https://staticprod.laneaxis.com/portal/images/before-login/direct-connections-slide-5-min.png?v=1',
      subImage:
        'https://staticprod.laneaxis.com/portal/images/before-login/ic-delivery-payment-min.png?v=1',
    },

    {
      id: 6,
      status: 'hide',
      name: 'Full Integration Options',
      description:
        'From front-end TMS systems to backend accounting systems and more',
      image:
        'https://staticprod.laneaxis.com/portal/images/before-login/direct-connections-slide-6-min.png?v=1',
      subImage:
        'https://staticprod.laneaxis.com/portal/images/before-login/ic-full-integrations-min.png?v=1',
    },
  ];

  public static fontList = [
    { class: 'Sacramento_txt' },
    { class: 'GreatVibes_txt' },
    { class: 'Yellowtail_txt' },
    { class: 'Tangerine_txt' },
    { class: 'Cedarville_txt' },
    { class: 'DancingScript_txt' },
  ];

  public static equipmentType = [
    {
      id: '1',
      name: 'Dry Van Trailer',
    },

    {
      id: '2',
      name: 'FlatBed Trailer',
    },
    {
      id: '3',
      name: 'Step Deck',
    },
    {
      id: '4',
      name: 'Conestoga Trailer',
    },
    {
      id: '5',
      name: 'RGN Trailer',
    },
    {
      id: '6',
      name: 'Stretch RGN Trailer',
    },
    {
      id: '7',
      name: 'Lowboy Trailer',
    },
    {
      id: '8',
      name: 'Refrigerated Trailer',
    },
    {
      id: '9',
      name: 'Power Unit Only',
    },
    {
      id: '10',
      name: 'Box Truck',
    },
    {
      id: '11',
      name: 'Refrigerated Box Truck',
    },
  ];

  public static timeZoneList = [
    { id: 1, tzShort: 'PST', tzLong: 'Pacific Standard Time' },
    { id: 2, tzShort: 'MST', tzLong: 'Mountain Standard Time' },
    { id: 3, tzShort: 'CST', tzLong: 'Central Standard Time' },
    { id: 4, tzShort: 'EST', tzLong: 'Eastern Standard Time' },
    { id: 5, tzShort: 'HST', tzLong: 'Hawaii Standard Time' },
    { id: 6, tzShort: 'AKST', tzLong: 'Alaska Standard Time' },
  ];

  public static newTimeZoneList = [
    { id: 1, tzShort: 'PST', tzLong: 'Pacific Standard Time' },
    { id: 2, tzShort: 'MST', tzLong: 'Mountain Standard Time' },
    { id: 3, tzShort: 'CST', tzLong: 'Central Standard Time' },
    { id: 4, tzShort: 'EST', tzLong: 'Eastern Standard Time' },
    { id: 5, tzShort: 'HST', tzLong: 'Hawaii Standard Time' },
    { id: 6, tzShort: 'AKST', tzLong: 'Alaska Standard Time' },
    { id: 7, tzShort: 'NST', tzLong: 'Newfoundland Standard Time' },
    { id: 8, tzShort: 'AST', tzLong: 'Atlantic Standard Time' },
  ];

  public static state = [
    {
      name: 'Alabama',
      sortname: 'AL',
    },
    {
      name: 'Alaska',
      sortname: 'AK',
    },
    {
      name: 'American Samoa',
      sortname: 'AS',
    },
    {
      name: 'Arizona',
      sortname: 'AZ',
    },
    {
      name: 'Arkansas',
      sortname: 'AR',
    },
    {
      name: 'California',
      sortname: 'CA',
    },
    {
      name: 'Colorado',
      sortname: 'CO',
    },
    {
      name: 'Connecticut',
      sortname: 'CT',
    },
    {
      name: 'Delaware',
      sortname: 'DE',
    },
    {
      name: 'District Of Columbia',
      sortname: 'DC',
    },
    {
      name: 'Federated States Of Micronesia',
      sortname: 'FM',
    },
    {
      name: 'Florida',
      sortname: 'FL',
    },
    {
      name: 'Georgia',
      sortname: 'GA',
    },
    {
      name: 'Guam',
      sortname: 'GU',
    },
    {
      name: 'Hawaii',
      sortname: 'HI',
    },
    {
      name: 'Idaho',
      sortname: 'ID',
    },
    {
      name: 'Illinois',
      sortname: 'IL',
    },
    {
      name: 'Indiana',
      sortname: 'IN',
    },
    {
      name: 'Iowa',
      sortname: 'IA',
    },
    {
      name: 'Kansas',
      sortname: 'KS',
    },
    {
      name: 'Kentucky',
      sortname: 'KY',
    },
    {
      name: 'Louisiana',
      sortname: 'LA',
    },
    {
      name: 'Maine',
      sortname: 'ME',
    },
    {
      name: 'Marshall Islands',
      sortname: 'MH',
    },
    {
      name: 'Maryland',
      sortname: 'MD',
    },
    {
      name: 'Massachusetts',
      sortname: 'MA',
    },
    {
      name: 'Michigan',
      sortname: 'MI',
    },
    {
      name: 'Minnesota',
      sortname: 'MN',
    },
    {
      name: 'Mississippi',
      sortname: 'MS',
    },
    {
      name: 'Missouri',
      sortname: 'MO',
    },
    {
      name: 'Montana',
      sortname: 'MT',
    },
    {
      name: 'Nebraska',
      sortname: 'NE',
    },
    {
      name: 'Nevada',
      sortname: 'NV',
    },
    {
      name: 'New Hampshire',
      sortname: 'NH',
    },
    {
      name: 'New Jersey',
      sortname: 'NJ',
    },
    {
      name: 'New Mexico',
      sortname: 'NM',
    },
    {
      name: 'New York',
      sortname: 'NY',
    },
    {
      name: 'North Carolina',
      sortname: 'NC',
    },
    {
      name: 'North Dakota',
      sortname: 'ND',
    },
    {
      name: 'Northern Mariana Islands',
      sortname: 'MP',
    },
    {
      name: 'Ohio',
      sortname: 'OH',
    },
    {
      name: 'Oklahoma',
      sortname: 'OK',
    },
    {
      name: 'Oregon',
      sortname: 'OR',
    },
    {
      name: 'Palau',
      sortname: 'PW',
    },
    {
      name: 'Pennsylvania',
      sortname: 'PA',
    },
    {
      name: 'Puerto Rico',
      sortname: 'PR',
    },
    {
      name: 'Rhode Island',
      sortname: 'RI',
    },
    {
      name: 'South Carolina',
      sortname: 'SC',
    },
    {
      name: 'South Dakota',
      sortname: 'SD',
    },
    {
      name: 'Tennessee',
      sortname: 'TN',
    },
    {
      name: 'Texas',
      sortname: 'TX',
    },
    {
      name: 'Utah',
      sortname: 'UT',
    },
    {
      name: 'Vermont',
      sortname: 'VT',
    },
    {
      name: 'Virgin Islands',
      sortname: 'VI',
    },
    {
      name: 'Virginia',
      sortname: 'VA',
    },
    {
      name: 'Washington',
      sortname: 'WA',
    },
    {
      name: 'West Virginia',
      sortname: 'WV',
    },
    {
      name: 'Wisconsin',
      sortname: 'WI',
    },
    {
      name: 'Wyoming',
      sortname: 'WY',
    },
    {
      name: 'Alberta',
      sortname: 'AB',
    },
    {
      name: 'British Columbia',
      sortname: 'BC',
    },
    {
      name: 'Manitoba',
      sortname: 'MB',
    },
    {
      name: 'New Brunswick',
      sortname: 'NB',
    },
    {
      name: 'Newfoundland and Labrador',
      sortname: 'NL',
    },
    {
      name: 'Northwest Territories',
      sortname: 'NT',
    },
    {
      name: 'Nova Scotia',
      sortname: 'NS',
    },
    {
      name: 'Nunavut',
      sortname: 'NU',
    },
    {
      name: 'Ontario',
      sortname: 'ON',
    },
    {
      name: 'Prince Edward Island',
      sortname: 'PE',
    },
    {
      name: 'Quebec',
      sortname: 'QC',
    },
    {
      name: 'Saskatchewan',
      sortname: 'SK',
    },
    {
      name: 'Yukon',
      sortname: 'YT',
    },
    {
      name: 'Aguascalientes',
      sortname: 'Ags.',
    },
    {
      name: 'Baja California',
      sortname: 'B.C.',
    },
    {
      name: 'Baja California Sur',
      sortname: 'BCS',
    },
    {
      name: 'Campeche',
      sortname: 'Camp.',
    },
    {
      name: 'Chiapas',
      sortname: 'Chis.',
    },
    {
      name: 'Chihuahua',
      sortname: 'Chih.',
    },
    {
      name: 'Coahuila',
      sortname: 'Coah.',
    },
    {
      name: 'Colima',
      sortname: 'Col.',
    },
    {
      name: 'Durango',
      sortname: 'Dgo.',
    },
    {
      name: 'Guanajuato',
      sortname: 'Gto.',
    },
    {
      name: 'Guerrero',
      sortname: 'YT',
    },
    {
      name: 'BCS',
      sortname: 'YT',
    },
    {
      name: 'Jalisco',
      sortname: 'Jal.',
    },
    {
      name: 'Mexico',
      sortname: 'CDMX',
    },
    {
      name: 'Michoacan',
      sortname: 'Mich.',
    },
    {
      name: 'Morelos',
      sortname: 'Mor.',
    },
    {
      name: 'Nuevo Leon',
      sortname: 'N.L.',
    },
    {
      name: 'Nayarit',
      sortname: 'Nay.',
    },
    {
      name: 'Oaxaca',
      sortname: 'Oax.',
    },
    {
      name: 'Puebla',
      sortname: 'Pue.',
    },
    {
      name: 'Queretaro',
      sortname: 'Qro.',
    },
    {
      name: 'Quintana Roo',
      sortname: 'Q.R.',
    },
    {
      name: 'San Luis Potosi',
      sortname: 'S.L.P.',
    },
    {
      name: 'Sinaloa',
      sortname: 'Sin.',
    },
    {
      name: 'Sonora',
      sortname: 'Son.',
    },
    {
      name: 'Tabasco',
      sortname: 'Tab.',
    },
    {
      name: 'Tamaulipas',
      sortname: 'Tamps.',
    },
    {
      name: 'Tlaxcala',
      sortname: 'Tlax.',
    },
    {
      name: 'Veracruz',
      sortname: 'Ver.',
    },
    {
      name: 'Yucatan',
      sortname: 'Yuc.',
    },
    {
      name: 'Zacatecas',
      sortname: 'Zac.',
    },
  ];

  public static caState = [
    {
      name: 'Alberta',
      sortname: 'AB',
    },
    {
      name: 'British Columbia',
      sortname: 'BC',
    },
    {
      name: 'Manitoba',
      sortname: 'MB',
    },
    {
      name: 'New Brunswick',
      sortname: 'NB',
    },
    {
      name: 'Newfoundland and Labrador',
      sortname: 'NL',
    },
    {
      name: 'Northwest Territories',
      sortname: 'NT',
    },
    {
      name: 'Nova Scotia',
      sortname: 'NS',
    },
    {
      name: 'Nunavut',
      sortname: 'NU',
    },
    {
      name: 'Ontario',
      sortname: 'ON',
    },
    {
      name: 'Prince Edward Island',
      sortname: 'PE',
    },
    {
      name: 'Quebec',
      sortname: 'QC',
    },
    {
      name: 'Saskatchewan',
      sortname: 'SK',
    },
    {
      name: 'Yukon',
      sortname: 'YT',
    },

  ]
  public static mxState = [
    {
      name: 'Aguascalientes',
      sortname: 'Ags.',
    },
    {
      name: 'Baja California',
      sortname: 'B.C.',
    },
    {
      name: 'Baja California Sur',
      sortname: 'BCS',
    },
    {
      name: 'Campeche',
      sortname: 'Camp.',
    },
    {
      name: 'Chiapas',
      sortname: 'Chis.',
    },
    {
      name: 'Chihuahua',
      sortname: 'Chih.',
    },
    {
      name: 'Coahuila',
      sortname: 'Coah.',
    },
    {
      name: 'Colima',
      sortname: 'Col.',
    },
    {
      name: 'Durango',
      sortname: 'Dgo.',
    },
    {
      name: 'Guanajuato',
      sortname: 'Gto.',
    },
    {
      name: 'Guerrero',
      sortname: 'YT',
    },
    {
      name: 'BCS',
      sortname: 'YT',
    },
    {
      name: 'Jalisco',
      sortname: 'Jal.',
    },
    {
      name: 'Mexico',
      sortname: 'CDMX',
    },
    {
      name: 'Michoacan',
      sortname: 'Mich.',
    },
    {
      name: 'Morelos',
      sortname: 'Mor.',
    },
    {
      name: 'Nuevo Leon',
      sortname: 'N.L.',
    },
    {
      name: 'Nayarit',
      sortname: 'Nay.',
    },
    {
      name: 'Oaxaca',
      sortname: 'Oax.',
    },
    {
      name: 'Puebla',
      sortname: 'Pue.',
    },
    {
      name: 'Queretaro',
      sortname: 'Qro.',
    },
    {
      name: 'Quintana Roo',
      sortname: 'Q.R.',
    },
    {
      name: 'San Luis Potosi',
      sortname: 'S.L.P.',
    },
    {
      name: 'Sinaloa',
      sortname: 'Sin.',
    },
    {
      name: 'Sonora',
      sortname: 'Son.',
    },
    {
      name: 'Tabasco',
      sortname: 'Tab.',
    },
    {
      name: 'Tamaulipas',
      sortname: 'Tamps.',
    },
    {
      name: 'Tlaxcala',
      sortname: 'Tlax.',
    },
    {
      name: 'Veracruz',
      sortname: 'Ver.',
    },
    {
      name: 'Yucatan',
      sortname: 'Yuc.',
    },
    {
      name: 'Zacatecas',
      sortname: 'Zac.',
    },
  ]

  public static usState = [
    {
      name: 'Alabama',
      sortname: 'AL',
    },
    {
      name: 'Alaska',
      sortname: 'AK',
    },
    {
      name: 'American Samoa',
      sortname: 'AS',
    },
    {
      name: 'Arizona',
      sortname: 'AZ',
    },
    {
      name: 'Arkansas',
      sortname: 'AR',
    },
    {
      name: 'California',
      sortname: 'CA',
    },
    {
      name: 'Colorado',
      sortname: 'CO',
    },
    {
      name: 'Connecticut',
      sortname: 'CT',
    },
    {
      name: 'Delaware',
      sortname: 'DE',
    },
    {
      name: 'District Of Columbia',
      sortname: 'DC',
    },
    {
      name: 'Federated States Of Micronesia',
      sortname: 'FM',
    },
    {
      name: 'Florida',
      sortname: 'FL',
    },
    {
      name: 'Georgia',
      sortname: 'GA',
    },
    {
      name: 'Guam',
      sortname: 'GU',
    },
    {
      name: 'Hawaii',
      sortname: 'HI',
    },
    {
      name: 'Idaho',
      sortname: 'ID',
    },
    {
      name: 'Illinois',
      sortname: 'IL',
    },
    {
      name: 'Indiana',
      sortname: 'IN',
    },
    {
      name: 'Iowa',
      sortname: 'IA',
    },
    {
      name: 'Kansas',
      sortname: 'KS',
    },
    {
      name: 'Kentucky',
      sortname: 'KY',
    },
    {
      name: 'Louisiana',
      sortname: 'LA',
    },
    {
      name: 'Maine',
      sortname: 'ME',
    },
    {
      name: 'Marshall Islands',
      sortname: 'MH',
    },
    {
      name: 'Maryland',
      sortname: 'MD',
    },
    {
      name: 'Massachusetts',
      sortname: 'MA',
    },
    {
      name: 'Michigan',
      sortname: 'MI',
    },
    {
      name: 'Minnesota',
      sortname: 'MN',
    },
    {
      name: 'Mississippi',
      sortname: 'MS',
    },
    {
      name: 'Missouri',
      sortname: 'MO',
    },
    {
      name: 'Montana',
      sortname: 'MT',
    },
    {
      name: 'Nebraska',
      sortname: 'NE',
    },
    {
      name: 'Nevada',
      sortname: 'NV',
    },
    {
      name: 'New Hampshire',
      sortname: 'NH',
    },
    {
      name: 'New Jersey',
      sortname: 'NJ',
    },
    {
      name: 'New Mexico',
      sortname: 'NM',
    },
    {
      name: 'New York',
      sortname: 'NY',
    },
    {
      name: 'North Carolina',
      sortname: 'NC',
    },
    {
      name: 'North Dakota',
      sortname: 'ND',
    },
    {
      name: 'Northern Mariana Islands',
      sortname: 'MP',
    },
    {
      name: 'Ohio',
      sortname: 'OH',
    },
    {
      name: 'Oklahoma',
      sortname: 'OK',
    },
    {
      name: 'Oregon',
      sortname: 'OR',
    },
    {
      name: 'Palau',
      sortname: 'PW',
    },
    {
      name: 'Pennsylvania',
      sortname: 'PA',
    },
    {
      name: 'Puerto Rico',
      sortname: 'PR',
    },
    {
      name: 'Rhode Island',
      sortname: 'RI',
    },
    {
      name: 'South Carolina',
      sortname: 'SC',
    },
    {
      name: 'South Dakota',
      sortname: 'SD',
    },
    {
      name: 'Tennessee',
      sortname: 'TN',
    },
    {
      name: 'Texas',
      sortname: 'TX',
    },
    {
      name: 'Utah',
      sortname: 'UT',
    },
    {
      name: 'Vermont',
      sortname: 'VT',
    },
    {
      name: 'Virgin Islands',
      sortname: 'VI',
    },
    {
      name: 'Virginia',
      sortname: 'VA',
    },
    {
      name: 'Washington',
      sortname: 'WA',
    },
    {
      name: 'West Virginia',
      sortname: 'WV',
    },
    {
      name: 'Wisconsin',
      sortname: 'WI',
    },
    {
      name: 'Wyoming',
      sortname: 'WY',
    },

  ];
  
  public static shipmentTypeArray = {
    inProgress: [
      {
        slugName: 'changeDriver',
        labelName: 'Change Driver',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'navigateToPickup',
        labelName: 'Navigate To Pickup',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'reachedInPickupGeofence',
        labelName: 'Reached In Pickup Geo Fence',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'atPickupLocation',
        labelName: 'At Pickup Location',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'shipmentLoading',
        labelName: 'Shipment Loading',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'shipmentLoaded',
        labelName: 'Shipment Loaded',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'verifyingPickupDocuments',
        labelName: 'Verifying Pickup Documents',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'accident',
        labelName: 'Accident',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'rest',
        labelName: 'Rest',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'underMaintenance',
        labelName: 'Under Maintenance',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'etc',
        labelName: 'Etc',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'navigateToDropOff',
        labelName: 'Navigate To DropOff',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'reachedAtDropOffGeofence',
        labelName: 'Reached At DropOff Geo Fence',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'atDropOffLocation',
        labelName: 'At DropOff Location',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'shipmentUnloading',
        labelName: 'Shipment Unloading',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'shipmentUnloaded',
        labelName: 'Shipment Unloaded',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'verifyingDropOffDocuments',
        labelName: 'Verifying DropOff Documents',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
    ],
    upcoming: [
      {
        slugName: 'accept',
        labelName: 'Accept',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
    ],
    past: [
      {
        slugName: 'cancel',
        labelName: 'Canceled',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'delivered',
        labelName: 'Delivered',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'completed',
        labelName: 'Completed',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'paymentReleased',
        labelName: 'Payment Released',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'paymentInitiated',
        labelName: 'Payment Initiated',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'paymentOnHold',
        labelName: 'Payment On Hold',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
    ],
    expired: [
      {
        slugName: 'expired',
        labelName: 'Expired',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
    ],
    pending: [
      { slugName: 'draft', labelName: 'Draft', isActiveForRoles: [] },
      {
        slugName: 'create',
        labelName: 'Create',
        isActiveForRoles: ['CARRIER'],
      },
      {
        slugName: 'assign',
        labelName: 'Assigned',
        isActiveForRoles: ['CARRIER'],
      },
      {
        slugName: 'reject',
        labelName: 'Rejected',
        isActiveForRoles: ['CARRIER'],
      },
      { slugName: 'unAssign', labelName: 'Unassigned', isActiveForRoles: [] },
      {
        slugName: 'paymentConfirmed',
        labelName: 'Payment Confirmed',
        isActiveForRoles: [],
      },
      {
        slugName: 'assignToCarrier',
        labelName: 'Assign To Carrier',
        isActiveForRoles: ['SHIPPER'],
      },
    ],
    newDispute: [
      { slugName: 'dispute', labelName: 'Dispute', isActiveForRoles: [] },
    ],

    ongoingDispute: [
      { slugName: 'dispute', labelName: 'Dispute', isActiveForRoles: [] },
    ],

    resolvedDispute: [
      { slugName: 'dispute', labelName: 'Dispute', isActiveForRoles: [] },
    ],
  };
  public static messageNotifictionList = [
    { slugName: 'changeDriver', labelName: 'Change Driver' },
    { slugName: 'navigateToPickup', labelName: 'Navigate To Pickup' },
    {
      slugName: 'reachedInPickupGeofence',
      labelName: 'Reached In Pickup Geo Fence',
    },
    { slugName: 'atPickupLocation', labelName: 'At Pickup Location' },
    { slugName: 'shipmentLoading', labelName: 'Shipment Loading' },
    { slugName: 'shipmentLoaded', labelName: 'Shipment Loaded' },
    {
      slugName: 'verifyingPickupDocuments',
      labelName: 'Verifying Pickup Documents',
    },
    { slugName: 'accident', labelName: 'Accident' },
    { slugName: 'rest', labelName: 'Rest' },
    { slugName: 'underMaintenance', labelName: 'Under Maintenance' },
    { slugName: 'etc', labelName: 'Etc' },
    { slugName: 'navigateToDropOff', labelName: 'Navigate To DropOff' },
    {
      slugName: 'reachedAtDropOffGeofence',
      labelName: 'Reached At DropOff Geo Fence',
    },
    { slugName: 'atDropOffLocation', labelName: 'At DropOff Location' },
    { slugName: 'shipmentUnloading', labelName: 'Shipment Unloading' },
    { slugName: 'shipmentUnloaded', labelName: 'Shipment Unloaded' },
    {
      slugName: 'verifyingDropOffDocuments',
      labelName: 'Verifying DropOff Documents',
    },
    { slugName: 'unAssign', labelName: 'Unassign' },
    { slugName: 'accept', labelName: 'Accepted Load' },
    { slugName: 'cancel', labelName: 'Cancel Load' },
    { slugName: 'delivered', labelName: 'Delivered Load' },
    { slugName: 'assign', labelName: 'Assign Load' },
    { slugName: 'reject', labelName: 'Rejected Load' },
    { slugName: 'unDelivered', labelName: 'UnDelivered' },
    { slugName: 'dispute', labelName: 'Dispute' },
    {
      slugName: 'newAccount',
      labelName: 'Congratulations! your account has been successfully created',
    },
    {
      slugName: 'newCompleteProfile',
      labelName: 'Please click here and update your profile',
    },
    { slugName: 'driverRegistered', labelName: 'Driver registered with us' },
    {
      slugName: 'paymentSuccess',
      labelName: 'Load has been purchased successfully',
    },
    { slugName: 'paymentFailed', labelName: 'Payment is Failed' },
  ];
  public static notificcation = [
    {
      class: 'noti_ship_accept',
      newClass: 'icon-ic-box',
    },
    {
      class: 'noti_ship_reject',
      newClass: 'icon-ic-box',
    },
    {
      class: 'noti_ship_undelivered',
      newClass: 'icon-ic-box',
    },
    {
      class: 'noti_ship_delivered',
      newClass: 'icon-ic-box',
    },
    {
      class: 'noti_ship_picup',
      newClass: 'icon-ic-pickup',
    },
    {
      class: 'noti_ship_dispute',
      newClass: 'icon-ic-dispute',
    },
    {
      class: 'noti_ship_accident',
      newClass: 'icon-ic-driver',
    },
    {
      class: 'noti_ship_driRegistered',
      newClass: 'icon-ic-driver',
    },
    {
      class: 'noti_ship_add_request',
      newClass: 'icon-ic-person',
    },
    {
      class: 'noti_ship_message',
      newClass: 'icon-ic-person',
    },
    {
      class: 'noti_ship_paymentSucc',
      newClass: 'icon-ic-money',
    },
    {
      class: 'noti_ship_paymentSucc',
      newClass: 'icon-ic-money',
    },
    {
      class: 'noti_ship_paymentFailed',
      newClass: 'icon-ic-money',
    },
    {
      class: 'noti_invite_request',
      newClass: 'icon-ic-envelope',
    },
    {
      class: 'noti_reinvite_request',
      newClass: 'icon-ic-envelope',
    },
    {
      class: 'noti_connected_request',
      newClass: 'icon-ic-envelope',
    },
  ];

  // public static chargesAsPerDays = {
  //   5: 2,
  //   10: 2.5,
  //   15: 3,
  //   20: 3.5,
  //   25: 4,
  //   30: 4.5,
  // };

  public static shipmentStatusType = {
    inProgress: [
      'changeDriver',
      'navigateToPickup',
      'reachedInPickupGeofence',
      'atPickupLocation',
      'shipmentLoading',
      'shipmentLoaded',
      'verifyingPickupDocuments',
      'accident',
      'rest',
      'underMaintenance',
      'etc',
      'navigateToDropOff',
      'reachedAtDropOffGeofence',
      'atDropOffLocation',
      'shipmentUnloading',
      'shipmentUnloaded',
      'verifyingDropOffDocuments',
    ],
    upcoming: ['accept'],
    past: ['cancel', 'delivered'],
    pending: ['assign', 'reject', 'unDelivered', 'dispute', 'unAssign'],
  };
  public static pickUpTime = [
    {
      time: '00:00 - 02:00',
      id: 1,
      disabled: false,
    },
    {
      time: '02:00 - 04:00',
      id: 2,
      disabled: false,
    },
    {
      time: '04:00 - 06:00',
      id: 3,
      disabled: false,
    },
    {
      time: '06:00 - 08:00',
      id: 4,
      disabled: false,
    },
    {
      time: '08:00 - 10:00',
      id: 5,
      disabled: false,
    },
    {
      time: '10:00 - 12:00',
      id: 6,
      disabled: false,
    },
    {
      time: '12:00 - 14:00',
      id: 7,
      disabled: false,
    },
    {
      time: '14:00 - 16:00',
      id: 8,
      disabled: false,
    },
    {
      time: '16:00 - 18:00',
      id: 9,
      disabled: false,
    },
    {
      time: '18:00 - 20:00',
      id: 10,
      disabled: false,
    },
    {
      time: '20:00 - 22:00',
      id: 11,
      disabled: false,
    },
    {
      time: '22:00 - 23:59',
      id: 12,
      disabled: false,
    },
  ];

  public static dropOffTime = [
    {
      time: '00:00 - 02:00',
      id: 1,
      disabled: false,
    },
    {
      time: '02:00 - 04:00',
      id: 2,
      disabled: false,
    },
    {
      time: '04:00 - 06:00',
      id: 3,
      disabled: false,
    },
    {
      time: '06:00 - 08:00',
      id: 4,
      disabled: false,
    },
    {
      time: '08:00 - 10:00',
      id: 5,
      disabled: false,
    },
    {
      time: '10:00 - 12:00',
      id: 6,
      disabled: false,
    },
    {
      time: '12:00 - 14:00',
      id: 7,
      disabled: false,
    },
    {
      time: '14:00 - 16:00',
      id: 8,
      disabled: false,
    },
    {
      time: '16:00 - 18:00',
      id: 9,
      disabled: false,
    },
    {
      time: '18:00 - 20:00',
      id: 10,
      disabled: false,
    },
    {
      time: '20:00 - 22:00',
      id: 11,
      disabled: false,
    },
    {
      time: '22:00 - 23:59',
      id: 12,
      disabled: false,
    },
  ];

  public static timeSet = [
    {
      time: '00:00 - 02:00',
      id: 1,
      disabled: false,
    },
    {
      time: '02:00 - 04:00',
      id: 2,
      disabled: false,
    },
    {
      time: '04:00 - 06:00',
      id: 3,
      disabled: false,
    },
    {
      time: '06:00 - 08:00',
      id: 4,
      disabled: false,
    },
    {
      time: '08:00 - 10:00',
      id: 5,
      disabled: false,
    },
    {
      time: '10:00 - 12:00',
      id: 6,
      disabled: false,
    },
    {
      time: '12:00 - 14:00',
      id: 7,
      disabled: false,
    },
    {
      time: '14:00 - 16:00',
      id: 8,
      disabled: false,
    },
    {
      time: '16:00 - 18:00',
      id: 9,
      disabled: false,
    },
    {
      time: '18:00 - 20:00',
      id: 10,
      disabled: false,
    },
    {
      time: '20:00 - 22:00',
      id: 11,
      disabled: false,
    },
    {
      time: '22:00 - 23:59',
      id: 12,
      disabled: false,
    },
  ];

  public static contyList = [
    {
      code: '1',
      flag: "assets/country/us.png",
      short: 'US',
      name: 'United States of America'
    },
    {
      code: '1',
      flag: "assets/country/ca.png",
      short: 'CA',
      name: 'Canada',
    },
    {
      code: '52',
      flag: "assets/country/mx.png",
      short: 'MX',
      name: 'Mexico',
    },
  ];

  public static usTimeZoneList = {
    AL: 'CST',
    AK: 'AKST',
    AZ: 'MST',
    AR: 'CST',
    CA: 'PST',
    CO: 'MST',
    CT: 'EST',
    DC: 'EST',
    DE: 'EST',
    FL: 'EST',
    GA: 'EST',
    GU: 'CHST',
    HI: 'HST',
    ID: 'MST',
    IL: 'CST',
    IN: 'EST',
    IA: 'CST',
    KS: 'CST',
    KY: 'CST',
    LA: 'CST',
    ME: 'EST',
    MD: 'EST',
    MA: 'EST',
    MI: 'EST',
    MN: 'CST',
    MS: 'CST',
    MO: 'CST',
    MT: 'MST',
    NE: 'CST',
    NV: 'PST',
    NH: 'EST',
    NJ: 'EST',
    NM: 'MST',
    NY: 'EST',
    NC: 'EST',
    ND: 'CST',
    OH: 'EST',
    OK: 'CST',
    OR: 'PST',
    PA: 'EST',
    RI: 'EST',
    SC: 'EST',
    SD: 'CST',
    TN: 'CST',
    TX: 'CST',
    UT: 'MST',
    VA: 'EST',
    WA: 'PST',
    WV: 'EST',
    WI: 'CST',
    WY: 'MST',
  };

  public static NewStateTimeZoneList = {
    AL: 'CST',
    AK: 'AKST',
    AZ: 'MST',
    AR: 'CST',
    CA: 'PST',
    CO: 'MST',
    CT: 'EST',
    DC: 'EST',
    DE: 'EST',
    FL: 'EST',
    GA: 'EST',
    GU: 'CHST',
    HI: 'HST',
    ID: 'MST',
    IL: 'CST',
    IN: 'EST',
    IA: 'CST',
    KS: 'CST',
    KY: 'CST',
    LA: 'CST',
    ME: 'EST',
    MD: 'EST',
    MA: 'EST',
    MI: 'EST',
    MN: 'CST',
    MS: 'CST',
    MO: 'CST',
    MT: 'MST',
    NE: 'CST',
    NV: 'PST',
    NH: 'EST',
    NJ: 'EST',
    NM: 'MST',
    NY: 'EST',
    NC: 'EST',
    ND: 'CST',
    OH: 'EST',
    OK: 'CST',
    OR: 'PST',
    PA: 'EST',
    RI: 'EST',
    SC: 'EST',
    SD: 'CST',
    TN: 'CST',
    TX: 'CST',
    UT: 'MST',
    VA: 'EST',
    WA: 'PST',
    WV: 'EST',
    WI: 'CST',
    WY: 'MST',
    BC: 'PST',
    AB: 'MST',
    YT: 'MST',
    NT: 'MST',
    SK: 'CST',
    MB: 'CST',
    ON: 'EST',
    QC: 'EST',
    NU: 'EST',
    NS: 'AST',
    NB: 'AST',
    PE: 'AST',
    NL: 'AST',
    NF: 'NST',
    AG: 'CST',
    BS: 'MST',
    CM: 'CST',
    CS: 'CST',
    CH: 'CST',
    CDMX: 'CST',
    CL: 'CST',
    DG: 'CST',
    GT: 'CST',
    GR: 'CST',
    HG: 'CST',
    JA: 'CST',
    NA: 'CST',
    OA: 'CST',
    PU: 'CST',
    QT: 'CST',
    QR: 'EST',
    SL: 'CST',
    SI: 'MST',
    SO: 'MST',
    TB: 'CST',
    TM: 'CST',
    TL: 'CST',
    VE: 'CST',
    YU: 'CST',
    ZA: 'CST'
  };



  public static trailerSpecs =
    ['Air-ride',
      'Blanket wrap',
      'B-train',
      'Chains',
      'Chassis',
      'Curtains',
      'E-track',
      'Extendable',
      'Haz-mat',
      'Hot shot',
      'Insulated',
      'Intermodal',
      'Lift-gate',
      'Load ramps',
      'Load out',
      'Pallet exchange',
      'Side kit',
      'Tarps',
      'Team driver',
      'Temp-controlled',
      'Vented',
      'Walking floor'
    ];

  // User Guide.
    
  public static userGuide = [
      {
        title:"Network Tutorial Guides" ,
        time:"3",
        subTitle:[ 
          {
            title:"How to Send New Connections",
            url:"/user-guide/send-new-connection",
            id:"send-new-connection",
            scribeUrl:"https://scribehow.com/embed/How_To_Create_a_New_Lane__XyF0l5-nSc61tN4pH9iOoA?as=scrollable",
            videoUrl:"https://www.youtube.com/embed/1ozGKlOzEVc"
          },
          {
            title:"How to Accept Connections",
            url:"/user-guide/accept-connection",
            id:"accept-connection",
            scribeUrl:"https://scribehow.com/embed/How_To_Create_a_New_Lane__XyF0l5-nSc61tN4pH9iOoA?as=scrollable",
            videoUrl:"https://www.youtube.com/embed/1ozGKlOzEVc"
          },
          {
            title:"How to Filter Search",
            url:"/user-guide/filter-search",
            id:"filter-search",
            scribeUrl:"https://scribehow.com/embed/How_To_Create_a_New_Lane__XyF0l5-nSc61tN4pH9iOoA?as=scrollable",
            videoUrl:"https://www.youtube.com/embed/1ozGKlOzEVc"
          },
          {
            title:"Marking a Carrier as Preferred",
            url:"/user-guide/carrier-preferred",
            id:"carrier-preferred",
            scribeUrl:"https://scribehow.com/embed/How_To_Create_a_New_Lane__XyF0l5-nSc61tN4pH9iOoA?as=scrollable",
            videoUrl:"https://www.youtube.com/embed/1ozGKlOzEVc"
          },
          {
            title:"How to Invite Carriers",
            url:"/user-guide/archived-lane",
            id:"archived-lane",
            scribeUrl:"https://scribehow.com/embed/How_To_Create_a_New_Lane__XyF0l5-nSc61tN4pH9iOoA?as=scrollable",
            videoUrl:"https://www.youtube.com/embed/1ozGKlOzEVc"
          }
        ]
      },
      {
        title:"Lanes Tutorial Guides" ,
        time:"3",
        subTitle:[ 
          {
            title:"How to create a lane",
            url:"/user-guide/create-lane",
            id:"create-lane",
            scribeUrl:"https://scribehow.com/embed/How_To_Create_a_New_Lane__XyF0l5-nSc61tN4pH9iOoA?as=scrollable",
            videoUrl:"https://www.youtube.com/embed/1ozGKlOzEVc"
          },
          {
            title:"How to accept a bid on shipment",
            url:"/user-guide/lane-accept-bid",
            id:"lane-accept-bid",
            scribeUrl:"https://scribehow.com/embed/How_To_Create_a_New_Lane__XyF0l5-nSc61tN4pH9iOoA?as=scrollable",
            videoUrl:"https://www.youtube.com/embed/1ozGKlOzEVc"
          },
          {
            title:"How to create shipment from lane",
            url:"/user-guide/create-shipment-by-lane",
            id:"create-shipment-by-lane",
            scribeUrl:"https://scribehow.com/embed/How_To_Create_a_New_Lane__XyF0l5-nSc61tN4pH9iOoA?as=scrollable",
            videoUrl:"https://www.youtube.com/embed/1ozGKlOzEVc"
          },
          {
            title:"How to re-bid on a lane",
            url:"/user-guide/lane-accept-rebid",
            id:"lane-accept-rebid",
            scribeUrl:"https://scribehow.com/embed/How_To_Create_a_New_Lane__XyF0l5-nSc61tN4pH9iOoA?as=scrollable",
            videoUrl:"https://www.youtube.com/embed/1ozGKlOzEVc"
          },
          {
            title:"How to archive a lane",
            url:"/user-guide/archived-lane",
            id:"archived-lane",
            scribeUrl:"https://scribehow.com/embed/How_To_Create_a_New_Lane__XyF0l5-nSc61tN4pH9iOoA?as=scrollable",
            videoUrl:"https://www.youtube.com/embed/1ozGKlOzEVc"
          }
        ]
      },
      {
        title:"Shipment Tutorial Guides" ,
        time:"3",
        subTitle:[ 
          {
            title:"How Direct Payout Works",
            url:"/user-guide/direct-payout-work",
            scribeUrl:"https://scribehow.com/embed/How_To_Create_a_New_Lane__XyF0l5-nSc61tN4pH9iOoA?as=scrollable",
            videoUrl:"https://www.youtube.com/embed/1ozGKlOzEVc"
          },
          {
            title:"24 hr window for dispute",
            url:"/user-guide/dispute",
            scribeUrl:"https://scribehow.com/embed/How_To_Create_a_New_Lane__XyF0l5-nSc61tN4pH9iOoA?as=scrollable",
            videoUrl:"https://www.youtube.com/embed/1ozGKlOzEVc"
          },
          {
            title:"How to edit appointment time, pick-up location and drop off location",
            url:"/user-guide/route-plan",
            scribeUrl:"https://scribehow.com/embed/How_To_Create_a_New_Lane__XyF0l5-nSc61tN4pH9iOoA?as=scrollable",
            videoUrl:"https://www.youtube.com/embed/1ozGKlOzEVc"
          },
          {
            title:"How to Rename a Shipment Title",
            url:"/user-guide/edit-shipment",
            scribeUrl:"https://scribehow.com/embed/How_To_Create_a_New_Lane__XyF0l5-nSc61tN4pH9iOoA?as=scrollable",
            videoUrl:"https://www.youtube.com/embed/1ozGKlOzEVc"
          },
          {
            title:"How assign a load to a driver",
            url:"/user-guide/assign-driver",
            scribeUrl:"https://scribehow.com/embed/How_To_Create_a_New_Lane__XyF0l5-nSc61tN4pH9iOoA?as=scrollable",
            videoUrl:"https://www.youtube.com/embed/1ozGKlOzEVc"
          }
        ]
      },
    ]    

  public static carrierTutotrial = {
    "Bookings" : {
      "title": "Booking Tutorial Guides",
      "items" : [ 
        {
          tutorialLab:"How to bid on a lane",
          tutorialViewGuide:"/tutorial/carrier/booking/bid-on-lane",
          tutorialId:"bid-on-lane",
          tutorialdTime:"3 minutes",
          tutorialdStep:"8 Steps",
          tutorialScribePortal:"https://scribehow.com/shared/How-To_Bid_on_Lanes__VffZFlR_T8em2YQpDbWC2Q",
          tutorialScribeMobile:"https://scribehow.com/shared/How_to_Bid_on_a_Lane__EI3h6373TVGV5tAIV9wjkg",
          tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
          tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
          ismoduleFeatured: 1,
          isSystemFeatured: 1,
          islistvisiable: 0
        },
        {
          tutorialLab:"Lane Confirmed: What to do after a lane is confimed",
          tutorialViewGuide:"/tutorial/carrier/booking/lane-confirmed",
          tutorialId:"lane-confirmed",
          tutorialdTime:"3 minutes",
          tutorialdStep:"8 Steps",
          tutorialScribePortal:"https://scribehow.com/shared/Confirmed_Lane_What_to_do_after_a_Lane_is_Confirmed__t0QgngzXRe2Oe5KVC0cXTw",
          tutorialScribeMobile:"https://scribehow.com/shared/Lane_Confirmed_What_to_do_after_a_lane_is_confimed__hEMdgtFJS1yVy-LBMF7svg",
          tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
          tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
          ismoduleFeatured: 1,
          isSystemFeatured: 0,
          islistvisiable: 0,
        },
        {
          tutorialLab:"How to view all Available Lanes and Confirmed Lanes",
          tutorialViewGuide:"/tutorial/carrier/booking/available-confirmed-lanes",
          tutorialId:"available-confirmed-lanes",
          tutorialdTime:"3 minutes",
          tutorialdStep:"8 Steps",
          tutorialScribePortal:"https://scribehow.com/shared/Carrier_How-to_View_all_Available_Lanes_and_Confirmed_Lanes__GUw2T0fmT8S6JcAA6LnfNA",
          tutorialScribeMobile:"https://scribehow.com/shared/CARRIER__How_To_all_Available_Lanes_and_Confirmed_Lanes__rfmOvx2lTqmya0psPM9owg",
          tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
          tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
          ismoduleFeatured: 1,
          isSystemFeatured: 0,
          islistvisiable: 0,
        },
        {
          tutorialLab:"How to accept an Offer Rate on a Lane",
          tutorialViewGuide:"/tutorial/carrier/booking/offer-rate-lane",
          tutorialId:"offer-rate-lane",
          tutorialdTime:"3 minutes",
          tutorialdStep:"8 Steps",
          tutorialScribePortal:"https://scribehow.com/shared/CARRIER__How_to_Accept_an_Offer_Rate_on_a_Lane__Mobile__dMBHxQGBRESeHv09nCUQzg",
          tutorialScribeMobile:"https://scribehow.com/shared/CARRIER__How_to_Accept_an_Offer_Rate_on_a_Lane__Mobile__dMBHxQGBRESeHv09nCUQzg",
          tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
          tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
          ismoduleFeatured: 0,
          isSystemFeatured: 0,
          islistvisiable: 0,
        },
      ]
    },
    "Network" : {
      "title": "Network Tutorial Guides",
      "items" : [ 
        {
          tutorialLab:"How to Send New Connections",
          tutorialViewGuide:"/tutorial/carrier/network/send-new-connection",
          tutorialId:"send-new-connection",
          tutorialdTime:"3 minutes",
          tutorialdStep:"8 Steps",
          tutorialScribePortal:"https://scribehow.com/shared/How_to_send_a_New_Connection__8DWfHwT7Tt629tChEJ3ftg",
          tutorialScribeMobile:"https://scribehow.com/shared/How_to_Send_a_New_Connection__sqZWPsb4SXKhJsP4M23bsQ",
          tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
          tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
          ismoduleFeatured: 1,
          isSystemFeatured: 0,
          islistvisiable: 0
        },
        {
          tutorialLab:"How to Accept Connections",
          tutorialViewGuide:"/tutorial/carrier/network/accept-connection",
          tutorialId:"accept-connection",
          tutorialdTime:"3 minutes",
          tutorialdStep:"8 Steps",
          tutorialScribePortal:"",
          tutorialScribeMobile:"https://scribehow.com/shared/How_to_Accept_a_Shipper_Network_invite__Mobile__BQrIkXdoTnakau3oEqylag",
          tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
          tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
          ismoduleFeatured: 1,
          isSystemFeatured: 1,
          islistvisiable: 0,
        },
        {
          tutorialLab:"How to Filter Search",
          tutorialViewGuide:"/tutorial/carrier/network/filter-search",
          tutorialId:"filter-search",
          tutorialdTime:"3 minutes",
          tutorialdStep:"8 Steps",
          tutorialScribePortal:"https://scribehow.com/shared/How_to_Filter_Search_in_the_Network__28TjOq-tSHuRmFy4ZJyvRQ",
          tutorialScribeMobile:"https://scribehow.com/shared/How_to_search_for_shippers_on_the_Network__Mobile__xwp3WlxfR_Wuiway93pmeg",
          tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
          tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
          ismoduleFeatured: 1,
          isSystemFeatured: 0,
          islistvisiable: 0,
        },
        {
          tutorialLab:"Marking a Shipper as Preferred ",
          tutorialViewGuide:"/tutorial/carrier/network/shipper-preferred",
          tutorialId:"shipper-preferred",
          tutorialdTime:"3 minutes",
          tutorialdStep:"8 Steps",
          tutorialScribePortal:"https://scribehow.com/shared/How_to_Mark_a_Shipper_as_Preferred__LpNa1smLRHW7oxmIe_xrsA",
          tutorialScribeMobile:"https://scribehow.com/shared/How_to_Mark_a_Shipper_as_Preferred__ZN-ABt1NTZaTh4cq1zZ3Jw",
          tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
          tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
          ismoduleFeatured: 0,
          isSystemFeatured: 0,
          islistvisiable: 0,
        },
      ]
    },
    "Shipments" : {
      "title": "Shipment Tutorial Guides",
      "items" :[ 
      {
        tutorialLab:"How to quote/bid on spot rate load",
        tutorialViewGuide:"/tutorial/carrier/shipment/spot-rate-load",
        tutorialId:"spot-rate-load",
        tutorialdTime:"3 minutes",
        tutorialdStep:"8 Steps",
        tutorialScribePortal:"https://scribehow.com/shared/How_to_QuoteBid_on_a_Load__VRzAMy5uTOe58Vfd39S76g",
        tutorialScribeMobile:"https://scribehow.com/shared/Carrier__How_to_QuoteBid_on_a_Load_Mobile__ezLy7m-1Qc-u25XGY3P3WA",
        tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
        tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
        ismoduleFeatured: 1,
	      isSystemFeatured: 0,
	      islistvisiable: 0,
      },
      {
        tutorialLab:"How to confirm/accept a load",
        tutorialViewGuide:"/tutorial/carrier/shipment/accept-load",
        tutorialId:"accept-load",
        tutorialdTime:"3 minutes",
        tutorialdStep:"8 Steps",
        tutorialScribePortal:"https://scribehow.com/shared/How_to_Confirm_and_Accept_a_Load__uA9g9njoTWyh3Ucrx_taSw",
        tutorialScribeMobile:"https://scribehow.com/shared/Carrier_How_to_Confirm_and_Accept_an_Load_Mobile__oxgtE3UqR_aZsdpod-3dbg",
        tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
        tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
        ismoduleFeatured: 1,
	      isSystemFeatured: 0,
	      islistvisiable: 0,
      },
      {
        tutorialLab:"How to assign a load to a driver",
        tutorialViewGuide:"/tutorial/carrier/shipment/assign-load-to-driver",
        tutorialId:"assign-load-to-driver",
        tutorialdTime:"3 minutes",
        tutorialdStep:"8 Steps",
        tutorialScribePortal:"https://scribehow.com/shared/Carrier_How_to_Confirm_and_Accept_an_Load_Mobile__oxgtE3UqR_aZsdpod-3dbg",
        tutorialScribeMobile:"https://scribehow.com/shared/Carrier_How_to_Assign_a_Shipment_to_a_Driver_Mobile__9KwXfyyHRrGnoD9pPBYeDw",
        tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
        tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
        ismoduleFeatured: 1,
        isSystemFeatured: 1,
	      islistvisiable: 0,
      },
      {

        tutorialLab:"How to message the shipper",
        tutorialViewGuide:"/tutorial/carrier/shipment/message-shipper",
        tutorialId:"message-shipper",
        tutorialdTime:"3 minutes",
        tutorialdStep:"8 Steps",
        tutorialScribePortal:"",
        tutorialScribeMobile:"",
        tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
        tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
        ismoduleFeatured: 0,
	      isSystemFeatured: 0,
	      islistvisiable: 0,
      },
      {
        tutorialLab:"How to submit a counter quote/bid ",
        tutorialViewGuide:"/tutorial/carrier/shipment/counter-quote-bid",
        tutorialId:"counter-quote-bid",
        tutorialdTime:"3 minutes",
        tutorialdStep:"8 Steps",
        tutorialScribePortal:"https://scribehow.com/shared/How_to_Submit_a_Counter_QuoteBid_for_a_Shipment__aUFrgN6bSkCkYiPg0w3R2w",
        tutorialScribeMobile:"https://scribehow.com/shared/Carrier__How_to_QuoteBid_on_a_Load_Mobile_Copy__RKzxWtT7ScKrAjtGn4wxbg",
        tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
        tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
        ismoduleFeatured: 0,
        isSystemFeatured: 1,
	      islistvisiable: 0,
      },
      {
        tutorialLab:"How to View shipment information",
        tutorialViewGuide:"/tutorial/carrier/shipment/view-shipment-information",
        tutorialId:"view-shipment-information",
        tutorialdTime:"3 minutes",
        tutorialdStep:"8 Steps",
        tutorialScribePortal:"",
        tutorialScribeMobile:"",
        tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
        tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
        ismoduleFeatured: 0,
	      isSystemFeatured: 0,
	      islistvisiable: 0,
      },
      {
        tutorialLab:"Difference between lanes and loads",
        tutorialViewGuide:"/tutorial/carrier/shipment/lanes-and-loads",
        tutorialId:"lanes-and-loads",
        tutorialdTime:"3 minutes",
        tutorialdStep:"8 Steps",
        tutorialScribePortal:"https://scribehow.com/shared/Carrier_Difference_Between_Lanes_and_Loads__rbneSW1HRca8kfm64SYIFw",
        tutorialScribeMobile:"https://scribehow.com/shared/Carrier_Difference_Between_Lanes_and_Loads__rbneSW1HRca8kfm64SYIFw",
        tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
        tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
        ismoduleFeatured: 1,
	      isSystemFeatured: 0,
	      islistvisiable: 0,
      }
    ]
   },
   "Drivers" : {
    "title": "Drivers Tutorial Guides",
    "items" :[ 
    {
      tutorialLab:"How to add drivers",
      tutorialViewGuide:"/tutorial/carrier/drivers/add-driver",
      tutorialId:"add-driver",
      tutorialdTime:"3 minutes",
      tutorialdStep:"8 Steps",
      tutorialScribePortal:"https://scribehow.com/shared/How-To_Add_Drivers__MrisQEEjRyyiQzHZr1jAuQ",
      tutorialScribeMobile:"https://scribehow.com/shared/Carrier_How_to_Add_Drivers__Mobile__v6bfC5akQYyGqQvODAGr3Q",
      tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
      tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
      ismoduleFeatured: 1,
      isSystemFeatured: 0,
      islistvisiable: 0,
    },
    {
      tutorialLab:"How to check driver details and edit a drivers profile",
      tutorialViewGuide:"/tutorial/carrier/drivers/driver-detail-edit-profile",
      tutorialId:"driver-detail-edit-profile",
      tutorialdTime:"3 minutes",
      tutorialdStep:"8 Steps",
      tutorialScribePortal:"https://scribehow.com/shared/Carrier_How_to_Check_Driver_Details__BIGUMBWWSz-ZqKrJli25FQ",
      tutorialScribeMobile:"https://scribehow.com/shared/Carrier_How_to_Check_Driver_Details_and_Edit_a_Drivers_Profile__Mobile__JYsbAACAQTWpqiWACDQWVw",
      tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
      tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
      ismoduleFeatured: 1,
      isSystemFeatured: 0,
      islistvisiable: 0,
    },
  ]
  },
  "Messages" : {
    "title": "Messages Tutorial Guides",
    "items" :[ 
    {
      tutorialLab:"How to view Active Shipment messages",
      tutorialViewGuide:"/tutorial/carrier/messaging/active-shipment-messages",
      tutorialId:"active-shipment-messages",
      tutorialdTime:"3 minutes",
      tutorialdStep:"8 Steps",
      tutorialScribePortal:"https://scribehow.com/shared/How-To_Add_Drivers__MrisQEEjRyyiQzHZr1jAuQ",
      tutorialScribeMobile:"https://scribehow.com/shared/Carrier_How_to_Add_Drivers__Mobile__v6bfC5akQYyGqQvODAGr3Q",
      tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
      tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
      ismoduleFeatured: 1,
      isSystemFeatured: 0,
      islistvisiable: 0,
    },
    ]
   },
  "Profile" : {
    "title": "Profile Tutorial Guides",
    "items" :[ 
    {
      tutorialLab:"How to change/update profile information",
      tutorialViewGuide:"/tutorial/carrier/profile/change-update-profile-information",
      tutorialId:"change-update-profile-information",
      tutorialdTime:"3 minutes",
      tutorialdStep:"8 Steps",
      tutorialScribePortal:"https://scribehow.com/shared/Carrier_How_to_ChangeUpdate_Profile_Information___ZYGlNsNScycFqfJF74cQw",
      tutorialScribeMobile:"https://scribehow.com/shared/Carrier_How_to_changeupdate_your_Profile_Details__Mobile__TEApB4X0SpWyqS-b8xU1VQ",
      tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
      tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
      ismoduleFeatured: 1,
      isSystemFeatured: 0,
      islistvisiable: 0,
    },
    {
      tutorialLab:"Upload photos",
      tutorialViewGuide:"/tutorial/carrier/profile/upload-photo",
      tutorialId:"upload-photo",
      tutorialdTime:"3 minutes",
      tutorialdStep:"8 Steps",
      tutorialScribePortal:"https://scribehow.com/shared/Carrier_How_to_changeupdate_your_Profile_Details__Mobile__TEApB4X0SpWyqS-b8xU1VQ",
      tutorialScribeMobile:"https://scribehow.com/shared/Carrier_How_to_ChangeUpdate_Profile_Information___ZYGlNsNScycFqfJF74cQw",
      tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
      tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
      ismoduleFeatured: 1,
      isSystemFeatured: 0,
      islistvisiable: 0,
    },
    {
      tutorialLab:"How to set Preferred Regions",
      tutorialViewGuide:"/tutorial/carrier/profile/set-preferred-regions",
      tutorialId:"set-preferred-regions",
      tutorialdTime:"3 minutes",
      tutorialdStep:"8 Steps",
      tutorialScribePortal:"",
      tutorialScribeMobile:"https://scribehow.com/shared/Carrier_How_to_Set_Preferred_Regions___DFKSKMIQyiMYoTSTI2bqQ",
      tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
      tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
      ismoduleFeatured: 0,
      isSystemFeatured: 0,
      islistvisiable: 0,
    },
    {
      tutorialLab:"How to Create a Connect Account",
      tutorialViewGuide:"/tutorial/shipper/profile/create-connect-account",
      tutorialId:"create-connect-account",
      tutorialdTime:"3 minutes",
      tutorialdStep:"8 Steps",
      tutorialScribePortal:"",
      tutorialScribeMobile:"",
      tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
      tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
      ismoduleFeatured: 1,
      isSystemFeatured: 0,
      islistvisiable: 0,
    },
    ]
   }
  }

  public static shipperTutotrial = {
    "Bookings" : {
      "title": "Booking Tutorial Guides",
      "items" : [ 
        {
          tutorialLab:"How to create a lane",
          tutorialViewGuide:"/tutorial/shipper/booking/create-a-lane",
          tutorialId:"create-a-lane",
          tutorialdTime:"3 minutes",
          tutorialdStep:"8 Steps",
          tutorialScribePortal:"https://scribehow.com/embed/How_To_Create_a_New_Lane__XyF0l5-nSc61tN4pH9iOoA?as=scrollable",
          tutorialScribeMobile:"https://scribehow.com/embed/How_to_create_a_Lane__Mobile__LgLWICBYRkWc3KiVc9atUg?as=scrollable",
          tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
          tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
          ismoduleFeatured: 1,
          isSystemFeatured: 0,
          islistvisiable: 0
        },
        {
          tutorialLab:"How to accept a bid on a lane",
          tutorialViewGuide:"/tutorial/shipper/booking/accept-bid-lane",
          tutorialId:"accept-bid-lane",
          tutorialdTime:"3 minutes",
          tutorialdStep:"8 Steps",
          tutorialScribePortal:"https://scribehow.com/shared/How_to_Accept_a_Bid_on_a_Lane__2OrPT2ajRZWbhe9f-zmd7g",
          tutorialScribeMobile:"https://scribehow.com/shared/How_to_Re-Bid_or_Accept_a_Bid_on_a_Lane___IKGErXqQ1O4d9hH7RNR1g",
          tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
          tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
          ismoduleFeatured: 0,
          isSystemFeatured: 0,
          islistvisiable: 0,
        },
        {
          tutorialLab:"How to create shipment from lane",
          tutorialViewGuide:"/tutorial/shipper/booking/create-shipment-fromt-lane",
          tutorialId:"create-shipment-fromt-lane",
          tutorialdTime:"3 minutes",
          tutorialdStep:"8 Steps",
          tutorialScribePortal:"https://scribehow.com/shared/How_to_Create_a_Shipment_from_a_Confirmed_Lane__JpWZYtXJQLmC2l8h7DJ5kg",
          tutorialScribeMobile:"https://scribehow.com/shared/How_to_Create_a_Shipment_from_a_Confirmed_Lane__JpWZYtXJQLmC2l8h7DJ5kg",
          tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
          tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
          ismoduleFeatured: 1,
          isSystemFeatured: 0,
          islistvisiable: 0,
        },
        {
          tutorialLab:"How to re-bid on a lane",
          tutorialViewGuide:"/tutorial/carrier/network/re-bid-lane",
          tutorialId:"re-bid-lane",
          tutorialdTime:"3 minutes",
          tutorialdStep:"8 Steps",
          tutorialScribePortal:"https://scribehow.com/shared/How_to_Submit_a_Re-bid_on_a_Lane__udpgczbnSdSGnggSzd3iIg",
          tutorialScribeMobile:"https://scribehow.com/shared/How_to_Re-Bid_or_Accept_a_Bid_on_a_Lane___IKGErXqQ1O4d9hH7RNR1g",
          tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
          tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
          ismoduleFeatured: 0,
          isSystemFeatured: 0,
          islistvisiable: 0,
        },
        {
          tutorialLab:"How to archive a lane",
          tutorialViewGuide:"/tutorial/shipper/network/archive-lane",
          tutorialId:"archive-lane",
          tutorialdTime:"3 minutes",
          tutorialdStep:"8 Steps",
          tutorialScribePortal:"https://scribehow.com/shared/How_to_Archive_a_Lane__fxsQ0LeBQIqA13jG-M-Rhw",
          tutorialScribeMobile:"",
          tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
          tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
          ismoduleFeatured: 0,
          isSystemFeatured: 0,
          islistvisiable: 0,
        },
        {
          tutorialLab:"View bids on lanes",
          tutorialViewGuide:"/tutorial/shipper/network/view-bids-lanes",
          tutorialId:"view-bids-lanes",
          tutorialdTime:"3 minutes",
          tutorialdStep:"8 Steps",
          tutorialScribePortal:"https://scribehow.com/embed/View_Bids_for_Lanes__p8gkWLvLQ7qYuXj-MXVR1g?as=scrollable",
          tutorialScribeMobile:"https://scribehow.com/shared/How_to_View_Bids_on_a_Lane__3XQSb0WKSSiZb66DEaAazA",
          tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
          tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
          ismoduleFeatured: 1,
          isSystemFeatured: 0,
          islistvisiable: 0,
        },
        {
          tutorialLab:"How to update information and location on a Lane",
          tutorialViewGuide:"/tutorial/shipper/network/update-lane-location",
          tutorialId:"update-lane-location",
          tutorialdTime:"3 minutes",
          tutorialdStep:"8 Steps",
          tutorialScribePortal:"https://scribehow.com/shared/Shipper_How_to_edit_information_on_a_Lane__desktop__5u73ehGFRViJ4pZIkkkNfA",
          tutorialScribeMobile:"https://scribehow.com/shared/How_to_edit_information_on_a_Lane__mobile__gpDd3vG5S5uh3bN3bZuAQA",
          tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
          tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
          ismoduleFeatured: 0,
          isSystemFeatured: 0,
          islistvisiable: 0,
        },
        {
          tutorialLab:"Difference between lanes and loads",
          tutorialViewGuide:"/tutorial/shipper/network/lanes-and-loads",
          tutorialId:"lanes-and-loads",
          tutorialdTime:"3 minutes",
          tutorialdStep:"8 Steps",
          tutorialScribePortal:"https://scribehow.com/shared/Carrier_Difference_Between_Lanes_and_Loads__rbneSW1HRca8kfm64SYIFw",
          tutorialScribeMobile:"https://scribehow.com/shared/Carrier_Difference_Between_Lanes_and_Loads__rbneSW1HRca8kfm64SYIFw",
          tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
          tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
          ismoduleFeatured: 1,
          isSystemFeatured: 0,
          islistvisiable: 0,
        },
      ]
    },
    "Network" : {
      "title": "Network Tutorial Guides",
      "items" : [ 
        {
          tutorialLab:"How to Send New Connections",
          tutorialViewGuide:"/tutorial/shipper/network/send-new-connection",
          tutorialId:"send-new-connection",
          tutorialdTime:"3 minutes",
          tutorialdStep:"8 Steps",
          tutorialScribePortal:"https://scribehow.com/shared/How_to_Send_New_Connections__sEbZz54YT86wl-VjWEq20Q",
          tutorialScribeMobile:"https://scribehow.com/embed/How_to_send_a_Network_Invite__Mobile__yuE3KfrOQjm6YLxhAYrKYg?as=scrollable",
          tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
          tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
          ismoduleFeatured: 1,
          isSystemFeatured: 1,
          islistvisiable: 0
        },
        {
          tutorialLab:"How to Accept Connections",
          tutorialViewGuide:"/tutorial/shipper/network/accept-connection",
          tutorialId:"accept-connection",
          tutorialdTime:"3 minutes",
          tutorialdStep:"8 Steps",
          tutorialScribePortal:"https://scribehow.com/shared/How_to_Accept_a_Network_Invitation__vbLIkV4UTAiAli3IyBxAwQ",
          tutorialScribeMobile:"https://scribehow.com/embed/How_to_view_and_accept_a_Network_invite__Mobile__K1zMJ5mKTn-2fPBRJz9X-Q?as=scrollable",
          tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
          tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
          ismoduleFeatured: 1,
          isSystemFeatured: 0,
          islistvisiable: 0,
        },
        {
          tutorialLab:"How to Filter Search",
          tutorialViewGuide:"/tutorial/shipper/network/filter-search",
          tutorialId:"filter-search",
          tutorialdTime:"3 minutes",
          tutorialdStep:"8 Steps",
          tutorialScribePortal:"https://scribehow.com/shared/How_to_Filter_Search_in_the_Network__mbv-rz7HTxOyusrVmb11mA",
          tutorialScribeMobile:"https://scribehow.com/embed/How_to_search_carriers_in_the_network__Mobile__s-KDq3_BTRO9G3cbk__8oQ?as=scrollable",
          tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
          tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
          ismoduleFeatured: 1,
          isSystemFeatured: 0,
          islistvisiable: 0,
        },
        {
          tutorialLab:"Marking a Carrier as Preferred",
          tutorialViewGuide:"/tutorial/shipper/network/carrier-preferred",
          tutorialId:"carrier-preferred",
          tutorialdTime:"3 minutes",
          tutorialdStep:"8 Steps",
          tutorialScribePortal:"https://scribehow.com/shared/How_to_Mark_a_Carrier_as_Preferred__fbW3I5azSiuGuZcvCcItPA",
          tutorialScribeMobile:"https://scribehow.com/embed/How_to_make_a_carrier_preferred__Mobile__BDg5ewrISPyZs3qUKUotow?as=scrollable",
          tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
          tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
          ismoduleFeatured: 0,
          isSystemFeatured: 0,
          islistvisiable: 0,
        },
        {
          tutorialLab:"How to veiw carriers profile",
          tutorialViewGuide:"/tutorial/carrier/network/view-carrier-profile",
          tutorialId:"view-carrier-profile",
          tutorialdTime:"3 minutes",
          tutorialdStep:"8 Steps",
          tutorialScribePortal:"https://scribehow.com/shared/How_to_View_a_Carriers_Profile__4Rv9fOizSPOnPnGPH5GQ6w",
          tutorialScribeMobile:"https://scribehow.com/embed/How_to_view_a_Carriers_Profile__Mobile__9o_upM_uRiiPt9iewMzLeQ?as=scrollable",
          tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
          tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
          ismoduleFeatured: 0,
          isSystemFeatured: 0,
          islistvisiable: 0,
        },

      ]
    },
   
    "Shipments" : {
      "title": "Shipment Tutorial Guides",
      "items" :[ 
      {
        tutorialLab:"How Direct Payout Works",
        tutorialViewGuide:"/tutorial/shipper/shipment/direct-payout-works",
        tutorialId:"direct-payout-works",
        tutorialdTime:"3 minutes",
        tutorialdStep:"8 Steps",
        tutorialScribePortal:"",
        tutorialScribeMobile:"",
        tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
        tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
        ismoduleFeatured: 0,
	      isSystemFeatured: 1,
	      islistvisiable: 0,
      },
      {
        tutorialLab:"24 hr window for dispute",
        tutorialViewGuide:"/tutorial/shipper/shipment/dispute",
        tutorialId:"dispute",
        tutorialdTime:"3 minutes",
        tutorialdStep:"8 Steps",
        tutorialScribePortal:"",
        tutorialScribeMobile:"",
        tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
        tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
        ismoduleFeatured: 0,
	      isSystemFeatured: 0,
	      islistvisiable: 0,
      },
      {
        tutorialLab:"How to edit pick-up location and drop off location",
        tutorialViewGuide:"/tutorial/shipper/shipment/edit-location",
        tutorialId:"edit-location",
        tutorialdTime:"3 minutes",
        tutorialdStep:"8 Steps",
        tutorialScribePortal:"https://scribehow.com/shared/Shipper_How_to_Create_and_Save_a_Route_in_LaneAxis_Network__SVMoybnjQbK0VjonojACVw",
        tutorialScribeMobile:"https://scribehow.com/shared/How_to_update_shipment_location_date_and_time__mobile__kkY9VjMNQRawPPPXCUb7Pg",
        tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
        tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
        ismoduleFeatured: 1,
	      isSystemFeatured: 0,
	      islistvisiable: 0,
      },
      {
        tutorialLab:"How to update infomration on a Shipment",
        tutorialViewGuide:"/tutorial/carrier/shipment/update-shipment-information",
        tutorialId:"update-shipment-information",
        tutorialdTime:"3 minutes",
        tutorialdStep:"8 Steps",
        tutorialScribePortal:"https://scribehow.com/shared/Shipper_How_to_Rename_a_Shipment_Title__2uG1IWvhRz-qmeaDG7L-Mw",
        tutorialScribeMobile:"https://scribehow.com/shared/How_to_edit_information_on_a_shipment__Mobile__e32hvrCVTGCPYotI1VPtAA",
        tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
        tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
        ismoduleFeatured: 1,
	      isSystemFeatured: 0,
	      islistvisiable: 0,
      },
      {
        tutorialLab:"How to create a shipment",
        tutorialViewGuide:"/tutorial/shipper/shipment/create-shipment",
        tutorialId:"create-shipment",
        tutorialdTime:"3 minutes",
        tutorialdStep:"8 Steps",
        tutorialScribePortal:"https://scribehow.com/shared/How_to_Create_a_Shipment__xtO1hN0BRyqXD_ada4mglw",
        tutorialScribeMobile:"https://scribehow.com/embed/How_to_Create_a_Shipment__Mobile___Gq75KfbSv-swUiYbuJPcA?as=scrollable",
        tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
        tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
        ismoduleFeatured: 1,
	      isSystemFeatured: 0,
	      islistvisiable: 0,
      },
      {
        tutorialLab:"Accept/Manage a bid on a shipment",
        tutorialViewGuide:"/tutorial/shipper/shipment/accept-manage-bid",
        tutorialId:"accept-manage-bid",
        tutorialdTime:"3 minutes",
        tutorialdStep:"8 Steps",
        tutorialScribePortal:"https://scribehow.com/embed/How_to_View_and_Confirm_Rates_on_a_Lane__Mobile__7qXmj3cLQBO9J_EoPM9ehw?as=scrollable",
        tutorialScribeMobile:"https://scribehow.com/embed/How_to_View_and_Manage_BidsQuotes_recieved_on_a_shipment__Mobile__ysaGqA5wTIuYcCiVFvIeaQ?as=scrollable",
        tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
        tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
        ismoduleFeatured: 1,
	      isSystemFeatured: 0,
	      islistvisiable: 0,
      },
      {
        tutorialLab:"View all shipments status",
        tutorialViewGuide:"/tutorial/shipper/shipment/view-all-shipment-status",
        tutorialId:"view-all-shipment-status",
        tutorialdTime:"3 minutes",
        tutorialdStep:"8 Steps",
        tutorialScribePortal:"https://scribehow.com/shared/Shipper_How_to_View_the_status_of_all_your_shipments__9bij40X7ShGMwcFvy20ygw",
        tutorialScribeMobile:"https://scribehow.com/embed/How_to_View_the_status_of_all_your_shipments__Mobile__RA0A0ugISceqWta1ksKT6A?as=scrollable",
        tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
        tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
        ismoduleFeatured: 0,
	      isSystemFeatured: 0,
	      islistvisiable: 0,
      },

    ]
   },
  "Messages" : {
    "title": "Messages Tutorial Guides",
    "items" :[ 
    {
      tutorialLab:"How to view Active Shipment messages",
      tutorialViewGuide:"/tutorial/shipper/messaging/active-shipment-messages",
      tutorialId:"active-shipment-messages",
      tutorialdTime:"3 minutes",
      tutorialdStep:"8 Steps",
      tutorialScribePortal:"https://scribehow.com/shared/Shipper_How_to_View_Active_Shipment_Messaging__jWCxtpJNQF2pnJ3leAmHEg",
      tutorialScribeMobile:"https://scribehow.com/shared/Carrier_How_to_Add_Drivers__Mobile__v6bfC5akQYyGqQvODAGr3Q",
      tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
      tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
      ismoduleFeatured: 1,
      isSystemFeatured: 0,
      islistvisiable: 0,
    },
    {
      tutorialLab:"How to view UNREAD messages",
      tutorialViewGuide:"/tutorial/shipper/messaging/unread-messages",
      tutorialId:"unread-messages",
      tutorialdTime:"3 minutes",
      tutorialdStep:"8 Steps",
      tutorialScribePortal:"",
      tutorialScribeMobile:"https://scribehow.com/shared/How_to_view_Unread_Messages__Mobile__-F-1Kp11QkaIzHI4wr48IA",
      tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
      tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
      ismoduleFeatured: 0,
      isSystemFeatured: 0,
      islistvisiable: 0,
    },
    {
      tutorialLab:"How to locate Past Shipment messages",
      tutorialViewGuide:"/tutorial/shipper/messaging/past-shipment-messages",
      tutorialId:"past-shipment-messages",
      tutorialdTime:"3 minutes",
      tutorialdStep:"8 Steps",
      tutorialScribePortal:"",
      tutorialScribeMobile:"https://scribehow.com/shared/How_to_locate_messages_on_Past_Shipments__1Rf6OxizQnWKP2hNYg8oew",
      tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
      tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
      ismoduleFeatured: 0,
      isSystemFeatured: 0,
      islistvisiable: 0,
    },
    ]
   },
   "Profile" : {
    "title": "Profile Tutorial Guides",
    "items" :[ 
    {
      tutorialLab:"How to change/update profile information",
      tutorialViewGuide:"/tutorial/shipper/profile/update-profile-information",
      tutorialId:"update-profile-information",
      tutorialdTime:"3 minutes",
      tutorialdStep:"8 Steps",
      tutorialScribePortal:"https://scribehow.com/shared/Shipper_How_to_ChangeUpdate_Profile_Information__ns_U8gwjRbuD07BHiqNXEQ",
      tutorialScribeMobile:"https://scribehow.com/shared/How_to_update_your_Profile_Details__Mobile__5skybvYbTI6Efh4_i9JYPQ",
      tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
      tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
      ismoduleFeatured: 1,
      isSystemFeatured: 0,
      islistvisiable: 0,
    },
    {
      tutorialLab:"Upload photos",
      tutorialViewGuide:"/tutorial/shipper/profile/upload-photo",
      tutorialId:"upload-photo",
      tutorialdTime:"3 minutes",
      tutorialdStep:"8 Steps",
      tutorialScribePortal:"",
      tutorialScribeMobile:"",
      tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
      tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
      ismoduleFeatured: 1,
      isSystemFeatured: 0,
      islistvisiable: 0,
    },
    {
      tutorialLab:"How to set Preferred Regions",
      tutorialViewGuide:"/tutorial/shipper/profile/set-preferred-regions",
      tutorialId:"set-preferred-regions",
      tutorialdTime:"3 minutes",
      tutorialdStep:"8 Steps",
      tutorialScribePortal:"",
      tutorialScribeMobile:"",
      tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
      tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
      ismoduleFeatured: 0,
      isSystemFeatured: 0,
      islistvisiable: 0,
    },
    {
      tutorialLab:"How to Download your Shipper Contract ",
      tutorialViewGuide:"/tutorial/shipper/profile/shipper-contract",
      tutorialId:"shipper-contract",
      tutorialdTime:"3 minutes",
      tutorialdStep:"8 Steps",
      tutorialScribePortal:"",
      tutorialScribeMobile:"",
      tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
      tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
      ismoduleFeatured: 0,
      isSystemFeatured: 0,
      islistvisiable: 0,
    },
    {
      tutorialLab:"How to add your payment details (Credit Card or ACH)",
      tutorialViewGuide:"/tutorial/shipper/profile/add-payment-details",
      tutorialId:"add-payment-details",
      tutorialdTime:"3 minutes",
      tutorialdStep:"8 Steps",
      tutorialScribePortal:"https://scribehow.com/shared/Shipper_How_to_update_debitcredit_card_and_ACH_account_details__Mobile__56NDgx8ySq6lwdofGgdALA",
      tutorialScribeMobile:"https://scribehow.com/shared/How_to_update_debitcredit_card_and_ACH_account_details__Mobile__soXjNGdCRvy2zBEypo9Q6g",
      tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
      tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
      ismoduleFeatured: 1,
      isSystemFeatured: 0,
      islistvisiable: 0,
    },
    ]
   }
  }
  public static driverTutotrial = {
    "Shipments" : {
      "title": "Shipment Tutorial Guides",
      "items" : [ 
        {
          tutorialLab:"How to Accept, Start and Complete a Load",
          tutorialViewGuide:"/tutorial/driver/shipment/accept-start-complete-load",
          tutorialId:"accept-start-complete-load",
          tutorialdTime:"3 minutes",
          tutorialdStep:"8 Steps",
          tutorialScribePortal:"",
          tutorialScribeMobile:"https://scribehow.com/shared/Driver_How_to_Accept_and_Complete_a_Shipment__Mobile__Wb1QNhdoTsCyrII5TkDAqg",
          tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
          tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
          ismoduleFeatured: 1,
          isSystemFeatured: 1,
          islistvisiable: 0
        },
        {
          tutorialLab:"How to Status During Shipment",
          tutorialViewGuide:"/tutorial/driver/shipment/status-during-shipment",
          tutorialId:"status-during-shipment",
          tutorialdTime:"3 minutes",
          tutorialdStep:"8 Steps",
          tutorialScribePortal:"",
          tutorialScribeMobile:"",
          tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
          tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
          ismoduleFeatured: 1,
          isSystemFeatured: 1,
          islistvisiable: 0
        },
        {
          tutorialLab:"How to Message Carrier or Shipper",
          tutorialViewGuide:"/tutorial/driver/shipment/message-carrier-shipper",
          tutorialId:"message-carrier-shipper",
          tutorialdTime:"3 minutes",
          tutorialdStep:"8 Steps",
          tutorialScribePortal:"",
          tutorialScribeMobile:"",
          tutorialVideoPortal:"https://www.youtube.com/embed/1ozGKlOzEVc",
          tutorialVideoMobile:"https://www.youtube.com/embed/1ozGKlOzEVc",
          ismoduleFeatured: 1,
          isSystemFeatured: 0,
          islistvisiable: 0
        },
      ]
    }
  }

  public static eCarrierCheckToolTip = {
    lowRisk:'The company can be considered as hirable. They have few, if any, safety violations or crashes. We have not detected any suspicious activity on their authority, such as recent transfers, insurance drops, or refusal of audits. Their inspection activity is consistent with what we would expect of a company of that size. 55% of companies in our database meet these criteria.',
    
    highRisk:'Be very wary of companies with high risk ratings. These companies have seen suspicious activity regarding their operating authority. It has been granted of transferred recently or has a high amount of notices on their authority. Their inspection data may also be outside the bounds that we consider normal, especially if they have no inspections within the last 12 months. We may have found one or more companies that have the same contact information, address, phone number, email, or has been seen running the same equipment. Safe driving practices are also factored into this rating. 20% of companies in our database meet these criteria.',

    elevatedRisk:'The company may require a closer look. They are still considered hirable in most cases, but we have seen discrepancies to some normal business practices. Make sure to vet these carriers more closely. Pay attention to the inspection ratio, safety ratings, matching companies, and authority. 25% of companies in our database meet these criteria.',

    disqualified :'When carriers have one of a few common hiring disqualifiers for interstate hauling, we label the company as disqualified. For example, if they are missing active interstate operating authority (MC#), or are designated out-of-service by the FMCSA, or if their operation classification is not a hirable category. We also use this category for passenger carriers, although passenger carriers can still be hired for the purpose of transporting passengers (just not property).',
  }


public static SMSPercentiles ={
  unSafeDriving :'The FMCSA aggregates Safety Measurement System (SMS) scores for all active motor carriers. These scores are derived from violations and then converted into percentiles based on carriers peer groups, which consist of companies of similar sizes for Unsafe Driving',
  hoursOfService: 'The FMCSA aggregates Safety Measurement System (SMS) scores for all active motor carriers. These scores are derived from violations and then converted into percentiles based on carriers peer groups, which consist of companies of similar sizes for Hours of Service.',
  driverFitness:'The FMCSA aggregates Safety Measurement System (SMS) scores for all active motor carriers. These scores are derived from violations and then converted into percentiles based on carriers peer groups, which consist of companies of similar sizes for Driver Fitness.',
  vehicleMaintenance:'The FMCSA aggregates Safety Measurement System (SMS) scores for all active motor carriers. These scores are derived from violations and then converted into percentiles based on carriers peer groups, which consist of companies of similar sizes for Vehicle Maintenance.',
  drugsAndAlcohol:'The FMCSA aggregates Safety Measurement System (SMS) scores for all active motor carriers. These scores are derived from violations and then converted into percentiles based on carriers peer groups, which consist of companies of similar sizes for Drugs and Alcohol.',
}
 public static outOfServicePercentages ={
  vehicleOOS: 'This indicator refers to the  Out of service (OOS) for the vehicle that is temporarily or permanently unable to operate due to a variety of reasons. The Federal Motor Carrier Safety Administration (FMCSA) has established strict regulations to ensure the safety of drivers, passengers, and other motorists on the road.',
  driverOOS: 'This indicator refers to the  Out of service (OOS) for the Driver that is temporarily or permanently unable to operate due to a variety of reasons. The Federal Motor Carrier Safety Administration (FMCSA) has established strict regulations to ensure the safety of drivers, passengers, and other motorists on the road.',
  hazmatOOS: 'This indicator refers to the  Out of service (OOS) for the Hazmat Vehicles that are temporarily or permanently unable to operate due to a variety of reasons. The Federal Motor Carrier Safety Administration (FMCSA) has established strict regulations to ensure the safety of drivers, passengers, and other motorists on the road.'
 }

 public static authorityAndInsurance ={

 property: 'This tile shows the Operating Authority status for Property. We monitor authority decisions every day and notify you of any changes to your carriers. This includes when a carrier’s authority is put on notice, deactivated, or transferred to a new company. ',
 
 passenger:' This tile shows the Operating Authority status for Passenger. We monitor authority decisions every day and notify you of any changes to your carriers. This includes when a carrier’s authority is put on notice, deactivated, or transferred to a new company.' ,
 
 householdGoods: 'This tile shows the Operating Authority status for Household Goods. We monitor authority decisions every day and notify you of any changes to your carriers. This includes when a carrier’s authority is put on notice, deactivated, or transferred to a new company.' 
 }
}