import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  @Input() currentTab;
  public userLogin = false;
  public isLogin: any;
  public serverPathForUrl: any;
  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.isLogin = localStorage.getItem('login');
    this.serverPathForUrl = environment.serverPathForUrl;
    if (localStorage.getItem('access_token') !== null) {
      this.userLogin = true;
    } else {
      this.userLogin = false;
    }
  }

  loginPage() {
    this.router.navigate(['/']);
  }
}
