import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedService } from '../../commons/service/shared.service';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/commons/service/common.service';
import { AlertService } from 'src/app/commons/service/alert.service';
import { AppSettings } from 'src/app/commons/setting/app_setting';
import * as moment from 'moment';
import { MapsAPILoader } from '@agm/core';

@Component({
  selector: 'app-driver-side-panel',
  templateUrl: './driver-side-panel.component.html',
  styleUrls: ['./driver-side-panel.component.css']
})
export class DriverSidePanelComponent implements OnInit {
  @Input() driverData: any;
  @Input() userType: any;
  @Input() shipmentDetail: any;
  @Input() findUserType: any;
  @Input() sidePanelProfileData: any;
  @Input() sidePanelProfileDataId: any
  @Input() currentUrlTab: any;
  @Output() closeSidePanel = new EventEmitter();
  @Input() getUseProfileNewTab: any;
  @Input() sidePanelDriverData: any;
  public driverSidePanelData: any;
  public ratingOwnerDetail: {};
  public loading = false;
  public profileSidePanelData: any;
  public currentlocation: any;
  public currentLat: any;
  public currentLon: any;
  public geoCoder: any;

  constructor(
    private sharedService: SharedService,
    public dialog: MatDialog,
    public commonService: CommonService,
    private alertService: AlertService,
    private mapsAPILoader: MapsAPILoader

  ) { }

  ngOnInit() {
    if (this.userType === 'CARRIER' && this.sidePanelProfileData) {
      this.ratingOwnerDetail = {
        key: 'driverId',
        id: this.sidePanelProfileData?.drivers?.userId,
        ratingPlace: 'driverDetail'
      }
    }
    if (this.userType === 'SHIPPER' && this.sidePanelProfileData?.drivers?.userId) {
      this.ratingOwnerDetail = {
        key: 'driverId',
        id: this.sidePanelProfileData?.userId ? this.sidePanelProfileData?.userId : this.sidePanelProfileData?.drivers?.userId,
        ratingPlace: 'driverDetail'
      }
    }
    if (this.driverData) {
      if (this.shipmentDetail == undefined) {
        this.shipmentDetail = this.driverData;

      }
    }

    if (this.sidePanelProfileDataId) {
      this.getApiDriver();
    }
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder();
    });


  }

  ngAfterViewInit() {
    if (this.shipmentDetail && this.shipmentDetail?.drivers) {
      if (this.shipmentDetail?.drivers?.latitude && this.shipmentDetail?.drivers?.longitude) {
        this.currentLat = this.shipmentDetail?.drivers?.latitude;
        this.currentLon = this.shipmentDetail?.drivers?.longitude;
        this.driverCurrentLocation();
      }
    }
  }
  eqipmentSpace(equipmentType) {
    return equipmentType.join(', ');
  }

  closePanel(value: any, type: any) {
    this.closeSidePanel.emit({ value, type });
  }
  userLoginPopup(UserType: any) {
    // const dialogRef = this.dialog.open(NetworkPopupComponent, {
    //   disableClose: true,
    //   backdropClass: AppSettings.backdropClass,
    //   width: AppSettings.popWidth,
    //   data: { openPop: 'required_Login', type: UserType },
    // });
  }
  getApi() {
    this.loading = true;
    let uri = null;
    let newParams = {
      id: this.sidePanelProfileDataId
    };
    if (newParams) uri = this.commonService.getAPIUriFromParams(newParams);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.MANAGE_CONNECTION.GETPROFILE,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe(
      (ServerRes) => {
        this.profileSidePanelData = ServerRes.response;

      })
  }

  getApiDriver() {
    this.loading = true;
    let uri = null;
    let now = new Date();
    const currentPickDate = moment(now).format('MM/DD/YYYY');
    let newParams = {
      driverId: this.sidePanelProfileDataId,
      availabilityDate: currentPickDate,
      isLastShipment: 1,
      isTotalShipments: 1,
    };
    if (newParams) uri = this.commonService.getAPIUriFromParams(newParams);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.DRIVER.GET,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe(
      (ServerRes) => {
        this.driverSidePanelData = ServerRes.response;
      })
  }
  checkEquipmentType(equipment: any) {
    if (equipment && equipment.length > 0 && Array.isArray(equipment)) {
      return equipment.toString()
    }
    else if (equipment && equipment.length > 0) {
      return equipment
    }
    else {
      return '-'
    }
  }
  async driverCurrentLocation() {
    this.currentlocation = await this.showPosition(
      this.shipmentDetail.drivers.latitude,
      this.shipmentDetail.drivers.longitude,

    );
  }

  showPosition(locationLat: any, locationLong: any) {
    let addressDriver = [];
    return new Promise((resolve, reject) => {
      this.mapsAPILoader.load().then(() => {
        let selatlong = { lat: locationLat, lng: locationLong };
        this.geoCoder?.geocode(
          { location: selatlong },
          function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
              let newname = '';
              if (results[0].address_components[3] !== undefined) {
                newname = results[0].address_components[3].long_name;
              } else {
                newname = '';
              }
              addressDriver['shortAddress'] =
                results[0].address_components[2].long_name + ', ' + newname;
              addressDriver['fullAddress'] = results[0].formatted_address;
              resolve(addressDriver);
            } else if (status === google.maps.GeocoderStatus.OVER_QUERY_LIMIT) {
            } else {
              resolve(null);
            }
          },
          (error) => {
            resolve(null);
          }
        );
      });
    });

  }
}


