import { Component, OnInit, Input } from '@angular/core';
import { PopupComponent } from '../popup/popup.component';
import { MatDialog } from '@angular/material/dialog';
import { AppSettings } from '../../commons/setting/app_setting';

@Component({
  selector: 'app-rating-view',
  templateUrl: './rating-view.component.html',
  styleUrls: ['./rating-view.component.scss']
})
export class RatingViewComponent implements OnInit {
  @Input() rate: any;
  @Input() shipmentDetail: any;
  @Input() shipmentRecord: any;
  @Input() ratingReview: any;
  @Input() type: any;
 public ratingOwnerDetails: any;
 @Input() ratingOwnerDetail: any;
  @Input() totalReviewForPopup: boolean;
  public userType:any;
  rating = [];
  ratingEmpty = [];
  isRatingHalf: boolean = false;
  constructor(
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.userType = localStorage.getItem('user_type');
    if(this.shipmentDetail){
      this.ratingOwnerDetails = {
        key: 'driverId',
        id: this.shipmentDetail?.userId ? this.shipmentDetail?.userId : this.shipmentDetail?.drivers?.userId,
        ratingPlace: 'driverDetail'
      }
    }

    if (this.rate) {
      const ratingWholeNumber = Math.floor(this.rate);
      const decimalNumber = this.rate - ratingWholeNumber;
      this.rating = Array(ratingWholeNumber)?.fill(1)?.map((x, i) => i + 1);
      this.isRatingHalf = decimalNumber > 0 ? true : false;
      const emptyStar = 5 - ratingWholeNumber - (this.isRatingHalf ? 1 : 0);
      this.ratingEmpty = Array(emptyStar)?.fill(1)?.map((x, i) => i + 1);
    } else {
      const ratingWholeNumber = Math.floor(this.rate);
      const emptyStar = 5 - ratingWholeNumber - (this.isRatingHalf ? 1 : 0);
      this.ratingEmpty = Array(emptyStar)?.fill(1)?.map((x, i) => i + 1);
    }
  }

  openReview(): void {
    const dialogRef = this.dialog.open(PopupComponent, {
      width: '680px',
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      data: {
        openPop: 'showReviews',
        totalReviewForPopup: false,
        shipmentDetail: this.shipmentDetail,
        ratingOwnerDetail: this.ratingOwnerDetail ? this.ratingOwnerDetail : this.ratingOwnerDetails
      },
    });
    dialogRef.afterClosed().subscribe((result) => {

    });
  }
}


