import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  STATUS_COLOR_CODES,
  STATUS_CODE,
  STATUS,
} from '../setting/Heremaps.constants';

@Injectable({
  providedIn: 'root'
})
export class HereMapsService8 {
  private apiUrl: string = 'https://router.hereapi.com/v8/routes';
  private apiKey: string = environment.heremapapiKey;
  private transportMode = 'truck'
  constructor(private http: HttpClient) { }

  getRouteSummary(origin: string, destination: string, transport?:string): Observable<any> {
    if(transport)
      {
        this.transportMode = transport;
      }
    const headers = new HttpHeaders().set('hereMap8', '');
    const url = `${this.apiUrl}?transportMode=${this.transportMode}&origin=${origin}&destination=${destination}&return=summary&apikey=${this.apiKey}`;
    return this.http.get(url,{ headers });
  }


  getRouteSummaryFromMultipleWaypoints(waypoints:any, transport?:string)
  {
    // console.log('waypoints',waypoints)
    if(transport)
      {
        this.transportMode = transport;
      }
    const headers = new HttpHeaders().set('hereMap8', '');
    let origin = waypoints[0]
    let destination = waypoints[(waypoints.length-1)]
    let via =''
    if(waypoints.length > 2)
      {
        for(let i=1; i<waypoints.length-1;i++)
          {
            via = via +'&via='+waypoints[i]
          }
      }
    const url = `${this.apiUrl}?transportMode=${this.transportMode}&origin=${origin}&destination=${destination}${via}&return=summary&apikey=${this.apiKey}`;
    return this.http.get(url,{ headers });
  }


  calculateMultiRoute(shipment, waypoint) {
    if (shipment && waypoint) {
      let multiWaypointPayload = this.multiWayPointPayload(waypoint);
      let latitude =
        shipment.drivers && shipment.drivers.latitude            // DYNAMIC current location
          ?  + shipment.drivers.latitude
          :  + waypoint[0].latitude;
      let longitude =
        shipment.drivers && shipment.drivers.longitude
          ? shipment.drivers.longitude
          : waypoint[0].longitude;
      let currentLocation = latitude + ',' + longitude;
      multiWaypointPayload.splice(0,0,currentLocation);
      let resultObservable = new Observable((observer)=>{
        this.getRouteSummaryFromMultipleWaypoints(multiWaypointPayload,'truck').subscribe((result)=>{
          if(result['routes'].length)
            {
              observer.next(result['routes'][0])
              return 
            }
          else if(!result['routes'].length)
            {
              let value =  this.getTripResponse(
                STATUS_COLOR_CODES.BLUE,
                STATUS.NO_ROUTE,
                '',
                '',
                '',
                0,
                shipment
              );
              observer.next(value)

            }
      },(error)=>{
        observer.error(this.getTripResponse(
          STATUS_COLOR_CODES.BLUE,
          STATUS.NO_ROUTE,
          '',
          '',
          '',
          0,
          shipment
        ))
      })
      })
      return resultObservable;
    }
  }


  multiWayPointPayload(waypoints, type?) {
    let routingParameters = []
    let index = 0;
    for (let i = 0; i < waypoints.length; i++) {
      if (waypoints[i].status !== 'Completed') {
        routingParameters.push(waypoints[i].latitude + ',' + waypoints[i].longitude) 
        index++;
      }
    }
    return routingParameters;

  }


  getTripResponse(
    colorCode,
    statusText,
    ETA,
    EtawithColon,
    ETAFor,
    milesCompleted,
    shipment
  ) {
    const tripResponse = {
      Color: colorCode,
      Text: statusText,
      ETA: ETA,
      ETAWithColon: EtawithColon,
      ETAFor: ETAFor,
      milesCompleted: milesCompleted,
      latitude:
        shipment.drivers && shipment.drivers.latitude
          ? shipment.drivers.latitude
          : '-',
      longitude:
        shipment.drivers && shipment.drivers.longitude
          ? shipment.drivers.longitude
          : '-',
    };
    return tripResponse;
  }



  calculateRoute(shipment) {
    console.log(
      'calculate route', shipment
    )
    if (
      shipment &&
      shipment.pickupLocationLat &&
      shipment.pickupLocationLong &&
      shipment.dropLocationLat &&
      shipment.dropLocationLong &&
      shipment.drivers
    ) {
      var routingParameters = [];

      let pickupLocation =
         shipment.pickupLocationLat + ',' + shipment.pickupLocationLong;
      let dropLocation =
         shipment.dropLocationLat + ',' + shipment.dropLocationLong;
      let currentLocation =
         shipment.drivers.latitude + ',' + shipment.drivers.longitude;
      if (shipment.status < STATUS_CODE.ATPICKUPLOCATION) {
          routingParameters.push(pickupLocation),
          routingParameters.push(dropLocation),
          routingParameters.push(currentLocation)
      } else {
          routingParameters.push(currentLocation),
          routingParameters.push(dropLocation)
      }
      let payload = new Promise((resolve,reject)=>{
        this.getRouteSummaryFromMultipleWaypoints(routingParameters).subscribe((result)=>{
          console.log('result',result)
          resolve(result);
        },(error)=>{
          reject( this.getTripResponse(
            STATUS_COLOR_CODES.BLUE,
            STATUS.NO_ROUTE,
            '',
            '',
            '',
            0,
            shipment
          ))
        })
      })
      return payload
    } else
      {
        let errorPromise = new Promise((resolve,reject)=>{
          resolve( this.getTripResponse(
            STATUS_COLOR_CODES.BLUE,
            STATUS.NO_ROUTE,
            '',
            '',
            '',
            0,
            shipment
          ))
      })
      return errorPromise
    }
  }
  
  async getRouteFound(
    newlatitude: any,
    newlongitude: any,
    getCurrentLatitude: any,
    getCurrentLongitude: any,
    type: any
  ) {
   
    if (
      newlatitude &&
      newlongitude &&
      getCurrentLatitude &&
      getCurrentLongitude
    ) {
      let pickupLocation = newlatitude + ',' + newlongitude;
      let dropLocation =
        getCurrentLatitude + ',' + getCurrentLongitude;
        let resultPromise = new Promise((resolve,reject)=>{
          resolve(this.getRouteSummary(pickupLocation,dropLocation))
          reject('error occuring')
        })
    }
  }


  calculateMultiRouteFind(waypoint: any, type: any) {
    let waypointArray = [];
      for(let latlong of waypoint)
        {
          waypointArray.push(latlong.latitude+','+latlong.longitude)
        }
    let payloadPromise = new Promise((resolve,reject)=>{
      this.getRouteSummaryFromMultipleWaypoints(waypointArray,type).subscribe((result)=>{
          resolve(result)
      },(error)=>{
        reject ('route not found')
      })
    })
    return payloadPromise;
  }
}
