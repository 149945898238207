<div *ngIf="data.condition === 1">
	<div class="text-center popup_activity">
		<p class="fs_14 fsm_5 txt_b header_mess"> {{data.msg}}</p>
		<div class="mt-4">
			<button type="button" class="btn custum_button button_primary me-4" (click)="templateDelete()">Yes
				Delete</button>
			<span class="fs_10 txt_p fsm_5 pointer f_clash" (click)="onClose()"><u class="f_clash">Cancel</u></span>
		</div>
	</div>
</div>

<div *ngIf="data.condition === 2">
	<div class="text-center popup_activity">
		<p class="fs_14 fsm_5 txt_b header_mess">You have Deleted <br> <span class="fsb_6">“{{data.title}}”</span>
			template</p>
		<div class="mt-4">
			<span class="fs_10 txt_p fsm_5 pointer f_clash" (click)="templateUndo()"><u class="f_clash">Undo</u></span>
		</div>
	</div>
</div>

<div *ngIf="data.condition === 3">
	<div class="text-center popup_activity">
		<form class="m-0" [formGroup]="templateShipment" (ngSubmit)="createShipSubmit(templateShipment)">
			<mat-form-field appearance="none" class="fs_14 fsm_5 txt_b">
				<mat-label> Name Your Template </mat-label>
				<input matInput formControlName="title" placeholder="Name Your Template">
				<mat-error class="error_mess" *ngIf="templateShipment.get('title').errors?.['required']">Title is
					required.</mat-error>
			</mat-form-field>
			<div class="mt-4">
				<button type="submit" class="btn custum_button button_primary me-4">Save</button>
				<span class="fs_10 txt_p fsm_5 pointer f_clash" (click)="onClose()"><u class="f_clash">Cancel</u></span>
			</div>
		</form>
	</div>
</div>

<!--First Bid Popup -->
<div class="popup_container" *ngIf="data.openPop ==='bid'">
	<div class="header_popup">
		<p class="fs_20 fsb_6 txt_b line_before f_clash">Enter Quote/Bid</p>
		<span class="close_popup_Icon" (click)="onClose()">
			<span class="icon-ic-cross close_Icon"></span>
		</span>
	</div>

	<div class="bid_amount" *ngIf="data.shipmentId">
		<p class="bid_amountTxt f_clash">EXPECTED RATE FROM {{data.userName | uppercase }}</p>
		<p class="bid_amountValue contract_offer_Rate">
			<span *ngIf="data?.paymentMethod === 2" class="df_c">
				<span class="usdc_iconM"></span>
				<span class="ms-1">{{priceAmount(0) | sixDecimalPipe}}</span>
			</span>
			<span *ngIf="data?.paymentMethod === 1">${{data.shipmentDetail.freightRate | twoDecimalPipe}}</span>

			<app-rate-per-mile *ngIf="data.paymentMethod === 1" class="fs_14 fsm_5 mt-1"
				[amount]="data.shipmentDetail.freightRate" [miles]="data?.shipmentDetail?.miles"></app-rate-per-mile>
		</p>
	</div>

	<div class="bid_amount" *ngIf="data.orderId">
		<p class="bid_amountTxt f_clash">EXPECTED RATE FROM {{data.userName | uppercase }}</p>
		<p class="bid_amountValue contract_offer_Rate">
			<span *ngIf="data?.paymentMethod === 2" class="df_c">
				<span class="usdc_iconM"></span>
				<span class="ms-1">{{priceAmount(0) | sixDecimalPipe}}</span>
			</span>
			<span *ngIf="data?.paymentMethod === 1">${{priceAmount(0) | twoDecimalPipe}}</span>
			
		</p>
	</div>
	<form class="mb-0 custum_input_css inputInPopup" [formGroup]="bidForm" (ngSubmit)="redibFormSubmit(bidForm)"
		autocomplete="off">
		<div class="v_scrollBar vertical_sh" style="max-height: 38vh;">
			<p class="fs_16 fsn_4 txt_lb mb-2">
				<span>Please enter your quote/bid amount and any optional notes for</span>
				<span class="fsm_5 txt_b"> {{data.userNameOther}}</span>.
			</p>
			<div class="bid_amt">
				<p class="fs_14 fsn_4 txt_lb mb-1 usdUdsd">
					<span>Quote/Bid amount</span>
					<span *ngIf="data?.paymentMethod === 2" class="ms-1 usdc_iconS"></span>
					<span class="ms-1" *ngIf="data?.paymentMethod === 1">($)</span>
					<span class="txt_d"> *</span>
				</p>
				<mat-form-field appearance="none">
					<input *ngIf="data?.paymentMethod === 1" maxlength="16" matInput type="text"
						placeholder="Enter quote/bid amount" class="form-control custom_input"
						formControlName="bidAmount" appTwoDigitDecimal />
					<span class="usdIcon_imgBg" *ngIf="data?.paymentMethod === 1">
						<img src="https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-currency-usd.svg"
							class="img-fluid">
					</span>
					<input *ngIf="data?.paymentMethod === 2" matInput maxlength="16" type="text"
						placeholder="Enter quote/bid amount" class="form-control custom_input"
						formControlName="bidAmount" appTwoDigitDecimaNumber />
					<span class="usdcIcon_imgBg" *ngIf="data?.paymentMethod === 2">
						<img src="https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-USDC-16.svg"
							class="img-fluid">
					</span>
					<mat-error class="error_mess" *ngIf="submitted && bidForm.get('bidAmount').errors?.['required']">
						Quote/Bid amount is required.</mat-error>
					<mat-error class="error_mess" *ngIf="bidForm.get('bidAmount').errors?.['pattern']">Enter a higher
						amount</mat-error>
				
				</mat-form-field>
			</div>
			<div class="feedback_input">
				<p class="fs_14 fsn_4 txt_lb mb-1">Quote/Bid note (100 characters)</p>
				<mat-form-field appearance="none">
					<textarea matInput formControlName="bidNote" type="text" class="form-control custom_input"
						placeholder="Enter Quote/Bid note" maxlength="100"
						style="background: var(--clBluh); min-height: 100px; padding: 0.9rem;"></textarea>

				</mat-form-field>
			</div>
			<div class="agreeTandC" *ngIf="data.amount && data.shipmentId">
				<p class="fs_12 fsn_4 txt_lb mb-0">
					<span>By submitting a quote/bid you agree to terms of the network contract. Click here to </span>
					<a href="{{data?.contractDocument}}" target="_blank" class="txt_p"><u class="f_clash"> view
							document.</u></a>
				</p>
			</div>
		</div>
		<div class="btn_action">
			<button type="submit" class="btn custum_button button_primary btn_primary mb_16">Submit Your Quote/Bid</button>
			<span class="btn_trans txt_lb pointer f_clash" (click)="onClose()">Cancel</span>
		</div>
	</form>
</div>

<!--Rebid Popup -->
<div class="popup_container" *ngIf="data.openPop ==='rebid'">
	<div class="header_popup">
		<p class="fs_20 fsb_6 txt_b line_before f_clash">Counter quote/bid</p>
		<span class="close_popup_Icon" (click)="onClose()">
			<span class="icon-ic-cross close_Icon"></span>
		</span>
	</div>
	<div class="bid_amount" *ngIf="data.amount">
		<p class="bid_amountTxt f_clash">CURRENT QUOTE/BID FROM {{data.userName | uppercase}} </p>
		<p class="bid_amountValue contract_offer_Rate">
			<span *ngIf="data.paymentMethod === 2" class="df_c">
				<span class="usdc_iconM"></span>
				<span class="ms-1">{{data.amount | sixDecimalPipe}}</span>
			</span>
			<span *ngIf="data.paymentMethod === 1">${{data.amount | twoDecimalPipe}}</span>
			<app-rate-per-mile *ngIf="data.shipmentId && data.paymentMethod === 1" class="fs_14 fsm_5 mt-1"
				[amount]="data.amount" [miles]="data?.shipmentDetail?.miles"></app-rate-per-mile>
		</p>
	</div>

	<form class="mb-0 custum_input_css inputInPopup" [formGroup]="bidForm" (ngSubmit)="redibFormSubmit(bidForm)"
		autocomplete="off">
		<div class="v_scrollBar vertical_sh" style="max-height: 38vh;">
			<p class="fs_16 fsn_4 txt_lb mb-2">
				<span>Please enter your counter quote/bid amount and any optional notes for</span>
				<span class="txt_b fsm_5"> {{data.userNameOther}}.</span>
			</p>
			<div class="bid_amt">
				<p class="fs_14 fsn_4 txt_lb mb-1 usdUdsd">
					<span>Quote/Bid amount</span>
					<span *ngIf="data.paymentMethod === 2" class="ms-1 usdc_iconS"></span>
					<span class="ms-1" *ngIf="data.paymentMethod === 1">($)</span>
					<span class="txt_d"> *</span>
				</p>
				<mat-form-field appearance="none">
					<input *ngIf="data.paymentMethod === 1" matInput type="text" placeholder="Enter quote/bid amount"
						class="form-control custom_input" formControlName="bidAmount" appTwoDigitDecimal
						maxlength="16" />
					<span class="usdIcon_imgBg" *ngIf="data.paymentMethod === 1">
						<img src="https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-currency-usd.svg"
							class="img-fluid">
					</span>
					<input *ngIf="data.paymentMethod === 2" maxlength="16" matInput type="text"
						placeholder="Enter Quote/Bid Rate" class="form-control custom_input"
						formControlName="bidAmount" appTwoDigitDecimaNumber />
					<span class="usdcIcon_imgBg" *ngIf="data.paymentMethod === 2">
						<img src="https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-USDC-16.svg"
							class="img-fluid">
					</span>
					<mat-error class="error_mess" *ngIf="submitted && bidForm.get('bidAmount').errors?.['required']">
						Quote/Bid amount is required.</mat-error>
					<mat-error class="error_mess" *ngIf="bidForm.get('bidAmount').errors?.['pattern']">Enter a higher
						amount.</mat-error>
					
				</mat-form-field>
			</div>
			<div class="feedback_input">
				<p class="fs_14 fsn_4 txt_lb mb-1">Quote/Bid note (100 characters) </p>
				<mat-form-field appearance="none">
					<textarea matInput formControlName="bidNote" type="text" class="form-control custom_input"
						placeholder="Enter Quote/Bid Note" maxlength="100"
						style="background: var(--clBluh); min-height: 100px; padding: 0.9rem;"></textarea>
				</mat-form-field>
			</div>
		</div>

		<div class="btn_action">
			<button type="submit" class="btn custum_button button_primary btn_primary mb_16">Submit Your Quote/Bid</button>
			<span class="btn_trans txt_lb pointer f_clash" (click)="onClose()">Cancel</span>
		</div>
	</form>
</div>

<div class="popup_container" *ngIf="data.openPop ==='counterBid'">
	<div class="header_popup">
		<p class="fs_20 fsb_6 txt_b line_before f_clash">Counter Quote/Bid</p>
		<span class="close_popup_Icon" (click)="onClose()">
			<span class="icon-ic-cross close_Icon"></span>
		</span>
	</div>
	<div class="bid_amount" *ngIf="data.amount">
		<!-- Overbid -->
		<p class="bid_amountTxt f_clash" >CURRENT QUOTE/BID FROM {{data.userName | uppercase}} </p>
		
		<p class="bid_amountValue">
			<span *ngIf="data.paymentMethod === 2" class="df_c">
				<span class="usdc_iconM"></span>
				<span class="ms-1">{{data.amount | sixDecimalPipe}}</span>
			</span>
			<span *ngIf="data.paymentMethod === 1">${{data.amount | twoDecimalPipe}}</span>
		</p>
	</div>

	<form class="mb-0 custum_input_css inputInPopup" [formGroup]="counterBiddForm"
		(ngSubmit)="counterBidSubmit(counterBiddForm)" autocomplete="off">
		<div class="v_scrollBar vertical_sh" style="max-height: 38vh;">
			<p class="fs_16 fsn_4 txt_lb mb-2">
				<span>Please enter your Counter quote/bid amount and Optional notes for</span>
				<span class="txt_b fsm_5"> {{data.userNameOther}}</span>.
			</p>
			<div class="bid_amt">
				<p class="fs_14 fsn_4 txt_lb mb-1 usdUdsd">
					<span>Counter Quote/Bid Rate</span>
					<span *ngIf="data.paymentMethod === 2" class="ms-1 usdc_iconS"></span>
					<span class="ms-1" *ngIf="data.paymentMethod === 1">($)</span>
					<span class="txt_d"> *</span>
				</p>
				<mat-form-field appearance="none">
					<input *ngIf="data.paymentMethod === 1" matInput type="text"
						placeholder="Enter Counter Quote/Bid Rate" class="form-control custom_input"
						formControlName="bidAmount" appTwoDigitDecimal maxlength="16" />
					<span class="usdIcon_imgBg" *ngIf="data.paymentMethod === 1">
						<img src="https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-currency-usd.svg"
							class="img-fluid">
					</span>
					<input *ngIf="data.paymentMethod === 2" maxlength="16" matInput type="text"
						placeholder="Enter Counter Quote/Bid Rate" class="form-control custom_input"
						formControlName="bidAmount" appTwoDigitDecimaNumber />
					<span class="usdcIcon_imgBg" *ngIf="data.paymentMethod === 2">
						<img src="https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-USDC-16.svg"
							class="img-fluid">
					</span>
					<mat-error class="error_mess"
						*ngIf="submitted && counterBiddForm.get('bidAmount').errors?.['required']">Counter quote/bid
						amount is
						required.</mat-error>
					<mat-error class="error_mess" *ngIf="counterBiddForm.get('bidAmount').errors?.['min']">The Counter
						Quote/Bid amount should not be less than the Shipment Rate.

					</mat-error>
				</mat-form-field>
			</div>
			<div class="feedback_input">
				<p class="fs_14 fsn_4 txt_lb mb-1">Counter Quote/Bid Notes (100 characters) </p>
				<mat-form-field appearance="none">
					<textarea matInput formControlName="bidNote" type="text" class="form-control"
						placeholder="Enter Counter Quote/Bid Note" maxlength="100"
						style="background: var(--clBluh); min-height: 100px; padding: 0.9rem;"></textarea>
				</mat-form-field>
			</div>
		</div>
		<div class="btn_action">
			<button type="submit" class="btn custum_button button_primary btn_primary mb_16">Submit</button>
			<span class="btn_trans txt_lb pointer f_clash" (click)="onClose()">Cancel</span>
		</div>
	</form>
</div>

<!-- Success popup -->
<div class="inviteDriver_con" *ngIf="data.openPop ==='accepBIdsSuccess'">
	<div class="text-center" style="margin: 0; padding: 1rem;">
		<span class="material-icons"
			style="margin: 0 0 1.5rem;font-size: 48px;color: var(--clGrna);">check_circle</span>
		<p class="fs_20 fsb_6 txt_b f_clash" style="margin: 0 0 2rem;">Congratulations</p>
		<p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">
			Your payment has been successfully processed. The shipment has been created and assigned to your Carrier.
		</p>
		<div class="text-center">
			<button type="submit" class="btn custum_button button_success w-100 text-capitalize fs_16 fsn_4"
				(click)="onNoClick()"
				style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">OK</button>
			<span class="fs_16 fsn_4 txt_p pointer f_clash px-4 py-2" (click)="onNoClick()">View Details</span>
		</div>
	</div>
</div>

<!-- Withdraw Bid popup -->
<div class="inviteDriver_con" *ngIf="data.openPop ==='cancelreBid'">
	<div class="header_popup">
		<p class="fs_20 fsb_6 txt_b line_before f_clash">Withdraw Quote/Bid</p>
		<span class="close_popup_Icon" (click)="onClose()">
			<span class="icon-ic-cross close_Icon"></span>
		</span>
	</div>
	<div class="bid_amount">
		<p class="bid_amountTxt f_clash">CURRENT QUOTE/BID</p>
		<p class="bid_amountValue">
			<span *ngIf="data.paymentMethod === 2" class="df_c">
				<span class="usdc_iconM"></span>
				<span class="ms-1">{{data.amount | sixDecimalPipe}}</span>
			</span>
			<span *ngIf="data.paymentMethod === 1">${{data.amount | twoDecimalPipe}}</span>
		</p>
	</div>

	<div class="v_scrollBar vertical_sh" style="max-height: 38vh;">
		<div class="text-center">
			<p class="fs_16 fsn_4 txt_lb mb_24">Withdrawing this quote/bid will remove it from the shipment’s bids and
				you
				will need to submit a new quote/bid.</p>
			<p class="fs_16 fsn_4 txt_lb mb_24">
				<span>Are you sure you want to withdraw your current quote/bid to </span>
				<span class="fsm_5 txt_b" >{{data.userName}}</span>
				
				<span>?</span>
			</p>
		</div>
	</div>
	<div class="btn_action">
		<button type="submit" class="btn custum_button button_primary btn_primary mb_16 bg_d"
			(click)="withdrawBid()">Withdraw Quote/Bid</button>
		<span class="btn_trans txt_lb pointer f_clash" (click)="onClose()">Cancel</span>
	</div>
</div>

<div class="inviteDriver_con" *ngIf="data.openPop ==='acceptBid'">
	<div class="header_popup">
		<p class="fs_20 fsb_6 txt_b line_before f_clash">Accept Quote/Bid</p>
		<span class="close_popup_Icon" (click)="onClose()">
			<span class="icon-ic-cross close_Icon"></span>
		</span>
	</div>
	<!-- FROM {{data.userName | uppercase}} -->
	<div class="bid_amount">
		<p class="bid_amountTxt f_clash">CURRENT QUOTE/BID</p>
		<p class="bid_amountValue">
			<span *ngIf="data.paymentMethod === 2" class="df_c">
				<span class="usdc_iconM"></span>
				<span class="ms-1">{{data.amount | sixDecimalPipe}}</span>
			</span>
			<span *ngIf="data.paymentMethod === 1">${{data.amount | twoDecimalPipe}}</span>
		</p>
	</div>
	<div class="v_scrollBar vertical_sh" style="max-height: 20vh;">
		<p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 20px;">You need to confirm the Quote/Bid and
			awarded shipment before Shipper company can assign it to you.</p>
		<p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 20px;">
			<span>Are you sure you want to accept the current quote/bid from</span>
            <span class="txt_b fsm_5"> {{data.userNameOther}}?</span>
			
		</p>
	</div>
	<div class="btn_action">
		<div class="bid_confirm">
			<button *ngIf="data.bidingStatus === 1" type="submit"
				class="btn custum_button button_primary btn_primary mb_16 bg_p px-1 "
				(click)="accetpBid('accept')">Accept Quote/Bid</button>
			<button *ngIf="data.bidingStatus === 1" type="submit"
				class="btn custum_button button_success btn_success mb_16 bg_s px-2"
				(click)="openDriverAlertPopupBid('acceptAndConfirm')">Accept & Confirm</button>
		</div>
		<span class="btn_trans txt_lb pointer f_clash" (click)="onNoClickPopup()">Cancel</span>
	</div>
</div>

<div class="inviteDriver_con" *ngIf="data.openPop ==='acceptBidConfirm'">
	<div class="header_popup">
		<p class="fs_20 fsb_6 txt_b line_before f_clash">Accept Shipment</p>
		<span class="close_popup_Icon" (click)="onClose()">
			<span class="icon-ic-cross close_Icon"></span>
		</span>
	</div>
	<div class="bid_amount">
		<p class="bid_amountTxt f_clash">CURRENT QUOTE/BID </p>
		<p class="bid_amountValue contract_offer_Rate">
			<span *ngIf="data.paymentMethod === 2" class="df_c">
				<span class="usdc_iconM"></span>
				<span class="ms-1">{{data.amount | sixDecimalPipe}}</span>
			</span>
			<span *ngIf="data.paymentMethod === 1">${{data.amount | twoDecimalPipe}}</span>
			
			<app-rate-per-mile *ngIf="data.paymentMethod === 1" class="fs_14 fsm_5 mt-1" [amount]="data.amount"
				[miles]="data?.shipmentDetail?.miles"></app-rate-per-mile>
		</p>
	</div>
	<div class="text-center" style="margin: 0; padding: 1rem;">
		<p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 20px;">
			<span class="txt_b fsm_5" >{{data.userNameOther}}</span> awarded this quote/bid to you.
			
		</p>
		<p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 20px;">Are you sure you want to accept and
			confirm this shipment?</p>
		

	</div>
	<div class="btn_action">
		<button type="submit" class="btn custum_button button_primary btn_primary mb_16 bg_p"
			(click)="accetpBid('confirm')">Yes, Confirm Shipment</button>
		<span class="btn_trans txt_lb pointer f_clash" (click)="onNoClickPopup()">Cancel</span>
	</div>
</div>
<!-- order confirm  -->
<div class="inviteDriver_con" *ngIf="data.openPop ==='acceptOrderConfirm'">
	<div class="header_popup">
		<p class="fs_20 fsb_6 txt_b line_before f_clash">Accept Order</p>
		<span class="close_popup_Icon" (click)="onClose()">
			<span class="icon-ic-cross close_Icon"></span>
		</span>
	</div>
	<div class="bid_amount">
		<p class="bid_amountTxt f_clash">CURRENT QUOTE/BID </p>
		<p class="bid_amountValue contract_offer_Rate">
			<span *ngIf="data.paymentMethod === 2" class="df_c">
				<span class="usdc_iconM"></span>
				<span class="ms-1">{{data.amount | sixDecimalPipe}}</span>
			</span>
			<span *ngIf="data.paymentMethod === 1">${{data.amount | twoDecimalPipe}}</span>
			<app-rate-per-mile *ngIf="data.paymentMethod === 1" class="fs_14 fsm_5 mt-1" [amount]="data.amount"
				[miles]="data?.orderDetail?.miles"></app-rate-per-mile>
		</p>
	</div>
	<div class="text-center" style="margin: 0; padding: 1rem;">
		
		<p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 20px;">Are you sure you want to confirm this
			order?</p>
	</div>
	<div class="btn_action">
		<button type="submit" class="btn custum_button button_primary btn_primary mb_16 bg_p"
			(click)="accetpOrderBid('confirm')">Yes, Confirm Order</button>
		<span class="btn_trans txt_lb pointer f_clash" (click)="onNoClickPopup()">Cancel</span>
	</div>
</div>

<div class="inviteDriver_con" *ngIf="data.openPop ==='shipmentConfirmation'">
	<div class="header_popup">
		<p class="fs_20 fsb_6 txt_b line_before f_clash">Post Shipment</p>
		<span class="close_popup_Icon" (click)="onClose()">
			<span class="icon-ic-cross close_Icon"></span>
		</span>
	</div>
	<div class="bid_amount">
		<p class="bid_amountTxt f_clash">Shipment Open to Bidding</p>
		<p class="bid_amountValue">
			<span *ngIf="data.paymentMethod === 2" class="df_c">
				<span class="usdc_iconM"></span>
				<span class="ms-1">{{data.amount | sixDecimalPipe}}</span>
			</span>
			<span *ngIf="data.paymentMethod === 1">${{data.amount | twoDecimalPipe}}</span>
		</p>
	</div>
	<div class="text-center" style="margin: 0; padding: 1rem;">
		<p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 20px;">Are you sure you want to post this
			shipment? Posting this shipment will alert thousands of Carriers in the network. All posted loads must be
			legitimate (no test loads allowed!).
		</p>
	</div>
	<div class="btn_action">
		<button type="submit" class="btn custum_button button_primary btn_primary mb_16 bg_p"
			(click)="shipmentConfirm()">Post Shipment</button>
		<span class="btn_trans txt_lb pointer f_clash" (click)="onNoClickPopup()">Cancel</span>
	</div>
</div>

<!--First Bid Popup -->
<div class="popup_container" *ngIf="data.openPop === 'overBids'">
	<div class="header_popup">
		<p class="fs_20 fsb_6 txt_b line_before f_clash"> Higher quote/overbid</p>
		<span class="close_popup_Icon" (click)="onClose()">
			<span class="icon-ic-cross close_Icon"></span>
		</span>
	</div>

	<div class="bid_amount" *ngIf="data.amount">
		<p class="bid_amountTxt f_clash">CURRENT RATE FROM {{data.userName | uppercase }}</p>
		<p class="bid_amountValue">
			<span *ngIf="data.paymentMethod === 2" class="df_c">
				<span class="usdc_iconM"></span>
				<span class="ms-1">{{data.amount | sixDecimalPipe}}</span>
			</span>
			<span *ngIf="data.paymentMethod === 1">${{data.amount | twoDecimalPipe}}</span>
		</p>
	</div>
	<form class="mb-0 custum_input_css inputInPopup" [formGroup]="overBidForm"
		(ngSubmit)="overBidFormSubmit(overBidForm)" autocomplete="off">
		<div class="v_scrollBar vertical_sh" style="max-height: 38vh;">
			<p class="fs_16 fsn_4 txt_lb mb-2">
				<span>Please enter your Higher quote/overbid amount and Optional notes for</span>
				<span class="txt_b fsm_5" >{{data.userNameOther}}</span> awarded this quote/bid to you.	
				
			</p>
			<div class="bid_amt">
				<p class="fs_14 fsn_4 txt_lb mb-1 usdUdsd">
					<span> Higher quote/overbid Amount</span>
					<span *ngIf="data.paymentMethod === 2" class="ms-1 usdc_iconS"></span>
					<span class="ms-1" *ngIf="data.paymentMethod === 1">($)</span>
					<span class="txt_d"> *</span>
				</p>
				<mat-form-field appearance="none">
					<input *ngIf="data.paymentMethod === 1" maxlength="16" matInput type="text"
						placeholder="Enter Overbid Amount" class="form-control custom_input" formControlName="bidAmount"
						appTwoDigitDecimal />
					<span class="usdIcon_imgBg" *ngIf="data.paymentMethod === 1">
						<img src="https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-currency-usd.svg"
							class="img-fluid">
					</span>
					<input *ngIf="data.paymentMethod === 2" maxlength="16" matInput type="text"
						placeholder="Enter Overbid Amount" class="form-control custom_input" formControlName="bidAmount"
						appTwoDigitDecimaNumber />
					<span class="usdcIcon_imgBg" *ngIf="data.paymentMethod === 2">
						<img src="https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-USDC-16.svg"
							class="img-fluid">
					</span>
					<mat-error class="error_mess"
						*ngIf="submitted && overBidForm.get('bidAmount').errors?.['required']">Quote/Bid amount is
						required.</mat-error>
					<mat-error class="error_mess" *ngIf="overBidForm.get('bidAmount').errors?.['min']">The Overbid
						amount should be higher than the Shipment Rate.

					</mat-error>
				</mat-form-field>
			</div>
			<div class="feedback_input">
				<p class="fs_14 fsn_4 txt_lb mb-1"> Higher quote/overbid Notes (100 characters)</p>
				<mat-form-field appearance="none">
					<textarea matInput formControlName="bidNote" type="text" class="form-control"
						placeholder="Enter Overbid Note" maxlength="100"
						style="background: var(--clBluh); min-height: 100px; padding: 0.9rem;"></textarea>
				</mat-form-field>
			</div>
			<div class="agreeTandC">
				<p class="fs_12 fsn_4 txt_lb mb-0">
					<span>By submitting a quote/bid you agree to terms of the network contract. Click here to </span>
					<a href="{{data.contractDocument}}" target="_blank" class="txt_p"><u class="f_clash"> view
							document.</u></a>
				</p>
			</div>
		</div>
		<div class="btn_action">
			<button type="submit" class="btn custum_button button_primary btn_primary mb_16">Submit</button>
			<span class="btn_trans txt_lb pointer f_clash" (click)="onClose()">Cancel</span>
		</div>
	</form>
</div>

<div class="popup_container" *ngIf="data.openPop==='rebidOverBids'">
	<div class="header_popup">
		<p class="fs_20 fsb_6 txt_b line_before f_clash"> Higher quote/overbid</p>
		<span class="close_popup_Icon" (click)="onClose()">
			<span class="icon-ic-cross close_Icon"></span>
		</span>
	</div>

	<div class="bid_amount" *ngIf="data.amount">
		<p class="bid_amountTxt f_clash">CURRENT RATE FROM {{data.userName | uppercase }}</p>
		<p class="bid_amountValue">
			<span *ngIf="data.paymentMethod === 2" class="df_c">
				<span class="usdc_iconM"></span>
				<span class="ms-1">{{data.amount | sixDecimalPipe}}</span>
			</span>
			<span *ngIf="data.paymentMethod === 1">${{data.amount | twoDecimalPipe}}</span>
		</p>
	</div>
	<form class="mb-0 custum_input_css inputInPopup" [formGroup]="overBidForm"
		(ngSubmit)="overBidFormSubmit(overBidForm)" autocomplete="off">
		<div class="v_scrollBar vertical_sh" style="max-height: 38vh;">
			<p class="fs_16 fsn_4 txt_lb mb-2">
				<span>Please enter your Higher quote/overbid amount and Optional notes for</span>
				<span class="fsm_5 txt_b">{{data.userNameOther}}</span>.
			</p>
			<div class="bid_amt">
				<p class="fs_14 fsn_4 txt_lb mb-1 usdUdsd">
					<span> Higher quote/overbid Amount</span>
					<span *ngIf="data.paymentMethod === 2" class="ms-1 usdc_iconS"></span>
					<span class="ms-1" *ngIf="data.paymentMethod === 1">($)</span>
					<span class="txt_d"> *</span>
				</p>
				<mat-form-field appearance="none">
					<input *ngIf="data.paymentMethod === 1" matInput type="text" maxlength="10"
						placeholder="Enter Overbid Amount" class="form-control custom_input" formControlName="bidAmount"
						appTwoDigitDecimal />
					<span class="usdIcon_imgBg" *ngIf="data.paymentMethod === 1">
						<img src="https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-currency-usd.svg"
							class="img-fluid">
					</span>
					<input *ngIf="data.paymentMethod === 2" matInput type="text" placeholder="Enter Overbid Amount"
						maxlength="10" class="form-control custom_input" formControlName="bidAmount"
						appTwoDigitDecimaNumber />
					<span class="usdcIcon_imgBg" *ngIf="data.paymentMethod === 2">
						<img src="https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-USDC-16.svg"
							class="img-fluid">
					</span>
					<mat-error class="error_mess"
						*ngIf="submitted && overBidForm.get('bidAmount').errors?.['required']">quote/bid amount is
						required.</mat-error>
					<mat-error class="error_mess" *ngIf="overBidForm.get('bidAmount').errors?.['min']">The Higher
						quotes/overbids
						amount should be higher than the Shipment Rate.

					</mat-error>
				</mat-form-field>
			</div>
			<div class="feedback_input">
				<p class="fs_14 fsn_4 txt_lb mb-1"> Higher quotes/overbids Notes (100 characters)</p>
				<mat-form-field appearance="none">
					<textarea matInput formControlName="bidNote" type="text" class="form-control"
						placeholder="Enter Overbid Note" maxlength="100"
						style="background: var(--clBluh); min-height: 100px; padding: 0.9rem;"></textarea>
				</mat-form-field>
			</div>
		</div>
		<div class="btn_action">
			<button type="submit" class="btn custum_button button_primary btn_primary mb_16">Submit</button>
			<span class="btn_trans txt_lb pointer f_clash" (click)="onClose()">Cancel</span>
		</div>
	</form>
</div>

<div class="inviteDriver_con" *ngIf="data.openPop ==='acceptOfferRate'">
	<div class="header_popup">
		<p class="fs_20 fsb_6 txt_b line_before f_clash">Accept Rate</p>
		<span class="close_popup_Icon" (click)="onClose()">
			<span class="icon-ic-cross close_Icon"></span>
		</span>
	</div>
	<div class="bid_amount">
		<p class="bid_amountTxt f_clash">OFFER RATE FROM {{data.userName | uppercase }}</p>
		<p class="bid_amountValue contract_offer_Rate">
			<span *ngIf="data.paymentMethod === 2" class="df_c">
				<span class="usdc_iconM"></span>
				<span class="ms-1">{{data.amount | sixDecimalPipe}}</span>
			</span>
			<span *ngIf="data.paymentMethod === 1">${{data.amount | twoDecimalPipe}}</span>
			<app-rate-per-mile *ngIf="data.paymentMethod === 1" class="fs_14 fsm_5 mt-1" [amount]="data.amount"
				[miles]="data?.shipmentDetail?.miles"></app-rate-per-mile>
		</p>
	</div>
	<div class="text-center" style="margin: 0; padding: 1rem;">
		<p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 20px;">You need to confirm the Rate before
			Shipper company can assign it to you.
			Are you sure you want to accept the current rate from <span class="fsm_5 txt_b">{{data.userNameOther}}</span> updated?</p>

		
	</div>
	<div class="btn_action">
		<button type="submit" class="btn custum_button button_primary btn_primary mb_16 bg_p"
			(click)="acceptOffer('offerRate')">Accept & Confirm</button>
		<span class="btn_trans txt_lb pointer f_clash" (click)="onNoClickPopup()">Cancel</span>
	</div>
</div>

<div class="inviteDriver_con" *ngIf="data.openPop ==='acceptBidConfirmOffer'">
	<div class="header_popup">
		<p class="fs_20 fsb_6 txt_b line_before f_clash">Accept Rate</p>
		<span class="close_popup_Icon" (click)="onClose()">
			<span class="icon-ic-cross close_Icon"></span>
		</span>
	</div>
	<div class="bid_amount">
		<p class="bid_amountTxt f_clash">CURRENT RATE</p>
		<p class="bid_amountValue">
			<span *ngIf="data.paymentMethod === 2" class="df_c">
				<span class="usdc_iconM"></span>
				<span class="ms-1">{{data.amount | sixDecimalPipe}}</span>
			</span>
			<span *ngIf="data.paymentMethod === 1">${{data.amount | twoDecimalPipe}}</span>
		</p>
	</div>
	<div class="text-center" style="margin: 0; padding: 1rem;">
		<p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 20px;">You need to confirm the Rate before
			Shipper company can assign it to you.
			Are you sure you want to accept the current rate from <span class="fsm_5 txt_b">{{data.userNameOther}}</span> updated?</p>
	</div>
	<div class="btn_action">
		<button type="submit" class="btn custum_button button_primary btn_primary mb_16 bg_p"
			(click)="openDriverAlertPopupBid('confirm')">Accept & Confirm</button>
		<span class="btn_trans txt_lb pointer f_clash" (click)="onNoClickPopup()">Cancel</span>
	</div>
</div>

<div class="inviteDriver_con" *ngIf="data.openPop ==='acceptBidShipper'">
	<div class="header_popup">
		<p class="fs_20 fsb_6 txt_b line_before f_clash">Accept Quote/Bid</p>
		<span class="close_popup_Icon" (click)="onClose()">
			<span class="icon-ic-cross close_Icon"></span>
		</span>
	</div>
	<div class="bid_amount">
		<p class="bid_amountTxt f_clash">CURRENT QUOTE/BID</p>
		<p class="bid_amountValue">
			<span *ngIf="data.paymentMethod === 2" class="df_c">
				<span class="usdc_iconM"></span>
				<span class="ms-1">{{data.amount | sixDecimalPipe}}</span>
			</span>
			<span *ngIf="data.paymentMethod === 1" class="d-flex" style="flex-direction: column;">
				<span>${{data.amount | twoDecimalPipe}}</span>
				<app-rate-per-mile class="fs_14 fsm_5 mt-1" [amount]="data.amount"
					[miles]="data?.shipmentDetail?.miles"></app-rate-per-mile>
			</span>
		</p>
	</div>
	<div class="text-center" style="margin: 0; padding: 1rem;">
		<p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 20px;">
			Accept and Award the Quote/Bid to <span class="fsm_5">{{data.userName}}</span>.
		</p>
		<p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 20px;">
			<span>Are you sure you want to accept the current quote/bid</span>
			<span class="fsm_5 txt_b"> {{data.userName}}</span>?
		</p>
	</div>
	<div class="btn_action">
		<button type="submit" class="btn custum_button button_primary btn_primary mb_16 bg_s"
			(click)="acceptBidShipper()">Yes, Accept</button>
		<span class="btn_trans txt_lb pointer f_clash" (click)="onNoClickPopup()">Cancel</span>
	</div>
</div>

<div class="popup_container" *ngIf="data.openPop==='shipperFinalPost'">
	<div class="header_popup">
		<p class="fs_20 fsm_5 txt_b line_before">Post Shipment</p>
		<span class="close_popup_Icon" (click)="onClose()">
			<span class="icon-ic-cross close_Icon"></span>
		</span>
	</div>
	
	<div style="margin-bottom: 32px;">
		<p class="fs_14 fsn_4 txt_lb mb-2">
			Are you sure you want to post this shipment? Posting this shipment will alert thousands of Carriers in the
			network.
			All posted loads must be legitimate (no test loads allowed!).
		</p>
	</div>
	<div class="btn_action">
		<button type="submit" class="btn custum_button button_primary btn_primary mb_16"
			(click)='shipmentConfirmed()'>Post Shipment</button>
		<span class="btn_trans txt_lb pointer" (click)="onClose()">Cancel</span>
	</div>
</div>

<div *ngIf="data.openPop ==='ConfettiPopupShow'">
	<div class="text-center">
	   <div class="inviteDriver_con">
		  <img src="https://s3.us-east-1.amazonaws.com/staticprod.laneaxis.com/portal/gifs/laneaxis-congrats.gif" class="congrats_gif_img">
		</div>
		
	</div>
 </div>



<!-- Accept bid shipper -->
<div class="inviteDriver_con" *ngIf="data.openPop ==='confirmAndAssign'">
	<div class="header_popup">
		<p class="fs_20 fsb_6 txt_b line_before f_clash">Assign Shipment</p>
		<span class="close_popup_Icon" (click)="onClose()">
			<span class="icon-ic-cross close_Icon"></span>
		</span>
	</div>
	<div class="bid_amount">
		<p class="bid_amountTxt f_clash"> {{data.newStatusLabel | uppercase}}</p>
		<p class="bid_amountValue">
			<span *ngIf="data.paymentMethod === 2" class="df_c">
				<span class="usdc_iconM"></span>
				<span class="ms-1">{{data.amount | sixDecimalPipe}}</span>
			</span>
			<span *ngIf="data.paymentMethod === 1" class="d-flex" style="flex-direction: column;">
				<span>${{data.amount | twoDecimalPipe}}</span>
				<app-rate-per-mile class="fs_14 fsm_5 mt-1" [amount]="data.amount"
					[miles]="data?.shipmentDetail?.miles"></app-rate-per-mile>
			</span>
		</p>
	</div>
	<div class="text-center" style="margin: 0; padding: 1rem;">
		<p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 20px;">
			<span>Click below to confirm acceptance of the <span class="fsm_5 txt_b">{{data.userName}}</span>'s
				{{data.newStatusLabel}} and assign the shipment to the </span>
			<span class="fsm_5 txt_b"> {{data.userName}}</span>
		</p>
	</div>
	<div class="btn_action">
		<button type="submit" class="btn custum_button btn_primary btn_primary mb_16 bg_s txt_w"
			(click)="confirmAssign()">Yes, Confirm & Assign</button>
		<span class="btn_trans txt_lb pointer f_clash" (click)="onNoClickPopup()">Cancel</span>
	</div>
</div>

<div class="inviteDriver_con" *ngIf="data.openPop ==='confirmAndAssignOrder'">
	<div class="header_popup">
		<p class="fs_20 fsb_6 txt_b line_before f_clash">Assign Order</p>
		<span class="close_popup_Icon" (click)="onClose()">
			<span class="icon-ic-cross close_Icon"></span>
		</span>
	</div>
	<div class="bid_amount">
		<p class="bid_amountTxt f_clash"> {{data.newStatusLabel | uppercase}}</p>
		<p class="bid_amountValue">
			<span *ngIf="data.paymentMethod === 2" class="df_c">
				<span class="usdc_iconM"></span>
				<span class="ms-1">{{data.amount | sixDecimalPipe}}</span>
			</span>
			<span *ngIf="data.paymentMethod === 1" class="d-flex" style="flex-direction: column;">
				<span>${{data.amount | twoDecimalPipe}}</span>
			</span>
		</p>
	</div>
	<div class="text-center" style="margin: 0; padding: 1rem;">
		<p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 20px;">
			<span>Click below to confirm acceptance of the <span class="fsm_5 txt_b">{{data.userName}}</span>'s
				{{data.newStatusLabel}} and assign the order to the </span>
			<span class="fsm_5 txt_b"> {{data.userName}}</span>
		</p>
	</div>
	<div class="btn_action">
		<button type="submit" class="btn custum_button btn_primary btn_primary mb_16 bg_s txt_w"
			(click)="confirmAssignOrder()">Yes, Confirm & Assign</button>
		<span class="btn_trans txt_lb pointer f_clash" (click)="onNoClickPopup()">Cancel</span>
	</div>
</div>

<div class="inviteDriver_con" *ngIf="data.openPop ==='stripeConenct'">
	<div class="text-center">
		<p class="fs_20 fsb_6 txt_b f_clash">Your Stripe connect account is not connected yet.</p>
		<p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">You require a Stripe connect account
			to Quote/Bid on a shipment with Payment Method USD</p>
	</div>
	<div class="btn_action">
		<button type="button" class="btn custum_button btn_primary btn_primary mb_16 bg_s txt_w"
			(click)="onNoClick()">OK</button>
		<span class="btn_trans txt_lb pointer f_clash" (click)="onNoClickPopup()">Cancel</span>
	</div>
</div>

<div class="inviteDriver_con" *ngIf="data.openPop ==='stripeConenctCheck'">
	<div class="text-center">
		<p class="fs_20 fsb_6 txt_b f_clash">Your Stripe Connect Account is not Activated</p>
		<p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">To submit a quote/bid you must first
			create
			a Stripe Connect account. This is a quick, one-time process.</p>
	</div>
	<div class="btn_action">
		<button type="button" class="btn custum_button button_primary btn_primary mb_16" (click)="createNow()">Create
			Now</button>
		<span class="btn_trans txt_lb pointer f_clash" (click)="onNoClickPopup()">Cancel</span>
	</div>
</div>

<div class="p-3 text-center" *ngIf="data.openPop ==='stripeConenctPending'">
	<p class="fs_20 fsb_6 txt_b f_clash" style="margin: 0 0 2rem; line-height: 24px; padding: 0 14%;">Your Stripe
		connect account is not active yet…</p>
	<p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">You stripe connect account is either
		disabled or not available, Please update additional information to your account.</p>
	<div class="btn_action">
		<button type="button" class="btn custum_button button_primary btn_primary mb_16" (click)="payPage()">Check your
			Status</button>
		<span class="btn_trans txt_lb pointer f_clash" (click)="onNoClickPopup()">Cancel</span>
	</div>
</div>

<div class="p-2 text-center" *ngIf="data.openPop ==='magicLinkConnection'">
	<div>
		<img src="https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/magic_message-icon.svg"
			class="img-fluid">
		<div class="fs_20 fsb_6 txt_b f_clash text-center" style="margin: 2rem 0 1.5rem; line-height: 24px;">Wallet is
			not created yet…</div>
		<p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">You require a Wallet to Quote/Bid on
			a
			Shipment with Payment Method USDC.</p>
		<div class="text-center">
			<button type="button" class="btn custum_button button_primary btn_primary mb_16"
				(click)="createNow()">Create Now</button>
			<span class="btn_trans txt_lb pointer f_clash" (click)="onClose()">Cancel</span>
		</div>
	</div>

	<div style="display: none;">
		<img src="https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/magic_message-icon.svg"
			class="img-fluid" style="max-width: 56px;">
		<p class="fs_20 fsb_6 txt_b f_clash" style="margin: 2rem 0 2rem; line-height: 24px;">Wallet is not created yet…
		</p>
		<p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">You require a Wallet to Quote/Bid on
			a
			Shipment with Payment Method USDC.</p>
		<div class="btn_action">
			<button type="submit" class="btn custum_button button_primary btn_primary mb_16"
				(click)="onNoClick()">Create Now</button>
			<span class="btn_trans txt_lb pointer f_clash" (click)="onNoClickPopup()">Cancel</span>
		</div>
	</div>
	<div style="display: none;">
		<img src="https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-accept-bid.svg"
			class="img-fluid" style="width: 48px;">
		<p class="fs_20 fsb_6 txt_b f_clash" style="margin: 2rem 0 2rem; line-height: 24px;">Please check your email</p>
		<p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">We emailed a magic link to
			{{data.email}}. Please go to your email box to approve your existence.</p>
		<div class="btn_action">
			<button type="submit" class="btn custum_button button_primary btn_primary mb_16 bg_s"
				(click)="onNoClick()">OK</button>
			<span class="btn_trans txt_p pointer f_clash" (click)="onNoClick()">I'll do it later</span>
		</div>
	</div>
</div>

<div class="inviteDriver_con" *ngIf="data.openPop ==='paymentSuccess'">
	<div class="text-center" style="margin: 0; padding: 1rem;">
		<span class="material-icons"
			style="margin: 0 0 1.5rem;font-size: 48px;color: var(--clGrna);">check_circle</span>
		<p class="fs_20 fsb_6 txt_b f_clash" style="margin: 0 0 2rem;">Congratulations</p>
		<p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">
			Congratulations! Your payment has been successfully processed. <span class="fsb_6" style="font-weight: 600; color: #000;">Your shipment has been created</span> and assigned to your selected carrier.
		</p>
		<div class="text-center">
			<button type="submit" class="btn custum_button button_success w-100 text-capitalize fs_16 fsn_4"
				[routerLink]="['/shipment/view/',data.shipmentId,'shipment']" (click)="onNoClick()"
				style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">OK</button>
		</div>
	</div>
</div>


<div class="payment_failed_pop" *ngIf="data.openPop ==='paymentFail'">
	<img src="https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/gr-payment-failed.svg" class="img-fluid pay_fld_img">
	<p class="pay_fld_tlt f_clash">Oops</p>
	<p class="pay_fld_dis">The transaction was not processed, please retry using a different payment method.</p>
	<div class="pf_action">
		<button type="button" class="btn custum_button button_danger"  (click)="onClose()">Retry</button>
  
	</div>
</div>

<div class="inviteDriver_con" *ngIf="data.openPop ==='paymentSuccessACH'">
	<div class="text-center" style="margin: 0; padding: 1rem;">
		<span class="material-icons"
			style="margin: 0 0 1.5rem;font-size: 48px;color: var(--clGrna);">check_circle</span>
		<p class="fs_20 fsb_6 txt_b f_clash" style="margin: 0 0 2rem;">Congratulations</p>
		<p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">
			<span>Your payment for shipment <span class="fsm_5 txt_b">#{{data.uniqueId}}</span> has been successfully
				submitted.</span>
		</p>
		<div class="text-center">
			<button type="submit" class="btn custum_button button_success w-100 text-capitalize fs_16 fsn_4"
				[routerLink]="['/shipment/view/',data.shipmentId,'shipment']" (click)="onNoClick()"
				style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">OK</button>
		</div>
	</div>
</div>

<div class="payment_failed_pop" *ngIf="data.openPop ==='paymentFailACH'">
	<img src="https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/gr-payment-failed.svg" class="img-fluid pay_fld_img">
	<p class="pay_fld_tlt f_clash">Oops</p>
	<p class="pay_fld_dis">There was a problem processing your payment for shipment<span class="fsm_5 txt_b">#{{data.uniqueId}}.</span> Please ensure there are sufficient funds in your account to process payment.</p>
	<div class="pf_action">
		<button type="button" class="btn custum_button button_danger" [routerLink]="['/shipment/list/pending']" (click)="onClose()">Back to shipment</button>
    
	</div>
</div>

<!-- order payment popup -->

<div class="inviteDriver_con" *ngIf="data.openPop ==='orderPaymentSuccess'">
	<div class="text-center" style="margin: 0; padding: 1rem;">
		<span class="material-icons"
			style="margin: 0 0 1.5rem;font-size: 48px;color: var(--clGrna);">check_circle</span>
		<p class="fs_20 fsb_6 txt_b f_clash" style="margin: 0 0 2rem;">Congratulations</p>
		<p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">
			The payment has been Authorized, the Order has been created and assigned to the Vendor
			{{data?.companyName}}.
		</p>
		<div class="text-center">
			<button type="submit" class="btn custum_button button_success w-100 text-capitalize fs_16 fsn_4"
				[routerLink]="['/storage-order/view/',data.orderId,'shipperOrderTracking']" (click)="onNoClick()"
				style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">OK</button>
		</div>
	</div>
</div>

<div class="payment_failed_pop" *ngIf="data.openPop ==='orderPaymentFail'">
	<img src="https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/gr-payment-failed.svg" class="img-fluid pay_fld_img">
	<p class="pay_fld_tlt f_clash">Oops</p>
	<p class="pay_fld_dis">The transaction was not processed, please retry using a different payment method.</p>
	<div class="pf_action">
		<button type="button" class="btn custum_button button_danger"  (click)="onClose()">Retry</button>
   
	</div>
</div>

<div class="inviteDriver_con" *ngIf="data.openPop ==='orderPaymentSuccessACH'">
	<div class="text-center" style="margin: 0; padding: 1rem;">
		<span class="material-icons"
			style="margin: 0 0 1.5rem;font-size: 48px;color: var(--clGrna);">check_circle</span>
		<p class="fs_20 fsb_6 txt_b f_clash" style="margin: 0 0 2rem;">Congratulations</p>
		<p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">
			The payment has been Authorized, the Order has been created and assigned to the Vendor
			{{data?.companyName}}.
		</p>
		<div class="text-center">
			<button type="submit" class="btn custum_button button_success w-100 text-capitalize fs_16 fsn_4"
				[routerLink]="['/storage-order/view/',data.orderId,'shipperOrderTracking']" (click)="onNoClick()"
				style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">OK</button>
		</div>
	</div>
</div>

<div class="payment_failed_pop" *ngIf="data.openPop ==='orderPaymentFailACH'">
	<img src="https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/gr-payment-failed.svg" class="img-fluid pay_fld_img">
	<p class="pay_fld_tlt f_clash">Oops</p>
	<p class="pay_fld_dis">There was a problem processing your payment for order<span	class="fsm_5 txt_b">#{{data.uniqueId}}.</span> Please ensure there are sufficient funds in your	account to process payment.</p>
	<div class="pf_action">
		<button type="button" class="btn custum_button button_danger" [routerLink]="['/storage-order/list/orders']" (click)="onClose()">Back to shipment</button>
   
	</div>
</div>

<!-- Success popu -->
<div class="inviteDriver_con" *ngIf="data.openPop ==='stripeConenctFail'">
	<div class="text-center" style="margin: 0; padding: 1rem;">
		<span class="material-icons" style="margin: 0 0 1.5rem;font-size: 48px;color: red;">check_circle</span>
		<p class="fs_20 fsb_6 txt_b f_clash" style="margin: 0 0 2rem;">Oops! Payment failed</p>
		<p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">
			Sorry, Carrier connect account has been not activated yet, please try later.
		</p>
		<div class="text-center">
			<button type="button" class="btn custum_button button_danger w-100 text-capitalize fs_16 fsn_4"
				(click)="onNoClick()"
				style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">OK</button>
		</div>
	</div>
</div>

<!-- Add and Edit Physical Address -->
<div *ngIf="data.openPop ==='editAddress' || data.openPop ==='addAddress'">
	<div class="header_popup mb-2">
		<p class="fs_20 fsb_6 txt_b line_before f_clash" *ngIf="data.openPop ==='addAddress'">Add Address</p>
		<p class="fs_20 fsb_6 txt_b line_before f_clash" *ngIf="data.openPop ==='editAddress'">Edit Address</p>
		<span class="close_popup_Icon" (click)="onNoClick()">
			<span class="icon-ic-cross close_Icon"></span>
		</span>
	</div>
	<form [formGroup]="addressForm" (ngSubmit)="saveAddress(addressForm)" autocomplete="off"
		class="mb-0 custum_input_css inputInPopup" autocomplete="off">

		<div class="v_scrollBar popup_vh pt-3 scroll_align">
			<div class="row">
				<div class="col-md-12 ic_dropdown">
					<p class="fs_14 fsn_4 txt_lb mb-1">Address Type <span class="txt_d"> *</span></p>
					<mat-form-field appearance="none">
						<mat-select formControlName="addressType" placeholder="Select address type"
							class="form-control custom_input">
							<mat-option class="mat_dropdown" *ngIf="mailAdd" value="Mailing Address">Mailing Address
							</mat-option>
							<mat-option class="mat_dropdown" *ngIf="phpAdd" value="Physical Address">Physical Address
							</mat-option>
						</mat-select>
						<mat-error class="error_mess" *ngIf="addressForm.get('addressType').errors?.['required']">
							Address is required.</mat-error>
					</mat-form-field>
				</div>
				<div class="col-md-12">
					<p class="fs_14 fsn_4 txt_lb mb-1">Address Line 1 <span class="txt_d"> *</span></p>
					<mat-form-field appearance="none">
						<input matInput placeholder="Enter address line 1" [country]="searchRestriction"
							formControlName="street" matGoogleMapsAutocomplete
							(onGermanAddressMapped)="phyAddresSearch($event)"
							(onAutocompleteSelected)="pickAutoCompleteSelected($event)" [(ngModel)]="streetValue"
							(keyup)="checkSpace($event)" class="form-control custom_input" maxlength="255"
							[value]="addressForm.get('street').value">
						<mat-error class="error_mess" *ngIf="addressForm.get('street').errors?.['required']">Address is
							required.</mat-error>
						<mat-error class="error_mess" *ngIf="addressForm.get('street').errors?.['maxlength']">Address
							max length 255 words.</mat-error>
					</mat-form-field>
				</div>
				<div class="col-md-12">
					<p class="fs_14 fsn_4 txt_lb mb-1">Address Line 2 </p>
					<mat-form-field appearance="none">
						<input matInput formControlName="addressline2" [(ngModel)]="streetValue2"
							(keyup)="checkSpace2($event)" type="text" placeholder="Enter address line 2"
							class="form-control custom_input" />
						<mat-error class="error_mess" *ngIf="addressForm.get('street').errors?.['maxlength']">Address
							line 2 max length 255 words.</mat-error>
					</mat-form-field>
				</div>
				<div class="col-md-6">
					<p class="fs_14 fsn_4 txt_lb mb-1">ZIP Code <span class="txt_d"> *</span></p>
					<mat-form-field appearance="none">
						<input matInput placeholder="ZIP Code" formControlName="zipCode" minlength="4" maxlength="8" class="form-control custom_input">
						<mat-error class="error_mess" *ngIf="addressForm.get('zipCode').errors?.['required']">ZIP Code is required.</mat-error>
					 
					    <mat-error class="error_mess" *ngIf="addressForm.get('zipCode').errors?.['pattern']">{{zipCodeErrorMsg}}</mat-error>
					</mat-form-field>
				</div>
				<div class="col-md-6">
					<p class="fs_14 fsn_4 txt_lb mb-1">City <span class="txt_d"> *</span></p>
					<mat-form-field appearance="none">
						<input matInput placeholder="City" [(ngModel)]="city" (keyup)="checkSpace3($event)"
							formControlName="cityBy" maxlength="100" maxlength="33" class="form-control custom_input">
						<mat-error class="error_mess" *ngIf="addressForm.get('cityBy').errors?.['required']">City is
							required.</mat-error>
					
						<mat-error class="error_mess" *ngIf="addressForm.get('cityBy').errors?.['maxlength']">Only 32
							characters are allowed.</mat-error>
					</mat-form-field>
				</div>
				<div class="col-md-6">
					<p class="fs_14 fsn_4 txt_lb mb-1">State <span class="txt_d"> *</span></p>
					<mat-form-field appearance="none">
						<mat-select formControlName="stateBy" class="form-control custom_input"
							placeholder="Select state">
							<mat-option class="mat_dropdown" *ngFor="let state of states" [value]="state.sortname">
								{{state.name}}</mat-option>
						</mat-select>
						<mat-error class="error_mess" *ngIf="addressForm.get('stateBy').errors?.['required']">State is
							required.</mat-error>
					</mat-form-field>
				</div>
				<div class="col-md-6 ic_dropdown">
					<p class="fs_14 fsn_4 txt_lb mb-1">Country <span class="txt_d"> *</span></p>
					<mat-form-field appearance="none">
						<input type="text" readonly matInput class="form-control custom_input" placeholder="Enter Country" formControlName="country"/>

						
					</mat-form-field>
				</div>
			</div>
		</div>
		<div class="text-center">
			<button type="submit" class="btn custum_button button_primary fs_16 fsm_5 w-100 h_40">Save Address</button>
			<span class="fs_16 fsm_5 txt_lb pointer f_clash px-4 py-3 h_40 d-block" type="submit"
				(click)="onNoClick()">Cancel</span>
		</div>
	</form>
</div>

<div *ngIf="data.openPop ==='fileDispute'">
	<div class="" style="margin: 0; padding: 1rem;">
		<p class="fs_20 fsb_6 txt_b f_clash text-center" style="margin: 0 0 2rem;">Filing a Dispute</p>
		<p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 1rem; line-height: 24px;">
			<span>You have chosen to file a dispute regarding this</span>
			<span class="txt_b"> {{data.title}} #{{data.uniqueId}}.</span>
		</p>
		<p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 1rem; line-height: 24px;">
			<span>When a dispute arises, we encourage both parties to communicate directly with each other in order to
				reach a timely resolution. If you would like to proceed with filing a dispute,</span>
			<span class="txt_b"> LaneAxis</span>
			<span> and/or a third party mediator will investigate the claim and seek a mutually agreed upon resolution.
				If necessary, a legally binding final decision will be issued.</span>
		</p>
		<p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 1rem; line-height: 24px;">
			<span>No payment will be released until the dispute is resolved. The timeline for resolving disputes or
				issuing final judgments will vary from case to case.</span>
		</p>
		<p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">
			<span>Would you still like to file a dispute?</span>
		</p>

		<div class="text-center">
			<button type="button" class="btn custum_button button_success w-100 fs_16 fsn_4"
				[routerLink]="['/shipment/dispute',data?.shipmentId]" (click)="onNoClick()"
				style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">Yes</button>
			<span class="fs_16 fsn_4 txt_lb pointer f_clash px-4 py-2" (click)="onNoClick()">No</span>
		</div>
	</div>
</div>

<!-- Success popu -->
<div class="inviteDriver_con" *ngIf="data.openPop ==='disputeSuccess'">
	<div class="text-center" style="margin: 0; padding: 1rem;">
		<span class="material-icons"
			style="margin: 0 0 1.5rem;font-size: 48px;color: var(--clGrna);">check_circle</span>
		<p class="fs_20 fsb_6 txt_b f_clash" style="margin: 0 0 2rem;">Congratulations</p>
		<p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">
			<span>You have successfully filed a dispute regarding </span>
			<span class="txt_b"> {{data.title}} # {{data.uniqueId}}</span>
			<span> shipment.</span>
		</p>
		<p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">
			<span class="txt_b">LaneAxis </span>
			<span> and/or a third party mediator will investigate the claim and seek a mutually agreed upon
				resolution.</span>
		</p>
		<div class="text-center">
			<button type="submit" class="btn custum_button button_success w-100 text-capitalize fs_16 fsn_4"
				(click)="onNoClick()" [routerLink]="['/shipment/list/inProgress']"
				style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">OK</button>
			<span class="fs_16 fsn_4 txt_p pointer f_clash px-4 py-2"
				[routerLink]="['/shipment/view', data?.shipmentId]" [queryParams]="{type: 'dispute'}">View
				Details</span>
		</div>
	</div>
</div>

<div class="allPrevios_bids" *ngIf="data.openPop ==='bidHistoryShow'">
	<div class="detail_card_con p_24">
		<div class="header_popup">
			<p class="fs_20 fsb_6 txt_b line_before f_clash">Previous Quotes/Bids from {{data.userName}}</p>
			<span class="close_popup_Icon" (click)="onClose()">
				<span class="icon-ic-cross close_Icon"></span>
			</span>
		</div>
		<div *ngIf="previousBidHistory?.length > 0 && !bidHistorySekeletonLoader" id="all_Drivers"
			class="list_scroll man_carrier_scroll" infiniteScroll [infiniteScrollDistance]="3"
			[infiniteScrollThrottle]="100" (scrolled)="onHiScroll()" [scrollWindow]="false"
			style="margin: 0px -24px; padding: 0px 24px;">
			<table class="table mb-0 acceptd_previous_table">
				<tbody>
					<tr *ngFor="let historyRecord of previousBidHistory;let j=index;">
						<td class="fs_16 fsm_5 txt_lb">
							<div class="carrierN">
								<div class="carrierN_img">
									<span *ngIf="historyRecord?.isPreferred === 1"
										class="material-icons star_marked">star</span>
									<img *ngIf="historyRecord?.profileImage" src="{{historyRecord?.profileImage}}"
										class="img-fluid">
									<span class="initial_name initial_r_32 f_clash"
										*ngIf="!historyRecord?.profileImage">{{historyRecord.userName |
										acronymOfName}}</span>
								</div>
								<div class="carrierNl">
									<p class="text_truncate mb-0" style="max-width: 180px;">
										<span class="f_clash">{{historyRecord.userName ? historyRecord.userName : '-'}}
										</span>
										<span *ngIf="currentId === historyRecord.actionBy" class="f_clash">(Me)</span>
										<img *ngIf="historyRecord?.subscription" src="https://staticprod.laneaxis.com/portal/images/after-login/premium_plan_icon.png" class="img-fluid verified_icon">
									</p>
								</div>
							</div>
						</td>
						<td class="fs_16 fsn_4 txt_lb" style="width: 200px;" *ngIf="historyRecord.actionTs">
							{{historyRecord.actionTs | date: 'MMM dd, yyyy'}} at {{historyRecord.actionTs | date:
							'HH:mm:ss'}}</td>

						<td class="fs_16 fsn_4 txt_lb" style="width: 200px;">
							<div class="col_rate_compare">
								<div class="rate_compare">
									<span *ngIf="data.paymentMethod === 2">
										<span class="ms-1 usdc_iconS"></span>
										<span>{{historyRecord.bidAmount | sixDecimalPipe}}</span>
									</span>
									<span *ngIf="data.paymentMethod === 1">
										<span *ngIf="historyRecord.bidAmount">
											<span>${{historyRecord.bidAmount | twoDecimalPipe}}</span><br>
											<app-rate-per-mile class="fs_12 fsm_5 txt_lb"
												[amount]="historyRecord.bidAmount"
												[miles]="data?.shipmentDetail?.miles"></app-rate-per-mile>
										</span>
										<span *ngIf="!historyRecord.bidAmount">-</span>
									</span>
								</div>
								<div class="usdUdsd" *ngIf="data?.shipmentDetail?.isOfferRate==='N'">
									<span *ngIf="data.paymentMethod === 2">
										<span class="ms-1 usdc_iconS"></span>
										<span>{{historyRecord.bidAmount | sixDecimalPipe}}</span>
									</span>
									<div style="display: flex; flex-direction: column;">
										<div class="fsm_5 row_label f_clash">Expected Rate </div>
										<span *ngIf="data.paymentMethod === 1">
											<span *ngIf="historyRecord.bidAmount">
												<span class="fs_13 fsn_4 txt_lb">${{data?.shipmentDetail?.freightRate |
													twoDecimalPipe}}</span>
												<app-rate-per-mile class="fs_12 fsn_4 txt_lb ml_1"
													[amount]="data?.shipmentDetail?.freightRate"
													[miles]="data?.shipmentDetail?.miles"></app-rate-per-mile>
											</span>
											<span *ngIf="!historyRecord.bidAmount">-</span>
										</span>
									</div>
								</div>
							</div>
							<span class="bid_message pointer" matTooltip="{{historyRecord.note}}">{{historyRecord.note ?
								historyRecord.note : '-'}}</span>
						</td>
						<td *ngIf="historyRecord.statusLabel==='Withdrawn'" class="fs_16 fsm_5 txt_d f_clash"
							style="width: 170px; text-align: end; text-transform: capitalize;">
							{{historyRecord.statusLabel ? historyRecord.statusLabel : '-'}}</td>
						<td *ngIf="historyRecord.statusLabel !=='Withdrawn'" class="fs_16 fsm_5 txt_p f_clash"
							style="width: 170px; text-align: end; text-transform: capitalize;">
							{{historyRecord.statusLabel ? historyRecord.statusLabel : '-'}}</td>
					</tr>
				</tbody>
			</table>
			<div class="text-center" *ngIf="spinnerLoaderBid">
				<div class="spinner-border txt_p" role="status">
					<span class="sr-only">
						<!-- Loading... -->
					</span>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="bidHistorySekeletonLoader && previousBidHistory?.length === 0">
		<table class="table mb-0 shipment_bidding_car">
			<tbody>
				<tr style="height: 65px;" *ngFor="let fake of generateFake(5)">
					<td>
						<div style="width: 10%; float: left; height: 40px;">
							<span>
								<ngx-skeleton-loader appearance="circle" [theme]="{ width: '40px', height: '40px' }"
									class="loader_m_0"></ngx-skeleton-loader>
							</span>
						</div>
						<div style="width: 80%; float: right;">
							<span>
								<ngx-skeleton-loader count="1"
									[theme]="{ 'border-radius': '0', width: '80%', height: '12px' }"
									class="loader_mb_0"></ngx-skeleton-loader>
							</span>
						</div>
					</td>
					<td>
						<div class="loader_mb_0">
							<ngx-skeleton-loader count="1"
								[theme]="{ 'border-radius': '0', width: '80%', height: '12px' }" class="loader_mb_0">
							</ngx-skeleton-loader>
						</div>
					</td>
					<td>
						<div class="loader_mb_0">
							<ngx-skeleton-loader count="1"
								[theme]="{ 'border-radius': '0', width: '80%', height: '12px' }" class="loader_mb_0">
							</ngx-skeleton-loader>
						</div>
					</td>
					<td>
						<div class="loader_mb_0">
							<ngx-skeleton-loader count="1"
								[theme]="{ 'border-radius': '0', width: '80%', height: '12px' }" class="loader_mb_0">
							</ngx-skeleton-loader>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>

	<div *ngIf="!bidHistorySekeletonLoader && previousBidHistory?.length === 0">
		<div class="Empty_State_body_withou_box py-2 mb-0">
			<div class="Empty_State_con">
				<div class="Empty_State_img">
					<img class="img-fluid"
						src="{{serverPathForUrl}}/portal/images/after-login/Empty_State_icons/graphic_no-transactions.svg ">
				</div>
				<div>
					<p class="fs_24 fsb_6 txt_b pb-1" style="margin: 30px 0 12px;">No Quote/Bid History... <span
							class="txt_p"> Yet</span> </p>
					<p class="fs_16 fsn_4 txt_lb pb-1" style="margin: 0px 0 20px;">You currently dont have any Bids on
						this shipment. Assign the Shipment to the carriers in your network to start the Bidding process.
						You can directly negotiate the Shipment rate by Accepting a Quote/Bid or Re-Bidding on the
						Carriers
						Quote/Bid price.</p>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="allPrevios_bids" *ngIf="data.openPop ==='showOrderPreviousHistory'">
	<div class="detail_card_con p_24">
		<div class="header_popup">
			<p class="fs_20 fsb_6 txt_b line_before f_clash">Previous Quotes/Bids from {{data.userName}}</p>
			<span class="close_popup_Icon" (click)="onClose()">
				<span class="icon-ic-cross close_Icon"></span>
			</span>
		</div>
		<div *ngIf="previousBidHistory?.length > 0 && !bidHistorySekeletonLoader" id="all_Drivers"
			class="list_scroll man_carrier_scroll" infiniteScroll [infiniteScrollDistance]="3"
			[infiniteScrollThrottle]="100" (scrolled)="onPreScroll()" [scrollWindow]="false"
			style="margin: 0px -24px; padding: 0px 24px;">
			<table class="table mb-0 acceptd_previous_table">
				<tbody>
					<tr *ngFor="let historyRecord of previousBidHistory;let j=index;">
						<td class="fs_16 fsm_5 txt_lb">
							<div class="carrierN">
								<div class="carrierN_img">
									<span *ngIf="historyRecord?.isPreferred === 1"
										class="material-icons star_marked">star</span>
									<img *ngIf="historyRecord?.profileImage" src="{{historyRecord?.profileImage}}"
										class="img-fluid">
									<span class="initial_name initial_r_32 f_clash"
										*ngIf="!historyRecord?.profileImage">{{historyRecord.userName |
										acronymOfName}}</span>
								</div>
								<div class="carrierNl">
									<p class="text_truncate mb-0" style="max-width: 180px;">
										<span class="f_clash">{{historyRecord.userName ? historyRecord.userName : '-'}}
										</span>
										<span *ngIf="currentId === historyRecord.actionBy" class="f_clash">(Me)</span>
										<img *ngIf="historyRecord.subscription" src="https://staticprod.laneaxis.com/portal/images/after-login/premium_plan_icon.png" class="img-fluid verified_icon">
									</p>
								</div>
							</div>
						</td>
						<td class="fs_16 fsn_4 txt_lb" style="width: 200px;" *ngIf="historyRecord.actionTs">
							{{historyRecord.actionTs | date: 'MMM dd, yyyy'}} at {{historyRecord.actionTs | date:
							'HH:mm:ss'}}</td>

						<td class="fs_16 fsn_4 txt_lb" style="width: 200px;">
							<div class="col_rate_compare">
								<div class="rate_compare">
									<span *ngIf="data.paymentMethod === 2">
										<span class="ms-1 usdc_iconS"></span>
										<span>{{historyRecord.bidAmount | sixDecimalPipe}}</span>
									</span>
									<span *ngIf="data.paymentMethod === 1">
										<span *ngIf="historyRecord.bidAmount">
											<span>${{historyRecord.bidAmount | twoDecimalPipe}}</span><br>
										</span>
										<span *ngIf="!historyRecord.bidAmount">-</span>
									</span>
								</div>
								<div class="usdUdsd" *ngIf="data?.shipmentDetail?.isOfferRate==='N'">
									<span *ngIf="data.paymentMethod === 2">
										<span class="ms-1 usdc_iconS"></span>
										<span>{{historyRecord.bidAmount | sixDecimalPipe}}</span>
									</span>
									<div style="display: flex; flex-direction: column;">
										<div class="fsm_5 row_label f_clash">Expected Rate </div>
										<span *ngIf="data.paymentMethod === 1">
											<span *ngIf="historyRecord.bidAmount">
												<span class="fs_13 fsn_4 txt_lb">${{data?.shipmentDetail?.freightRate |
													twoDecimalPipe}}</span>
											</span>
											<span *ngIf="!historyRecord.bidAmount">-</span>
										</span>
									</div>
								</div>
							</div>
							<span class="bid_message pointer" matTooltip="{{historyRecord.notes}}">{{historyRecord.notes ?
								historyRecord.notes : '-'}}</span>
						</td>
						<td *ngIf="historyRecord.statusLabel==='Withdrawn'" class="fs_16 fsm_5 txt_d f_clash"
							style="width: 170px; text-align: end; text-transform: capitalize;">
							{{historyRecord.statusLabel ? historyRecord.statusLabel : '-'}}</td>
						<td *ngIf="historyRecord.statusLabel !=='Withdrawn'" class="fs_16 fsm_5 txt_p f_clash"
							style="width: 170px; text-align: end; text-transform: capitalize;">
							{{historyRecord.statusLabel ? historyRecord.statusLabel : '-'}}</td>
					</tr>
				</tbody>
			</table>
			<div class="text-center" *ngIf="spinnerLoaderBid">
				<div class="spinner-border txt_p" role="status">
					<span class="sr-only">
						<!-- Loading... -->
					</span>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="bidHistorySekeletonLoader && previousBidHistory?.length === 0">
		<table class="table mb-0 shipment_bidding_car">
			<tbody>
				<tr style="height: 65px;" *ngFor="let fake of generateFake(5)">
					<td>
						<div style="width: 10%; float: left; height: 40px;">
							<span>
								<ngx-skeleton-loader appearance="circle" [theme]="{ width: '40px', height: '40px' }"
									class="loader_m_0"></ngx-skeleton-loader>
							</span>
						</div>
						<div style="width: 80%; float: right;">
							<span>
								<ngx-skeleton-loader count="1"
									[theme]="{ 'border-radius': '0', width: '80%', height: '12px' }"
									class="loader_mb_0"></ngx-skeleton-loader>
							</span>
						</div>
					</td>
					<td>
						<div class="loader_mb_0">
							<ngx-skeleton-loader count="1"
								[theme]="{ 'border-radius': '0', width: '80%', height: '12px' }" class="loader_mb_0">
							</ngx-skeleton-loader>
						</div>
					</td>
					<td>
						<div class="loader_mb_0">
							<ngx-skeleton-loader count="1"
								[theme]="{ 'border-radius': '0', width: '80%', height: '12px' }" class="loader_mb_0">
							</ngx-skeleton-loader>
						</div>
					</td>
					<td>
						<div class="loader_mb_0">
							<ngx-skeleton-loader count="1"
								[theme]="{ 'border-radius': '0', width: '80%', height: '12px' }" class="loader_mb_0">
							</ngx-skeleton-loader>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>

	<div *ngIf="!bidHistorySekeletonLoader && previousBidHistory?.length === 0">
		<div class="Empty_State_body_withou_box py-2 mb-0">
			<div class="Empty_State_con">
				<div class="Empty_State_img">
					<img class="img-fluid"
						src="{{serverPathForUrl}}/portal/images/after-login/Empty_State_icons/graphic_no-transactions.svg ">
				</div>
				<div>
					<p class="fs_24 fsb_6 txt_b pb-1" style="margin: 30px 0 12px;">No Quote/Bid History... <span
							class="txt_p"> Yet</span> </p>
					<p class="fs_16 fsn_4 txt_lb pb-1" style="margin: 0px 0 20px;">You currently dont have any
						Quotes/Bids on
						this shipment. Assign the Shipment to the carriers in your network to start the Bidding process.
						You can directly negotiate the Shipment rate by Accepting a Quote/Bid or Re-Bidding on the
						Carriers
						Quote/Bid price.</p>
				</div>
			</div>
		</div>
	</div>
</div>

<div *ngIf="data.openPop ==='orderConfirmation'">
	<div class="header_popup">
		<p class="fs_20 fsb_6 txt_b line_before f_clash">Delete Order</p>
		<span class="close_popup_Icon" (click)="onClose()">
			<span class="icon-ic-cross close_Icon"></span>
		</span>
	</div>
	<div class="">
		<p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">
			<span>Are you sure you want to delete this order?</span>
		</p>
		<div class="text-center">
			<button type="button" class="btn custum_button button_primary w-100 fs_16 fsn_4" (click)="orderDelete()"
				style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">Yes</button>
			<span class="fs_16 fsn_4 txt_lb pointer f_clash px-4 py-2" (click)="onNoClick()">Cancel</span>
		</div>
	</div>
</div>

<div class="popup_container" *ngIf="data?.openPop==='firstLaneBid' || data?.openPop==='firstLaneBidCarrier'">
	<div class="header_popup">
		<p class="fs_20 fsb_6 txt_b line_before f_clash">Enter Quote/Bid</p>
		<span class="close_popup_Icon" (click)="onClose()">
			<span class="icon-ic-cross close_Icon"></span>
		</span>
	</div>
	<div class="bid_amount" *ngIf="data?.laneId">
		<p class="bid_amountTxt f_clash">EXPECTED RATE FROM <span class="blur">{{data?.userName | uppercase }}</span></p>
		<p class="bid_amountValue contract_offer_Rate">
			<span>-</span>
		
		</p>
	</div>	
		<form class="mb-0 custum_input_css inputInPopup" [formGroup]="newLaneBidForm" (ngSubmit)="newLaneBidFormSubmit(newLaneBidForm)" autocomplete="off">
			<div class="v_scrollBar vertical_sh" style="max-height: 38vh;">
				<p class="fs_16 fsn_4 txt_lb mb-2">
					<span>Please enter your quote/bid rate and any optional notes for</span>
			    	<span class="fsm_5 txt_b" [ngClass]="laneShipperDetail?.lastBid == 'null' ? '' : 'blur'"> {{data.userNameOther}}</span>.
				</p>
				<div class="bid_amt">
					<p class="fs_14 fsn_4 txt_lb mb-1 usdUdsd">
						<span>Quote/Bid Rate</span>
						<span class="ms-1">($)</span>
						<span class="txt_d"> *</span>
					</p>
					<mat-form-field appearance="none">
						<input maxlength="16" matInput type="text" placeholder="Enter Quote/Bid Rate" class="form-control custom_input" 
						formControlName="laneBidAmount" appTwoDigitDecimal />
						<span class="usdIcon_imgBg">
							<img src="https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-currency-usd.svg" class="img-fluid">
						</span>
						<mat-error class="error_mess" *ngIf="submitted && newLaneBidForm.get('laneBidAmount').errors?.['required']">
							Quote/Bid rate is required.</mat-error>

					</mat-form-field>
				</div>
				<div class="feedback_input">
					<p class="fs_14 fsn_4 txt_lb mb-1">Quote/Bid Note (100 characters)</p>
					<mat-form-field appearance="none">
						<textarea matInput formControlName="laneBidNote" type="text" class="form-control custom_input"
							placeholder="Enter Quote/Bid Note" maxlength="100"
							style="background: var(--clBluh); min-height: 100px; padding: 0.9rem;"></textarea>
					</mat-form-field>
				</div>
				<div class="agreeTandC" *ngIf="data?.laneDetail?.estimatedPrice">
						<span class="fs_14 fsn_4 txt_lb">By bidding on this lane you are also accepting the shipper</span>
						<a *ngIf="data?.contractDocument" href="{{data?.contractDocument}}" target="_blank"
						   class="txt_p">
						   <u class="txt_p ms-1 f_clash">contract</u>.
						</a>
				</div>
			</div>	
			<div class="btn_action">
				<button type="submit" class="btn custum_button button_primary btn_primary mb_16">Submit Your Quote/Bid</button>
				<span class="btn_trans txt_lb pointer f_clash" (click)="onClose()">Cancel</span>
			</div>
		</form>
</div>

<div class="popup_container" *ngIf="data?.openPop==='laneRebid' || data?.openPop==='laneRebidCarrier'">
	<div class="header_popup">
		<p class="fs_20 fsb_6 txt_b line_before f_clash">Counter Quote/Bid</p>
		<span class="close_popup_Icon" (click)="onClose()">
			<span class="icon-ic-cross close_Icon"></span>
		</span>
	</div>
	<div class="bid_amount" *ngIf="data?.amount">
		<p class="bid_amountTxt f_clash" *ngIf="userType=='SHIPPER'">CURRENT QUOTE/BID FROM {{data?.userName | uppercase }}</p>
		<p class="bid_amountTxt f_clash" *ngIf="userType=='CARRIER'">CURRENT QUOTE/BID FROM <span [ngClass]="(data?.laneDetail?.bids?.actionBy || data?.laneDetail?.lastBid?.actionBy) == userId ?  '' : 'blur'">{{data?.userName | uppercase }}</span></p>
		<p class="bid_amountValue contract_offer_Rate">
			<span>${{data?.amount | twoDecimalPipe}}</span>
			<app-rate-per-mile class="fs_14 fsm_5 mt-1"[amount]="data?.amount" [miles]="data?.laneDetail?.miles"></app-rate-per-mile>
		</p>
	</div>	
		<form class="mb-0 custum_input_css inputInPopup" [formGroup]="newLaneBidForm" (ngSubmit)="newLaneBidFormSubmit(newLaneBidForm)" autocomplete="off">
			<div class="v_scrollBar vertical_sh" style="max-height: 38vh;" >
				
				<p class="fs_16 fsn_4 txt_lb mb-2">
					<span>Please enter your counter quote/bid rate and any optional notes for</span>
				    <span class="txt_b fsm_5" [ngClass]="data?.laneDetail?.lastBid == 'null' || data?.laneDetail?.status == '1' ?  '' : 'blur'"> {{data.userNameOther}}.</span>
				</p>
				<div class="bid_amt">
					<p class="fs_14 fsn_4 txt_lb mb-1 usdUdsd">
						<span>Quote/Bid Rate</span>
						<span class="ms-1">($)</span><span class="txt_d"> *</span>
						
					</p>
					<mat-form-field appearance="none">
						<input maxlength="16" matInput type="text" placeholder="Enter Quote/Bid Rate" class="form-control custom_input" 
						formControlName="laneBidAmount" appTwoDigitDecimal />
						<span class="usdIcon_imgBg">
							<img src="https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-currency-usd.svg" class="img-fluid">
						</span>
						<mat-error class="error_mess" *ngIf="submitted && newLaneBidForm.get('laneBidAmount').errors?.['required']">
							Quote/Bid Rate is required.</mat-error>
					</mat-form-field>
				</div>
				<div class="feedback_input">
					<p class="fs_14 fsn_4 txt_lb mb-1">Quote/Bid Note (100 characters)</p>
					<mat-form-field appearance="none">
						<textarea matInput formControlName="laneBidNote" type="text" class="form-control custom_input"
							placeholder="Enter Quote/Bid Note" maxlength="100"
							style="background: var(--clBluh); min-height: 100px; padding: 0.9rem;"></textarea>
					</mat-form-field>
				</div>
				<div class="agreeTandC" *ngIf="userType=='CARRIER' && data?.amount">
						<p class="fs_12 fsn_4 txt_lb m-0">By bidding on this lane you are also accepting the shipper</p>
						<a *ngIf="data?.contractDocument" href="{{data?.contractDocument}}" target="_blank"
						   class="txt_p">
						   <u class="txt_p ms-1 f_clash">contract</u>.
						</a>
				</div>
				<div class="agreeTandC" *ngIf="userType=='SHIPPER' && data?.amount">
						<span class="fs_14 fsn_4 txt_lb">By bidding on this lane you are also accepting the carrier</span>
						<a *ngIf="data?.coiDoc" href="{{data?.coiDoc}}" target="_blank"
						   class="txt_p">
						   <u class="txt_p ms-1 f_clash">document</u>.
						</a>
				</div>
			</div>	
			<div class="btn_action">
				<button type="submit" class="btn custum_button button_primary btn_primary mb_16">Submit Your Quote/Bid</button>
				<span class="btn_trans txt_lb pointer f_clash" (click)="onClose()">Cancel</span>
			</div>
		</form>
</div>

<div class="inviteDriver_con" *ngIf="data?.openPop ==='laneAcceptBidCarrier' || data?.openPop ==='laneAcceptBidForFixRate' || data?.openPop ==='laneAcceptBidShipperCarrier'">
	<div class="header_popup">
		<p class="fs_20 fsb_6 txt_b line_before f_clash" *ngIf="data?.rateType=='1' || data?.rateType=='acceptForCarrierList'">Accept Quote/Bid</p>
		<p class="fs_20 fsb_6 txt_b line_before f_clash" *ngIf="data?.rateType=='2'">Accept Fixed Rate</p>
		<span class="close_popup_Icon" (click)="onClose()">
			<span class="icon-ic-cross close_Icon"></span>
		</span>
	</div>
	<!-- FROM {{data.userName | uppercase}}ll -->
	<div class="bid_amount">
		<p class="bid_amountTxt f_clash" *ngIf="data?.rateType=='1' || data?.rateType=='acceptForCarrierList'">CURRENT QUOTE/BID</p>
		<p class="bid_amountTxt f_clash" *ngIf="data?.rateType=='2'">CURRENT FIXED RATE</p>
		<p class="bid_amountValue">
			<span>${{data?.amount | twoDecimalPipe}}</span>
		</p>
	</div>
	<div class="v_scrollBar vertical_sh" style="max-height: 20vh;">
		<p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 20px;">
			<span>Are you sure you want to accept the current <span *ngIf="data?.rateType=='1' || data?.rateType=='acceptForCarrierList'">quote/bid</span> <span *ngIf="data?.rateType=='2'">fixed rate</span> from</span>
            <span class="txt_b fsm_5" [ngClass]="userType==='CARRIER' ? 'blur' : '' "> {{data?.userNameOther}}?</span>
		</p>
	</div>
	<div class="btn_action">
			<button *ngIf="data?.rateType === '1'" type="submit" class="btn custum_button button_success btn_success mb_16 bg_s px-2"
				(click)="accetpBidLane('accept')">Accept Quote/Bid</button>
			<button *ngIf="data?.rateType === '2'" type="submit" class="btn custum_button button_success btn_success mb_16 bg_s px-2"
				(click)="accetpBidLane('acceptAndConfirm')">Accept Fixed Rate</button>
				
			<button *ngIf="data?.rateType === 'acceptForCarrierList'" type="submit" class="btn custum_button button_success btn_success mb_16 bg_s px-2"
				(click)="accetpBidLane('accept')">Accept Quote/Bid</button>
		<span class="btn_trans txt_lb pointer f_clash" (click)="onNoClickPopup()">Cancel</span>
	</div>
</div>

<div *ngIf="data?.openPop ==='connectInviteLane' || data?.openPop ==='connectInviteLaneCarrier' || data?.openPop ==='connectInviteLaneCarrierForFixRate' || data?.openPop ==='connectInviteLaneFixRate'" class="accept_invitations"> 
	<div class="header_popup">
	   <p class="fs_20 fsb_6 txt_b line_before f_clash" *ngIf="data?.rateType === '1'">Accept Quote/Bid</p>
	   <p class="fs_20 fsb_6 txt_b line_before f_clash" *ngIf="data?.rateType === 'acceptForCarrierList'">Accept Quote/Bid</p>
	   <p class="fs_20 fsb_6 txt_b line_before f_clash" *ngIf="data?.rateType === '2'">Accept Fixed Rate</p>
	   <span class="close_popup_Icon" (click)="onClose()">
		  <span class="icon-ic-cross close_Icon"></span>
	   </span>
	</div>
	
	<div class="v_scrollBar vertical_sh" style="max-height: 70vh;">
	   <p class="fs_16 fsn_4 txt_lb" *ngIf="userType=='SHIPPER'">Build a quality network by connecting with {{userType==='SHIPPER' ? 'Carriers' :
		  'Shippers'}} based in your region, run the same lanes as you, and/or have the equipment required to haul your
		  freight. If this information is unclear, you can ask them in the “message” section.</p>
	    <p class="fs_16 fsn_4 txt_lb" *ngIf="userType=='CARRIER' && data?.laneDetail?.isNetworkCreditExists">By Accepting this Lane, you will be added to the Shippers Network and
			one invite credit will be deducted from your account.</p>
	    <p class="fs_16 fsn_4 txt_d" *ngIf="userType=='CARRIER' && !data?.laneDetail?.isNetworkCreditExists && premiumType == '1'">You are low on invite Credits, <span (click)="buyCredit()" class="txt_p underline pointer">Click here</span> to purchase additional invite credits.
			one invite Credit will be deducted from your account and you would be added to the shippers network.</p>
		<p class="fs_16 fsn_4 txt_d" *ngIf="userType=='CARRIER' && !data?.laneDetail?.isNetworkCreditExists && premiumType == '0'">You are not a premium user, please upgrade to premium. Note: One invite credit required to connect a shipper</p>
 
	   <div class="doc_container"> 
		  <div class="doc_containerl" *ngIf="data?.laneDetail?.carrier?.profileImage && userType==='SHIPPER'">
			 <img [src]="data?.laneDetail?.carrier?.profileImage" class="img-fluid">
		  </div>
		  <div class="doc_containerl" *ngIf="data?.laneDetail?.shippers?.profileImage && userType==='CARRIER'">
			<img [src]="data?.laneDetail?.shippers?.profileImage" class="img-fluid">
		 </div>
		 <div class="doc_containerl" *ngIf="data?.laneDetail?.shipper?.profileImage && userType==='CARRIER'">
			<img [src]="data?.laneDetail?.shipper?.profileImage" class="img-fluid">
		 </div>
		  <div class="doc_containerl bg_w" *ngIf="!data?.laneDetail?.carrier?.profileImage && userType==='SHIPPER'">
			 <span class="initial_name f_clash initial_r_44" *ngIf="">{{data?.laneDetail?.carrier?.legalName |
				acronymOfName}}</span>
		  </div>
		  <div class="doc_containerl bg_w" *ngIf="!data?.laneDetail?.shippers?.profileImage && userType==='CARRIER' && !data?.laneDetail?.shipper">
			<span class="initial_name f_clash initial_r_44" *ngIf="">{{data?.laneDetail?.shippers?.companyName |
			   acronymOfName}}</span>
		 </div>
		 <div class="doc_containerl" *ngIf="!data?.laneDetail?.shipper?.profileImage && userType==='CARRIER' && !data?.laneDetail?.shippers">
			<span class="initial_name f_clash initial_r_44" *ngIf="">{{data?.laneDetail?.shipper?.companyName |
				acronymOfName}}</span>
		 </div>

		  <div class="doc_containerr"> 
			 <p class="fs_18 fsb_6 txt_b mb-1 f_clash blur" *ngIf="userType==='CARRIER' && !data?.laneDetail?.shipper" style="text-transform: capitalize;">
				{{data?.laneDetail?.shippers?.companyName ? data?.laneDetail?.shippers?.companyName : '-'}}
			</p>
			 <p class="fs_18 fsb_6 txt_b mb-1 f_clash" *ngIf="userType==='SHIPPER'" style="text-transform: capitalize;">
				{{data?.laneDetail?.carrier?.legalName ? data?.laneDetail?.carrier?.legalName : '-'}}<span>
				   <img *ngIf="data?.laneDetail?.carrier?.subscription"
					  src="https://staticprod.laneaxis.com/portal/images/after-login/premium_plan_icon.png"
					  class="img-fluid verified_icon">
				</span></p>
				<p class="fs_18 fsb_6 txt_b mb-1 f_clash blur" *ngIf="userType==='CARRIER' && data?.laneDetail?.shipper" style="text-transform: capitalize;">
					{{data?.userNameOther ? data?.userNameOther : '-'}}</p>
			 <p class="fs_14 fsn_4 mb-1 txt_lb">
				<span class="create_date"><span
					  *ngIf="data?.city && data?.state">{{data?.city + ","}}
					  {{data?.state}}</span></span>
				<span class="create_date"><span
					  *ngIf="!data?.city && !data?.state">-</span></span>
			 </p>
		
			 <p class="fs_14 fsn_4 txt_lb mb-1">Equipment Type(s) :
				<span class="txt_b" *ngFor="let equipment of data?.equipmentType; let i=index">
				   {{ equipment ? equipment : '-'}}<span *ngIf="i < data?.equipmentType.length-1">, </span>
				</span>
			 </p>
			 <p class="fs_14 fsn_4 txt_lb mb-1">Regions :
				<span class="txt_b" *ngIf="data?.preferredRegions.length=== 0">I’m open for all Regions</span>
				<span class="txt_b" *ngIf="data?.preferredRegions.length > 0">
				   <span *ngFor="let regionSelect of regionSelectedData; let i = index;" class="pointer"
					  matTooltip="{{regionAddSpace(regionSelect.stateNameArray)}}">
					  {{regionSelect.region ? regionSelect.region : '-'}}<span
						 *ngIf="i < regionSelectedData.length - 1">, </span>
				   </span>
				</span>
			 </p>
		  </div>
	   </div>
	   <div class="bid_amount bg-green">
		<p class="bid_amountTxt f_clash c-white">CURRENT QUOTE/BID</p>
		<p class="bid_amountValue" *ngIf="data?.amount"><span>${{data?.amount | twoDecimalPipe}}</span></p>
	</div>
	   <div>
		  <p class="fs_14 fsn_4 txt_lb" style="margin: 20px 0 16px;">Add a note to personalize your request or to ask
			 questions.</p>
		  <div class="">
			 <div class="cus_textarea">
				<div class="mess_limit">
				   <p class="fs_14 fsn_4 txt_lb mb-0">Message (Optional)</p>
				   <p class="fs_14 fsn_4 txt_lb mb-0">200 characters</p>
				</div>
				<mat-form-field appearance="none">
				   <textarea matInput type="text" placeholder="Enter your message here…"
					  class="form-control custom_input" maxlength="200" rows="3" [(ngModel)]="searchModel.message"
					  #message="ngModel" [ngModelOptions]="{standalone: true}"></textarea>
				</mat-form-field>
			 </div>
		  </div>
	   </div>
	   <!-- carrier side  -->
	   <div>
		  <p class="custom_checkbox view_doc" *ngIf="!data?.contractDocument && userType==='CARRIER'"
			 style="margin: -4px 0 24px;">
			 <mat-checkbox [disabled]="true">
				<span class="fs_14 fsn_4 txt_lb">The Shipper has not uploaded their contract yet.</span>
			 </mat-checkbox>
		  </p>
 
		  <p class="custom_checkbox view_doc" *ngIf="data?.contractDocument && userType==='CARRIER'"
			 style="margin: -4px 0 24px;">
			 <mat-checkbox [checked]="documentCheck" [disabled]="true" (change)="checkDocument($event)">
				<span class="fs_14 fsn_4 txt_lb">Accept Shipper Contract document. Click here to</span>
			
			 </mat-checkbox>
			 <a *ngIf="data.contractDocument" href="{{data?.contractDocument}}" target="_blank"
				   class="txt_p">
				   <u class="txt_p ms-1 f_clash">view document</u>.
			</a>
		  </p>
	   </div>
 
	   <!-- sipper side  -->
	   <div>
		  <p class="custom_checkbox view_doc"
			 *ngIf="!data?.coiDoc && userType==='SHIPPER' && !data?.coiExpired"
			 style="margin: -4px 0 24px;">
			 <mat-checkbox [disabled]="true">
				<span class="fs_14 fsn_4 txt_lb" style="white-space: break-spaces;">The Carrier has not uploaded their
				   insurance document yet.</span>
			 </mat-checkbox>
		  </p>
		  <p class="custom_checkbox view_doc"
			 *ngIf="(!data?.coiDoc || data?.coiDoc) && data?.coiExpired && userType==='SHIPPER'"
			 style="margin: -4px 0 24px;">
			 <mat-checkbox [disabled]="true">
				<span class="fs_14 fsn_4 txt_lb" style="white-space: break-spaces;">The Carrier insurance document has
				   expired {{data?.insuranceCertificateExpireDate | date: 'MMM dd, yyyy' }}.</span>
			 </mat-checkbox>
		  </p>
		  <p class="custom_checkbox view_doc"
			 *ngIf="!data?.coiExpired && data?.coiDoc && userType==='SHIPPER'"
			 style="margin: -4px 0 24px;">
			 <mat-checkbox [checked]="documentCheck" [disabled]="true" (change)="checkDocument($event)">
				<span class="fs_14 fsn_4 txt_lb">Accept COI document. Click here to</span>
			 </mat-checkbox>
			 <a href="{{data?.certificateOfInsurance}}" target="_blank" class="txt_p">
				<u class="txt_p ms-1 f_clash">view document</u>.
			 </a>
		  </p>
	   </div>
 
	   <div class="text-center">
		  <button type="submit" *ngIf="data?.laneDetail?.isNetworkCreditExists" class="btn custum_button button_primary fs_16 fsm_5" (click)="sendAcceptInvite(data)">
			 <span class="f_clash">Accept and Add to Network</span>
		  </button>
		  <button type="submit" *ngIf="!data?.laneDetail?.isNetworkCreditExists && userType == 'CARRIER' && premiumType == '1'" class="btn custum_button button_primary fs_16 fsm_5" (click)="buyCredit()">
			<span class="f_clash">Buy Additional Invite Credits</span>
		 </button>
		 <button type="submit" *ngIf="!data?.laneDetail?.isNetworkCreditExists && userType == 'CARRIER' && premiumType == '0'" class="btn custum_button button_primary fs_16 fsm_5" (click)="buyCredit()">
			<span class="f_clash">Upgrade to Carrier Community Plan</span>
		 </button>
		 <button type="submit" *ngIf="!data?.laneDetail?.isNetworkCreditExists && userType == 'SHIPPER'" class="btn custum_button button_primary fs_16 fsm_5" (click)="sendAcceptInvite(data)">
			<span class="f_clash">Accept and Add to Network</span>
		 </button>
		  <p (click)="onClose()" class="f_clash pointer mt-2 txt_p underline">Go back and review other quotes</p>
	   </div>
	   
	</div>
 </div>

 <div *ngIf="data.openPop ==='archivedLane'">
	<div class="header_popup">
		<p class="fs_20 fsb_6 txt_b line_before f_clash">Archived Lane</p>
		<span class="close_popup_Icon" (click)="onClose()">
			<span class="icon-ic-cross close_Icon"></span>
		</span>
	</div>
	<div class="">
		<p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">
			<span *ngIf="data?.isArchived == true">Are you sure you want to Archive this Lane?</span>
			<span *ngIf="data?.isArchived == null || data?.isArchived == false">Are you sure you want to Un-Archive this Lane?</span>
		</p>
		<div class="text-center">
			<button type="button" class="btn custum_button button_primary w-100 fs_16 fsn_4" (click)="getArchivedLane()"
				style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">Yes</button>
			<span class="fs_16 fsn_4 txt_lb pointer f_clash px-4 py-2" (click)="onClose()">Cancel</span>
		</div>
	</div>
</div>

<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px',fullScreenBackdrop:true }"></ngx-loading>