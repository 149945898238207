import {
  Component,
  OnInit,
  Inject,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { PopupComponent } from '../../shared/popup/popup.component';
import { AppSettings } from '../../commons/setting/app_setting';
import { AlertService } from '../../commons/service/alert.service';
import { CommonService } from '../../commons/service/common.service';
import { environment } from '../../../environments/environment';

export interface DialogData {
  name: string;
  condition: string;
  imgUrl: any;
  driverId: any;
  type: any;
  docId: any;
  description: any;
  showOption: any;
}

@Component({
  selector: 'app-document-view-popup',
  templateUrl: './document-view-popup.component.html',
  styleUrls: ['./document-view-popup.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class DocumentViewPopupComponent implements OnInit {
  public imgWidth: any;
  public imgHeight: any;
  public width = '400';
  public maxWidth = '450';
  public minWidth = '350';
  public height = '400';
  public base64Image = '';
  public loading = false;
  public serverPathForUrl: any;
  @ViewChild('printSection', { static: false }) printSection: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<DocumentViewPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog,
    private commonService: CommonService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.serverPathForUrl = environment.serverPathForUrl;
    this.imgWidth = this.width;
    this.imgHeight = this.height;
    this.base64Image = this.data.imgUrl;
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  zoomIn() {
    if (this.imgWidth !== this.maxWidth) {
      this.imgWidth = parseInt(this.imgWidth) + parseInt('10');
      this.imgHeight = parseInt(this.imgHeight) + parseInt('10');
    }
  }

  zoomReset() {
    this.imgWidth = this.width;
    this.imgHeight = this.height;
  }

  zoomOut() {
    if (this.imgWidth !== this.minWidth) {
      this.imgWidth = parseInt(this.imgWidth) - parseInt('10');
      this.imgHeight = parseInt(this.imgHeight) - parseInt('10');
    }
  }

  /*confirmation popup*/
  confirmDialogDelete(confirm: any, contain: any): void {
    const dialogRef = this.dialog.open(PopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      height: AppSettings.popHeight,
      data: {
        openPop: 'confirm',
        contain: contain,
        id: this.data.docId,
        driverId: this.data.driverId,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
          this.dialogRef.close({ event: 'Done' });
        }
      }
    });
  }
  /*confirmation popup*/
  confirmPopup(confirm: any, contain: any, buttontext: any): void {
    const dialogRef = this.dialog.open(PopupComponent, {
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      height: AppSettings.popHeight,
      data: { openPop: confirm, contain: contain, buttontext: buttontext },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
          this.confirmDeleteImages();
        }
      }
    });
  }
  confirmDeleteImages() {
    let uri = '';
    let params = {
      userId: this.data.driverId,
      mediaId: this.data.docId,
    };
    //get dynamic URI for APIs
    if (params) uri = this.commonService.getAPIUriFromParams(params);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.MEDIA.DELETE,
      uri: uri,
    };
    this.commonService.delete(APIparams).subscribe(
      (success) => {
        if (success.success === true) {
          this.alertService.showNotificationMessage(
            'success',
            'bottom',
            'right',
            'txt_s',
            'check_circle',
            'Driver CDL Images',
            'You have successfully removed CDL Image.'
          );
          this.dialogRef.close({ event: 'Ok' });
        } else if (success.success === false) {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_d',
            'cancel',
            'Driver CDL Images',
            'There is some issue, Please try again'
          );
        }
      },
      (error) => {
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_g',
          'error',
          'Unexpected Error',
          AppSettings.ERROR
        );
      }
    );
  }
}
