<div class="wallet_header">
   <div class="container">
      <div class="header_title f_clash">
         Notification Control
      </div>
   </div>
</div>
<div class="container">
   <div class="notification_list cus_matTab">
      <mat-tab-group [selectedIndex]="selectedIndex" (click)="onTabChanged($event)">
         <mat-tab label="Network">
            <mat-accordion>
               <mat-expansion-panel [expanded]="true">
                  <mat-expansion-panel-header>
                     <mat-panel-title>Invitation Email</mat-panel-title>
                  </mat-expansion-panel-header>
                  <form [formGroup]="newInvitionNetwork" (ngSubmit)="newInvitionNetworkForm(newInvitionNetwork)"
                     autocomplete="off">
                     <div class="col-md-6">
                        <mat-form-field appearance="none" class="removeappearance" class="drop_bg">
                           <mat-select placeholder="Select Invitation Email" class="form-control custom_input"
                              formControlName="email">
                              <mat-option value="">Please select option </mat-option>
                              <mat-option value="every-event">Every event</mat-option>
                              <mat-option value="once-a-day">Once a day</mat-option>
                              <mat-option value="once-a-week">Once a week</mat-option>
                           </mat-select>
                           <mat-error *ngIf="newInvitionNetwork.get('email').errors?.['required']"
                              class="error_mess">Please select email option. </mat-error>
                        </mat-form-field>
                     </div>

                     <p>The invitation email will be sent to you according to the invitation email you select.</p>
                     <div class="button_box">
                        <div class="Update_button">
                           <button class="btn custum_button button_primary button_rounded" type="submit">Update</button>
                        </div>
                     </div>
                  </form>
               </mat-expansion-panel><br>
            </mat-accordion>
         </mat-tab>
      </mat-tab-group>

      <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px',fullScreenBackdrop:true }"></ngx-loading>

   </div>
</div>
