<nav class="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top custom_nav" id="topnav">
  <div class="container nav_sm_fluid" [ngClass]="userType==='CARRIER' ? 'carrierBadgeIcon' : 'shipperBadgeIcon'">
    <div class="logo-img pointer" (click)="redirectMainPage()">
      <a class="simple-text">
        <img src="{{logo}}" alt="logo" class="img-fluid" >
      </a>
    </div>
    <div class="custom_menu">    
      <ul class="nav" id="accordion" style="align-items: center;">
        <li *ngFor="let menuItem of menuItems" class="{{menuItem.class}} nav-item"
          [ngClass]="menuItem.id === currentRoute ? 'ActiveMenu' : ''" (click)="setActiveMenu(menuItem.id)">
          <div *ngIf="userType===menuItem.name" >
            <a *ngIf="menuItem.subMenu?.length===0 && menuItem.path !=='' " 
              class="nav-link" [routerLink]="[menuItem.path]">
              <span class="f_clash" >{{menuItem.title}}</span>
              <span class="badge custom_mess_badge" *ngIf="menuItem.count != 0">{{menuItem.count}}</span>
            </a>
            <a *ngIf="menuItem.subMenu?.length===0 && menuItem.path ==='' " class="nav-link pointer">
              <span class="f_clash">{{menuItem.title}}</span>
            </a>
          </div>
        </li>
        <li routerLinkActive="active" class="nav-item pointer"
          [ngClass]="'carrier' === currentRoute ? 'ActiveMenu' : ''">
          <div class="drop_menu dropdown">
            <button type="button" class="btn dropdown-toggle" data-toggle="dropdown">
              <span class="header_proImg" *ngIf="proImage">
                <img *ngIf="proImage" [src]="proImage" class="img-fluid">
              </span>
              <span class="header_proImg" *ngIf="imgURL && !proImage && userName ===null">
                <ngx-skeleton-loader count="1" [theme]="{ 'border-radius': '50%', width: '35px', height: '35px' }"
                  class="loader_mb_0"></ngx-skeleton-loader>
              </span>
              <!--  -->
              <span class="initial_name initial_c_34 f_clash" *ngIf="!proImage && userName !==null">{{userName |
                acronymOfName}}</span>
            </button>
            <div class="dropdown-menu" (click)="sideMenuClick()">

              <span class="dropdown-item" *ngIf="userType==='CARRIER'" [routerLink]="['/carrier/transaction/shipments']">
                <span class="menu_icon">
                  <img
                    src="https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-billing.svg"
                    class="img-fluid">
                </span>
                <span class="fs_14 fsm_5 f_clash">Transactions</span>
              </span>
              <span class="dropdown-item" *ngIf="userType==='STORAGE'" [routerLink]="['/storage/transactions']">
                <span class="menu_icon">
                  <img
                    src="https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-billing.svg"
                    class="img-fluid">
                </span>
                <span class="fs_14 fsm_5 f_clash">Transactions</span>
              </span>
              <span class="dropdown-item" *ngIf="userType==='SHIPPER'" [routerLink]="['/storage/shipper']">
                <span class="menu_icon">
                  <img
                    src="https://s3.us-east-1.amazonaws.com/staticprod.laneaxis.com/storage/storage-icon.svg"
                    class="img-fluid">
                </span>
                <span class="fs_14 fsm_5 f_clash">Storage</span>
              </span>
              <span class="dropdown-item" *ngIf="userType==='SHIPPER'" [routerLink]="['/storage-order/list/orders']">
                <span class="menu_icon">
                  <img
                    src="https://s3.us-east-1.amazonaws.com/staticprod.laneaxis.com/storage/storage-order-icon.svg"
                    class="img-fluid">
                </span>
                <span class="fs_14 fsm_5 f_clash">Storage Orders</span>
              </span>
              <span class="dropdown-item" *ngIf="userType==='SHIPPER'" [routerLink]="['/shipper/transaction/shipments']">
                <span class="menu_icon">
                  <img
                    src="https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-billing.svg"
                    class="img-fluid">
                </span>
                <span class="fs_14 fsm_5 f_clash">Transactions</span>
              </span>
     

              <span *ngIf="userType==='CARRIER' || userType==='STORAGE'" class="dropdown-item"
                [routerLink]="['/billing/overview']">
                <span class="menu_icon">
                  <img
                    src="https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-billing.svg"
                    class="img-fluid">
                </span>
                <span class="fs_14 fsm_5 f_clash">Billing</span>
              </span>

              <span *ngIf="userType==='SHIPPER' " class="dropdown-item" [routerLink]="['/billing/address']">
                <span class="menu_icon">
                  <img src="https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-billing.svg" class="img-fluid">
                </span>
                <span class="fs_14 fsm_5 f_clash">Billing</span>
              </span>
              <span class="dropdown-item" *ngIf="userType==='CARRIER' || userType==='SHIPPER'" (click)="redirectToSubscription()">
                <span class="menu_icon">
                  <img src="https://laneaxis.com/wp-content/uploads/2023/03/456184-200-1.png" class="img-fluid">
                </span>
                <span class="fs_14 fsm_5 f_clash">Subscription</span>
              </span>

            <span class="dropdown-item" *ngIf="userType==='SHIPPER'"
              [routerLink]="['/shipper/notification-control-panel/network']">
              <span class="icon icon-ic-notifications menu_icon fs_16 txt_lb"></span>
              <span class="fs_14 fsm_5 f_clash">Notification Control</span></span>
            <span class="dropdown-item" *ngIf="userType==='CARRIER'" 
              [routerLink]="['/carrier/notification-control-panel/network']">

              <span class="icon icon-ic-notifications menu_icon fs_16 txt_lb"></span>
              <span class="fs_14 fsm_5 f_clash">Notification Control</span>
            </span>

              <span class="dropdown-item" (click)="openDialog()">
                <span class="menu_icon">
                  <img
                    src="https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-feedback.svg"
                    class="img-fluid">
                </span>
                <span class="fs_14 fsm_5 f_clash">Feedback</span>
              </span>

              <span *ngIf="userType==='CARRIER' " class="dropdown-item d_block" [routerLink]="['/carrier/referral/refer-carrier']">
                <div>
                <span class="menu_icon">
                  <img src="https://staticprod.laneaxis.com/portal/images/after-login/referral_icon.svg" class="img-fluid" class="w_16">
                </span>
                <span class="fs_14 fsm_5 f_clash">Referral Benefits</span>
              </div>
                <span class="fs_12 free_invite">(Earn Free Credits)</span>
              </span>

              <span class="dropdown-item" (click)="settingPage('setting')">
                <span class="menu_icon">
                  <img
                    src="https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-settings.svg" class="img-fluid">
                </span>
                <span class="fs_14 fsm_5 f_clash">Settings</span>
              </span>

              <span class="dropdown-item" (click)="logoutUser()">
                <span class="menu_icon">
                  <img
                    src="https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-logout.svg"
                    class="img-fluid">
                </span>
                <span class="fs_14 fsm_5 f_clash">Sign Out</span>
              </span>
            </div>
          </div>
        </li>

        <li class="nav-item pointer" [class.add_nav_notifications]="isMenuOpen">
          <div class="notificationDrop_menu">
            <span class="notificationDrop_menu_tolg" #toggleButton1 (click)="toggleMenu()">
              <span class="icon icon-ic-notifications notifications fs_24"></span>
              <span class="badge custom_badge" *ngIf="allUnreadNotification"></span>
            </span>
            <div class="menu nav_notifications" *ngIf="isMenuOpen" #menu>
              <div class="notification_header fs_18 fsb_6 txt_b">
                <span class="f_clash">Notifications</span>
                <div class="" style="position: relative;">
                  <div data-toggle="dropdown">
                    <div class="second_lable_dropdown">
                      <span class="icon icon-ic-dots list_option"></span>
                      <div class="dropdown-menu" style="min-width: 11rem;">
                        <span class="dropdown-item pointer f_clash" (click)="markAllRed()"> Mark all as Read</span>
                        <span class="dropdown-item pointer f_clash" (click)="clickRefresh()"> Refresh </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cus_matTab" >
                <mat-tab-group animationDuration="0ms" [selectedIndex]="selectedIndex" 
                  (click)="notifictionList($event.target.outerText)">
                  <mat-tab label="Bookings"> 
                    <ng-template mat-tab-label>
                    <span [ngClass]="totalUnreadTabs['bookings'] ? 'well_inner_noti' : ''">Bookings</span>
                    </ng-template>
                  </mat-tab>
                  <mat-tab *ngIf="userType!=='STORAGE'" label="Shipments">
                    <ng-template mat-tab-label>
                      <span [ngClass]="totalUnreadTabs['shipments'] ? 'well_inner_noti' : ''">Shipments</span>
                      </ng-template>
                  </mat-tab>
                  <mat-tab label="Network">
                    <ng-template mat-tab-label>
                      <span [ngClass]="totalUnreadTabs['network'] ? 'well_inner_noti' : ''">Network</span>
                      </ng-template>
                  </mat-tab>
                  <mat-tab *ngIf="userType==='CARRIER'" label="Drivers">
                    <ng-template mat-tab-label>
                      <span [ngClass]="totalUnreadTabs['drivers'] ? 'well_inner_noti' : ''">Drivers</span>
                      </ng-template>
                  </mat-tab>
                  <mat-tab label="Others">
                    <ng-template mat-tab-label>
                      <span [ngClass]="totalUnreadTabs['others'] ? 'well_inner_noti' : ''">Others</span>
                      </ng-template>
                  </mat-tab>
                </mat-tab-group>
              </div>
              <p class="missed_noti" *ngIf="newnotificationList?.length===0 && notifcionsklitor">No Notifications Yet</p>
              <div>
                <div class="list_scroll notification_scroll" infiniteScroll [infiniteScrollDistance]="3"
                  [infiniteScrollThrottle]="100" (scrolled)="onScroll()" [scrollWindow]="false"
                  *ngIf="notifcionsklitor && newnotificationList?.length > 0">
                  <div class="dropdown-item" *ngFor="let notiList of newnotificationList;let i=index"
                    [ngClass]="notiList?.isRead===1 ? 'readed_notification' : 'unreaded_notification'"
                    id="setnotification_{{i}}">
                    <div class="notification_menu fs_12 fsm_5 txt_b pointer"
                      (click)="readFun(notiList?.id,notiList?.isRead,notiList?.data.url)">
                      <div class="notification_menu_l">
                        <span [ngClass]="notiList?.data?.notiClass !=='' ? notiList?.data?.notiClass : 'laSq_logo'"
                          class="notification_icon">
                          <img src="{{notiList?.data?.mapIconPng}}" class="img-fluid">
                        </span>
                      </div>
                      <div class="notification_menu_r">
                        <div class="" style="width: 100%;">
                          <!-- notification_menu_r1 -->
                          <div class="noti_row_title_date">
                            <div class="newtast noti_row_title f_clash" [innerHTML]="notiList?.data?.title"></div>
                            <!-- getInitials -->
                            <span *ngIf="notiList?.createdAt" class="txt_g noti_row_date">{{notiList?.createdAt |
                              dateAgo }}</span>
                          </div>
                          <div style="max-width: 22rem;">
                            <div class="newtast1 txt_lb fs_12 text_truncateQW" [innerHTML]="notiList?.data?.description">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="notification_actionbtn" *ngIf="notiList?.data?.slug=== 'invited'">
                      <span *ngIf="userType==='CARRIER'">
                        <button *ngIf="notiList?.data?.actionTaken===1" class="btn custum_button button_primary"
                          (click)="notificationAction(notiList?.id,notiList?.data.shipperId,'CONNECT')">Accept</button>
                        <button *ngIf="notiList?.data?.actionTaken===1"
                          class="btn custum_button button_primary txt_p ms-3"
                          (click)="notificationAction(notiList?.id,notiList?.data?.shipperId,'REJECT')"
                          style="background: #3787e61a">Decline</button>
                      </span>
                      <span *ngIf="userType==='SHIPPER'">
                        <button *ngIf="notiList?.data?.actionTaken===1" class="btn custum_button button_primary"
                          (click)="notificationAction(notiList?.id,notiList?.data.carrierId,'CONNECT')">Accept</button>
                        <button *ngIf="notiList?.data?.actionTaken===1"
                          class="btn custum_button button_primary txt_p ms-3"
                          (click)="notificationAction(notiList?.id,notiList?.data?.carrierId,'REJECT')"
                          style="background: #3787e61a">Decline</button>
                      </span>
                      <p *ngIf="notiList?.data?.actionTaken===0" class="fs_12 txt_s fsm_5 mt-2 mb-0"
                        style="display: flex; align-items: center;">
                        <span class="material-icons fs_16 me-1">check_circle_outline</span>
                        <span>You have responded</span>
                      </p>
                    </div>

                  </div>
                  <div class="nav_spiner" *ngIf="spinnderLoader">
                    <div class="spinner-border txt_p" role="status">
                      <span class="sr-only">
                        <!-- Loading... -->
                      </span>
                    </div>
                  </div>
                </div>
                <div *ngIf="notifcionsklitor">
                  <div *ngIf="userType==='CARRIER'" class="see_more_notification text-center bg_w txt_p fs_14 fsm_5"
                    (click)="toggleMenu()" [routerLink]="['/carrier/notification']">
                    <span class="f_clash">See All</span>
                  </div>

                  <div *ngIf="userType==='SHIPPER'" class="see_more_notification text-center bg_w txt_p fs_14 fsm_5"
                    (click)="toggleMenu()" [routerLink]="['/shipper/notification']">
                    <span class="f_clash">See All</span>
                  </div>

                  <!-- disable -link class added to disable this see all -->
                  <div *ngIf="userType==='STORAGE'" class="see_more_notification text-center bg_w txt_p fs_14 fsm_5"
                    (click)="toggleMenu()" [routerLink]="['/storage/notification']">
                    <span class="f_clash">See All</span>
                  </div>

                </div>

              </div>
              <!-- Skelaton Loader Start -->
              <div *ngIf="!notifcionsklitor">
                <div class="nav_noti_skton" *ngFor="let fake of generateFake(7)">
                  <div class="dropdown-item bg_w" style="padding: 5px 10px;">
                    <div class="notification_menu">
                      <div class="notification_menu_l" style="width: 15%; float: left;">
                        <ngx-skeleton-loader appearance="circle" [theme]="{ width: '40px', height: '40px'}">
                        </ngx-skeleton-loader>
                      </div>
                      <div class="loader_mb_0" style="width: 70%; float: left;">
                        <ngx-skeleton-loader count="1" [theme]="{ width: '70%', height: '12px'}"></ngx-skeleton-loader>
                        <br>
                        <ngx-skeleton-loader count="1" [theme]="{ width: '55%', height: '12px'}"></ngx-skeleton-loader>
                      </div>
                      <div style="width: 15%; text-align: end; padding-top: 8px;">
                        <ngx-skeleton-loader count="1" [theme]="{ width: '80%', height: '12px'}"></ngx-skeleton-loader>
                      </div>
                    </div>
                  </div>
                </div>
              </div><!-- Skelaton Loader End -->
            </div>
          </div>
          <a class="nav-link notifications_txt" [routerLink]="['/carrier/view-notifications']">
            <span class="f_clash">Notifications</span>
            <span class="badge custom_badge" *ngIf="totalNotificationCount">{{totalNotificationCount}} </span>
          </a>
        </li>
        <li class="" *ngIf="userType==='DRIVER'">
          <div class="drop_menu dropdown">
            <button type="button" class="btn dropdown-toggle" data-toggle="dropdown">
              <span class="portal_user f_clash">DRIVER</span>
            </button>
            <div class="dropdown-menu">
              <span class="dropdown-item f_clash" (click)="logoutUser()"><i class="material-icons"> power_settings_new
                </i>Logout</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>

<!-- !emailVerified || !phoneVerified -->
<div class="vrifn_banner" *ngIf="emailVerified == 'false' && userType === 'CARRIER' && userDataForBanner.profileImage && userDataForBanner.coverImage">
  <div class="container">
    <p class="vb_txt">Your email address is not verified. Please go to <a
        href="/carrier/setting/verify-email">Settings</a> and enter your email verification code to verify.  
    <p>
  </div>
</div>

<div class="vrifn_banner" *ngIf="emailVerified == 'false' && userType === 'SHIPPER' && userDataForBanner.coverImage && userDataForBanner.profileImage && userDataForBanner.contractDocument">
  <div class="container">
    <p class="vb_txt">Your email address is not verified. Please go to <a
        href="/shipper/setting/verify-email">Settings</a> and enter your email verification code to verify.
    <p>
  </div>
</div>