<!-- What is my dot number  -->
<div class="whatsDotNo_hint" *ngIf="data.openPop ==='whatsDotNo'">
   <div class="header_popup">
     <p class="fs_20 fsm_5 txt_b mb-0 line_before f_clash">What's My DOT Number?</p>
     <span class="close_popup_Icon" (click)="onNoClick()">
       <span class="icon-ic-cross close_Icon"></span>
     </span>
   </div>
   <div class="v_scrollBar pop_vh">
     <p class="fs_16 fsn_4 txt_lb mb_30">In general, companies that do the following are required to have interstate Operating Authority in addition to a DOT number:</p>
     <div class="dotPoints mb_30">
       <ul>
         <li class="f_clash">Transport passengers in interstate commerce (for a fee or other compensation, whether direct or indirect)</li>
         <li class="f_clash">Transport federally-regulated commodities owned by others or arranging for their transport, (for a fee or other compensation, in interstate commerce)</li>
       </ul>
     </div>
     <p class="fs_14 fsn_4 txt_lb mb_30">FMCSA operating authority is often identified as an "MC," "FF," or "MX" number, depending on the type of authority that is granted. Unlike the USDOT Number application process, a company may need to obtain multiple operating authorities to support its planned business operations. Operating Authority dictates the type of operation a company may run and the cargo it may carry.</p>
   </div>
 </div>
 
 <!-- Hits for wrong Dot number(Onboarding Issues) -->
 <div *ngIf="data.openPop ==='showHintPopup'">
   <div class="header_popup">
     <p class="fs_20 fsm_5 txt_b mb-0 line_before f_clash">Onboarding Issues?</p>
     <span class="close_popup_Icon" (click)="onNoClick()">
       <span class="icon-ic-cross close_Icon"></span>
     </span>
   </div>
   <div class="v_scrollBar pop_vh">
     <p class="fs_16 mb-3">Having trouble getting started? We're here to help!</p>
     <p class="fs_16 mb-2">Here are some common issues we can help you with quickly:</p>
     <div class="verify_dot_hint_point">
       <div class="row">
         <div class="col-md-11 mx-auto">
           <div class="row">
             <div class="col-md-5 pe-0">
               <p class=""><i class="material-icons txt_p fs_14">fiber_manual_record</i> Signin/Signup</p>
               <p class=""><i class="material-icons txt_p fs_14">fiber_manual_record</i> Carrier Registration</p>
               <p class="mb-3"><i class="material-icons txt_p fs_14">fiber_manual_record</i> Forgot Password</p>
             </div>
             <div class="col-md-7 pe-0">
               <p class=""><i class="material-icons txt_p fs_14">fiber_manual_record</i> DOT Number Verification</p>
               <p class=""><i class="material-icons txt_p fs_14">fiber_manual_record</i> Shipper Registration</p>
             </div>
           </div>
         </div>
       </div>
     </div>
     <p class="fs_12">If you're having problems with any of the above - or any other onboarding issues - click <a href="https://laneaxis.com/get-in-touch/" target="_blank" class="txt_p fs_14 me-1 f_clash"><u>CONTACT US </u></a> and a member of our team will respond within 24 hours.</p>
   </div>
 </div>

<!-- Video tutorial popup -->
 <div *ngIf="data.openPop ==='videoTutorail'">
  <div class="header_popup">
    <p class="fs_20 fsm_5 txt_b mb-0 line_before f_clash">Sign In Tutorial</p>
    <span class="close_popup_Icon" (click)="onNoClick()">
      <span class="icon-ic-cross close_Icon"></span>
    </span>
  </div>
  <div [ngClass]="!loader  ? 'showiframe' : 'hideiframe'">
    <iframe [src]="subscripeUrl" #iframe width="100%" height="640" allowfullscreen frameborder="0"></iframe>
  </div>
   <div class="v_scrollBar pop_vh" *ngIf="loader">
    <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '50px'}"></ngx-skeleton-loader>
    <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '50px'}"></ngx-skeleton-loader>
    <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '50px'}"></ngx-skeleton-loader>
    <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '50px'}"></ngx-skeleton-loader>
    <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '50px'}"></ngx-skeleton-loader>
   </div>
</div>
 