<div class="wrapper">
  <div class="sidebar lg_sidebar" data-color="white" data-background-color="white" id="sidebar">
    <div class="sidebar-background"></div>
  </div>
  <div class="main-panel">
    <nav class="navbar lg_sidebar_hide" *ngIf="userAccessTokan && checkNavbar==='after_login'" >
      <div class="container">
        <div class="mobile_view">
          <span class="head_logo">
            <img class="img-fluid" src="https://staticprod.laneaxis.com/portal/images/before-login/LA-logo-patant-min.png?v=1">
          </span>
          <div class="sign_outBtn" (click)="callToLogout()">
            <span class="menu_icon">
              <img src="https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-logout.svg" class="img-fluid">
            </span>
            <span class="fs_16 fsm_5 txt_b">Sign Out</span>
          </div>
        </div>
      </div> 
      </nav>
    <app-top-navbar *ngIf="userAccessTokan && checkNavbar==='after_login'" class="sm_navbar_hide"></app-top-navbar>
    <router-outlet></router-outlet>
  </div>
</div>


 