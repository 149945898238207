import { Renderer2, RendererFactory2, Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ScriptStore } from '../interface/script.store/script.store';
declare var gtag: Function;
declare global {
  interface Window {
    Appcues: any;
    dataLayer: any;
  }
}
@Injectable({
  providedIn: 'root',
})
export class ScriptService {
  private scripts: any = [];
  private renderer: Renderer2;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
    ScriptStore.forEach((script: any) => {
      let scriptsInstance: any = {};
      scriptsInstance[script.name] = {
        loaded: false,
        src: script.src,
      };
      this.scripts.push(scriptsInstance);
    });
  }

  load() {
    var promises: any[] = [];
    this.scripts.forEach((script: any) =>
      promises.push(this.loadScript(script))
    );
    return Promise.all(promises);
  }

  public loadScript(script: any) {
    return new Promise((resolve, reject) => {
      let scriptName = Object.keys(script)[0];
      var scriptElement;
      
      if (scriptName == 'mapbox-gl' || scriptName == 'mapbox-gl-geocoder')
        scriptElement = this.appendCSSTag(
          this.renderer,
          script[scriptName].src
        );
      else
        scriptElement = this.appendScriptTag(
          this.renderer,
          script[scriptName].src
        );
      scriptElement.onload = () => {
        resolve({ script: scriptName, loaded: true, status: 'Loaded' });
      };
    }).then((result: any) => {
      // console.log('result', result);
      this.checkResolverFunction(result);
    });
  }

  checkResolverFunction(response) {
    if (response.script === 'GoogleAnalytics') this.GoogleAnalyticsResolver();
   
    if (response.script == 'heremap_1') this.hereMapsResolver();
    else return { script: response.script, loaded: true, status: 'Loaded' };
  }

  hereMapsResolver() {
    new Promise((resolve, reject) => {
      const scriptElement = this.appendScriptTag(
        this.renderer,
        'https://js.api.here.com/v3/3.1/mapsjs-service.js'
      );
      scriptElement.onload = (result) => {
        resolve({ script: 'hereMaps2', loaded: true, status: 'Loaded' });
      };
    })
      .then((script) =>
        this.appendScriptTag(
          this.renderer,
          'https://js.api.here.com/v3/3.1/mapsjs-ui.js'
        )
      )
      .then((script) =>
        this.appendScriptTag(
          this.renderer,
          'https://js.api.here.com/v3/3.1/mapsjs-mapevents.js'
        )
      );
  }

  

  GoogleAnalyticsResolver() {
    if(window.dataLayer){
      window.dataLayer = window.dataLayer || [];
    }
    
   
  }

  public appendScriptTag(renderer: Renderer2, src: string) {
    const script = renderer.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.defer=true;
    script.src = src;
    renderer.appendChild(this.document.body, script);
    return script;
  }

  public appendImageTag(renderer: Renderer2, src: string) {
    const imgData = renderer.createElement('img');
    imgData.style.display = 'none';
    imgData.style.height = '1';
    imgData.style.width = '1';
    imgData.src = src;
    renderer.appendChild(this.document.body, imgData);
    return imgData;
  }

  public appendCSSTag(renderer: Renderer2, src: string) {
    const style = renderer.createElement('link');
    style.id = 'css-styling';
    style.rel = 'stylesheet';
    style.href = src;
    renderer.appendChild(this.document.head, style);
    return style;
  }
}
