<div class="" *ngIf="data.condition === 'cdlViewImage'" >
	<div class="view_img_info">
		<div class="image_popup">
			<div class="left_icon">
				<span class="material-icons pointer me-3 fs_20 txt_w" (click)="onNoClick()">west</span>
				<img class="img-fluid mx-2" src="https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/gr-jpg.svg" style="width: 18px;">
				<span *ngIf="data.type==='cdlFrontImage'">CDL Front Image</span>
				<span *ngIf="data.type==='cdlBackImage'">CDL Back Image</span>
				<span *ngIf="data.type==='documentImage'">My Document</span>
			</div>
			<div class="right_icon">
				<span *ngIf="(data.type==='cdlBackImage' || data.type === 'cdlFrontImage') && (data.showOption !== '1')" class="material-icons fs_20 txt_w pointer" (click)="confirmPopup('cdlImagesConfirm','Are you sure you want to delete the document?','Yes, Delete')">delete_outline</span>
				<span *ngIf="data.type==='documentImage' && data.showOption !== '1'" class="material-icons fs_20 txt_w pointer" (click)="confirmDialogDelete('confirm','Are you sure you want to permanently delete this document?')">delete_outline</span>
			</div>
		</div>
		<div id="print-section" #printSection>
			<div class="center-img_bg">
				<div class="center-img">
				 	<div class="text-center" *ngIf="!base64Image">
	          <div class="spinner-border text-secondary" role="status">
	            <span class="sr-only"><!-- Loading... --></span>
	          </div>
	        </div>
					<img *ngIf="base64Image" [src]="base64Image"  [style.width.px]="imgWidth" [style.height.px]="imgHeight">
				</div>
			</div>
			<div class="min_max_btn">
				<span class="img_ac_con">
					<span class="material-icons img_action_ic" (click)="zoomOut()">remove</span>
					<span class="material-icons img_action_ic mx-4" (click)="zoomReset()">zoom_out</span>
					<span class="material-icons img_action_ic" (click)="zoomIn()">add</span>
				</span>
				<p class="mt-4 mb-0 text-white" *ngIf="data.description">{{data.description}}</p>
			</div>
	  </div>
	</div>
</div>

<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px',fullScreenBackdrop:true }"></ngx-loading>