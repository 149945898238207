import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { UserOnboardingComponent } from '../user-onboarding/user-onboarding.component';
import { AppSettings } from 'src/app/commons/setting/app_setting';
import { ShipmentPopupComponent } from '../../shipment-shared/shipment-popup/shipment-popup.component';
import { CommonService } from 'src/app/commons/service/common.service';
import { AlertService } from 'src/app/commons/service/alert.service';
import { SharedService } from '../../commons/service/shared.service';

@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.css'],
})

export class BannersComponent implements OnInit {
  @Output() tapPopupShow = new EventEmitter();
  @Input() bannerType;
  @Input() carrierInfo;
  @Input() bannerTypeSub;
  @Input() userDetail;
  @Input() bannerHomeSub;
  @Input() subcriptionType;

  public userType: any;
  public getwindow: any;
  public checkValue: any;
  public checkValueCertificate: any;
  public checkValueSubscription: any;
  public subscriptionCheck: any;
  public serverPathForUrl: any;
  public connectStripe: any;
  public loading: any;
  public showSubscriptionBanner = '0';
  public showBanner = true; 
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private commonService: CommonService,
    private alertService: AlertService,
    public sharedService: SharedService,

  ) { }

  ngOnInit() {
    this.userType = localStorage.getItem('user_type');
    this.serverPathForUrl = environment.serverPathForUrl;
    this.subscriptionCheck = localStorage.getItem('isSubscriptionEnable');
    this.getWithExpiryCertifcate();
    this.getWithExpiryLoad();
    this.getWithExpirySubscription();
    if (localStorage.getItem('subBannerAction') == '1') {
      this.showSubscriptionBanner = localStorage.getItem('subBannerAction');
    }
    this.connectStripe = localStorage.getItem('stripeConenct');
    let isCookieExists = this.getCookie('isGuideUser');
    if(isCookieExists === "false"){
      this.showBanner = false; 
    }
  }

  startTapTop() {
    this.tapPopupShow.emit();

     var obj = {
       eventName: 'steps_to_get_started_begin',
       event_category: 'steps_to_get_statrted',
       userType: localStorage.getItem('user_type'),
       EmailID: localStorage.getItem('email'),
     };
     this.sharedService.track(obj);
  }

  getCookie(name) {
    let cookie = {};
    document.cookie.split(';').forEach(function(el) {
      let [k,v] = el.split('=');
      cookie[k.trim()] = v;
    })
    return cookie[name];
  }

  dismissBanner(){
    document.cookie = 'isGuideUser=false';
    this.showBanner = false; 
    var obj = {
      eventName: 'skip_get_started',
      event_category: 'steps_to_get_statrted',
      userType: localStorage.getItem('user_type'),
      EmailID: localStorage.getItem('email'),
    };
    this.sharedService.track(obj);
  }

  subBannerHide() {
    this.subscriptionCheck = localStorage.setItem('subBannerAction', '1');
    this.showSubscriptionBanner = '1';
  }

  setWithExpiry(event: any) {
    const now = new Date();
    const item = {
      value: 1,
      expiry: now.getTime() + 86400000,
    };
    localStorage.setItem(event, JSON.stringify(item));
    this.checkValue = localStorage.getItem('load_expired');
    this.checkValueCertificate = localStorage.getItem(
      'certificate_insurance_expired'
    );
    this.checkValueSubscription = localStorage.getItem('subscription_expired');
  }

  getWithExpiryCertifcate() {
    this.checkValueCertificate = localStorage.getItem(
      'certificate_insurance_expired'
    );
    if (!this.checkValueCertificate) {
      return null;
    }
    const item = JSON.parse(this.checkValueCertificate);
    const now = new Date();
    if (now.getTime() > item.expiry) {

      this.checkValueCertificate = localStorage.removeItem(
        'certificate_insurance_expired'
      );
    }
  }

  getWithExpiryLoad() {
    this.checkValue = localStorage.getItem('load_expired');
    if (!this.checkValue) {
      return null;
    }
    const item = JSON.parse(this.checkValue);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      this.checkValue = localStorage.removeItem('load_expired');
    }
  }
  getWithExpirySubscription() {
    this.checkValueSubscription = localStorage.getItem('subscription_expired');
    if (!this.checkValueSubscription) {
      return null;
    }
    const item = JSON.parse(this.checkValueSubscription);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      this.checkValueSubscription = localStorage.removeItem(
        'subscription_expired'
      );
    }
  }

  openView() {
    this.router.navigate(['/carrier/profile/'], {
      queryParams: {
        certificate: 'true',
      },
    });
  }
  connectAccount() {
    this.router.navigate(['payment/overview']);
  }

  COIStripeSteps() {
    this.connectStripe = localStorage.getItem('stripeConenct');
   
    if ((!this.userDetail?.coiDoc || this.userDetail?.coiExpired) && this.userType == 'CARRIER') {

      const dialogRef = this.dialog.open(UserOnboardingComponent, {
        disableClose: true,
        backdropClass: AppSettings.backdropClass,
        panelClass: 'userOnboarding_con',
        width: '690px',
      });
      dialogRef.afterClosed().subscribe((result) => { });
    }
    else if (this.connectStripe !== 'active') {

      this.stripeConenctFun()
    }
  }

  stripeConenctFun() {
    const dialogRef = this.dialog.open(ShipmentPopupComponent, {
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      data: { openPop: 'stripeConenctCheck' },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'createNow') {
          this.stripeConnect();
        }
      }
    });
  }

  stripeConnect() {
    this.loading = true;
    let postBody = null;
    if (this.userType != 'STORAGE') {
      postBody = {
        email: this.userDetail.emailAddress,
        url: '',
        country: 'US',
        phone_number: this.userDetail.telephone,
        business_name: this.userDetail.legalName,
        business_type: 'company',
        first_name: this.userDetail.firstName,
        last_name: this.userDetail.lastName,
        dob_day: '',
        dob_month: '',
        dob_year: '',
      };
    }
    else {
      postBody = {
        email: this.userDetail.email,
        url: '',
        country: 'US',
        phone_number: this.userDetail.phone,
        business_name: this.userDetail.companyName,
        business_type: 'company',
        first_name: this.userDetail.firstName,
        last_name: this.userDetail.lastName,
        dob_day: '',
        dob_month: '',
        dob_year: '',
      };

    }
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.NEWPAYMENT.GETCONNECTLINK,
      uri: '',
      postBody: postBody,
    };
    this.commonService.post(APIparams).subscribe(
      (success) => {
        if (success.success === true) {
          this.getwindow = window.open(
            success.response,
            'myWindow',
            'width=600,height=600'
          );
          this.loading = false;
        } else if (success.success === false) {
          this.loading = false;
        }
      },
      (error) => {
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_g',
          'error',
          'Unexpected Error',
          AppSettings.ERROR
        );
        this.loading = false;
      }
    );
  }
}
