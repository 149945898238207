import {
  Component,
  Input,
  ViewEncapsulation,
  OnChanges,
} from '@angular/core';
import { SharedService } from '../../commons/service/shared.service';
@Component({
  selector: 'app-order-progress',
  templateUrl: './order-progress.component.html',
  styleUrls: ['./order-progress.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class OrderProgressComponent implements  OnChanges {
  @Input() statusType: number;
  @Input() pageType: string;
  @Input() statusSlug: any;
  @Input() updatedAt: any;
  @Input() deliverydate: any;
  @Input() statusBars: any;
  @Input() statusLabel: any;
  public showOrderStatus: any;
  @Input() showSection: any;
  counter(i: number) {
    return new Array(i);
  }

  constructor(
    public sharedService: SharedService
  ) {}

  ngOnChanges() {}

  toltipPick() {
    let levelValue = 'order';
    return levelValue;
  }
  toltipDrop() {
    let levelValue = 'bidding';
    return levelValue;
  }
  toltipDeliverd() {
    let levelValue = 'confirmed';
    return levelValue;
  }
}
