<div class="notificn_detail_pg">
  <div class="page_con_header">
    <div class="container">
      <div class="container_header">
        <div class="ch_l">
          <span class="material-icons page_back" (click)="backHistory()">arrow_back</span>
          <span class="ch_ID f_clash">Notifications</span>
        </div>
        <div class="ch_c"></div>
        <div class="ch_r">
          <div class="" style="position: relative;">
            <div data-toggle="dropdown">
              <div class="second_lable_dropdown">
                <span class="icon icon-ic-dots list_option"></span>
                <div class="dropdown-menu">
                  <span class="dropdown-item pointer fsm_5 f_clash" (click)="markAllRed()"> Mark all as read</span>
                  <span class="dropdown-item pointer fsm_5 f_clash" (click)="clickRefresh()"> Refresh </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="notification_list cus_matTab ">
      <mat-tab-group (selectedTabChange)="getData($event.tab.textLabel)">
      <mat-tab label="Bookings"> 
        <ng-template mat-tab-label>
        <span [ngClass]="totalUnreadTabs?.bookings ? 'well_inner_noti' : ''">Bookings</span>
        </ng-template>
      </mat-tab>
      <mat-tab *ngIf="userType!=='STORAGE'" label="Shipments">
        <ng-template mat-tab-label>
          <span [ngClass]="totalUnreadTabs?.shipments ? 'well_inner_noti' : ''">Shipments</span>
          </ng-template>
      </mat-tab>
      <mat-tab label="Network">
        <ng-template mat-tab-label>
          <span [ngClass]="totalUnreadTabs?.network ? 'well_inner_noti' : ''">Network</span>
          </ng-template>
      </mat-tab>
      <mat-tab *ngIf="userType==='CARRIER'" label="Drivers">
        <ng-template mat-tab-label>
          <span [ngClass]="totalUnreadTabs?.drivers ? 'well_inner_noti' : ''">Drivers</span>
          </ng-template>
      </mat-tab>
      <mat-tab label="Others">
        <ng-template mat-tab-label>
          <span [ngClass]="totalUnreadTabs?.others ? 'well_inner_noti' : ''">Others</span>
          </ng-template>
      </mat-tab>
    </mat-tab-group>
      <div class="vertical_tab">
        <div class="tabcontent" id="tabcontent">
          <div class="v_scrollBar bg_w" infiniteScroll [infiniteScrollDistance]="3" [infiniteScrollThrottle]="100"
            (scrolled)="onScroll()" [scrollWindow]="false" style="height: calc(100vh - 180px);">
            <div *ngIf="notifcionsklitor" style="border-radius: 4px;">
              <div class="card-body" *ngFor="let notiList of newnotificationList; let i=index"
                [ngClass]="notiList?.isRead === 1 ? 'noti_tabs' : 'unreaded_notification'" id="focus_{{i}}">
                <div class="view_all_noti fs_14 fsm_5 txt_b pointer" [routerLink]="[notiList?.data?.url]"
                  (click)="readFun(notiList?.id, notiList?.data?.url)">
                  <div class="view_all_noti_l">
                    <div class="view_all_noti_l1">
                      <span [ngClass]="notiList?.data?.notiClass !=='' ? notiList?.data?.notiClass : 'laSq_logo'"
                        class="notification_icon">
                        <img src="{{notiList?.data?.mapIconPng}}" class="img-fluid">
                      </span>
                    </div>
                    <div class="view_all_noti_l2">
                      <div class="view_all_noti_l21">
                        <div class="m-0 fsb_6">
                          <div class="newtast fs_14 fsb_6 txt_b f_clash" [innerHTML]="notiList?.data?.title"></div>
                          <div *ngIf="notiList?.data?.description" class="newtast1 fs_14 fsn_4 txt_lb"
                            [innerHTML]="notiList?.data?.description"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="view_all_noti_r" style="height: 45px;">
                    <span class="txt_g fs_14 fsn_4" style="color: var(--clBlkd);"><i>{{notiList?.createdAt |
                        dateAgo}}</i></span>
                  </div>
                </div>

                <div class="notification_action btn" *ngIf="notiList?.data?.slug === 'invited'">
                  <span *ngxPermissionsOnly="['CARRIER']">
                    <button *ngIf="notiList?.data?.actionTaken === 1" class="btn custum_button button_primary"
                      (click)="notificationAction(notiList?.id,notiList?.data?.shipperId,'CONNECT')">Accept</button>
                    <button *ngIf="notiList?.data?.actionTaken === 1"
                      class="btn custum_button button_primary txt_p ms-3"
                      (click)="notificationAction(notiList?.id,notiList?.data?.shipperId,'REJECT')"
                      style="background: #3787e61a">Decline</button>
                  </span>
                  <span *ngxPermissionsOnly="['SHIPPER']">
                    <button *ngIf="notiList?.data?.actionTaken === 1" class="btn custum_button button_primary"
                      (click)="notificationAction(notiList?.id,notiList?.data?.carrierId,'CONNECT')">Accept</button>
                    <button *ngIf="notiList?.data?.actionTaken === 1"
                      class="btn custum_button button_primary txt_p ms-3"
                      (click)="notificationAction(notiList?.id,notiList?.data?.carrierId,'REJECT')"
                      style="background: #3787e61a">Decline</button>
                  </span>
                  <p *ngIf="notiList?.data?.actionTaken === 0" class="fs_12 txt_s fsm_5 mt-2 mb-0"
                    style="display: flex; align-items: center;">
                    <span class="material-icons fs_16 me-1">check_circle_outline</span>
                    <span>You have responded</span>
                  </p>
                </div>
              </div>

              <div class="mt-2 data_not_found bg_w" *ngIf="totalCNotification === 0">
                <div class="row">
                  <div class="col-lg-10 col-sm-11 mx-auto txt_b">
                    <p class="fs_18 fsb_6 text-center mb-2 f_clash">No Notifications Yet</p>
                  </div>
                </div>
              </div>
            </div>

            <!-- Skelaton Loader Start -->
            <div *ngIf="!notifcionsklitor">
              <div *ngFor="let fake of generateFake(7)">
                <div class="ntf_skt_con">
                  <div class="ntf_skt_l">
                    <ngx-skeleton-loader count="1" [theme]="{ width: '40px', height: '40px'}" appearance="circle">
                    </ngx-skeleton-loader>
                  </div>
                  <div class="ntf_skt_r">
                    <ngx-skeleton-loader count="1" [theme]="{ width: '75%', height: '14px'}"></ngx-skeleton-loader>
                    <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '12px'}"></ngx-skeleton-loader>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="spinnderLoader">
              <span class="loader_image">
                <img
                  src="https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/loader-blue.svg"
                  class="img-fluid" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px',fullScreenBackdrop:true }"></ngx-loading>