export const environment = {
  production: true,
  pointType:"prod",
  laneAxisDotCom: 'https://laneaxis.com',
  apiEndpoint: 'https://api.laneaxis.com',
  domainPoint: 'https://network.laneaxis.com',
  searchRestriction: ['us'],
  searchMultipleRestriction: ['us', 'ca', 'mx'],
  stripePublicKey: "pk_live_zjL5w9gOnJcnCs5ptE8g3AOr00OuijpVh5",
  heremapapiKey: 'iQVSw9yPlsVLkIIsqZu5VpZYDRF7V0PHyofw0fUUMo0',
  googleAnalyticsKey: 'G-9ZCJSWCLGQ',
  paymentApiEndpoint: 'https://payapi.laneaxis.com',
  axisTokanApiEndpoint: 'https://axispayapi.laneaxis.com',
  magicLinkPublicKey: 'pk_live_4EDEBD1B84FD67D6',
  inviteLink: 'https://onelink.to/laneaxis-inc/',
  // imagePathUrl: 'https://s3.us-east-2.amazonaws.com/static.laneaxis.com',
  imagePathUrl: 'https://staticprod.laneaxis.com',
  serverPathForUrl: 'https://staticprod.laneaxis.com',
  mapbox: {
    accessToken: 'pk.eyJ1IjoibGFuZWF4aXMiLCJhIjoiY2tidnZma2VpMGEydzJ0bnFvdjdvejFmMiJ9.A8iCJZIH4tL1IlmM3mTQHA'
  },
  firebase: {
    apiKey: "AIzaSyDlvyLEAmZhA3ydhvZ8wizSSIEjWPFS79k",
    authDomain: "laneaxismobileapp.web.app.com",
    databaseURL: "https://laneaxis-network.firebaseio.com",
    projectId: "laneaxismobileapp",
    storageBucket: "laneaxismobileapp.appspot.com",
    messagingSenderId: "606002027658",
    appId: "1:606002027658:web:9f5516e869428b45cf6173",
  }
};
