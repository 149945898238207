<div class="payment_add_card mb-3" id="card-info">
  <div class="row" *ngIf="skeletonLoaderCard">
    <div class="col-md-5 cus_md5" *ngFor="let cardRecord of cardListing" (click)="selectCardAch(cardRecord?.id)">
      <div class="payment_cardAdded pointer" [ngClass]="currentCardActive == cardRecord?.id ? 'active_card_child' : ''">
        <div class="selt_card_con">
          <div class="pay_card_t">
            <div class="pay_card_tl">
              <div class="bank_name">
                <img src="https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-bank.svg"
                  class="img-fluid">
                <span class="fs_14 fsm_5 txt_p f_clash">{{cardRecord?.us_bank_account?.bank_name}}</span>
              </div>
            </div>
            <div class="pay_card_tr">
              <div *ngIf="cardRecord?.default">
                <span class="material-icons fs_12 txt_y">star</span>
                <span class="fs_12 fsm_5 txt_lb">Default</span>
                <div *ngIf="checkSelectedCard(cardRecord?.id)">
                </div>
              </div>
              <div class="pointer" *ngIf="!cardRecord?.default" (click)="setDefualtCard(cardRecord?.id)">
                <span class="fs_12 fsm_5 txt_p">Set Default</span>
              </div>
            </div>
          </div>
          <div class="pay_card_b">
            <div class="pay_card_bl">
              <span class="fs_14 fsn_4 txt_lb">Routing #</span>
              <p class="f_clash">{{cardRecord?.us_bank_account?.routing_number}}</p>
            </div>
            <div class="pay_card_br">
              <span class="fs_14 fsn_4 txt_lb">Account #</span>
              <p class="f_clash">xxxxxxxxxx<span>{{cardRecord?.us_bank_account?.last4}}</span></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-5 cus_md5">
      <div class="add_bill_info pointer mb-3" (click)="addPaymentCard()">
        <span class="material-icons fs_30 txt_p mb-1">control_point</span>
        <p class=" mb-0 fs_12 fsb_7 txt_p">Add Bank Details</p>
      </div>
    </div>
  </div>
  <div *ngIf="skeletonLoaderCard" class="text-center">
    <u *ngIf="has_more && cardListing.length" class="fs_16 fsm_5 txt_p pointer f_clash" (click)="MoreCards()">View More
    </u>
    <p *ngIf="!has_more && cardListing.length" class="fs_16 fsm_5 txt_lb f_clash">No More Cards</p>
  </div>
  <div class="row" *ngIf="!skeletonLoaderCard">
    <div class=" col-md-5 cus_md5" *ngFor="let fake of generateFake(3)">
      <div class="paycark_skelaton">
        <div class="loader_mb_5">
          <ngx-skeleton-loader count="1" [theme]="{ width: '30%', height: '40px'}"></ngx-skeleton-loader>
        </div>
        <div class="loader_mb_0">
          <ngx-skeleton-loader count="1" [theme]="{ width: '90%', height: '12px'}"></ngx-skeleton-loader>
        </div>
        <div class="loader_mb_0">
          <ngx-skeleton-loader count="1" [theme]="{ width: '90%', height: '12px'}"></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px',fullScreenBackdrop:true }"></ngx-loading>