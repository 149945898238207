import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { CommonService } from '../../commons/service/common.service';
import { AlertService } from '../../commons/service/alert.service';
import { SharedService } from '../../commons/service/shared.service';
import { environment } from '../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-payment-usdc-card',
  templateUrl: './payment-usdc-card.component.html',
  styleUrls: ['./payment-usdc-card.component.css'],
})
export class PaymentUsdcCardComponent implements OnInit {
  @Input() currentCardActive: any;
  @Input() usdcCardLoader: any;
  @Input() balance: any;
  @Output() paymentBuyUsdcPopup = new EventEmitter();
  public cardListing = [];
  public loading = false;
  public serverPathForUrl: any;

  constructor(
    public sharedService: SharedService,
    public dialog: MatDialog,
    public commonService: CommonService,
    public alertService: AlertService
  ) {}

  ngOnInit() {
    this.serverPathForUrl = environment.serverPathForUrl;
  }

  paymentBuyUsdcAdd() {
    this.paymentBuyUsdcPopup.emit();
  }

}
