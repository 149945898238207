<div *ngIf="manageSection==='contactInfo'">
  <div class="header_popup mb-2" *ngIf="!contactInfoLoader">
    <p class="fs_20 fsb_6 txt_b line_before f_clash">Add Contact Info</p>
    <span class="close_popup_Icon" (click)="closePopupFail()">
      <span class="icon-ic-cross close_Icon"></span>
    </span>
  </div>
  <p class="fs_14 fsn_4 txt_lb mb-4" *ngIf="!contactInfoLoader">We need this information to build your profile and to
    show network suggestions on the basis of your location.</p>
  <form autocomplete="off" *ngIf="!contactInfoLoader" [formGroup]="carrierContactInfo"
    (ngSubmit)="carrerInfoSave(carrierContactInfo)" class="mb-0 custum_input_css inputInPopup" autocomplete="off">
    <div class="v_scrollBar vertical_scl">
      <p class="fs_16 fsm_5 txt_b mb-0 f_clash">Add Physical Address</p>
      <div class="row">
       
        <div class="col-md-6">
          <p class="fs_14 fsn_4 txt_lb mb-1">Address Line 1<span class="txt_d"> *</span></p>
          <div fxFlexAlign="center" class="autocomplete-container">
            <mat-form-field appearance="none">
              <input matInput matGoogleMapsAutocomplete (onGermanAddressMapped)="phyAddress($event)"
                (input)="noAutoselection('pick')" (focusout)="noFocusOut('pick')" placeholder="Type your Location"
                class="form-control custom_input controls" formControlName="addressLine1" [country]="searchRestriction"
                required>
              <mat-error class="error_mess m-0"
                *ngIf="submitted && carrierContactInfo.get('addressLine1').errors?.['required'] && !addAutoNot">Address
                Line 1 is required.</mat-error>
              <span *ngIf="addAutoNot" class="error_mess">Address Line 1 is required.</span>
              <mat-error class="error_mess m-0"
                *ngIf="carrierContactInfo.get('addressLine1').errors?.['pattern'] && !addAutoNot" class="error_mess">
                Please enter valid information.</mat-error>
              <mat-error class="error_mess m-0"
                *ngIf="carrierContactInfo.get('addressLine1').errors?.['maxlength'] && !addAutoNot">Only 64 characters
                are allowed.</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="col-md-6">
          <p class="fs_14 fsn_4 txt_lb mb-1">Address Line 2</p>
          <mat-form-field appearance="none">
            <input matInput placeholder="Enter Address Line 2" class="form-control custom_input"
              formControlName="addressLine2" maxlength="65">
            <mat-error class="error_mess m-0" *ngIf="carrierContactInfo.get('addressLine2').errors?.['pattern']"
              class="error_mess">Please enter valid information.</mat-error>
            <mat-error class="error_mess m-0" *ngIf="carrierContactInfo.get('addressLine2').errors?.['maxlength']">Only
              64 characters are allowed.</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <p class="fs_14 fsn_4 txt_lb mb-1">ZIP Code <span class="txt_d"> *</span></p>
          <mat-form-field appearance="none">
            <input matInput type="text" placeholder="Enter ZIP Code" class="form-control custom_input"
              formControlName="zipcode" maxlength="6" minlength="4" (keypress)="keyPress($event)" />
            <mat-error class="error_mess" *ngIf="submitted && carrierContactInfo.get('zipcode').errors?.['required']">
              ZIP Code is required.</mat-error>
            <mat-error class="error_mess" *ngIf="carrierContactInfo.get('zipcode').errors?.['pattern']">ZIP Code should
              be number.</mat-error>
            <mat-error class="error_mess"
              *ngIf="(carrierContactInfo.get('zipcode').errors?.['minlength']) && !(carrierContactInfo.get('zipcode').errors?.['pattern'])">
              ZIP Code should be minimum 4 digit.</mat-error>
          
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <p class="fs_14 fsn_4 txt_lb mb-1">City<span class="txt_d"> *</span></p>
          <mat-form-field appearance="none">
            <input matInput placeholder="Enter City" class="form-control custom_input" formControlName="city"
              maxlength="33">
            <mat-error class="error_mess" *ngIf="submitted && carrierContactInfo.get('city').errors?.['required']">City
              is required.</mat-error>
            <mat-error class="error_mess m-0" *ngIf="carrierContactInfo.get('city').errors?.['pattern']"
              class="error_mess">Please enter valid information.</mat-error>
            <mat-error class="error_mess" *ngIf="carrierContactInfo.get('city').errors?.['maxlength']">Only 32
              characters are allowed.</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6 ic_dropdown">
          <p class="fs_14 fsn_4 txt_lb mb-1">State/Province<span class="txt_d"> *</span></p>
          <mat-form-field appearance="none" class="">
            <mat-select class="form-control custom_input" placeholder="Enter State/Province" formControlName="phyState">
              <mat-option *ngFor="let state of phStates" [value]="state.sortname" class="mat_dropdown">{{state?.name}}
              </mat-option>
            </mat-select>
            <mat-error class="error_mess" *ngIf="submitted && carrierContactInfo.get('phyState').errors?.['required']">
              State is required.</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6 ic_dropdown">
          <p class="fs_14 fsn_4 txt_lb mb-1">Country/Region<span class="txt_d"> *</span></p>
          <mat-form-field appearance="none">
            <mat-select placeholder="Enter Country/Region" class="form-control custom_input" formControlName="country">
              <mat-option class="mat_dropdown" value="US">US</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-md-6" *ngIf="userType==='SHIPPER'">
          <p class="fs_14 fsn_4 txt_lb mb-1">Job Title <span class="txt_d"> *</span></p>
          <mat-form-field appearance="none">
            <input matInput placeholder="Enter Job Title" maxlength="129" formControlName="jobTitle"
              class="form-control custom_input">
            <mat-error *ngIf="submitted && carrierContactInfo.get('jobTitle').errors?.['required']" class="error_mess">
              Job title is required.</mat-error>
            <mat-error class="error_mess m-0" *ngIf="carrierContactInfo.get('jobTitle').errors?.['pattern']"
              class="error_mess">Please enter valid information.</mat-error>
            <mat-error *ngIf="carrierContactInfo.get('jobTitle').errors?.['maxlength']" class="error_mess">Only 128
              characters are allowed.</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="form_action">
      <button type="submit" class="btn custum_button button_success fs_16 fsm_5 min_w_180">Next</button>
    </div>
  </form>
</div>

<div class="onboarding_skt" *ngIf="skeletonLoader || contactInfoLoader">
  <div class="skt_rows" style="margin: 0 0 10px;" *ngFor="let fake of generateFake(5)">
    <ngx-skeleton-loader count="1" [theme]="{ 'border-radius': '0', width: '100%', height: '16px' }"
      class="loader_mb_5"></ngx-skeleton-loader>
    <ngx-skeleton-loader count="1" [theme]="{ 'border-radius': '0', width: '100%', height: '40px' }">
    </ngx-skeleton-loader>
  </div>
</div>

<div *ngIf="manageSection==='equipmentType' && !skeletonLoader">
  <div class="header_popup mb-2">
    <p class="fs_20 fsb_6 txt_b line_before f_clash">Add Equipment type</p>
   
  </div>
  <p class="fs_14 fsn_4 txt_lb mb-4">We need this information to build your profile and to show network suggestions on
    the basis of your preferred equipment type.</p>
  <div class="v_scrollBar vertical_scl" *ngIf="equipmentList && equipmentList.length > 0">
    <div class="equmt_types">
      <div class="row">
        <div class="col-md-6" *ngFor="let equipRecord of equipmentList; let i = index">
          <div class="chooseEqupment" (click)="selectEquipmentType(equipRecord.id,equipRecord.isChecked)"
            id="{{equipRecord.dbName}}">
            <span class="check_icon {{equipRecord.isChecked}}">
              <span class="material-icons txt_s fs_22">check_circle</span>
            </span>
            <img class="img-fluid" src="{{equipRecord.icon}}">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form_action">
    <button [disabled]="checkValue" class="btn custum_button button_success fs_16 fsm_5 min_w_180" type="button"
      (click)="sumbitEquipment()">Next</button>
  </div>
</div>

<div *ngIf="manageSection==='lane'">
  <div class="header_popup mb-2">
    <p class="fs_20 fsb_6 txt_b line_before f_clash">Choose Lane Preferences</p>
   
  </div>
  <p class="fs_14 fsn_4 txt_lb mb-4">We need this information to build your profile and to show network suggestions on
    the basis of your preferred Lanes.</p>
  <div class="v_scrollBar vertical_scl">
    <div class="row">
      <div class="col-md-12 col-sm-12">
        <button class="all_lanes" (click)="checkLaneShowHide(lanLocation)" type="button"
          [ngClass]="lanLocation === 2  ? 'otherBtnActive ':'otherBtnUnactive'">
          <span class="material-icons btn_checked fs_16">check_box</span>
          <span class="fsm_5 ms-2 f_clash">I’m open for all Lanes</span>
        </button>
      </div>
    </div>
    <div class="row justify-content-center" *ngIf="lanLocation === 1">
      <section class="container" *ngFor="let location of locations; let i = index;">
        <div class="add_remove_lanes mt-3 user_onboarding_lane">
          <p class="fs_16 fsm_5 txt_b mb-0 f_clash">Lane {{i + 1}}</p>

          <span *ngIf="i > 0" class="remove_laneIcon remove_lane" (click)="removeLocation(i)">
            <span class="icon-ic-cross removeIcon"></span>
          </span>

        
        </div>
        <div class="row">
          <div class="col-md-6 ic_dropdown mat_dropdown">
            <p class="fs_14 fsn_4 txt_lb" style="margin: .6rem 0 -.7rem;">Pickup Location </p>
            <mat-form-field appearance="none">
              <mat-select [disabled]="laneDisabled" (selectionChange)="checkOption($event,'Pick',i)"
                [(ngModel)]="location.pickUp" name="pickup_{{i}}" class="form-control custom_input"
                placeholder="Enter pickup location" id="{{i}}" [ngModelOptions]="{standalone: true}">
                <ngx-mat-select-search noEntriesFoundLabel="'No Matching Lane found'" [placeholderLabel]="'Search...'"
                  name="search" (keyup)="searchLane($event)"></ngx-mat-select-search>
                  <mat-optgroup *ngFor="let currentLaneGroup of laneListGroup | keyvalue" label="{{currentLaneGroup.key}}">
                    <ng-container *ngFor="let lane of currentLaneGroup.value">
                      <mat-option *ngIf="location.pickUp !== lane.state" [value]="lane.id">{{lane.state}}</mat-option>
                    </ng-container>
                  </mat-optgroup>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-6 ic_dropdown mat_dropdown">
            <p class="fs_14 fsn_4 txt_lb" style="margin: .6rem 0 -.7rem;">Dropoff Location</p>
            <mat-form-field appearance="none">
              <mat-select [disabled]="laneDisabled" (selectionChange)="checkOption($event,'Drop',i)"
                [(ngModel)]="location.dropOff" name="dropoff_{{i}}" id="{{i}}" class="form-control custom_input"
                placeholder="Enter dropoff location" id="{{i}}" [ngModelOptions]="{standalone: true}">
                <ngx-mat-select-search noEntriesFoundLabel="'No Matching Lane found'" [placeholderLabel]="'Search...'"
                  name="search" (keyup)="searchLane($event)"></ngx-mat-select-search>
                <mat-optgroup *ngFor="let currentLaneGroup of laneListGroup | keyvalue" label="{{currentLaneGroup.key}}">
                  <ng-container *ngFor="let lane of currentLaneGroup.value">
                    <mat-option *ngIf="location.dropOff !== lane.state" [value]="lane.id">{{lane.state}}</mat-option>
                  </ng-container>
                </mat-optgroup>
              </mat-select>
            </mat-form-field>
            <p class="fs_12 txt_d fsm_5 txt_d" style="margin: -0.7rem 0 0;" *ngIf="sameLaneError == [i]"> Two lanes
              should not be the same.</p>
          </div>
        </div>
      </section>
    </div>
    <div class="add_more_lane" *ngIf="addOption && lanLocation === 1">
      <span type="button" class="fs_14 fsm_5 txt_p pointer lanes" (click)="addLocation()">
        <span class="material-icons txt_p fs_18">control_point</span>
        <span class="ms-1 f_clash">Add more Lane</span>
      </span>
    </div>
    <div class="add_more_lane disabled" *ngIf="!addOption && lanLocation === 1">
      <span type="button" class="fs_14 fsm_5 txt_lb lanes" style="cursor:default;">
        <span class="material-icons fs_18 txt_p">control_point</span>
        <span class="ms-1 txt_p f_clash">Add more Lane</span>
      </span>
    </div>
  </div>
  <div class="form_action">

    <button [disabled]="checkValueLane" class="btn custum_button button_success fs_16 fsm_5 min_w_180" type="button"
      (click)="showSignature()">Next</button>

  </div>
</div>

<div *ngIf="manageSection==='signature'">
  <div class="header_popup mb-2">
    <p class="fs_20 fsb_6 txt_b line_before f_clash">Choose Your Singnature</p>
    
  </div>
  <p class="fs_14 fsn_4 txt_lb mb-4">We need this information to build your profile and to capture all relevant
    information on the Network Contract.</p>
  <form [formGroup]="signatureForm" (ngSubmit)="signatureSave(signatureForm)" class="mb-0 custum_input_css inputInPopup"
    autocomplete="off">
    <div class="v_scrollBar vertical_scl">
      <div class="row">
        <div class="col-md-8">
          <p class="fs_14 fsm_5 txt_b mb-1">Name</p>
          <mat-form-field appearance="none">
            <input matInput placeholder="Enter name" formControlName="name" class="form-control custom_input"
              readonly="true">
            <mat-hint class="fs_12 fsn_4 txt_db mat-hint">To update/edit your signature please update in profile
            </mat-hint>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <p class="fs_14 fsn_4 txt_lb mt-1" *ngIf="!getCarrierRecord?.firstName && !getCarrierRecord?.lastName">
            <span>Make sure to check your name above before selecting your signature. </span>
            <span class="txt_p pointer" (click)="userProfileRedirect()"> Click here to verify.</span>
          </p>
        </div>
      </div>
      <div class="row" *ngIf="signatureSkeleton">
        <div class="col-md-6" *ngFor="let fake of generateFake(6)">
          <div class="sign_skelaton">
            <ngx-skeleton-loader count="1"
              [theme]="{ 'border-radius': '0', width: '100%', height: '90px', margin: '0px' }"></ngx-skeleton-loader>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="fontList?.length > 0 && !signatureSkeleton">
        <div class="col-md-{{columnSize}}" *ngFor="let fontType of fontList">
          <div class="signatureCon" (click)="selectFont(fontType.class)">
            <div class="sign_img" [ngClass]="selectedFont === fontType.class ? 'fontActive' : '' ">
              <span class="{{fontType.class}}">{{getCarrierRecord?.firstName}} {{getCarrierRecord?.lastName}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <mat-form-field appearance="outline" class="hide_input">
            <input matInput placeholder="Please enter font type" formControlName="type"
              class="form-control custom_input">
            <mat-error class="error_mess" *ngIf="submitted && signatureForm.get('type').errors?.['required']"
              class="mt-1">Select font type required.</mat-error>
          </mat-form-field>
        </div>
      </div>
      <p class="fs_12 fsn_4 txt_db mb-3"><i>You can not change the signature once selected and the same will be used in
          all your documents.</i></p>
    </div>
    <div class="form_action">
      <button type="submit" [disabled]="signatureDisable"
        class="btn custum_button button_success fs_16 fsm_5 min_w_180">Generate Document</button>
    </div>
  </form>
</div>

<div *ngIf="manageSection==='document' && userType==='SHIPPER'">
  <div>
    <div class="header_popup mb-2">
      <p class="fs_20 fsb_6 txt_b line_before f_clash">Contract Guidelines</p>
      <span class="close_popup_Icon" (click)="closePopupFail()">
        <span class="icon-ic-cross close_Icon"></span>
      </span>
    </div>
    <p class="fs_14 lh_20 fsn_4 txt_lb pr-4">The profile details below will be used to generate the LaneAxis Network
      standard contract. Please ensure all profile details are accurate. Once you and a Carrier sign off on all relevant
      documents, a standard LaneAxis contract is generated between the two parties, allowing Carriers to quote/bid on
      your
      posted loads and accept an assigned load without any delay.</p>
   
    <div class="v_scrollBar vertical_scl">
      <div class="Guidelines">
        <ul>
          <li>
            <span class="Guide_title">Name</span>
            <span class="Guide_value"
              *ngIf="getCarrierRecord?.firstName && getCarrierRecord?.lastName">{{getCarrierRecord?.firstName +" "+
              getCarrierRecord?.lastName}}</span>
            <span class="Guide_value"
              *ngIf="getCarrierRecord?.firstName && !getCarrierRecord?.lastName">{{getCarrierRecord?.firstName}}</span>
          </li>
          <li>
            <span class="Guide_title">Company Name</span>
            <span class="Guide_value">{{getCarrierRecord?.companyName}}</span>
          </li>
          <li>
            <span class="Guide_title">Equipment Type</span>
            <span class="Guide_value" style="display: block;">
              <span *ngFor="let equipment of getCarrierRecord.equipmentType; let i=index">{{ equipment}}<span
                  *ngIf="i < getCarrierRecord.equipmentType.length-1">, </span>
              </span>
            </span>
          </li>
          <li><span class="Guide_title">Address, State, Zip Code</span>
            <span class="Guide_value">{{getCarrierRecord?.phyAddressLine1}}, {{getCarrierRecord?.phyState}},
              {{getCarrierRecord?.phyZip}}
            </span>
          </li>
          <li>
            <span class="Guide_title">E-signature</span>
            <span class="Guide_value">
              <div class="e_sign">
                <span class="{{getCarrierRecord?.signatureFont}}">{{getCarrierRecord?.firstName}}
                  {{getCarrierRecord?.lastName}}</span>
              </div>
            </span>
          </li>
          <li>
            <span class="Guide_title">Job Title</span>
            <span class="Guide_value"> {{getCarrierRecord?.title}}</span>
          </li>
        </ul>
        <p class="fs_16 fsn_4 txt_lb" style="margin: 32px 0 0;">
          <span>Please view and accept the Laneaxis Standard Contract, click here to </span>
          <a href="{{shipperContract}}" target="_blank" class="txt_p fsm_5 f_clash"><u> view document</u></a>.
        </p>
      </div>
      <a href="{{shipperContract}}" target="_blank">
        <div class="doc_container">
          <img src="https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/gr-pdf-r.svg" class="img-fluid">
          <p class="fs_16 fsm_5 txt_b m-0 f_clash">Shipper Contract</p>
        </div>
      </a>
    </div>
    <div class="form_action">
      <button type="submit" class="btn custum_button button_success fs_16 fsm_5 min_w_180"
        (click)="acceptContractDocument()">Accept</button>
    </div>
  </div>
</div>

<div *ngIf="manageSection==='document' && userType==='CARRIER'">
  <div class="header_popup mb-2">
    <p class="fs_20 fsb_6 txt_b line_before f_clash">Upload Certificate of Insurance</p>
    <span class="close_popup_Icon" (click)="closePopupFail()">
      <span class="icon-ic-cross close_Icon"></span>
    </span>
  </div>
  <div
    *ngIf="(!getCarrierRecord?.coiDoc && !getCarrierRecord?.coiExpired) || (getCarrierRecord?.coiDoc && getCarrierRecord?.coiExpired)">
    <p class="fs_14 fsn_4 txt_lb mb-4">We would need below documents before establishing any connection and start with
      shipments.</p>
    <form [formGroup]="certifcateVerify" (ngSubmit)="certifcateUpload(certifcateVerify)" class="mb-0 custum_input_css">
      <div class="v_scrollBar vertical_scl">
        <div class="row">
          <div class="col-md-6">
            <p class="fs_14 fsn_4 txt_lb mb-1">Certificate of Insurance <span class="txt_d"> *</span></p>
            <div class="upload_dock reg_upload_dock">
              <mat-form-field appearance="none" class="custom_matSuffix_icon">
                <input matInput type="text" formControlName="certificate" value="{{name}}" placeholder="Upload Document"
                  class="form-control custom_input" readonly="readonly">
                <mat-error *ngIf="imagmessage" class="error_mess" style="margin: 19px 0 0;">{{ imagmessage }}
                </mat-error>
                <mat-error class="error_mess" *ngIf="certifcateVerify.get('certificate').errors?.['required']"
                  style="margin: 19px 0 0;">Certificate of Insurance is required.</mat-error>
              </mat-form-field>
              <div>
                <input accept="application/pdf, image/jpeg, image/png, image/jpg" class="inputfile" id="file" name="file" type="file"
                  (change)="onSelectedFileCertifcate($event)" style="display: none;">
                <label class="btn btn-primary py-0 mt-3" for="file"
                  style="background: #f5f5f5; border-radius: 0 4px 4px 0; margin: 0px; border-color: #0000;">
                  <span class="icon icon-ic-upload fs_16 txt_lb"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="ic_calander">
              <p class="fs_14 fsn_4 txt_lb mb-1">Expiry Date <span class="txt_d"> *</span></p>
              <mat-form-field appearance="none" class="drop_bg cal_input">
                <input matInput formControlName="insurance_expiry_date" [matDatepicker]="dp1"
                  placeholder="Enter Expiry Date" [min]="minDateBefore" [readonly]="true"
                  class="form-control custom_input">
                <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                <mat-datepicker #dp1 disabled="false"></mat-datepicker>
                <mat-error class="error_mess"
                  *ngIf="certifcateVerify.get('insurance_expiry_date').errors?.['required']">Expiry date is required.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <p class="fs_12 fsn_4 txt_db" style="margin: -2px 0 16px;"><i>You can update the Certificate of Insurance 30 days
          before expiry</i></p>
      <div class="form_action">
        <button type="submit" class="btn custum_button button_success fs_16 fsm_5 min_w_180"
          [disabled]="disbu">Upload</button>
      </div>
    </form>
  </div>

  <div *ngIf="getCarrierRecord?.coiDoc && !getCarrierRecord?.coiExpired">
    <div class="certificate_doc_uploaded">
      <div class="list-lable-wrapper document_card">
        <a href="{{getCarrierRecord.coiDoc}}" target="_blank" style="width: 100%">
          <div class="ins_certificate m-0">
            <div class="ins_certificate_icon">
              <span class="icon icon-ic-documents txt_lb" style="font-size: 36px"></span>
            </div>
            <div class="ins_certificate_info" style="width: 100%">
              <p class="fs_14 fsm_5 txt_b mb-1 text_truncate" style="width: 99%;">Certificate Of Insurance</p>
              <p class="fs_12 fsn_4 mb-0 txt_lb">
                <span class="create_date"> Expires At {{getCarrierRecord?.insuranceCertificateExpireDate | date: 'MMM
                  dd, yyyy'}}</span>
              </p>
            </div>
          </div>
        </a>
      </div>
    </div>
    <div class="text-end mt-3">
      <button type="button" class="btn custum_button button_success txt_b me-4" (click)="closePopupFail()"
        style="background: transparent;">Cancel</button>
      <button class="btn custum_button button_success" type="button" (click)="closePopupFail()"
        style="min-width: 145px;">Ok</button>
    </div>
  </div>
</div>





<div *ngIf="manageSection==='stripe'">
  <div class="header_popup mb-2">
    <p class="fs_20 fsb_6 txt_b line_before f_clash">Create Stripe Connect Account</p>
    <span class="close_popup_Icon" (click)="closePopupFail()">
      <span class="icon-ic-cross close_Icon"></span>
    </span>
  </div>
  <p class="fs_14 fsn_4 txt_lb mb-4">Your Certificate of Insurance has been submitted successfully. Click here to create
    Stripe Connect Account.</p>
  <div class="form_action">
    <button class="btn custum_button button_success fs_16 fsm_5 min_w_180" type="button"
      (click)="createStripeAccount()">Create Account</button>
  </div>
</div>

<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px',fullScreenBackdrop:true }"></ngx-loading>