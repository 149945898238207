import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { SharedService } from '../service/shared.service';
import { MatDialog } from '@angular/material/dialog';
import { AppSettings } from '../../commons/setting/app_setting';
import { PopupComponent } from '../../shared/popup/popup.component';
import { RoutePlaneComponent } from 'src/app/shipment-shared/route-plane/route-plane.component';

@Injectable({
  providedIn: 'root',
})
export class PendingChangesGuard
  implements CanDeactivate<RoutePlaneComponent>
{

  constructor(
    private sharedService: SharedService,
    public dialog: MatDialog,
    ){}

  canDeactivate(
    component: RoutePlaneComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ) {
    
    if (currentRoute.params['type'] === 'route-plan' && this.sharedService.getGuardValue()) {
      if (confirm('WARNING: You have unsaved changes, press Cancel and save these changes, or press OK to leave this page and discard your changes.')) {
        this.sharedService.setGuardValueFalse();
        return true;
        this.sharedService.stopTimer();
      } else {
        return false;
      }
    } else {
      this.sharedService.stopTimer();
      return true;
    }
    
  }

  
}
