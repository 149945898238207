import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { AppSettings } from '../../commons/setting/app_setting';

@Component({
  selector: 'app-login-navbar',
  templateUrl: './login-navbar.component.html',
  styleUrls: ['./login-navbar.component.css'],
})
export class loginNavbarComponent implements OnInit {
  @Input() currentTab;
  public userLogin = false;
  public isLogin: any;
  public serverPathForUrl: any;
  public logo:any;
  
  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.logo =AppSettings.logo;
    this.isLogin = localStorage.getItem('login');
    this.serverPathForUrl = environment.serverPathForUrl;
    if (localStorage.getItem('access_token') !== null) {
      this.userLogin = true;
    } else {
      this.userLogin = false;
    }
  }

  // Function to navigate login page
  loginPage() {
    this.router.navigate(['/']);
  }

  // Function to navigate track-your-shipment page
  trackShipment() {
    this.router.navigate(['/shipment/track-your-shipment']);
  }
}
