import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { CommonService } from '../../commons/service/common.service';
import { AppSettings } from '../../commons/setting/app_setting';
import { AlertService } from '../../commons/service/alert.service';
import { SharedService } from '../../commons/service/shared.service';
import { MatDialog } from '@angular/material/dialog';
import { PopupComponent } from '../../shared/popup/popup.component';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-payment-card',
  templateUrl: './payment-card.component.html',
  styleUrls: ['./payment-card.component.css'],
})

export class PaymentCardComponent implements OnInit {
  @Input() currentCardActive: any;
  @Input() pageName: any;
  @Input() type: any;
  @Output() selectPaymentCard = new EventEmitter();
  public skeletonLoaderCard = false;
  public cardListing = [];
  public has_more = false;
  public loading = false;
  public serverPathForUrl: any;
  public today = new Date();
  public currentYear: any;
  public currentMonth: any;
  public startingAfter = null;

  constructor(
    public sharedService: SharedService,
    public dialog: MatDialog,
    public commonService: CommonService,
    public alertService: AlertService
  ) { }

  ngOnInit() {
    this.currentMonth = this.today.getMonth() + 1;
    this.currentYear = this.today.getFullYear();
    this.serverPathForUrl = environment.serverPathForUrl;
    this.getCardListing();
  }

  selectCard(id: any) {
    if (this.pageName !== 'overview') {
      this.selectPaymentCard.emit(id);
      this.currentCardActive = id;
    }
  }

  public generateFake(number: any) {
    return this.sharedService.generateFakeSkeleton(number);
  }

  addPaymentCard() {
    const dialogRef = this.dialog.open(PopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      data: { openPop: 'addPaymentCardPopup', type: this.type },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.event === 'Success') {
        this.getCardListing();
      }
    });
  }

  getCardListing(params = {}) {
    this.skeletonLoaderCard = false;
    let uri = null;
    //get dynamic URI for APIs
    let paramsNew = {
      type: 'card',
      limit: '8',
    };
    if (paramsNew) uri = this.commonService.getAPIUriFromParams(paramsNew);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.LOADPAYMENT.CARDLIST,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe(
      (ServerRes) => {
        if (ServerRes.success === true) {
          let expiry;
          this.cardListing = ServerRes.response.data;
          this.has_more = ServerRes.response.has_more;
          this.startingAfter = this.cardListing.length ? this.cardListing[this.cardListing?.length - 1].id : '';
          for (let i = 0; i < this.cardListing.length; i++) {
            if (this.cardListing[i]?.card?.exp_year >
              this.currentYear || (this.cardListing[i]?.card?.exp_year ===
                this.currentYear && this.cardListing[i]?.card?.exp_month >= this.currentMonth)) {
              expiry = { expiry: false };
              this.cardListing[i] = Object.assign(this.cardListing[i], expiry)
            }
            else {
              expiry = { expiry: true };
              this.cardListing[i] = Object.assign(this.cardListing[i], expiry)
            }
          }
          this.skeletonLoaderCard = true;
        } else {
          this.cardListing = [];
          this.skeletonLoaderCard = true;
        }
      },
      (error) => {
        this.skeletonLoaderCard = true;
        this.cardListing = [];
      }
    );
  }

  removeCard(id: any) {
    this.cardConfirmationCard(id);
  }

  cardConfirmationCard(id: any) {
    const dialogRef = this.dialog.open(PopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      data: { openPop: 'cardConfirmation' },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.event === 'Ok') {
        this.removePaymentMethod(id);
      }
    });
  }

  removePaymentMethod(id: any) {
    this.loading = true;
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.LOADPAYMENT.REMOVEPAYMENT,
      uri: '',
      postBody: { type: 'card', cardId: id },
    };
    this.commonService.post(APIparams).subscribe(
      (success) => {
        if (success.success === true) {
          this.alertService.showNotificationMessage(
            'success',
            'bottom',
            'right',
            'txt_s',
            'check_circle',
            'Payment Method',
            'You have successfully removed card details.'
          );
          this.loading = false;
          this.getCardListing();
        } else if (success.success === false) {
          this.loading = false;
          this.alertService.showNotificationMessage(
            'success',
            'bottom',
            'right',
            'txt_d',
            'check_circle',
            'Payment Method',
            success.message
          );
        }
      },
      (error) => {
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_g',
          'error',
          'Unexpected Error',
          AppSettings.ERROR
        );
        this.loading = false;
      }
    );
  }

  MoreCards() {
    this.skeletonLoaderCard = false;
    let uri = null;
    //get dynamic URI for APIs
    let paramsNew = {
      type: 'card',
      limit: '8',
      startingAfter: this.startingAfter
    };
    if (paramsNew) uri = this.commonService.getAPIUriFromParams(paramsNew);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.LOADPAYMENT.CARDLIST,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe(
      (ServerRes) => {
        if (ServerRes.success === true) {
          let expiry;
          let newCards = ServerRes.response.data
          for (let i = 0; i < newCards.length; i++) {
            this.cardListing.push(newCards[i]);
          }
          this.has_more = ServerRes.response.has_more;
          this.startingAfter = this.cardListing[this.cardListing.length - 1].id;
          for (let i = 0; i < this.cardListing.length; i++) {
            if (this.cardListing[i]?.card?.exp_year >
              this.currentYear || (this.cardListing[i]?.card?.exp_year ===
                this.currentYear && this.cardListing[i]?.card?.exp_month >= this.currentMonth)) {
              expiry = { expiry: false };
              this.cardListing[i] = Object.assign(this.cardListing[i], expiry)
            }
            else {
              expiry = { expiry: true };
              this.cardListing[i] = Object.assign(this.cardListing[i], expiry)
            }
          }
          this.skeletonLoaderCard = true;
        } else {
          this.cardListing = [];
          this.skeletonLoaderCard = true;
        }
      },
      (error) => {
        this.skeletonLoaderCard = true;
        this.cardListing = [];
      }
    );
  }
}
