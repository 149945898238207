import { Injectable, Inject, Renderer2, ChangeDetectorRef } from '@angular/core';
import { CommonService } from '../service/common.service';
import { AlertService } from '../service/alert.service';
import { DOCUMENT, DatePipe } from '@angular/common';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';
import { AppSettings } from '../setting/app_setting';
declare var require: any;
declare var gtag: Function;
declare var ga: Function;
declare global {
  interface Window {
    attachEvent: any;
    dataLayer: any;
  }
}
@Injectable()
export class StripeAccountCreateService {
  public stripeSketelonder = false;
  public getRetrieveAccount: any;
  public newCreated: any;
  public disableStripeConnect = false;
  public getwindow: any;
  public loading = false;
  public timer : any;
  constructor(
  
    private commonService: CommonService,
    // private cd: ChangeDetectorRef,
    private alertService: AlertService,
  ) {}

  getRetrieve() {
    let uri = null;
    let newparams = {};
    if (newparams) uri = this.commonService.getAPIUriFromParams(newparams);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.NEWPAYMENT.CONNECTACCOUNT,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe(
      (ServerRes) => {
        if (ServerRes.success === true) {
          this.newCreated = moment.unix(ServerRes.response.created).toString();
          this.getRetrieveAccount = ServerRes.response;
          if (ServerRes.response.status === 'Active') {
            localStorage.setItem('stripeConenct', 'active');
          } else {
            localStorage.setItem('stripeConenct', ServerRes.response.status);
          }
        } else {
          this.getRetrieveAccount = '';
          this.stripeSketelonder = false;

          localStorage.setItem('stripeConenct', '');
        }
      },
      (error) => {
        this.stripeSketelonder = false;
        this.getRetrieveAccount = '';
        localStorage.setItem('stripeConenct', '');
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_g',
          'error',
          'Unexpected Error',
          AppSettings.ERROR
        );
      }
    );
  }

  connectStripe(userDetail) {
    this.disableStripeConnect = true;
    this.loading = true;
    let postBody = null;
    postBody = {
      email: userDetail.emailAddress,
      url: '',
      country: 'US',
      phone_number: userDetail.telephone,
      business_name: userDetail.legalName,
      business_type: 'company',
      first_name: userDetail.firstName,
      last_name: userDetail.lastName,
      dob_day: '',
      dob_month: '',
      dob_year: '',
    };
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.NEWPAYMENT.GETCONNECTLINK,
      uri: '',
      postBody: postBody,
    };
    this.commonService.post(APIparams).subscribe(
      (success) => {
        if (success.success === true) {
          this.getwindow = window.open(
            success.response,
            'myWindow',
            'width=600,height=600'
          );
          this.loading = false;
          this.timer = setInterval(() => {
            this.checkChild()
          },500)
        } else if (success.success === false) {
          this.loading = false;
        }
      },
      (error) => {
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_g',
          'error',
          'Unexpected Error',
          AppSettings.ERROR
        );
        this.loading = false;
      }
    );
  }

  checkChild() {
    if (this.getwindow.closed) {
       clearInterval(this.timer);
       this.closeWindowStripe();
    }
  }

  closeWindowStripe() {
    if (this.getwindow) {
      this.disableStripeConnect = false;
      this.getwindow.close();
    }
    this.getRetrieve();
    window.location.reload();
  }

}
