import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { SharedService } from '../../commons/service/shared.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-address-card',
  templateUrl: './address-card.component.html',
  styleUrls: ['./address-card.component.css'],
})
export class AddressCardComponent implements OnInit {
  @Input() userDetail: any;
  @Input() currentAddressActive: any;
  @Input() pageName: any;
  @Input() skeletonAddress: any;
  @Output() editAdd = new EventEmitter();
  @Output() addCardAdrress = new EventEmitter();
  @Output() selectAddresCard = new EventEmitter();

  public showAddresButton = false;
  public addresBuDisabled = false;
  public userType:any;

  constructor(public sharedService: SharedService, public dialog: MatDialog) { }

  ngOnInit() {
    this.userType=localStorage.getItem('user_type')

    if (
      this.userDetail &&
      this.userDetail.phyState &&
      this.userDetail.mailingState
    ) {
      this.showAddresButton = false;
      this.addresBuDisabled = true;
    } else {
      this.showAddresButton = true;
      this.addresBuDisabled = false;
    }

  }

  userEditAdd(
    addLine1: any,
    addLine2: any,
    city: any,
    state: any,
    zipCode: any,
    country: any,
    type: any
  ) {
    this.editAdd.emit({
      addLine1,
      addLine2,
      city,
      state,
      zipCode,
      country,
      type,
    });
  }

  addAddress(type: any) {
    this.addCardAdrress.emit({ type });
  }

  selectAddress(
    addLine1: any,
    addLine2: any,
    state: any,
    city: any,
    country: any,
    zipCode: any,
    type: any
  ) {
    if (this.pageName !== 'overview') {
      this.selectAddresCard.emit({
        addLine1,
        addLine2,
        state,
        city,
        country,
        zipCode,
        type,
      });
      this.currentAddressActive = type;
    }
  }

  public generateFake(number: any) {
    return this.sharedService.generateFakeSkeleton(number);
  }
}
