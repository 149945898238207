import { environment } from '../../../environments/environment';
export class AppSettings {
  public static API_ENDPOINT = 'https://api-dev.laneaxis.com';
  public static API_GATEWAY = 'api';
  public static limit = '10';
  public static milesPrice = 40;
  public static laneaxisTerms = '3 Days from Delivery';
  public static status = null;
  public static search = null;
  public static s_key = null;
  public static driver_total = null;
  public static logo ="https://staticprod.laneaxis.com/portal/images/after-login/laneaxis-logo.png";
  public static updated_at = '';
  public static created_at = '';
  public static sort = 'updated_at';
  public static orderBy = 'DESC';
  public static dot_no = null;
  public static offset = 0;
  public static page = 1;
  public static ERROR = 'Something went wrong, Please try again later.';
  public static zipCodeError = "ZIP Code should be a character.";
  public static CREATE_SHIPMENT_ERROR =
    'Validation error appear.Please check step1 or step2.';
  public static CARRIER_CREATE_SHIPMENT_ERROR =
    'Validation error appear.Please check all data fill correct';
  public static shipperContract =
    '/shipper/contract_document/laneAxisShipperCarrierContractTemplate.pdf';

  public static commonImage = environment.imagePathUrl + '/portal/images/after-login/img_trans.gif';
  public static dashbord_Current_loc =
    'https://staticprod.laneaxis.com/portal/images/after-login/map-icons/Driver_Location.png';

 
  public static stripePublicKey = 'pk_test_jqNFapP28xsD2U7ZWC4sH1Bl00n5lthUOE';
  public static countryAuto = 'us';
  public static popWidth = '430px';
  public static popHeight = '240px';
  public static backdropClass = 'g-transparent-backdrop';
  public static APIsNameArray = {
    DRIVER: {
      GET: 'driver/get',
      LIST: 'driver/list',
      UPDATE: 'driver/update',
      DOCLIST: 'carrier/driver-doc-list',
      DOCADD: 'carrier/add-driver-doc',
      DELETE: 'carrier/del-driver-doc',
      INVITE: 'driver/invite',
      REINVITE: 'driver/reinvite',
      ASSOCIATEINVITE: 'driver/associate-invite',
      REVOKE: 'driver/revoke-invite',
    },

    PAYMENT: {
      ATTACTH: 'card/attach',
      LIST: 'card/list',
      DELETE: 'payment-method/delete',
      PAYMENTINTENT: 'loads/add-payment-intend',
      TRANSITION: 'loads/transaction-history',
      // INVOICE: 'transaction/invoice', NOT IN USE
    },

    NEWPAYMENT: {
      AUTORENEW: 'NEWPAYMENT/subscription/toggle-autorenew',
      CREATE: 'NEWPAYMENT/subscription/create',
      RETRIEVE: 'NEWPAYMENT/subscription/retrieve',
      LIST: 'NEWPAYMENT/subscription/list',
      GETPRICE: 'NEWPAYMENT/subscription/plans',
      GETPRICEGTFO: 'NEWPAYMENT/subscription/gtfo-plans',
      // BANNER: 'NEWPAYMENT/subscription/manage-banner',
      STATUS: 'NEWPAYMENT/subscription/manage',
      CARDLIST: 'NEWPAYMENT/get-paymentmethods',
      GETPRODUCT: 'NEWPAYMENT/get-products',
      GETPAY: 'NEWPAYMENT/get-paymentmethods',
      CANCELSUB: 'NEWPAYMENT/cancel-subscription',
      UPDATE: 'NEWPAYMENT/update-subscription',
      CHECKSTATUS: 'NEWPAYMENT/others/check-status',
      GETCONNECTLINK: 'NEWPAYMENT/get-connect-link',
      BIDPAYMENT: 'NEWPAYMENT/shipper/payment',
      RETRIEVEACCOUNT: 'NEWPAYMENT/retrieve-account',
      CONNECTACCOUNT: 'NEWPAYMENT/connect/account-details',
      TRAILDATE: 'NEWPAYMENT/get-trialend-date',
      TRANSFERLIST: 'NEWPAYMENT/carrier/transfers',
      TRANSFERLISTNEW: 'NEWPAYMENT/carrier/transfers',
      PAYMENTUSDC: 'NEWPAYMENT/shipper-payment-usdc',
      TRANSITION: 'NEWPAYMENT/loads/transaction-history',
      SHIPPERTRANSITION: 'NEWPAYMENT/shipper/transaction-history',
      FEES: 'NEWPAYMENT/contracts/fees',
      DETAIL: 'NEWPAYMENT/payment-method/details',
      SENDINVOICE: 'NEWPAYMENT/shipper/send-invoice-mail-with-pdf',
      CANCELSHIPMENTPAYMENT: 'NEWPAYMENT/shipper/shipment-cancel',
    },

    SUBSCRIPTION: {
      PLANLIST: 'NEWPAYMENT/subscription/plans',
      SHIPPERPLANLIST: 'NEWPAYMENT/subscription/shipper/plans',
      CREATE: 'NEWPAYMENT/subscription/create',
      // FREESUB: 'NEWPAYMENT/subscription/create-free',
      ACTIVEPLAN: 'NEWPAYMENT/subscription/active-plan',
      TRANSACTION: 'NEWPAYMENT/subscription/transactions',
      ADDITIONALCREDIT: 'NEWPAYMENT/subscription/additional-credit-transactions',
      ENTERPRICE:'enterprise-support',
      ADDCOUPONCODE :'NEWPAYMENT/verify-promo-code'
    },


    ACHPAYMENT: {
      ACHADD: 'NEWPAYMENT/shipper/ach-checkout',
      ACHLIST: 'NEWPAYMENT/card/list',
      ACHPAY: 'NEWPAYMENT/shipper/ach-payment',
      SETCARD: 'NEWPAYMENT/payment-method/set-default',
    },

    LOADPAYMENT: {
      CARDLIST: 'NEWPAYMENT/card/list',
      ADDCARD: 'NEWPAYMENT/card/attach',
      REMOVEPAYMENT: 'NEWPAYMENT/card/delete',
      HISTORY: 'NEWPAYMENT/loads/transaction-history',
      SHIPMENTCHECK: 'NEWPAYMENT/loads/shipment-credit-check',
      ADDLOAD: 'NEWPAYMENT/loads/add-payment-intend',
      ADDAdditionalCredits: 'NEWPAYMENT/subscription/additional-credit-payment-intent',
    },
    SENDMAILTEMPLATE: {
      EMAILSEND: 'email/send-document',
      EMAILRECEIPT: 'email/send-receipt',
      EMAILINVOICE: 'email/send-invoice',
    },

    EXTERNAL: {
      INVITE: 'external/invite-user',
      INVITESTORAGE: 'storage-network/invite',
      WAYPOINTS: 'external/shipment-waypoints',
      CARRIERPUBLIC: 'external/public/carrier-network',
      SHIPPERPUBLIC: 'external/public/shipper-network',
      PUBLICPROFILE: 'external/get-public-profile',
      DRIVERGET: 'external/track-driver',
      ADDNEWDOTNUMBERREQUST: 'external/add-new-dot-number-request',
      LOGINTOKEN: 'website-external/login-with-one-time-token',
      CARRIERREFERRAL: 'external/carrier-referral',
    },

    DOCUMENT: {
      ACCEPT: 'contracts/accept',
      REQUEST: 'contracts/request',
      REMIND: 'contracts/remind',
      SHIPPERCONTRACT: 'shipper/update-contract-document',
    },

    DISPUTE: {
      GET: 'dispute/get',
      LIST: 'dispute/get-reasons',
      ADD: 'dispute/add-dispute',
      ADDMESSAG: 'dispute/add-message',
      MESSAGELIST: 'dispute/list-dispute-message',
    },

    NOTIFICATION: {
      SENDNOTIFICATION: 'notification/send-fcm',
      LIST: 'notification/list',
      ADDTOKEN: 'device/add',
      REFRESHTOKEN: 'refresh',
      DEVICETOKENDELETE: 'device/delete',
      BEllNOTIFICATION: 'bell-notification/list',
      NOTIREAD: 'bell-notification/read',
      ALLMARKREAD: 'bell-notification/all-read',
      ALLALERTNOTIFICATION: 'badge/notification/get',
      ALERTNOTIREAD: 'badge/notification/read',
      PANEL: 'user-notification-panel/preference-get',
      PANELUPDATE: 'user-notification-panel/preference-update',
    },

    CARD: {
      ADD: 'card/add',
      LIST: 'card/list',
      DELETE: 'card/delete',
    },

    DASHBOARD: {
      // LIST: 'dashboard/new-added-users',Not in use
      DRIVER: 'dashboard/driver',
      SHIPMENT: 'dashboard/shipment',
      OVERVIEW: 'dashboard/overview',
    },

    CARRIER_REGISTRATION: {
      LIST: 'admin/carrier-list',
      VERIFY: 'carrier/verify',
      REGISTRATION: 'carrier/add',
      VERIFYOPT: 'user/verify-otp',
      GET: 'carrier/get',
      UPDATE: 'carrier/update',
      RESENDOTP: 'user/resend-otp',
      CERIFICATE: 'carrier/updateCertificate',

    },

    COLDSTORAGE: {
      SIGNUP: 'storage/add',
      VERIFY: 'storage/verify',
      GET: 'storage/get',
      UPDATE: 'storage/update',
      LOCATIONLIST: 'storage-location/list',
      LOCATIONADD: 'storage-location/add',
      LOCATIONEDIT: 'storage-location/edit',
      LOCATIONGET: 'storage-location/get',
      LOCATIONNETWORK: 'storage-network/home',
      NETWORKLIST: 'storage-network/list',
    },
    
    STORAGE: {
      TRANSITION: 'NEWPAYMENT/storage/transfers',
    },

    STORAGEPAYMENT: {
      ORDERPAY: 'NEWPAYMENT/shipper/order-ach-payment',
      CREATEINVOICE: 'NEWPAYMENT/storage-order/invoice-create',
      APPROVEDECLINE: 'NEWPAYMENT/storage-order/invoice-action',
      EDITINVOICE: 'NEWPAYMENT/storage-order/invoice-edit',
    },
    STORAGEORDER: {
      CREATE: 'storage-order/create',
      UPDATE: 'storage-order/edit',
      CREATEINVOICE: 'storage-order/invoice-create',
      EDITINVOICE: 'storage-order/invoice-edit',
      CREATENOTES: 'storage-order/add-invoice-notes',
      LIST: 'storage-order/list',
      EDITNOTES: 'storage-order/edit-invoice-notes',
      GET: 'storage-order/get',
      BIDADD: 'storage-order-bid/add',
      QUATES: 'storage-order/quotes',
      ORDERTRACK: 'storage-order/tracking',
      WITHDRAW: 'storage-order/withdraw',
      ACCEPT: 'storage-order-bid/action',
      DELETE: 'storage-order/delete',
      PREVIOUS: 'storage-order/previous-bids',
      INVOICELIST: 'storage-order/invoice-list',
      INVOICEGET: 'storage-order/invoice-get',
      ADDNOTE: 'storage-order/add-notes',
    },

    TRACK: {
      SHIPMENT: 'external/track-shipment',
    },

    MEDIA: {
      ADD: 'media/add',
      LIST: 'media/list',
      UPDATE: 'media/update',
      DELETE: 'media/delete',
      CHATMEDIA: 'media/upload-chat-media',
      MULTICHATMEDIA: 'media/chat',
    },

    CARRIER: {
      VERIFY: 'carrier/verify',
      REGISTRATION: 'carrier/add',
      VERIFYOPT: 'user/verify-otp',
      GET: 'carrier/get',
      RESENDOTP: 'user/resend-otp',
      UPDATE: 'carrier/update',
      USERCREDENTIALS: 'user/change-credentials',
      LIST: 'connection/carriers',
      // LANE: 'connection/list',
      LANE: 'region/list',
      DOCUMENT: 'carrier/remove-docs',
      // CARRIERSEARCH: 'home/carrier-search', not in use
      REFERRALGET: 'referral/get-code',
      ECARRIERCHECK: 'get/carrier-safety-score',
      ECARRIERCHECKRECORD: 'carrier/get-self-safety-report'
    },

    MESSAGE: {
      SEARCHUSER: 'message/search/user',
    },

    MANAGE_CONNECTION: {
      REMOVE: 'connection/remove',
      REINVITE: 'connection/re-invite',
      INVITE: 'connection/invite',
      REJECT: 'connection/reject',
      CANCEL: 'connection/cancel',
      CONNECT: 'connection/connect',
      GETPROFILE: 'connection/get-profile',
      SHIPPERNETWORK: 'connection/shipper-network',
      CARRIERNETWORK: 'connection/carrier-network',
      AUTOCONNECTION: 'connection/auto-generate-connection',
    },

    // Not in use
    // HOME: {
    //   CARRIERLOADS: 'home/carrier-loads',
    //   SHIPPERLOADS: 'home/shipper-loads',
    // },

    SHIPMENT: {
      LIST: 'driver/list',
      SHIPMENTLIST: 'shipment/list',
      NEWSHIPMENTLIST: 'shipment/list',
      ADD: 'shipment/add',
      GET: 'shipments/get',
      NEWGET: 'shipment/get',
      WAYPOINT: 'waypoint/list',
      SHLIST: 'shipment/list',
      WAYPOINTLIST: 'waypoint/list',
      UPDATEROUTE: 'shipment/update-routes',
      UPDATE: 'shipment/edit',
      MANAGESTATUS: 'shipment-status/manage-status',
      ASSIGN: 'shipment/assign-shipment',
      SHIPMENTCOUNT: 'shipment/tab-count',
      SHIPMENTHISTORY: 'shipment/history',
      SHIPMENTHISTORYNEW: 'shipment/history-new',
      SHIPMENTCREDITCHECK: 'loads/shipment-credit-check',
      SHIPMENTCANCEL: 'shipment/cancel',
      SHIPMENTTRIPSTATUS: 'shipment/trip-status',
      SHIPMENTDOCUMENT: 'media/shipment-documents',
      NEWLIST: 'shipment/list',
      GETMILES: 'shipments/get-miles',
      SHIPPERDOC: 'shipper/add-shipper-doc',
      SHIPPERDOCEDIT: 'shipper/edit-shipper-doc',
      SHIPPERDOCDELETE: 'shipper/del-shipper-doc',
      COMMODITYMEDIA : 'commodity/upload-media',  
      CREATELANES: 'lanes/create',
      EDITLANES:'lanes/edit',
      UPDATEROUTES:'lane/update-routes',
      LANELIST: 'lanes/list',
      LANEGET: 'lanes/get',
      LANEWAYPOINTS: 'lane/waypoint/list',
      LANECARRIERLIST: 'lane-bid/carrier-list',
      LANESHIPPERGET: 'lane-bid/carrier',
      STATELIST :'state/list',
      BIDASSISTANT :'bid-assistant/get',
      BIDASSISTANTCREATE :'bid-assistant/create',
      BIDASSISTANTUPDATE :'bid-assistant/update'
    },

    SHIPMENTFORSHIPPER: {
      ADD: 'shipment/add-shipper-shipment',
      CREATSHIPMENT: 'shipment/create',
      CARRIER: 'preferred/list',
      ASSIGNCARRIER: 'shipment-bid/re-assign',
    },
    
    PREFERRED: {
      ADD: 'preferred/add',
      DELETE: 'preferred/delete',
    },

    RATING: {
      ADD: 'rating-review/add',
      RATINGLIST: 'rating-review/list'
    },

    TEMPLATE: {
      LIST: 'template/list',
      ADD: 'template/add',
      GET: 'template/get',
      UPDATE: 'template/edit',
      DELETE: 'template/status',
    },

    SHIPPER: {
      ADD: 'shipper/add',
      GET: 'shipper/get',
      UPDATE: 'shipper/update',
      LIST: 'connection/shippers',
      VERIFY: 'shipper/verify',
      REGIONS: 'region/list',
      home:'home/user-tutorial'
    },

    BIDS: {
      ACCEPT: 'shipment-bid/accept',
      ADD: 'shipment-bid/add',
      HISTORY: 'shipment-bid/history',
      PREHISTORY: 'shipment-previous-bid/history',
      WITHDRAL: 'shipment-bid/delete',
      ACTIVEBID: 'shipment-bid/active-shipments',
      LOWESTBID: 'shipment-bid/lowest-bid',
      LANEADD: 'lane-bid/add',
      LANEACCEPT: 'lane-bid/accept'
    },

    ADMIN_MANAGMENT: {
      FLIST: 'feedback/list',
      FADD: 'feedback/add',
      ELIST: 'equipment/list',
      LLIST: 'load/list',
    },

    OTHER: {
      SICCODE: 'connection/sic-code-list',
      EQUIPMENT: 'config/get',
      CONFIG: 'config/get',
    },

    NETWORK: {
      HOME: 'network/home',
      MYNETWORK: 'network/my-network',
    },

    MY_PROFILE: {
      DRIVERGET: 'driver',
      DRIVERUPDATE: 'driver',
      PASSWORD: 'change_password',
      CHECKCREDENTIALS: 'user/check-credentials',
      UPDATECREDENTIALS: 'user/update-credentials',
    },

    AUTH: {
      REGISTER: 'me/register',
      FORGOTPASSWORD: 'user/forgot_password',
      FORGOTPASSWORDOTP: 'forgot_password',
      FORGOTPASSWORDVERIFY: 'verify_forget_password_otp',
      RESETPASSWORD: 'reset-password',
      LOGIN: 'api/login',
      USER_INFO: 'api/user',
      NELOGIN: 'login',
    },

    PAYUSER: {
      ADDRESS: 'PAYAPI/v1/user/address',
      INFO: 'PAYAPI/v1/user/info',
      CONTRACTS: 'PAYAPI/v1/contracts/axis/price',
    },
  };
}
