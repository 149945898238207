import { MapsAPILoader } from '@agm/core';
import { GermanAddress } from '@angular-material-extensions/google-maps-autocomplete';
import { DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { HereMapsService } from 'src/app/commons/service/here-maps.service';
import { HereMapsService8 } from 'src/app/commons/service/here-maps8.service';
import { environment } from '../../../environments/environment';
import { AlertService } from '../../commons/service/alert.service';
import { CommonService } from '../../commons/service/common.service';
import { SharedService } from '../../commons/service/shared.service';
import { AppSettings } from '../../commons/setting/app_setting';
import { StatusSetting } from '../../commons/setting/status_setting';
import PlaceResult = google.maps.places.PlaceResult;
declare var _: _.groupBy;
declare let Stripe: any;

export interface DialogData {
  animal: string;
  openPop: string;
  contain: string;
  title: string;
  driverId: any;
  id: any;
  driverName: any;
  status: any;
  successMeg: any;
  buttontext: any;
  buttonnew: any;
  inviteDriverId: any;
  type: any;
  addressLine1: any;
  addressLine2: any;
  state: any;
  waypointLength: any;
  lastIndexPickup: any;
  city: any;
  zipCode: any;
  wayPoints: any;
  country: any;
  receiverId: any;
  shipmentId: any;
  shipmentUniqueId: any;
  invoiceId: any;
  uniqueId: any;
  laneId: any;
  loads: any;
  email: any;
  phone: any;
  plans: any;
  isDraft: any;
  stripeUrl: any;
  documentUrl: any;
  name: any;
  date: any;
  documentId: any;
  expiredDate: any;
  carrierStatus: any;
  shipperStatus: any;
  waypointlaneView: any;
  userId: any;
  allRecord: any;
  coiExpired: any;
  offerRate: any;
  storageData: any;
  index: any;
  selectedWayPointToEdit: any;
  previousWayPointToEdit: any;
  invoiceData: any;
  shipmentDetail: any;
  point: any;
  otherData: any;
  ratingOwnerDetail: any;
  locationOrder:any;
  wayPointArrays:any;
  wayPoint:any;
}

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css'],
})
export class PopupComponent implements OnInit {
  @ViewChildren('uploader', { read: ElementRef }) uploader: QueryList<ElementRef>;
  public equipmentListing: any = [];
  disableDriver: boolean = true;
  inviteDriSuccess: boolean = true;
  public submitted = false;
  addDocUpload: FormGroup;
  EditLaneForm:FormGroup;
  editBidQuoteForm:FormGroup;
  public emailSendForm: FormGroup;
  public contractForm: FormGroup;
  public certifcateVerify: FormGroup;
  public emailOtpVerify: FormGroup;
  public phoneOtpVerify: FormGroup;
  public imagmessage: any;
  public message: any;
  public imgURL: any;
  public currentStatus = 'relavent';
  public selectTime: any;
  public deliveryTimeShow: any;
  public disbu = true;
  public imagePath: any;
  public rateList: any;
  public name: any;
  public notVerifiedLanes = []
  public loading = false;
  public params: any;
  public profileImge: any;
  public driverName: any;
  public carrierPA: FormGroup;
  public sendInvoiceFrom: FormGroup;
  public wayPointEditForm: FormGroup;
  public wayPointAddForm: FormGroup;
  public pickAutoNot: boolean;
  public editLaneAutoNot :boolean;
  public pickupFullLocation: any;
  public shortNameError: any;
  public totalReviewForPopup = true;
  public newSelectedLatitude: any;
  public newSelectedLongitude: any;
  public previousSelectedLatitude: any;
  public ifPickupLoadInfo: boolean = false;
  public previousSelectedLongitude: any;
  public skeletonLoader = false;
  public getRouteResponse: any;
  public selectedCountry: any;
  public isTimeSloteAvailble = false;
  public isTimeSloteExpired = false; 
  public noTimeSloteError: any;
  public selectedIndex: any;
  public shortNameValue = false;
  public pickupBussinessName: any = '';
  public pickupContactName: any = '';
  pickupDate: any;
  pickuptimeslot: any;
  public pickupTimeArr: any;
  public pickupTZ: any;
  public minDate: any = new Date();
  public maxDate: any = new Date();
  public geoCoder: any;
  olddropDate: any;
  public today: any;
  public pickupCity: any;
  public pickupState: any;
  public pickupLatitude: any;
  public sameLocation: boolean = false;
  public dimensions = '';
  public pickupLongitude: any;
  public pickUp: any;
  public totalLanemiles=0
  public originalRouteType: any;
  public pickupCityError: any;
  public pickupShortName: any;
  public hideImageRemove = false;
  public searchBoxError = false;
  public uploadState = true;
  public deliveryLatitude: any;
  public deliveryLongitude: any;
  public shipmentPingRate: any;
  public uploadState1 = false;
  public uploadState2 = false;
  public addshipment: any = {};
  public pickupInstructionError: boolean = false;
  public dropOffInstructionError: boolean = false;
  public uploadFileDownload: any;
  public usTimeZoneList = {};
  public countSpace = 1;
  public commonImage: any;
  public stripe: any;
  public cardNumber = false;
  public cardCvc = false;
  public cardExpiry = false;
  public emailPattern = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$';
  public cardNumberError: any;
  public cardCvvError: any;
  public cardExpiryError: any;
  public brandCard = 'unknown';
  public card: any;
  public keyCounter = 1;
  public paymentCard: FormGroup;
  public saveCardButton = true;
  public routeStatus = [];
  public states: any;
  public spinnerLoading = false;
  public resendmessage: any;
  public searchRestriction: any;
  public minDateBefore = new Date();
  public pickupCountryName: any = '';
  public pickupCountryCode: any;
  public lanePostal:any;
  public laneEventData:any;
  public newSelectPickupCountryCode: any;
  public addressForm: FormGroup;
  public subject: Subject<boolean>;
  //multi file upload
  public alphmenumb = /^[a-z\d\-_\s]+$/i;
  public numOnly = '[0-9]*';
  public dropTZ: any;
  public phpAdd = false;
  public mailAdd = false;
  public disabledAssign = false;
  public checkErrorMessage = false;
  public maxDateDropOff = new Date();
  public noteShow = false;
  public pickupTimeZone: any;
  public shortNameDropValue = false;
  public pickupChangeDetected = false;
  public locationOrder: any;
  public searchModel: any = {};
  public userType: any;
  public documentType = false;
  public equipmentList = [];
  public countryList: any;
  public defualtPickupCountryFlag: any;
  defualtPickupCountryFlagAdd:any;
  public newSelectPickupCountryCodeAdd:any;
  public page: any;
  public getCarrierRecord: any;
  public dropTimeArr: any;
  public searchText = '';
  public finalAmount: any;
  public errorMessage: any;
  public disabled1 = true;
  public pickupTimeError = false;
  public currentIndex:any;
  public showErrorImage:any;
  public dropAutoNot: boolean;
  public dropupChangeDetected = false;
  public editLocationChangeDetected = false;
  public timeZoneSelectedPick = '';
  public timedat: any;
  public pitime: any;
  public isDraft: any;
  public deliveryTimedisabled = false;
  public delivery: any;
  public pimaxDate: any;
  public calemaxte: any;
  public certificateInsuranceExpired = false;
  public show: any;
  public totalPage:any
  public currentDate: any;
  public serverPathForUrl: any;
  public shipperContract: any;
  public documentCheck = false;
  public communityDataSubmiited=false;
  public newPickupCountryName:any;
  public locations: any[] = [
    {
      id: 1,
      pickUp: '',
      dropOff: '',
    },
  ];
  public storageService = [];
  public isWayPointTypeChanged: boolean = false;
  @Output() public myOutput = new EventEmitter();
  isClosed: EventEmitter<any> = new EventEmitter();
  zipCodeErrorMsg: string;
  public checkRouteMatch=false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private sharedService: SharedService,
    private datePipe: DatePipe,
    private commonService: CommonService,
    private mapsAPILoader: MapsAPILoader,
    private hereMapsService: HereMapsService,
    private HereMapsService8:HereMapsService8,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<PopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (this.data.openPop=='addMultiStopRoute' || this.data.openPop === 'editMultiStop'|| this.data.openPop === 'editLaneLocation' || this.data.openPop=='editLaneLocationBidQuest') {
      const body = document.getElementsByTagName('body')[0];
      body.classList.add('userProfile_sugBox');
    }

    if (this.data.openPop === 'routeVerify') {
      this.verifiedRoute(this.data.contain);
    }

    if (this.data.openPop === 'showReviews') {
      this.getRatingList();
    }
    

    this.storageService = this.sharedService.getStorageServices();
    this.serverPathForUrl = environment.serverPathForUrl;
    let configValue = this.sharedService.getConfig();
    if (configValue !== undefined) {
      this.getConfigSet(configValue);
    }

    this.commonService.configData.subscribe((userData) => {
      this.getConfigSet(userData);
    });
    this.shipperContract =
      'https://s3.us-east-2.amazonaws.com/staticdev.laneaxis.com/shipper/contract_document/laneAxisShipperCarrierContractTemplate.pdf';
    this.userType = localStorage.getItem('user_type');
    if (this.data.allRecord && this.data.allRecord.contractDocument) {
      this.documentCheck = true;
    } else if (
      this.data.allRecord &&
      this.data.allRecord.coiDoc &&
      !this.data.allRecord.coiExpired
    ) {
      this.documentCheck = true;
    } else {
      this.documentCheck = false;
    }

    let days = 1;
    this.minDateBefore.setDate(this.minDateBefore.getDate() + days);

    this.uploadState = true;
    this.uploadFileDownload =
      environment.imagePathUrl +
      '/carrier/batch/1436_20200424_124216_shipment Sample1 - Copy.xlsx';
    this.commonImage = AppSettings.commonImage;

    this.addDocUpload = this.formBuilder.group({
      document: ['', [Validators.required]],
      description: ['', [Validators.required]],
      driverId: [this.data.driverId, [Validators.required]],
    });


    this.EditLaneForm = this.formBuilder.group({
      editLocation: [this.data.title, [Validators.required]],
    });

    this.editBidQuoteForm = this.formBuilder.group({
      editLocation: [this.data.title, [Validators.required]],
    });

    this.searchRestriction = environment.searchMultipleRestriction;
    this.pickupTimeArr = StatusSetting.pickUpTime;
    this.dropTimeArr = StatusSetting.dropOffTime;
    this.pickupTZ = StatusSetting.newTimeZoneList;
    this.dropTZ = StatusSetting.newTimeZoneList;
    this.usTimeZoneList = StatusSetting.NewStateTimeZoneList;
    this.zipCodeErrorMsg = AppSettings.zipCodeError;
    
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder();
    });
    this.checkTimePeriod();
    this.paymentCard = this.formBuilder.group({
      cardName: ['',[Validators.required, Validators.maxLength(64)],],
    });

    this.emailOtpVerify = this.formBuilder.group({
      email: [this.data.email, [Validators.required]],
      otp: ['', [Validators.required, Validators.pattern(/^-?([1-9]\d*)?$/)]],
    });

    this.contractForm = this.formBuilder.group({
      contractDocument: [, [Validators.required]],
    });

    this.certifcateVerify = this.formBuilder.group({
      certificate: ['', [Validators.required]],
      insurance_expiry_date: ['', [Validators.required]],
    });

    this.phoneOtpVerify = this.formBuilder.group({
      phone: [
        this.data.phone,
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern(this.numOnly),
        ],
      ],
      otp: ['', [Validators.required]],
    });
    this.wayPointEditForm = this.formBuilder.group({
      pickupLocation: [
        '',
        [
          Validators.required,
          Validators.maxLength(256),
          Validators.pattern(/^[^\s].*/),
        ],
      ],
      pickupPincode: [
        '',
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(8),
          Validators.pattern(/^[a-z0-9]+([-\s]{1}[a-z0-9]+)*$/i)
        ],
      ],
      pickupDate: ['', [Validators.required]],
      pickupTime: ['', [Validators.required]],
      pickupTimeZone: ['', [Validators.required]],
      pickupCountryCode: [''],
      pickupCountryName: [''],
      pickPhoneNumber: [
        '',
        [
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern(/^([0-9]+\s?)*$/),
        ],
      ],
      pickInstructions: ['', [Validators.maxLength(516)]],
      heightInches: [''],
      lengthInches: [''],
      widthInches: [''],
      weight: ['', [Validators.pattern(/^\d+(\.\d+)?$/)]],
      quantity: [
        '',
        [Validators.maxLength(10), Validators.pattern(/^\d+(\.\d+)?$/)],
      ],
      wayPointType: ['pickup'],
      pickupBussinessName: ['', [Validators.maxLength(64)]],
      pickupContactName: ['', [Validators.maxLength(64)]],
      commodityForm: this.formBuilder.array([]),
    });

    this.wayPointAddForm = this.formBuilder.group({
      pickupBussinessName: ['', [Validators.maxLength(64)]],
      pickupLocation: [
        '',
        [
          Validators.required,
          Validators.maxLength(256),
          Validators.pattern(/^[^\s].*/),
        ],
      ],
      pickupPincode: [
        '',
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(8),
          Validators.pattern(/^[a-z0-9]+([-\s]{1}[a-z0-9]+)*$/i)
        ],
      ],
      pickupDate: ['', [Validators.required]],
      pickupTime: ['', [Validators.required]],
      pickupTimeZone: ['', [Validators.required]],
      pickupContactName: ['', [Validators.maxLength(64)]],
      pickupCountryCode: [''],
      pickupCountryName: [''],
      pickPhoneNumber: [
        '',
        [
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern(/^([0-9]+\s?)*$/),
        ],
      ],
      wayPointType: [this.data.type],
      pickInstructions: ['', [Validators.maxLength(516)]],

      heightInches: [''],
      lengthInches: [''],
      widthInches: [''],
      weight: ['', [Validators.pattern(/^\d+(\.\d+)?$/)]],
      quantity: ['',[Validators.maxLength(10), Validators.pattern(/^\d+(\.\d+)?$/)]],
      commodityForm: this.formBuilder.array([]),
    });
    if(this.data.openPop ==='editMultiStop' || this.data.openPop ==='addMultiStopRoute'){
    let currentDate = moment(new Date()).format('YYYY-MM-DD'); 
    this.setLastWayPointRangeWithTimeZoneForAddNewWaypoint(currentDate);

    }

    this.carrierPA = this.formBuilder.group({
      addressType: ['', [Validators.required]],
      street: ['', [Validators.required, Validators.maxLength(254)]],
      addressline2: ['', [Validators.maxLength(254)]],
      stateBy: ['', [Validators.required]],
      cityBy: [
        '',
        [
          Validators.required,
          Validators.pattern(this.alphmenumb),
          Validators.maxLength(32),
        ],
      ],
      zipCode: [
        '',
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(8),
          Validators.pattern(/^[a-z0-9]+([-\s]{1}[a-z0-9]+)*$/i)
        ],
      ],
      country: ['US'],
    });
    this.emailSendForm = this.formBuilder.group({
      email: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.maxLength(128),
          Validators.pattern(this.emailPattern),
        ],
      ],
      tabType: [this.data.type],
      id: [this.data.id],
    });

    this.sendInvoiceFrom = this.formBuilder.group({
      emailAddress: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.maxLength(128),
          Validators.pattern(this.emailPattern),
        ],
      ],
      message: [],
      id: [this.data.id],
    });


    if (this.data.openPop === 'addPaymentCardPopup') {
   
      this.stripe = Stripe(environment.stripePublicKey);
      this.showAddPaymentDetailPopup();
    }
    if (
      this.data.type === 'Mailing Address' ||
      this.data.type === 'Physical Address' ||
      this.data.type === 'Both Address'
    ) {
      if (this.data.type === 'Mailing Address') {
        this.mailAdd = true;
      }
      if (this.data.type === 'Physical Address') {
        this.phpAdd = true;
      }
      if (this.data.type === 'Both Address') {
        this.mailAdd = true;
        this.phpAdd = true;
      }
      this.states = StatusSetting.state;
    }
    this.addressForm = this.formBuilder.group({
      addressType: [this.data.type, [Validators.required]],
      street: [
        this.data.addressLine1,
        [Validators.required, Validators.maxLength(254)],
      ],
      addressline2: [this.data.addressLine2, [Validators.maxLength(254)]],
      stateBy: [this.data.state, [Validators.required]],
      cityBy: [
        this.data.city,
        [
          Validators.required,
          Validators.pattern(this.alphmenumb),
          Validators.maxLength(32),
        ],
      ],
      zipCode: [
        this.data.zipCode,
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(8),
          Validators.pattern(/^[a-z0-9]+([-\s]{1}[a-z0-9]+)*$/i)
        ],
      ],
      country: [this.data.country],
    });

    if (this.data.openPop === 'chooseEqupments') {
      this.getUserRecord();
      this.currentDate = this.datePipe.transform(new Date(), 'yyyy/MM/dd');
    }
    if (this.data.openPop === 'editMultiStop') {
      this.populateSelectedWayPoint();
   
    }

  }

  get commodityForm() {
    if (this.data.openPop === 'addMultiStopRoute'){
      return this.wayPointAddForm.get('commodityForm') as FormArray;
    }
    if (this.data.openPop === 'editMultiStop'){
      return this.wayPointEditForm.get('commodityForm') as FormArray;
    }
  }

  priceFixed(price: any) {
    const newPrice = (price / 100).toFixed(2);
    return newPrice;
  }

  getConfigSet(configValue: any) {
    this.shipmentPingRate = configValue.onShipmentPingRate;
    this.equipmentListing = configValue.equipmentTypes;
    this.countryList = configValue.countries;
    this.defualtPickupCountryFlag = this.countryList[0].flag;
  }

  // certifcate insurace
  onSelectedFileCertifcate(event) {
    if (event.target.files.length === 0) return;
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/pdf\/*/) === null) {
      this.imagmessage = 'Only supported pdf file.';
      this.imgURL = '';
      return;
    }
    var reader = new FileReader();
    this.imagePath = event.target.files;
    this.name = event.target.files[0].name;
    this.disbu = false;
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
    this.imagmessage = '';
    if (event.target.files.length) {
      const file = event.target.files[0];
      this.certifcateVerify.get('certificate').setValue(file);
    }
  }


  setLastWayPointRangeWithTimeZone(currentDate){
    this.enableTimeZones();
    if(moment(this.data.wayPoints[this.selectedIndex-1].date).isBefore(new Date(), 'day'))
    this.minDate = new Date(); 
    else
    this.minDate = new Date(this.data.wayPoints[this.selectedIndex-1].date.replaceAll('-', '/'));
    this.maxDate = null;
    if(moment(currentDate).isSame(this.data.wayPoints[this.selectedIndex-1].date, 'day')) {
      let minTimeslot =  this.data.wayPoints[this.selectedIndex-1].timeSlot;
      let minIndex = this.getIndex(minTimeslot);  
      let maxIndex =  StatusSetting.pickUpTime.length; 
      this.setTimeSlotDropDownForCurrentSelectedDate(minIndex+1, maxIndex);
    }
    else
    this.pickupTimeArr = StatusSetting.pickUpTime; 
    this.checkIfTodaysDate(currentDate);
}

setLastWayPointRangeWithTimeZoneForAddNewWaypoint(currentDate){
    this.enableTimeZones();
    if(this.data.wayPoints && this.data.wayPoints.length > 0){
    if(moment(this.data.wayPoints[this.data.wayPoints.length-1].date).isBefore(new Date(), 'day'))
    this.minDate = new Date(); 
    else
    this.minDate = new Date(this.data.wayPoints[this.data.wayPoints.length-1].date.replaceAll('-', '/'));
    this.maxDate = null;
    if(moment(currentDate).isSame(this.data.wayPoints[this.data.wayPoints.length-1].date, 'day')) {
      let minTimeslot =  this.data.wayPoints[this.data.wayPoints.length-1].timeSlot;
      let minIndex = this.getIndex(minTimeslot);  
      let maxIndex =  StatusSetting.pickUpTime.length; 
      this.setTimeSlotDropDownForCurrentSelectedDate(minIndex+1, maxIndex);
    }
    else
    this.pickupTimeArr = StatusSetting.pickUpTime; 
    this.checkIfTodaysDate(currentDate);
  }
}

enableTimeZones(){
  StatusSetting.pickUpTime.map((obj)=>{
    obj.disabled = false; 
  });
}

  setFirstWayPointRangeWithTimeZone(currentDate){
    this.enableTimeZones();
    this.minDate = new Date(); //set min date to current one 
    if(moment(this.data.wayPoints[this.selectedIndex+1].date).isBefore(new Date(), 'day'))
    this.maxDate = null; 
    else
    this.maxDate = new Date(this.data.wayPoints[this.selectedIndex+1].date.replaceAll('-', '/'));
    if(moment(currentDate).isSame(this.data.wayPoints[this.selectedIndex+1].date, 'day')) {
      let maxTimeslot = this.data.wayPoints[this.selectedIndex+1].timeSlot;
      let maxIndex = this.getIndex(maxTimeslot); 
      this.setTimeSlotDropDownForCurrentSelectedDate(0, maxIndex);
    }
    else
    this.pickupTimeArr = StatusSetting.pickUpTime; 
    this.checkIfTodaysDate(currentDate); 
  }

  setTimeSlotDropDownForCurrentSelectedDate(minTimeSlotIndex: any, maxTimeSlotIndex: any){
    let pickupArray = StatusSetting.pickUpTime; 
    if(pickupArray && pickupArray.length > 0){
      this.pickupTimeArr = StatusSetting.pickUpTime.slice(minTimeSlotIndex,maxTimeSlotIndex);
    }
    if (this.pickupTimeArr && this.pickupTimeArr.length === 0){
      this.isTimeSloteAvailble = true;
      this.noTimeSloteError = "No time slot is available for this date. Please select the next available date.";
    }
    else{
      this.isTimeSloteAvailble = false; 
    }
  }

  getIndex(timeSlot){
    const index =  StatusSetting.pickUpTime.findIndex((x: { time: any; }) => x.time === timeSlot);
    return index; 
  }
  
  checkTimeSlot(currentDate,previousDate, nextDate){
    //2. way point date != previous date and way point date == next date
    if(!(moment(currentDate).isSame(previousDate, 'day')) && 
    moment(currentDate).isSame(nextDate, 'day')) {
      let maxTimeslot = this.data.wayPoints[this.selectedIndex+1].timeSlot;
      let maxIndex = this.getIndex(maxTimeslot); 
      this.setTimeSlotDropDownForCurrentSelectedDate(0, maxIndex);
    }
    // 4 way point date == previous date and way point date !== next date
    else if((moment(currentDate).isSame(previousDate, 'day')) && !(moment(currentDate).isSame(nextDate, 'day'))) {
      let minTimeslot = this.data.wayPoints[this.selectedIndex-1].timeSlot; 
      let minIndex = this.getIndex(minTimeslot); 
      let maxIndex =  StatusSetting.pickUpTime.length; 
      this.setTimeSlotDropDownForCurrentSelectedDate(minIndex+1, maxIndex);
    }
    // 3. way point date == previous date and way point date == next date
    else if((moment(currentDate).isSame(previousDate, 'day')) && (moment(currentDate).isSame(nextDate, 'day'))) {
      let minTimeslot = this.data.wayPoints[this.selectedIndex-1].timeSlot; 
      let minIndex = this.getIndex(minTimeslot); 
      let maxTimeslot = this.data.wayPoints[this.selectedIndex+1].timeSlot;
      let maxIndex = this.getIndex(maxTimeslot); 
      this.setTimeSlotDropDownForCurrentSelectedDate(minIndex+1, maxIndex);
    }
    else{
     this.pickupTimeArr = StatusSetting.pickUpTime; 
    }
  }

  setMinMaxDate(currentDate){
    //first way point 
    if (this.selectedIndex ===  0){
      this.setFirstWayPointRangeWithTimeZone(currentDate);
    }
    //second to second last way point 
    if(this.selectedIndex > 0 &&  (this.selectedIndex <= this.data.wayPoints.length-2)){
      this.enableTimeZones();
      if(moment(this.data.wayPoints[this.selectedIndex-1].date).isBefore(new Date(), 'day'))
      this.minDate = new Date(); 
      else
      this.minDate = new Date(this.data.wayPoints[this.selectedIndex-1].date.replaceAll('-', '/'));
      if(moment(this.data.wayPoints[this.selectedIndex+1].date).isBefore(new Date(), 'day'))
      this.maxDate = null; 
      else 
      this.maxDate = new Date(this.data.wayPoints[this.selectedIndex+1].date.replaceAll('-', '/'));
      let previousDate = this.data.wayPoints[this.selectedIndex-1].date; 
      let nextDate = this.data.wayPoints[this.selectedIndex+1].date; 
      this.checkTimeSlot(currentDate,previousDate, nextDate ); 
      this.checkIfTodaysDate(currentDate); 
    }
    //last way point 
    if(this.selectedIndex > 0 && this.selectedIndex === this.data.wayPoints.length-1){
      this.setLastWayPointRangeWithTimeZone(currentDate); 
    }
  }

  populateSelectedWayPoint() {
    this.selectedIndex = this.data.index;
    let newPickdate: string | number | Date;
    if (this.data.selectedWayPointToEdit.date) {
      newPickdate = this.data.selectedWayPointToEdit.date.replaceAll('-', '/');
    } else {
      newPickdate = null;
    }
    let currentDate = this.data.wayPoints[this.selectedIndex].date;;
    this.setMinMaxDate(currentDate);

    this.wayPointEditForm = this.formBuilder.group({
      pickupBussinessName: [
        this.data.selectedWayPointToEdit.businessName,
        [Validators.maxLength(64)],
      ],
      pickupLocation: [
        this.data.selectedWayPointToEdit.locationLongName,
        [
          Validators.required,
          Validators.maxLength(256),
          Validators.pattern(/^[^\s].*/),
        ],
      ],
      pickupPincode: [
        this.data.selectedWayPointToEdit.zip,
        [Validators.required, Validators.minLength(4), Validators.maxLength(8), Validators.pattern(/^[a-z0-9]+([-\s]{1}[a-z0-9]+)*$/i)],
      ],
      pickupDate: [new Date(newPickdate), [Validators.required]],
      pickupTime: [
        this.setTimeSlot(this.data.index),
        [Validators.required],
      ],
      pickupTimeZone: [
        this.data.selectedWayPointToEdit.timeZone,
        [Validators.required],
      ],
      pickupCountryCode: [this.data.selectedWayPointToEdit.countryCode],
      pickPhoneNumber: [
        this.data.selectedWayPointToEdit.phone,
        [
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern(/^([0-9]+\s?)*$/),
        ],
      ],
      pickupContactName: [
        this.data.selectedWayPointToEdit.contactName,
        [Validators.maxLength(64)],
      ],
      pickInstructions: [
        this.data.selectedWayPointToEdit.specialRequirement,
        [Validators.maxLength(516)],
      ],
      wayPointType: [this.data.selectedWayPointToEdit.type, []],
      quantity: [
        this.data.selectedWayPointToEdit.quantity,
        [Validators.maxLength(10), Validators.pattern(/^\d+(\.\d+)?$/)],
      ],
      weight: [
        this.data.selectedWayPointToEdit.weight,
        [Validators.maxLength(10), Validators.pattern(/^\d+(\.\d+)?$/)],
      ],
      lengthInches: [this.data.selectedWayPointToEdit.length],
      widthInches: [this.data.selectedWayPointToEdit.width],
      heightInches: [this.data.selectedWayPointToEdit.height],
      commodityForm: this.formBuilder.array([]),
    });


    if (this.data.selectedWayPointToEdit.commodity && this.data.selectedWayPointToEdit.commodity.length > 0) {
      this.waypointEditFormArray(this.data.selectedWayPointToEdit.commodity);
      this.ifPickupLoadInfo = true;
    } else {
      this.ifPickupLoadInfo = false;
    }
  if(this.wayPointEditForm.get('pickPhoneNumber').value && this.wayPointEditForm.get('pickupCountryCode').value){
      if(this.data.selectedWayPointToEdit.countryCode === "CA"){
        this.defualtPickupCountryFlag = this.countryList[1].flag;
        this.newSelectPickupCountryCode = this.countryList[1].countryAbbr;
      } else if (this.data.selectedWayPointToEdit.countryCode === "MX"){
        this.defualtPickupCountryFlag = this.countryList[2].flag;

        this.newSelectPickupCountryCode = this.countryList[2].countryAbbr;
      } else if(this.data.selectedWayPointToEdit.countryCode === "US"){

        this.defualtPickupCountryFlag = this.countryList[0].flag;
        this.newSelectPickupCountryCode = this.countryList[0].countryAbbr;

      }
      this.wayPointEditForm.get('pickupCountryCode').setValue(this.data.selectedWayPointToEdit?.countryCode);
    }
    else {
         this.defualtPickupCountryFlag = this.countryList[0].flag;
      this.newSelectPickupCountryCode = this.countryList[0].countryAbbr;
    }
  }
 

  
  setTimeSlot(index){
    let i = this.getIndex(this.data.wayPoints[index].timeSlot); 
    return StatusSetting.pickUpTime[i];
  }

waypointEditFormArray(item) {
  const formArray = new FormArray([]);
  for (let record of item) {
    formArray.push(this.formBuilder.group({
      commodity: [record.commodity, [Validators.required,Validators.maxLength(128)]],
      packagingType: [record.packagingType, [Validators.required]],
      quantity: [record.quantity, [Validators.required]],
      dimension: [record.dimension, ],
      length: [record.dimension.split('x')[0], ],
      width: [record.dimension.split('x')[1], ],
      height: [record.dimension.split('x')[2], ],
      weight: [record.weight, ],
      value: [record.value, ],
      sku: [record.sku, [Validators.maxLength(15), Validators.pattern("^[A-Za-z0-9_-]*$")]],
      media: [record.media, ],
      handleWithCare:[record.handleWithCare],
    }));
  }
  this.wayPointEditForm.setControl('commodityForm', formArray);
}

  showNoRoute() {
    const dialogRef = this.dialog.open(PopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      data: {
        openPop: 'noRoutePlan'
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.wayPointEditForm.get('pickupLocation').setValue(null);
        this.wayPointEditForm.get('pickupPincode').setValue(null);
        this.wayPointAddForm.get('pickupLocation').setValue(null);
        this.wayPointAddForm.get('pickupPincode').setValue(null);
      }
    });
  }

  showCarRoute() {
    const dialogRef = this.dialog.open(PopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      data: {
        openPop: 'carRoutePlan'
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.wayPointEditForm.get('pickupLocation').setValue(null);
        this.wayPointEditForm.get('pickupPincode').setValue(null);
        this.wayPointAddForm.get('pickupLocation').setValue(null);
        this.wayPointAddForm.get('pickupPincode').setValue(null);
      }
    });
  }

  async getRouteCompetability(newlatitude,newlongitude,getCurrentLatitude,getCurrentLongitude) {
    this.loading=true;
    return await this.HereMapsService8.getRouteFound(newlatitude,newlongitude,getCurrentLatitude,getCurrentLongitude, 'truck').then(
      (hereMapsRoutingResponse) => {
        let miles:any; 
        if(hereMapsRoutingResponse['routes'][0]?.sections[0].summary.length==0){
          miles = 1 ;
         }else{
          miles = Math.floor(hereMapsRoutingResponse['routes'][0]?.sections[0].summary.length / 1609.344)
         }
         this.loading=false;
      return { miles};
      },
       (error) => {
        this.loading=false;
        return  this.HereMapsService8.getRouteFound(newlatitude,newlongitude,getCurrentLatitude,getCurrentLongitude, 'car').then(
          (hereMapsRoutingResponse) => {
            this.showCarRoute();   // Show Car popup
          },
          (error) => {
             this.showNoRoute();       // Show no route popup,  set pickup loc null
          })
      });
  }

  pickUpLocAuto($event: GermanAddress) {
    this.newSelectedLatitude = $event.geoLocation.latitude;
    this.newSelectedLongitude = $event.geoLocation.longitude;
    if (this.data?.previousWayPointToEdit?.latitude){
    this.previousSelectedLatitude = this.data?.previousWayPointToEdit?.latitude;
    }
    if (this.data?.previousWayPointToEdit?.longitude){
    this.previousSelectedLongitude = this.data?.previousWayPointToEdit?.longitude;
    }

    for (let x in this.usTimeZoneList) {
      if ($event.state.short === x) {
        this.timeZoneSelectedPick = this.usTimeZoneList[x];
      }
    }
    this.wayPointAddForm.get('pickupLocation').setValue($event.displayAddress);
    this.wayPointAddForm.get('pickupLocation').setValue($event.displayAddress);

    if ($event.country.short === 'US') {
      this.newPickupCountryName = $event.country.short;
      this.selectedCountry = this.countryList.filter(
        (item) => item.country === $event.country.short);
    } else {
      this.selectedCountry = this.countryList.filter(
        (item) => item.country === $event.country.long);
      this.newPickupCountryName = $event.country.long;
    }
   
    if(this.data?.selectedWayPointToEdit?.id){
       if(this.data?.previousWayPointToEdit?.countryCode && this.data.selectedWayPointToEdit?.countryCode){
        this.defualtPickupCountryFlagAdd = this.selectedCountry[0].flag;
        this.newSelectPickupCountryCodeAdd = this.selectedCountry[0].countryAbbr;
      }
    }
    else
      if(this.newPickupCountryName=='Canada'){
      let countryCode='CA';
      this.defualtPickupCountryFlag = this.selectedCountry[0].flag;
      this.newSelectPickupCountryCode = countryCode;
      this.wayPointEditForm.get('pickupCountryCode').setValue(countryCode);
      }
      else if(this.newPickupCountryName=='Mexico'){
      let countryCode='MX'; 
      this.defualtPickupCountryFlag = this.selectedCountry[0].flag;
      this.newSelectPickupCountryCode = countryCode;
      this.wayPointEditForm.get('pickupCountryCode').setValue(countryCode);
      }
      else if(this.newPickupCountryName=='US'){
      let countryCode='US'; 
      this.defualtPickupCountryFlag = this.selectedCountry[0].flag;
      this.newSelectPickupCountryCode = countryCode;
      this.wayPointEditForm.get('pickupCountryCode').setValue(countryCode);
    }
    

    this.wayPointAddForm.get('pickupCountryCode').setValue(this.selectedCountry[0].countryAbbr);
    this.wayPointAddForm.get('pickupCountryName').setValue(this.selectedCountry[0].country);
    if ($event.postalCode) {
      this.wayPointEditForm.get('pickupPincode').setValue($event?.postalCode);
    } else {
      this.wayPointEditForm.get('pickupPincode').setValue('');
    }
    this.pickupFullLocation = $event.displayAddress;
    this.pickupCity = $event.locality.short ? $event.locality.short : $event.vicinity;
    this.pickupState = $event.state.long;
    this.pickupLatitude = $event.geoLocation.latitude;
    this.pickupLongitude = $event.geoLocation.longitude;
    this.sameLocation = false;
    this.pickAutoNot = false;

    this.pickupChangeDetected = false;
    if (this.pickupCity !== undefined) {
      this.pickupShortName = this.pickupCity;
    } else {
      if (this.pickupState) {
        this.pickupShortName = this.pickupState;
      } else {
        this.pickupShortName = '';
      }
    }

    if (this.pickupShortName !== '') {
      this.pickupShortName = this.pickupShortName + ', ';
    }
    if (this.pickupState !== undefined) {
      this.pickupShortName = this.pickupShortName + this.pickupState;
    }

    this.pickUp = $event.displayAddress;
  

    if (this.pickUp && !this.pickupCity) {
      this.shortNameError = 'Please select city level address.';
      this.shortNameValue = true;
    }

    if (this.pickUp && this.pickupCity) {
      this.shortNameError = '';
      this.shortNameValue = false;
    }

    if (this.disabledAssign || this.sameLocation || this.shortNameValue) {
      this.checkErrorMessage = true;
    } else {
      this.checkErrorMessage = false;
    }
    if (this.shortNameValue === true || this.shortNameDropValue === true) {
      this.checkErrorMessage = true;
    }

  }

  noAutoselection(loc: any) {
    if (loc === 'pick') {
      this.pickupChangeDetected = true;
    }
    if (loc === 'drop') {
      this.dropupChangeDetected = true;
    }
    if(loc == 'editlane')
    this.editLocationChangeDetected = true;
  }

  sleep(milliseconds) {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  }

  noFocusOut(pickdrop: any) {
    this.sleep(500);
    if (pickdrop === 'pick' && this.pickupChangeDetected) {
      this.pickAutoNot = true;
    }
    if (pickdrop === 'drop' && this.dropupChangeDetected) {
      this.dropAutoNot = true;
    }
    if (pickdrop === 'editlane' && this.editLocationChangeDetected) {
      this.editLaneAutoNot = true;
    }
  }

  pickAutoCompleteSelected(result: PlaceResult) {
    let checklocality=false;
    for (let i = 0; i < result.address_components.length; i++) {
      if (result.address_components[i].types[0] == "locality") {
        if (result.address_components[i].long_name) {
          this.shortNameError = '';
          this.shortNameValue = false;        } 
          checklocality=true; 
      }else if (result.address_components[i].types[0] == "postal_code") {
        if (result.address_components[i].long_name) {
          this.wayPointAddForm.get('pickupPincode').setValue(result.address_components[i].long_name);
        }
      //  break;
      } else{
        this.wayPointAddForm.get('pickupPincode').setValue('');
        if(!checklocality){
          this.shortNameError = 'Please select city level address.';
          this.shortNameValue = true;
        }
      }
    }
 
    this.pickupCityError = '';
    if (this.disabledAssign  || this.shortNameValue) {
      this.checkErrorMessage = true;
    } else {
      this.checkErrorMessage = false;
    }
    if (this.shortNameValue === true || this.shortNameDropValue === true) {
      this.checkErrorMessage = true;
    }
  }
  

  pickDateEventForAddWaypoint(event1: any) {
    this.noTimeSloteError = "";
    let currentDate = moment(event1.value).format('YYYY-MM-DD'); 
    this.setLastWayPointRangeWithTimeZoneForAddNewWaypoint(currentDate); 
  }

  pickDateEvent(event1: any) {
    this.isTimeSloteAvailble = false;
    let currentDate = moment(event1.value).format('YYYY-MM-DD');
    this.checkIfTodaysDate(event1); 
    this.setMinMaxDate(currentDate); 
  }

  checkIfTodaysDate(event1){
    let selectedFormattedDate = moment(event1).format('YYYY-MM-DD');
    let today = new Date(); 
    let todayFormattedDate = moment(today).format('YYYY-MM-DD').valueOf();
    let ctTime = moment(today, 'hh:mm');
    if (todayFormattedDate === selectedFormattedDate) {
     this.pickupTimeArr.map((item)=>{
       let timeSlot   = item.time.split('-');
       let timeSlotToCompare =  moment(timeSlot[0], 'hh:mm'); 
       if( ctTime.isSameOrAfter(timeSlotToCompare)){
         item.disabled = true; 
       }
       else{
         item.disabled = false; 
       }
     });
    }
 }


  gaTrackOnEdit(type) {
    const key = 'key' + this.keyCounter + '=' + type;
    const dimension = this.dimensions !== '' ? '&' + key : key;
    this.dimensions = this.dimensions + dimension; // this.dimensions.includes(key)
    this.keyCounter++;
  }

  keyInstructionPress(event: any, type: any) {
    let valueGet = event.target.value;
    if (valueGet.length === 128) {
      if (type === 'pickup') {
        this.pickupInstructionError = true;
      } else {
        this.dropOffInstructionError = true;
      }
    } else {
      if (type === 'pickup') {
        this.pickupInstructionError = false;
      } else {
        this.dropOffInstructionError = false;
      }
    }
  }

  setScrollTop() {
    let top = document.getElementById('topnav');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

  checkTimePeriod() {
    let now = new Date();
    const currentPickDate = moment(now).format('YYYY-MM-DD');
    const ctTime: any = moment(now).format('H:m');
    let checkeCurrentTime = ctTime.split(':');

    if (
      checkeCurrentTime[0].match(/20/g) ||
      checkeCurrentTime[0].match(/21/g) ||
      checkeCurrentTime[0].match(/22/g) ||
      checkeCurrentTime[0].match(/23/g)
    ) {
      let days = 1;
      this.minDate.setDate(this.minDate.getDate() + days);
    }
  }

  
  reviewAndRating() {
    this.router.navigate(['/shipment/view/' + this.data.shipmentDetail.id + '/rating']);
    this.dialogRef.close({ event: 'Ok' });
  }

  ratingTab(event: any) {
    if (event === 'Relavent') {
      this.currentStatus = 'relavent';
      this.getRatingList();
    } else if (event === 'Most Recent') {
      this.currentStatus = 'mostRecent';
      this.getRatingList();
    } else if (event === 'Highest') {
      this.currentStatus = 'highest';
      this.getRatingList();
    } else if (event === 'Lowest') {
      this.currentStatus = 'lowest';
      this.getRatingList();
    }
  }

  getRatingList() {
    this.skeletonLoader = true;
    let uri = null;
    let paramsNew = {
      limit: 10,
      tabType: this.currentStatus,
    }
    paramsNew[this.data.ratingOwnerDetail.key] = this.data.ratingOwnerDetail.id;
    if (paramsNew) uri = this.commonService.getAPIUriFromParams(paramsNew);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.RATING.RATINGLIST,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe(
      (ServerRes) => {

        if (ServerRes.response) {
          this.rateList = ServerRes.response;
    this.skeletonLoader = false;
          
        } else {
          this.rateList = [];
    this.skeletonLoader = false;
        }
      },
      (err) => {
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_g',
          'error',
          'Unexpected Error',
          AppSettings.ERROR
        );
    this.skeletonLoader = false;

      }
    );
  }

  generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }

  carRouteOption(carRouteState: string): void {
    let popupName;
    if (carRouteState === 'true') {
      popupName = 'forceCarRouteCarrier';
    } else if (carRouteState === 'false') {
      popupName = 'noRouteCarrier';
    }

    const dialogRef = this.dialog.open(PopupComponent, {
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      data: {
        openPop: popupName,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'carSelected') {
          this.addshipment.forceCarRoute = true;
          this.checkRouteSubmitForm();
        } else if (result.event === 'addressChange') {
        }
      }
    });
  }

  getRouteMiles() {
    return new Promise((resolve, reject) => {
      let uri = null;
      let newParams;
       if (this.addshipment.forceCarRoute === true) {
        newParams = {
          pickupLatitude: this.pickupLatitude,
          pickupLongitude: this.pickupLongitude,
          dropLatitude: this.deliveryLatitude,
          dropLongitude: this.deliveryLongitude,
          forceCarRoute: this.addshipment.forceCarRoute,
        };
      } else  {
        newParams = {
          pickupLatitude: this.pickupLatitude,
          pickupLongitude: this.pickupLongitude,
          dropLatitude: this.deliveryLatitude,
          dropLongitude: this.deliveryLongitude,
        };
      }

      if (newParams) uri = this.commonService.getAPIUriFromParams(newParams);
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.SHIPMENT.GETMILES,
        uri: uri,
      };
      this.commonService.getList(APIparams).subscribe(
        async (ServerRes) => {
          if (ServerRes.success === true) {
            resolve(ServerRes.response);
          } else {
            resolve(ServerRes.response);
          }
        },
        (error) => {
          resolve(error.error);
        }
      );
    });
  }

 async routeDelete(i) {
  this.loading=true;
    if (this.data.wayPoints) var count = 0;
    var type = this.data.wayPoints[i].type;
    for (let i = 0; i <= this.data.wayPoints.length - 1; i++) {
      if (this.data.wayPoints[i].type === type) {
        count = count + 1;
      }
    }
    if (count > 1) {
      this.sharedService.setGuardValue();
      let startingIndex = i + 1;
      while (startingIndex <= this.data.wayPoints.length - 1) {
        if (this.data.wayPoints[startingIndex].type === type) {
          this.data.wayPoints[startingIndex].locationOrder =
            this.data.wayPoints[startingIndex].locationOrder - 1;
        }
        startingIndex++;
      }
      this.data.wayPoints.splice(i, 1);
    } else {
      this.pickDropAlert();
    }
    this.dialogRef.close({ event: 'Ok' });
  }

  pickDropAlert() {
    const dialogRef = this.dialog.open(PopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      data: {
        openPop: 'pickAndDropAlert',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }

  removeCardLocation(i) {
    this.dialogRef.close({ event: 'cardRemoveSuccessFully' });
  }

  getDateFormat(inputDate) {
    if (inputDate !== '' && typeof inputDate !== undefined) {
      return moment(inputDate).format('MM/DD/YYYY');
    } else {
      return '';
    }
  }

  changeLocationType(event: any) {
    if (event.value === 'dropoff') {
      this.data.type = 'dropoff';
    }
    if (event.value === 'pickup') {
      this.data.type = 'pickup';
    }
    if (this.originalRouteType !== this.data.type) {
      this.isWayPointTypeChanged = true;
    }
  }

  async wayPointAddFormSubmit({ value, valid }) {
    this.communityDataSubmiited=true;
    this.isTimeSloteExpired = value.pickupTime.disabled == true ? true :  false; 
    if (valid  && !this.isTimeSloteExpired && !this.isTimeSloteAvailble) {
      let sequence;
      if (this.data?.type === 'pickup') {
        sequence = this.data.wayPoints.length - 1 + 1;
      }
      if (this.data?.type === 'dropoff') {
        sequence = this.data.wayPoints.length + 1;
      }

      let addNewWayPoint: any = {};

      if (this.wayPointAddForm.get('pickupBussinessName').value) {
        addNewWayPoint.businessName = this.wayPointAddForm.get('pickupBussinessName').value;
      }
      addNewWayPoint.city = this.pickupCity ? this.pickupCity : '';
  
      if (this.wayPointAddForm.get('pickupContactName').value) {
        addNewWayPoint.contactName =
          this.wayPointAddForm.get('pickupContactName').value;
      }
      if (this.wayPointAddForm.get('pickupCountryCode').value) {
        addNewWayPoint.countryCode = this.wayPointAddForm.get('pickupCountryCode').value;
      }

        addNewWayPoint.countryName = this.newPickupCountryName;

      addNewWayPoint.date = this.getDateFormat(
        this.wayPointAddForm.get('pickupDate').value.toString()
      );

      if (this.wayPointAddForm.get('heightInches').value) {
        addNewWayPoint.height = this.wayPointAddForm.get('heightInches').value;
      }
      addNewWayPoint.latitude = this.pickupLatitude ? this.pickupLatitude : '';
      if (this.wayPointAddForm.get('lengthInches').value) {
        addNewWayPoint.length = this.wayPointAddForm.get('lengthInches').value;
      }
      addNewWayPoint.locationLongName =
        this.wayPointAddForm.get('pickupLocation').value;
      addNewWayPoint.locationOrder = this.locationOrder
        ? this.locationOrder
        : '';
      addNewWayPoint.longitude = this.pickupLongitude
        ? this.pickupLongitude
        : '';
      if (this.wayPointAddForm.get('pickPhoneNumber').value) {
        addNewWayPoint.phone =
          this.wayPointAddForm.get('pickPhoneNumber').value;
      }
      if (this.wayPointAddForm.get('quantity').value) {
        addNewWayPoint.quantity = this.wayPointAddForm.get('quantity').value;
      }
      addNewWayPoint.sequence = sequence;
        addNewWayPoint.specialRequirement =
          this.wayPointAddForm.get('pickInstructions').value;
      addNewWayPoint.state = this.pickupState ? this.pickupState : '';
      addNewWayPoint.timeSlot = this.wayPointAddForm.get('pickupTime').value.time;
      addNewWayPoint.timeZone =
        this.wayPointAddForm.get('pickupTimeZone').value;
      addNewWayPoint.type = this.wayPointAddForm.get('wayPointType').value;

      if (this.wayPointAddForm.get('weight').value) {
        addNewWayPoint.weight = this.wayPointAddForm.get('weight').value;
      }
      if (this.wayPointAddForm.get('widthInches').value) {
        addNewWayPoint.width = this.wayPointAddForm.get('widthInches').value;
      }
      addNewWayPoint.zip = this.wayPointAddForm.get('pickupPincode').value ? this.wayPointAddForm.get('pickupPincode').value : '';

      if(this.ifPickupLoadInfo){
        addNewWayPoint.commodity = []; 
        for (let i =0; i< this.commodityForm.length; i++) {
          const commodityElementForm = this.commodityForm.at(i);
          if (commodityElementForm.valid) {
               var commodityElement = {
                commodity : commodityElementForm.get('commodity').value,
                packagingType : commodityElementForm.get('packagingType').value,
                quantity : commodityElementForm.get('quantity').value,
                dimension : commodityElementForm.get('dimension').value,
                length: commodityElementForm.get('dimension').value.split('x')[0],
                height: commodityElementForm.get('dimension').value.split('x')[1],
                width: commodityElementForm.get('dimension').value.split('x')[2],
                weight : commodityElementForm.get('weight').value,
                value : commodityElementForm.get('value').value,
                sku : commodityElementForm.get('sku').value,
                media : commodityElementForm.get('media').value,
                handleWithCare : commodityElementForm.get('handleWithCare').value,
                type : this.data.type
               }
               addNewWayPoint.commodity.push(commodityElement);
          }
        }
      }
      this.sharedService.setGuardValue();
      this.dialogRef.close({
        addNewWayPoint: addNewWayPoint,
      });
    }
  }

  modifyAddress(full, zip){    
    let country =  full.split(',')[ full.split(',').length-1];
    let full_address = (full.split(',')[0]+ ', '+full.split(',')[1]+ full.split(',')[2].split(' ')[0]+ ', '+ zip + ', '+country)
    return full_address;
  }

 async wayPointEditFormSubmit({ value, valid } ) {
    this.communityDataSubmiited=true;
    this.isTimeSloteExpired = value.pickupTime.disabled == true ? true :  false;
    // this.getRouteResponse = await this.getRouteMiles();
  

    if (valid && !this.isTimeSloteExpired && !this.isTimeSloteAvailble) {
      let updatedWayPoint: any = {};
      if (this.wayPointEditForm.get('pickupBussinessName').value) {
        updatedWayPoint.businessName = this.wayPointEditForm.get('pickupBussinessName').value;
      }
      updatedWayPoint.city = this.pickupCity ? this.pickupCity : this.data.selectedWayPointToEdit.city;
      if (this.wayPointEditForm.get('pickupContactName').value) {
        updatedWayPoint.contactName = this.wayPointEditForm.get('pickupContactName').value;
      }
      if ((this.wayPointEditForm.get('pickupCountryCode').value) && (this.wayPointEditForm.get('pickPhoneNumber').value)) {
        updatedWayPoint.countryCode = this.newSelectPickupCountryCode ? this.newSelectPickupCountryCode : this.data.selectedWayPointToEdit.countryCode;
      }
      else{
        updatedWayPoint.countryCode = '1'; 
      }
      updatedWayPoint.countryName =  this.newPickupCountryName ? this.newPickupCountryName : this.data.selectedWayPointToEdit.countryName; 
     
      updatedWayPoint.date = this.getDateFormat(
        this.wayPointEditForm.get('pickupDate').value.toString()
      );
      if (this.wayPointEditForm.get('heightInches').value) {
        updatedWayPoint.height = this.wayPointEditForm.get('heightInches').value;
      }
      if (this.data.selectedWayPointToEdit.id) {
        updatedWayPoint.id = this.data.selectedWayPointToEdit.id;
      }
      updatedWayPoint.latitude = this.pickupLatitude ? this.pickupLatitude : this.data.selectedWayPointToEdit.latitude;

      if (this.wayPointEditForm.get('lengthInches').value) {
        updatedWayPoint.length = this.wayPointEditForm.get('lengthInches').value;
      }
      updatedWayPoint.locationLongName = this.modifyAddress(this.wayPointEditForm.get('pickupLocation').value,this.wayPointEditForm.get('pickupPincode').value.toString());
      updatedWayPoint.locationOrder = this.data.selectedWayPointToEdit.locationOrder ? this.data.selectedWayPointToEdit.locationOrder : '';
      updatedWayPoint.longitude = this.pickupLongitude ? this.pickupLongitude : this.data.selectedWayPointToEdit.longitude;
      if (this.wayPointEditForm.get('pickPhoneNumber').value) {
        updatedWayPoint.phone = this.wayPointEditForm.get('pickPhoneNumber').value;
      }
      else{
        updatedWayPoint.phone = "";
      }
      if (this.wayPointEditForm.get('quantity').value) {
        updatedWayPoint.quantity = this.wayPointEditForm.get('quantity').value;
      }
      updatedWayPoint.sequence = this.data.selectedWayPointToEdit.sequence ? this.data.selectedWayPointToEdit.sequence : '';
        updatedWayPoint.specialRequirement = this.wayPointEditForm.get('pickInstructions').value;
      updatedWayPoint.state = this.pickupState ? this.pickupState : this.data.selectedWayPointToEdit.state;
      updatedWayPoint.timeSlot = this.wayPointEditForm.get('pickupTime').value.time;
      updatedWayPoint.timeZone = this.wayPointEditForm.get('pickupTimeZone').value;
      updatedWayPoint.type = this.wayPointEditForm.get('wayPointType').value;
      if (this.wayPointEditForm.get('weight').value) {
        updatedWayPoint.weight = this.wayPointEditForm.get('weight').value;
      }
      if (this.wayPointEditForm.get('widthInches').value) {
        updatedWayPoint.width = this.wayPointEditForm.get('widthInches').value;
      }
      updatedWayPoint.zip = this.wayPointEditForm.get('pickupPincode').value ? (this.wayPointEditForm.get('pickupPincode').value).toString() : '';
      if(updatedWayPoint.status === 'In-Transit' && this.wayPointEditForm.dirty){
        updatedWayPoint.status = 'Open'; 
        updatedWayPoint.isCurrentRoute = true; 
      }
      else{
        updatedWayPoint.isCurrentRoute = false; 
        updatedWayPoint.status = this.data.selectedWayPointToEdit.status; 
      }

      if(this.wayPointEditForm.dirty){
        updatedWayPoint.isUpdated = true; 
      } 
      else{
        updatedWayPoint.isUpdated = false; 
      }
      this.getRouteResponse = await this.getRouteMiles();
         
     
    
      if(this.ifPickupLoadInfo){
        updatedWayPoint.commodity = []; 
        for (let i = 0; i < this.commodityForm.length; i++) {
          const commodityElementForm = this.commodityForm.at(i);
          if (commodityElementForm.valid) {
               var commodityElement = {
                commodity : commodityElementForm.get('commodity').value,
                packagingType : commodityElementForm.get('packagingType').value,
                quantity : commodityElementForm.get('quantity').value,
                dimension : commodityElementForm.get('dimension').value ? commodityElementForm.get('dimension').value : '',
                length: commodityElementForm.get('dimension').value.split('x')[0] ? commodityElementForm.get('dimension').value.split('x')[0]:'',
                height: commodityElementForm.get('dimension').value.split('x')[1] ? commodityElementForm.get('dimension').value.split('x')[1] :'',
                width: commodityElementForm.get('dimension').value.split('x')[2] ? commodityElementForm.get('dimension').value.split('x')[2] :'',
                weight : commodityElementForm.get('weight').value ? commodityElementForm.get('weight').value : '',
                value : commodityElementForm.get('value').value ? commodityElementForm.get('value').value : '',
                sku : commodityElementForm.get('sku').value ? commodityElementForm.get('sku').value : '',
                media : commodityElementForm.get('media').value ? commodityElementForm.get('media').value : '',
                handleWithCare : commodityElementForm.get('handleWithCare').value ? commodityElementForm.get('handleWithCare').value : '',
                type : this.data.type ? this.data.type : ''
               }
               updatedWayPoint.commodity.push(commodityElement);
          }
        }
      }
      this.sharedService.setGuardValue();
      this.dialogRef.close({
        updatedWayPoint: updatedWayPoint,
        isWayPointTypeChanged: this.isWayPointTypeChanged,
      });
    }
  }

  async checkRouteSubmitForm() {
    this.getRouteResponse = await this.getRouteMiles();
    if (this.getRouteResponse && this.getRouteResponse.miles) {
      this.finalAmount = this.getRouteResponse.miles * AppSettings.milesPrice;
      this.wayPointEditForm
        .get('freightRate')
        .setValidators([Validators.max(this.finalAmount)]);
      this.setScrollTop();
    } else {
      if (this.getRouteResponse.forceCarRoute === true) {
        this.carRouteOption('true');
        this.loading = false;
      } else if (this.getRouteResponse.forceCarRoute === false) {
        this.carRouteOption('false');
        this.loading = false;
      } else {
        this.loading = false;
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_d',
          'cancel',
          'Route Fail',
          this.getRouteResponse.message
        );
      }
    }
    this.loading = false;
  }

  addLoadInformation() {
    this.ifPickupLoadInfo = true;
      let control = <FormArray>this.wayPointAddForm.controls.commodityForm;
      if(control.length===0){
        this.addCommunityField();
    }
  }

  addLoadInformationForEdit() {
    this.ifPickupLoadInfo = true;
      let control = <FormArray>this.wayPointEditForm.controls.commodityForm;
      if(control.length===0){
        this.addCommunityFieldForEdit();
    }
  }

  addCommunityField() {
    this.communityDataSubmiited=false;
    let control = <FormArray>this.wayPointAddForm.controls.commodityForm;
    control.push(
      this.formBuilder.group({
        commodity: ['', [Validators.required,Validators.maxLength(128)]],
        packagingType: ['', [Validators.required]],
        quantity: ['', [Validators.required]],
        dimension: ['', ],
        length: ['', ],
        width: ['', ],
        height: ['', ],
        weight: ['', ],
        value: ['', ],
        sku: ['', [Validators.maxLength(15),Validators.pattern("^[A-Za-z0-9_-]*$")]],
        media: ['',],
        handleWithCare:[''],
      }))
      this.wayPointAddForm.setControl('commodityForm', control);   
  }

  
  addCommunityFieldForEdit() {
    this.communityDataSubmiited=false;
    let control = <FormArray>this.wayPointEditForm.controls.commodityForm;
    control.push(
      this.formBuilder.group({
        commodity: ['', [Validators.required,Validators.maxLength(128)]],
        packagingType: ['', [Validators.required]],
        quantity: ['', [Validators.required]],
        dimension: ['', ],
        length: ['', ],
        width: ['', ],
        height: ['', ],
        weight: ['', ],
        value: ['', ],
        sku: ['', [Validators.maxLength(15), Validators.pattern("^[A-Za-z0-9_-]*$")]],
        media: ['',],
        handleWithCare:[''],
      }))
      this.wayPointEditForm.setControl('commodityForm', control);   
  }

  onSelectedUploadeImage(event:any, index :any) {
    this.currentIndex=index;
    if (event.target.files.length === 0) return;
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) === null) {
      this.showErrorImage = 'Only supported Images file.';
      return;
    }else{
      this.showErrorImage=null;
    }
    var reader = new FileReader();
    this.imagePath = event.target.files;
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
    if (event.target.files.length) {
       const file = event.target.files[0];
      this.commodityMedia(file,index);
    }
  }

  commodityMedia(file:any,index:any) {
    const formData = new FormData();
    formData.append('media',file);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.SHIPMENT.COMMODITYMEDIA,
      uri: '',
      postBody: formData,
    };
    this.commonService.post(APIparams).subscribe(
      (success) => {
        this.currentIndex=null;
        if (success.success === true) {  
        if (this.data.openPop === 'addMultiStopRoute'){  
        this.wayPointAddForm['controls']['commodityForm']['controls'][index]['controls'].media.patchValue(success.response.location);
        }
        if(this.data.openPop === 'editMultiStop'){
        this.wayPointEditForm['controls']['commodityForm']['controls'][index]['controls'].media.patchValue(success.response.location);
        }
        } else if (success.success === false) {
        }
      },
      (error) => {
      }
    );
  }

    // remove last index of contacts array 
    removeCommunityField(index:any) {
      let control = <FormArray>this.wayPointAddForm.controls.commodityForm;
      control.removeAt(index);
      if(control.length===0){
        this.ifPickupLoadInfo=false;
      }
    }

    removeCommunityFieldForEdit(index:any) {
      let control = <FormArray>this.wayPointEditForm.controls.commodityForm;
      control.removeAt(index);
      if(control.length===0){
        this.ifPickupLoadInfo=false;
      }
    }

    removeMedia(index){
      if (this.data.openPop === 'addMultiStopRoute'){  
         this.wayPointAddForm['controls']['commodityForm']['controls'][index]['controls'].media.patchValue('');
         this.uploader.toArray().forEach(el => {
            el.nativeElement.value = '';
         });
      }
      if (this.data.openPop === 'editMultiStop'){  
         this.wayPointEditForm['controls']['commodityForm']['controls'][index]['controls'].media.patchValue('');
         this.uploader.toArray().forEach(el => {
          el.nativeElement.value = '';
       });
      }
    }

  selectPickCountryCode(event: any) {
    const getRecord = this.countryList.filter(
      (item) => item.countryAbbr === event.value
    );
    if (getRecord && getRecord.length > 0) {
      this.defualtPickupCountryFlag = getRecord[0]?.flag;
      this.newSelectPickupCountryCode = getRecord[0]?.countryAbbr;
      this.pickupCountryName = getRecord[0]?.country;
    } else {
      this.defualtPickupCountryFlag = this.countryList[0].flag;
      this.newSelectPickupCountryCode = this.countryList[0].countryAbbr;
      this.pickupCountryName = this.countryList[0].country;
    }
  }

  timeRestriction(event) {
    this.isTimeSloteAvailble = false; 
    this.isTimeSloteExpired = false; 
    this.calemaxte = new Date(this.calemaxte);
    this.pickupTimeError = false;
    let now = new Date();
    const ctTime = moment(now).format('HH:00');
    const currentPickDate: any = moment(now).format('YYYY-MM-DD');

    /*check current time is less than ot grather thne 10pm*/
    let checkeCurrentTime = event.value.time.split('-');
    if (
      checkeCurrentTime[0].match(/22:00/g) ||
      checkeCurrentTime[0].match(/23:00/g)
    ) {
      let days = 1;
      this.calemaxte.setDate(this.calemaxte.getDate() + days);
      if (this.maxDate >= this.maxDateDropOff) {
      }
    } else if (this.pickupDate === currentPickDate) {
      this.calemaxte = now;
    } else {
      this.calemaxte = this.pickupDate;
    }
    if (this.pickupDate === this.olddropDate) {
      this.deliveryTimeShow = [];
    } else {
    }

    this.selectTime = event.value.time;
    if (this.pimaxDate && this.selectTime) {
      this.disabled1 = false;
    }
    const maxDateDropOffNew: any = moment(this.maxDateDropOff).format(
      'YYYY-MM-DD'
    );

    this.maxDateDropOff = maxDateDropOffNew;

    const timeDatById = this.dropTimeArr.filter(
      (item) => item.time === event.value.time
    );
    this.timedat = timeDatById;
    this.deliveryTimeShow = [];

    for (let reIndex in this.dropTimeArr) {
      if (
        this.maxDate === currentPickDate &&
        this.maxDate === this.maxDateDropOff
      ) {
        if (this.dropTimeArr[reIndex].id > timeDatById[0].id) {
          this.dropTimeArr[reIndex].disabled = false;
          this.dropTimeArr[reIndex].time = this.dropTimeArr[reIndex].time;
        } else {
          this.dropTimeArr[reIndex].disabled = true;
          this.dropTimeArr[reIndex].time = this.dropTimeArr[reIndex].time;
        }
        if (this.pimaxDate && this.selectTime) {
          this.wayPointEditForm.get('deliveryTime').setValue('');
        }
      } else if (this.maxDate === this.maxDateDropOff) {
        if (this.dropTimeArr[reIndex].id > timeDatById[0].id) {
          this.dropTimeArr[reIndex].disabled = false;
          this.dropTimeArr[reIndex].time = this.dropTimeArr[reIndex].time;
        } else {
          this.dropTimeArr[reIndex].disabled = true;
          this.dropTimeArr[reIndex].time = this.dropTimeArr[reIndex].time;
        }
        this.wayPointEditForm.get('deliveryTime').setValue('');
      }
    }
    this.deliveryTimeShow = this.dropTimeArr;
    this.pickuptimeslot = checkeCurrentTime[0];
  }

  checkDropTime(event: any) {
    this.deliveryTimedisabled = false;
    let today: any = new Date();
    let now = new Date();
    today = moment(today).format('YYYY-MM-DD');
    let newMax1 = event.replace(/\-/g, '/');
    let newMax: any = moment(newMax1).format('YYYY-MM-DD');
    this.olddropDate = newMax;
    this.maxDateDropOff = newMax;
    this.deliveryTimeShow = this.dropTimeArr;
    const ctTime: any = moment(now).format('HH:mm');
    let checkeCurrentTime = ctTime.split(':');
    if (this.maxDateDropOff === today) {
      let currTimeSlot = [];

      if (checkeCurrentTime[0] % 2) {
        currTimeSlot[0] = parseInt(checkeCurrentTime[0]) - 1;
        currTimeSlot[0] = currTimeSlot[0].toString().padStart(2, 0);
        currTimeSlot[1] = parseInt(checkeCurrentTime[1]) + 1;
        currTimeSlot[1] = currTimeSlot[1].toString().padStart(2, 0);
      } else {
        currTimeSlot[0] = parseInt(checkeCurrentTime[0]);
        currTimeSlot[0] = currTimeSlot[0].toString().padStart(2, 0);
        currTimeSlot[1] = parseInt(checkeCurrentTime[1]) + 2;
        currTimeSlot[1] = currTimeSlot[1].toString().padStart(2, 0);
      }
      let timeSlot = currTimeSlot[0] + ':00' + ' - ' + currTimeSlot[1] + ':00';
    } else if (this.maxDateDropOff >= today) {
      for (let deliveryIndex in this.deliveryTimeShow) {
        let indexTime = this.dropTimeArr[deliveryIndex].time.split('-');
        indexTime = indexTime[0].split(':');
        this.dropTimeArr[deliveryIndex].disabled = false;
        this.dropTimeArr[deliveryIndex].time =
          this.dropTimeArr[deliveryIndex].time;
      }
    }
    if (
      this.maxDateDropOff === this.pickupDate ||
      this.maxDateDropOff === today
    ) {
      let pickuptimeslot = this.pickuptimeslot.split(':');
      let biggerTimeslot;

      if (
        this.maxDateDropOff === this.pickupDate &&
        this.maxDateDropOff === today
      ) {
        if (pickuptimeslot[0] >= checkeCurrentTime[0]) {
          biggerTimeslot = pickuptimeslot[0];
        } else {
          biggerTimeslot = checkeCurrentTime[0];
        }
      } else if (
        this.maxDateDropOff === this.pickupDate &&
        this.maxDateDropOff > today
      ) {
        biggerTimeslot = pickuptimeslot[0];
      } else if (
        this.maxDateDropOff > this.pickupDate &&
        this.maxDateDropOff === today
      ) {
        biggerTimeslot = checkeCurrentTime[0];
      }

      for (let deliveryIndex in this.deliveryTimeShow) {
        let indexTime = this.dropTimeArr[deliveryIndex].time.split('-');
        indexTime = indexTime[0].split(':');

        if (biggerTimeslot >= indexTime[0]) {
          this.dropTimeArr[deliveryIndex].disabled = true;
          this.dropTimeArr[deliveryIndex].time =
            this.dropTimeArr[deliveryIndex].time;
        } else {
          this.dropTimeArr[deliveryIndex].disabled = false;
          this.dropTimeArr[deliveryIndex].time =
            this.dropTimeArr[deliveryIndex].time;
        }
      }
    }
  }

  onSeleted(event) {
    const height = '';
    const width = '';
    if (event.target.files.length === 0) return;
    var mimeType = event.target.files[0].type;

    if (
      mimeType === 'image/gif' ||
      mimeType === 'image/png' ||
      mimeType === 'image/jpg' ||
      mimeType === 'image/jpeg'
    ) {
      var reader = new FileReader();
      this.imagePath = event.target.files;

      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (_event) => {
        this.profileImge = reader.result;
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          const height = img.naturalHeight;
          const width = img.naturalWidth;
        };
      };
      if (event.target.files.length) {
        const file = event.target.files[0];
        this.dialogRef.close({ event: file });
      }
    } else {
      this.imagmessage = 'Pease upload valid formate image';
      this.imgURL = '';
      return;
    }
  }

  closePoup() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('userProfile_sugBox');
    this.dialogRef.close({ event: this.routeStatus });
  }

  uploadNext() {
    this.data.contain = 'Upload Sheet';
    this.uploadState = false;
    this.uploadState1 = true;
  }
  uploadNext1() {
    this.data.contain = 'Upload Sheet';
    this.uploadState1 = false;
    this.uploadState2 = true;
  }
  inviteSuccess() {
    this.inviteDriSuccess = true;
  }

  goToDriverList() {
    this.dialogRef.close({ event: 'Ok' });
  }

  successPayment() {
    this.dialogRef.close({ event: 'fail' });
    this.router.navigateByUrl('/sign-up-verify-email');
  }
  onSuccessConfirm() {
    this.dialogRef.close({ event: 'paymentConfirm' });
  }
  onConfirmationClick() {
    this.dialogRef.close({ event: 'Ok' });
  }

  onNoConfirmClick() {
    this.dialogRef.close({ event: 'fail' });
  }

  shipmentConfirm() {
    this.dialogRef.close({ event: 'shipmentConfirmation' });
  }
  /*upload certificate of insurance*/
 
  resetAllData() {
    this.dialogRef.close({ event: 'fail' });
    let currentUrl = this.router.url;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }
  successShipmentCreate() {
    this.dialogRef.close({ event: 'success' });
  }
  
  onNoClick(): void {
    this.isTimeSloteAvailble = false;
    this.isTimeSloteExpired = false; 
    this.dialogRef.close({ event: 'fail' });
    
  }

  onNoClickRegion(event){

    this.dialogRef.close({ event: event });

  }
  cancelSubscription(): void {
    
    this.dialogRef.close({ cancelSubscription: true });
  }

  autoRenewOff(): void {
    this.dialogRef.close({ autoRenewOff: true });
  }

  okViewDetail(invoiceId): void {
    this.dialogRef.close({ event: 'viewDetail' });
  }

  onCarRoute(): void {
    this.dialogRef.close({ event: 'carSelected' });
  }

  backToAddPage(): void {
    this.dialogRef.close({ event: 'addressChange' });
  }

  onSuccess(): void {
    this.router.navigate(['/billing/overview']);
    this.dialogRef.close({ event: 'OK' });
  }

  onSuccessCredits(): void {
    if (this.userType == 'SHIPPER') {
      this.router.navigate(['/shipper/subscription-overview']);
    }
    if (this.userType == 'CARRIER') {
      this.router.navigate(['/carrier/subscription-overview']);
    }
    this.dialogRef.close({ event: 'OK' });
  }

  onFail()
  {
    this.dialogRef.close({ event: 'OK' });
  }

  onPublic(): void {
    this.dialogRef.close({ event: 'OK' });
  }

  successInviteDriver() {
    this.dialogRef.close({ event: 'OK' });
  }

  backLoad(): void {
    this.dialogRef.close({ event: 'LOAD' });
  }

  closePopUp(event: any) {
    this.dialogRef.close({ event: event });
  }

  confirmReturn() {
    if (this.subject) {
      this.subject.next(true);
      this.subject.complete();
    }
    this.dialogRef.close(true);
  }

  onSelectedFile(event) {
    if (event.target.files.length === 0) return;
    var reader = new FileReader();
    this.name = event.target.files[0].name;
    this.disbu = false;
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
    if (event.target.files.length) {
      const file = event.target.files[0];
      this.addDocUpload.get('document').setValue(file);
    }
  }

  onSelectedContract(event) {
    if (event.target.files.length === 0) return;
    let mimeType = event.target.files[0].type;
    if (mimeType.match(/pdf\/*/) === null) {
      this.imagmessage = 'Only supported pdf file.';
      this.imgURL = '';
      return;
    }

    var reader = new FileReader();
    this.name = event.target.files[0].name;
    this.disbu = false;
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
    if (event.target.files.length) {
      const file = event.target.files[0];
      this.contractForm.get('contractDocument').setValue(file);
    }
  }

  /*upload certificate of insurance*/
  uploadDocument({ value, valid }) {
    if (valid) {
      this.loading = true;
      const formData = new FormData();
      formData.append('document', this.addDocUpload.get('document').value);
      formData.append(
        'description',
        this.addDocUpload.get('description').value
      );
      formData.append('driverId', this.addDocUpload.get('driverId').value);
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.DRIVER.DOCADD,
        uri: '',
        postBody: formData,
      };
      this.commonService.postMediaMethod(APIparams).subscribe(
        (success) => {
          if (success.success === true) {
            this.alertService.showNotificationMessage(
              'success',
              'bottom',
              'right',
              'txt_s',
              'check_circle',
              'Driver Profile',
              'You have successfully uploaded a document.'
            );
            this.loading = false;
            this.dialogRef.close({ event: 'Ok' });
          } else if (success.success === false) {
            this.loading = false;
            this.alertService.showNotificationMessage(
              'danger',
              'bottom',
              'right',
              'txt_d',
              'check_circle',
              'Driver Profile',
              'You have not successfully uploaded a document.'
            );
          }
        },
        (error) => {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_g',
            'error',
            'Unexpected Error',
            AppSettings.ERROR
          );
        }
      );
    }
  }

  deleteRecord(id: any, driverId: any) {
    let uri = '';
    let params = {
      docId: id,
      driverId: driverId,
    };
    //get dynamic URI for APIs
    if (params) uri = this.commonService.getAPIUriFromParams(params);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.DRIVER.DELETE,
      uri: uri,
    };
    this.commonService.delete(APIparams).subscribe(
      (success) => {
        if (success.success === true) {
          this.alertService.showNotificationMessage(
            'success',
            'bottom',
            'right',
            'txt_s',
            'check_circle',
            'Driver Profile',
            'You have successfully removed a document.'
          );
          this.loading = false;
          this.dialogRef.close({ event: 'Ok' });
        }
      },
      (error) => {
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_g',
          'error',
          'Unexpected Error',
          AppSettings.ERROR
        );
      }
    );
  }

  /*send mail for invoice and email tempalte*/

  sendMail({ value, valid }) {
    if (valid) {
      this.loading = true;
      let postBody = null;
      let urlSenmail = null;
      if (this.data.type === 'invoice') {
        urlSenmail = AppSettings.APIsNameArray.SENDMAILTEMPLATE.EMAILINVOICE;
        postBody = {
          email: this.emailSendForm.get('email').value,
          id: this.emailSendForm.get('id').value,
        };
      } else {
        urlSenmail = AppSettings.APIsNameArray.SENDMAILTEMPLATE.EMAILRECEIPT;
        postBody = {
          email: this.emailSendForm.get('email').value,
          shipmentId: this.emailSendForm.get('id').value,
        };
      }

      let APIparams = {
        apiKey: urlSenmail,
        uri: '',
        postBody: postBody,
      };
      this.commonService.post(APIparams).subscribe(
        (success) => {
          if (success.success === true) {
            this.alertService.showNotificationMessage(
              'success',
              'bottom',
              'right',
              'txt_s',
              'check_circle',
              'Email Receipt',
              'You have shared email receipt on email shared.'
            );
            this.loading = false;
            this.dialogRef.close({ event: 'Ok' });
          } else if (success.success === false) {
            this.loading = false;
            this.alertService.showNotificationMessage(
              'danger',
              'bottom',
              'right',
              'txt_d',
              'cancel',
              'Email Receipt',
              'You have not shared email receipt on email shared.'
            );
          }
        },
        (error) => {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_g',
            'error',
            'Unexpected Error',
            AppSettings.ERROR
          );
          this.loading = false;
        }
      );
    }
  }

  onNoClickCancel() {
    return true;
  }
  confirmAtion() {
    return false;
  }
  /*Set Defulat Address Carrier Profile*/
  setDefault() {
    this.dialogRef.close({ event: 'Ok' });
  }

  deleteRecordBy() {
    this.dialogRef.close({ event: 'Ok' });
  }

  getSize(bytes) {
    let k = 1024,
      dm = 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  goToLogin() {
    this.router.navigate(['/']);
    this.dialogRef.close({ event: 'Ok' });
    localStorage.removeItem('user_type');
    localStorage.removeItem('userProfileImage');
    localStorage.removeItem('access_token');
    localStorage.removeItem('userName');
    localStorage.removeItem('user_id');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('showExpiryPopup');
    localStorage.removeItem('email_verified');
    localStorage.removeItem('onboarding');
    sessionStorage.clear();
  }

  showAddPaymentDetailPopup() {
    this.cardNumberError = '';
    this.cardExpiryError = '';
    this.cardCvvError = '';
    this.submitted = false;
    this.saveCardButton = true;
    this.brandCard = 'unknown';

    const style = {
      base: {
        fontSize: '14px',
        lineHeight: '18px',
        fontWeight: 'bold',
        color: '#000000',
        '::placeholder': {
          fontSize: '14px',
          fontWeight: 'bold',
          color: '#818181',
        },
      },
    };
    const elements = this.stripe.elements();
    if (elements) {
      this.cd.detectChanges();
      let cardNumberElement = elements.create('cardNumber', { style: style });
      cardNumberElement.mount('#card-number-element');
      this.card = cardNumberElement;
      let cardExpiryElement = elements.create('cardExpiry', { style: style });
      cardExpiryElement.mount('#card-expiry-element');

      this.card = cardExpiryElement;
      let cardCvcElement = elements.create('cardCvc', {
        style: style,
        placeholder: '***',
      });
      cardCvcElement.mount('#card-cvc-element');

      this.card = cardCvcElement;
      let that = this;
      this.saveCardButton = true;
      cardNumberElement.addEventListener('change', function (event) {
        if (event.brand) {
          that.brandCard = event.brand;
        }

        if (event.error) {
          that.cardNumberError = event.error.message;
          that.cardNumber = event.complete;
        } else {
          that.cardNumberError = '';
          that.cardNumber = event.complete;
        }
      });

      cardExpiryElement.addEventListener('change', function (event) {
        if (event.error) {
          that.cardExpiryError = event.error.message;
          that.cardExpiry = event.complete;
        } else {
          that.cardExpiryError = '';
          that.cardExpiry = event.complete;
        }
      });

      cardCvcElement.addEventListener('change', function (event) {
        if (event.error) {
          that.cardCvvError = event.error.message;
          that.cardCvc = event.complete;
        } else {
          that.cardCvvError = '';
          that.cardCvc = event.complete;
        }
      });
    }
  }

  checkNameValue(event: any) {
    let searchStr = event.target.value;
    if (searchStr.length > 0 || searchStr.length === ' ') {
      this.saveCardButton = false;
    } else {
      this.saveCardButton = true;
    }
    let lastword = searchStr.charAt(searchStr.length - 1);
    if (lastword === ' ') {
      this.countSpace = this.countSpace + 1;
    } else {
      this.countSpace = 0;
    }
    if (this.countSpace >= 2 || (lastword === ' ' && searchStr.length === 1)) {
      this.searchText = this.searchText.substring(
        0,
        this.searchText.length - 1
      );
      this.searchBoxError = true;
    } else {
      this.searchBoxError = false;
    }
  }

  /*add to card*/
  async onSubmit({ value, valid }) {
    if (
      this.cardNumber === false ||
      this.cardExpiry === false ||
      this.cardCvc === false
    ) {
      if (this.cardNumber === false) {
        this.cardNumberError = 'Card number is required.';
      }
      if (this.cardExpiry === false) {
        this.cardExpiryError = 'Expiration date is required.';
      }
      if (this.cardCvc === false) {
        this.cardCvvError = 'CVV  is required.';
      }
      return;
    } else {
      this.loading = true;
      this.saveCardButton = true;
      this.submitted = true;
      const { paymentMethod, error } = await this.stripe.createPaymentMethod({
        type: 'card',
        card: this.card,
        billing_details: {
          name: value.cardName,
        },
      });
      if (error) {
        this.loading = false;
        this.saveCardButton = false;
        if (error.code) {
          this.alertService.showNotificationMessage(
            'success',
            'bottom',
            'right',
            'txt_d',
            'check_circle',
            'Payment Method',
            error.message
          );
        } else {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_g',
            'error',
            'Unexpected Error',
            AppSettings.ERROR
          );
        }
      } else {
        this.cardAddByStripe(paymentMethod.id);
      }
    }
  }

  cardAddByStripe(paymentId: any) {
    this.loading = true;
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.LOADPAYMENT.ADDCARD,
      uri: '',
      postBody: { paymentMethodId: paymentId },
    };
    this.commonService.post(APIparams).subscribe(
      (success) => {
        if (success.success === true) {
          if (this.data.type == 'Subscription') {
            var dimensions = null;
            if (this.paymentCard.get('cardName').value) {
              dimensions = 'nameOnCard=' + this.paymentCard.get('cardName').value;
            }
            var obj = {
              eventName: 'save_card',
              event_category: 'Subscription',
              userType: localStorage.getItem('user_type'),
              EmailID: localStorage.getItem('email'),
              Dimensions: dimensions ? dimensions : '-',
            };
            this.sharedService.track(obj);
          }
          this.alertService.showNotificationMessage(
            'success',
            'bottom',
            'right',
            'txt_s',
            'check_circle',
            'Payment Method',
            'You have successfully added card details.'
          );
          this.loading = false;
          this.dialogRef.close({ event: 'Success' });
        } else if (success.success === false) {
          this.saveCardButton = false;
          this.loading = false;
          this.alertService.showNotificationMessage(
            'success',
            'bottom',
            'right',
            'txt_d',
            'check_circle',
            'Payment Method',
            success.message
          );
        }
      },
      (error) => {
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_g',
          'error',
          'Unexpected Error',
          AppSettings.ERROR
        );
        this.loading = false;
        this.saveCardButton = false;
      }
    );
  }
  // addres update
  carrierProfileSubmit({ value, valid }) {
    if (valid) {
      this.loading = true;
      const formData = new FormData();
      let postBody = null;
      let phyAdd2;
      if (
        this.addressForm.get('addressline2').value === null ||
        this.addressForm.get('addressline2').value === undefined
      ) {
        phyAdd2 = '';
      } else {
        phyAdd2 = this.addressForm.get('addressline2').value;
      }

      if (value.addressType === 'Physical Address') {
        formData.append('phyStreet', value.street);
        formData.append('phyZipShort', value.zipCode);
        formData.append('phyAddressLine2', phyAdd2);
        formData.append('phyCity', value.cityBy);
        formData.append('phyState', value.stateBy);
        formData.append('phyCountry', 'US');
      } else {
        formData.append('mailingStreet', value.street);
        formData.append('mailingZipShort', value.zipCode);
        formData.append('mailingAddressLine2', phyAdd2);
        formData.append('mailingCity', value.cityBy);
        formData.append('mailingState', value.stateBy);
        formData.append('mailingCountry', 'US');
      }

      let APIparams = {
        apiKey: AppSettings.APIsNameArray.CARRIER.UPDATE,
        uri: '',
        postBody: formData,
      };
      this.commonService.post(APIparams).subscribe(
        (success) => {
          if (success.success === true) {
            this.loading = false;
            this.dialogRef.close({ event: 'Address' });
          } else if (success.success === false) {
            this.loading = false;
            this.alertService.showNotification(
              'danger',
              'bottom',
              'right',
              success.message
            );
          }
        },
        (error) => {
          this.alertService.showNotification(
            'danger',
            'bottom',
            'right',
            AppSettings.ERROR
          );
          this.loading = false;
        }
      );
    }
  }
  /*get user information*/

  keyPaste(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    if (!pattern.test(event.target.value)) {
      return this.emailOtpVerify.get('otp').setValue('');
    }
  }

  keyPress(event: any) {
    const pattern = /^[0-9]*$/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  /*Verify OTP AND  RESENT OTP*/
  verifyOtpEmail({ value, valid }) {
    if (valid) {
      this.loading = true;
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.MY_PROFILE.UPDATECREDENTIALS,
        uri: '',
        postBody: value,
      };
      this.commonService.post(APIparams).subscribe(
        (success) => {
          if (success.success === true) {
            this.alertService.showNotificationMessage(
              'success',
              'bottom',
              'right',
              'txt_s',
              'check_circle',
              'Email Update',
              success.message
            );
            this.dialogRef.close({ event: 'OTP_VERIFY' });
            this.loading = false;
          } else if (success.success === false) {
            this.message = 'Invalid verfication code';
            this.loading = false;
          }
        },
        (error) => {
          this.message = AppSettings.ERROR;
          this.loading = false;
        }
      );
    }
  }

  verifyOtpPhone({ value, valid }) {
    if (valid) {
      this.loading = true;
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.MY_PROFILE.UPDATECREDENTIALS,
        uri: '',
        postBody: value,
      };
      this.commonService.post(APIparams).subscribe(
        (success) => {
          if (success.success === true) {
            this.alertService.showNotificationMessage(
              'success',
              'bottom',
              'right',
              'txt_s',
              'check_circle',
              'Phone Update',
              success.message
            );
            this.dialogRef.close({ event: 'OTP_VERIFY' });
            this.loading = false;
          } else if (success.success === false) {
            this.message = 'Invalid verfication code';
            this.loading = false;
          }
        },
        (error) => {
          this.message = AppSettings.ERROR;
          this.loading = false;
        }
      );
    }
  }

  resendOTP() {
    this.emailOtpVerify.get('otp').setValue('');
    this.phoneOtpVerify.get('otp').setValue('');
    this.spinnerLoading = true;
    let postBody = null;
    postBody = { email: this.data.email, phone: this.data.phone };
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.MY_PROFILE.CHECKCREDENTIALS,
      uri: '',
      postBody: postBody,
    };
    this.commonService.post(APIparams).subscribe(
      (success) => {
        if (success.success === true) {
          this.resendmessage = 'Verification code sent successfully';
          this.message = '';
          this.spinnerLoading = false;
        } else if (success.success === false) {
          this.resendmessage = 'Verification code sent successfully';
          this.spinnerLoading = false;
          this.message = '';
        }
      },
      (error) => {
        this.resendmessage = AppSettings.ERROR;
        this.message = '';
        this.spinnerLoading = false;
      }
    );
  }

  loginPage() {
    this.dialogRef.close({ event: 'Ok' });
    this.router.navigate(['/']);
  }

  /*upload certificate of insurance*/
  contractUploadDocument({ value, valid }) {
    if (valid) {
      this.loading = true;
      const formData = new FormData();
      formData.append(
        'mediaContractDoc',
        this.contractForm.get('contractDocument').value
      );
      formData.append('mediaType', 'SHIPPER_CONTRACT_DOCUMENT');
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.SHIPPER.UPDATE,
        uri: '',
        postBody: formData,
      };
      this.commonService.put(APIparams).subscribe(
        (success) => {
          if (success.success === true) {
            localStorage.setItem('is_contract', '1');
            this.alertService.showNotificationMessage(
              'success',
              'bottom',
              'right',
              'txt_s',
              'check_circle',
              'Contract Document',
              'You have successfully uploaded a contract document.'
            );
            this.loading = false;
            this.dialogRef.close({ event: 'Ok' });
          } else if (success.success === false) {
            this.loading = false;
            this.alertService.showNotificationMessage(
              'danger',
              'bottom',
              'right',
              'txt_d',
              'check_circle',
              'Contract Document',
              'You have error while uploading a contract document.'
            );
          }
        },
        (error) => {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_g',
            'error',
            'Unexpected Error',
            AppSettings.ERROR
          );
        }
      );
    }
  }
  /*upload certificate of insurance*/
  certifcateUpload({ value, valid }) {
    if (valid) {
      this.loading = true;
      const formData = new FormData();
      let expiryDate = this.datePipe.transform(
        new Date(value.insurance_expiry_date),
        'MM/dd/yyyy'
      );
      formData.append('media', this.certifcateVerify.get('certificate').value);
      formData.append('coiExpiryDate', expiryDate);
      formData.append('mediaType', 'CERTIFICATE_OF_INSURANCE');

      let APIparams = {
        apiKey: AppSettings.APIsNameArray.MEDIA.ADD,
        uri: '',
        postBody: formData,
      };
      this.commonService.post(APIparams).subscribe(
        (success) => {
          if (success.success === true) {
            this.alertService.showNotificationMessage(
              'success',
              'bottom',
              'right',
              'txt_s',
              'check_circle',
              'Certificate insurance',
              'You have successfully uploaded a document.'
            );
            this.loading = false;
            this.dialogRef.close({ event: 'Ok' });
          } else if (success.success === false) {
            this.loading = false;
            this.alertService.showNotificationMessage(
              'danger',
              'bottom',
              'right',
              'txt_d',
              'check_circle',
              'Certificate insurance',
              'You have not successfully uploaded a document.'
            );
          }
        },
        (error) => {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_g',
            'error',
            'Unexpected Error',
            AppSettings.ERROR
          );
          this.loading = false;
        }
      );
    }
  }

  addNote() {
    this.noteShow = !this.noteShow;
    this.searchModel.message =null;
  }

  acceptRequest() {
    this.dialogRef.close({ documentType: this.documentCheck, event: 'Ok' });
  }

  checkDocument($event) {
    this.documentCheck = $event.checked;
  }

  getUserRecord() {
    this.loading = true;
    let uri = null;
    let newparams = {};
    let userType = null;
    if (localStorage.getItem('user_type') === 'CARRIER') {
      userType = AppSettings.APIsNameArray.CARRIER_REGISTRATION.GET;
    } else {
      userType = AppSettings.APIsNameArray.SHIPPER.GET;
    }
    //get dynamic URI for APIs
    if (newparams) uri = this.commonService.getAPIUriFromParams(newparams);
    let APIparams = {
      apiKey: userType,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe(
      (ServerRes) => {
        this.loading = false;
        if (ServerRes.success === true) {
          this.getCarrierRecord = ServerRes.response;
        } else {
          this.loading = false;
        }
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  // Send invoice
  sendInvoiceFromSubmit({ value, valid }) {
    this.submitted = true;
    let postBody = {
      emailAddress: value.emailAddress,
      message: value.message,
      shipmentId: this.data.shipmentDetail.id,
    };
    if (valid) {
      this.loading = true;
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.NEWPAYMENT.SENDINVOICE,
        uri: '',
        postBody: postBody,
      };
      this.commonService.post(APIparams).subscribe(
        (ServerRes) => {
          if (ServerRes.success === true) {
            this.loading = false;
            this.alertService.showNotificationMessage(
              'success',
              'bottom',
              'right',
              'txt_s',
              'check_circle',
              'Invoice sent',
              'Invoice has sent successfully.'
            );
            this.dialogRef.close({
              event: 'Ok',
            });
          } else if (ServerRes.success === false) {
            // this.errorMessage = ServerRes.message;
            this.alertService.showNotificationMessage(
              'danger',
              'bottom',
              'right',
              'txt_d',
              'check_circle',
              'Invoice send',
              ServerRes.message
            );
            this.loading = false;
          }
        },
        (error) => {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_g',
            'error',
            'Unexpected Error',
            AppSettings.ERROR
          );
          this.loading = false;
        }
      );
    }
  }

  closPopupWaypoint(type: any) {
    this.dialogRef.close({ event: type });
  }
  onClose(): void {
    this.dialogRef.close({ event: 'close' });
  }

  verifiedRoute(data){
    let notVerified;
    this.routeStatus = [];
    for(let i= 0; i < data.length-1; i++){
      let location1 = {latitude :  data[i].latitude, longitude:data[i].longitude}; 
      let location2 = {latitude :  data[i+1].latitude, longitude:data[i+1].longitude}; 
      let origin = location1.latitude.toString()+','+location1.longitude.toString()
      let destination = location2.latitude.toString()+','+location2.longitude.toString()
        this.HereMapsService8.getRouteSummary(origin, destination)
          .subscribe(response => {
            this.totalLanemiles+=response.routes[0]?.sections[0].summary.length/1609.344;
            if(response.routes?.length)
            this.routeStatus[i]='Verified'
             else if(!response.routes?.length)
           { 
             notVerified = true;
            this.routeStatus[i]='No route available'
            this.notVerifiedLanes.push(data[i].title + ' '+data[i].sequence+ ' to ' +data[i+1].title + ' '+data[i+1].sequence+', ')
           }
          },(error) =>
        {
          notVerified = true;
          this.routeStatus[i]='Not Verified'
          this.notVerifiedLanes.push(data[i].title + ' '+data[i].sequence+ ' to ' +data[i+1].title + ' '+data[i+1].sequence+', ')
        });
    }

    setTimeout(() => {
      if(!notVerified){ 
      this.notVerifiedLanes = []
      this.dialogRef.close({ event: {status:'Ok', miles:this.totalLanemiles} })}
    }, 2000);

  }   

  trimNoRoute(location,index){
    if(this.notVerifiedLanes.length-1 == index){ 
        return location.slice(0,-2)  
    }else {
    return location
     }
  }

  onSuccessLanes(): void {
    this.router.navigate(['/booking/lane/postedLanes']);
    this.dialogRef.close({ event: 'OK' });
  }


  payLoadWaypoints(data){
    return {
      "latitude": data.latitude,
      "longitude": data.longitude,
      "locationLongName": data.locationLongName,
      "city": data.city,
      "state": data.state,
      "type": data.type,
      "zip":  data.zip.toString(),
      "locationOrder": data.locationOrder,
      "sequence": data.sequence,
      "commodity":data.commodity,
      "isUpdated":data.isUpdated
    }
  }

  onEditLanes({ value, valid }){
    this.submitted = true;
    if(valid) {
      this.editLaneAutoNot = false;
      this.dialogRef.close({ event: {data : this.laneEventData,zipCode : this.lanePostal, index:  this.data.index} });
      const body = document.getElementsByTagName('body')[0];
      body.classList.remove('userProfile_sugBox');
    }
  }

  autoLocAuto($event: GermanAddress) {
    this.editLaneAutoNot = true;
    this.EditLaneForm.get('editLocation').setValue($event.displayAddress);
    this.laneEventData = $event
  }

  editLaneAutoCompleteSelected(result){
    let checklocality=false;
    for (let i = 0; i < result.address_components.length; i++) {
      if (result.address_components[i].types[0] == "locality" || result.address_components[i].types[1] == "sublocality") {
        if (result.address_components[i].long_name) {
          this.shortNameError = '';
          this.shortNameValue = false;        } 
          checklocality=true; 
          this.lanePostal='';
      }else if (result.address_components[i].types[0] == "postal_code") {
        if (result.address_components[i].long_name) {
          this.lanePostal= result.address_components[i].long_name
        }
       break;
      } else{
        if(!checklocality){
          this.shortNameError = 'Please select city level address.';
          this.shortNameValue = true;
        }
      }
    }
    this.pickupCityError = '';
    if (this.disabledAssign ||  this.shortNameValue) {
      this.checkErrorMessage = true;
    } else {
      this.checkErrorMessage = false;
    }
    if (this.shortNameValue === true || this.shortNameDropValue === true) {
      this.checkErrorMessage = true;
    }
  }

  dimensionCombiner(index){
    let commodityForm =  this.wayPointEditForm.get('commodityForm').value;
    let dimension = (commodityForm[index].length ? commodityForm[index].length :'000') + 'x'+ (commodityForm[index].width ? commodityForm[index].width :'000') +'x'+(commodityForm[index].height ? commodityForm[index].height :'000')
    
   
    if (!commodityForm[index].length && !commodityForm[index].width && !commodityForm[index].height)
    {
      let control = <FormArray>this.wayPointEditForm.controls.commodityForm;
      control.at(index).get('length').setValidators([])
      control.at(index).get('height').setValidators([])
      control.at(index).get('width').setValidators([])
      this.wayPointEditForm.setControl('commodityForm', control);     
    }
    else if(!commodityForm[index].length || !commodityForm[index].width || !commodityForm[index].height)
    {
      let control = <FormArray>this.wayPointEditForm.controls.commodityForm;
      control.at(index).get('length').setValidators([Validators.required])
      control.at(index).get('height').setValidators([Validators.required])
      control.at(index).get('width').setValidators([Validators.required])
        this.wayPointEditForm.setControl('commodityForm', control);     
    }
    else 
    {
      let control = <FormArray>this.wayPointEditForm.controls.commodityForm;
      control.at(index).get('dimension').setValue(dimension)
      this.wayPointEditForm.setControl('commodityForm', control);    
    }
  }


// bid quotes edit locations
autoLocAutoBidQuote($event: GermanAddress) {
  this.editLaneAutoNot = true;
  this.EditLaneForm.get('editLocation').setValue($event.displayAddress);
  this.laneEventData = $event
}

 editBidAutoCompleted(result){
  let checklocality=false;
  for (let i = 0; i < result.address_components.length; i++) {
    if (result.address_components[i].types[0] == "locality" || result.address_components[i].types[1] == "sublocality") {
      if (result.address_components[i].long_name) {
        this.shortNameError = '';
        this.shortNameValue = false;        
      } 
        checklocality=true; 
        this.lanePostal='';
    }else if (result.address_components[i].types[0] == "postal_code") {
      if (result.address_components[i].long_name) {
        this.lanePostal= result.address_components[i].long_name
      }
     break;
    } else{
      if(!checklocality){
        this.shortNameError = 'Please select city level address.';
        this.shortNameValue = true;
      }
    }
  }
}
async onEditBidQuots({ value, valid }){
  this.submitted = true;
  if(valid) {
    this.pickupCityError=null;
      if(this.data.wayPointArrays.length > 1 && this.laneEventData){
        let location2;
        let location1 = {latitude :  this.laneEventData.geoLocation.latitude, longitude: this.laneEventData.geoLocation.longitude}; 
        if(this.data.index==0){
           location2 = {latitude :  this.data.wayPointArrays[this.data.index+1].latitude, longitude:this.data.wayPointArrays[this.data.index+1].longitude}; 
        }else if(this.data.index > 0){
           location2 = {latitude :  this.data.wayPointArrays[this.data.index-1].latitude, longitude:this.data.wayPointArrays[this.data.index-1].longitude};   
        }else{
           location2 = {latitude :  this.data.wayPointArrays[this.data.wayPointArrays.length-1].latitude, longitude:this.data.wayPointArrays[this.data.wayPointArrays.length-1].longitude}; 
        }
        this.checkRouteMatch = await this.verifiedRouteBidQoutes(location1,location2);
        if(this.checkRouteMatch){
          this.pickupCityError = null; 
          this.editLaneAutoNot = false;
          this.dialogRef.close({ event: {data : this.laneEventData,zipCode : this.lanePostal, index:  this.data.index} });
          const body = document.getElementsByTagName('body')[0];
          body.classList.remove('userProfile_sugBox'); 
        }else{
        this.pickupCityError ="No route found for this location, please update the location";
        }
      }else{
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('userProfile_sugBox'); 
      this.dialogRef.close({ event: {data : this.laneEventData,zipCode : this.lanePostal, index:  this.data.index} });        
      }
  }
}


async verifiedRouteBidQoutes(location1:any,location2:any){
  let origin = location1.latitude.toString() + ',' + location1.longitude.toString();
  let destination = location2.latitude.toString() + ',' + location2.longitude.toString();

  try {
    const response = await this.HereMapsService8.getRouteSummary(origin, destination, 'truck').toPromise();
    let miles = response.routes[0]?.sections[0].summary.length === 0 ? 1 : Math.floor(response.routes[0]?.sections[0].summary.length / 1609.344);
 if(response.routes?.length)
    return true
  else 
  {
    return false
  }
} catch (error) {
    return false;
}
  }

  purchaseNow(){
    this.router.navigate(['/carrier/premium-plan'])
    this.dialogRef.close({ event: 'close' });    
  }

  onCarrierDataScroll() {
    if (this.page !== this.totalPage) {
      this.skeletonLoader = true;
      this.page = this.page + 1;
      this.getRatingList();
    }
    else{
      this.skeletonLoader = true;

    }
  }
}
