import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { CoreRoutingModule } from './core.routing';
import { MaterialModule } from '../material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxLoadingModule } from 'ngx-loading';
import { DatePipe } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AgmCoreModule } from '@agm/core';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { ConnectAccountSuccessComponent } from './connect-account-success/connect-account-success.component';
import { SignUpInLoadingComponent } from './sign-up-in-loading/sign-up-in-loading.component';
import { loginNavbarComponent } from './login-navbar/login-navbar.component';
import { PopupInfoComponent } from './popup-info/popup-info.component';
import { PlanPurchaseComponent } from './plan-purchase/plan-purchase.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SignInComponent } from './sign-in/sign-in.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { FooterComponent } from './footer/footer.component';
import { SignUpVerifyComponent } from './sign-up-verify/sign-up-verify.component';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxLoadingModule,
    CoreRoutingModule,
    NgxSkeletonLoaderModule,
    NgxMatSelectSearchModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAMMBwZYg03hSwJEnODnIWf14YJKjWz_2A',
      libraries: ['places'],
    }),
    MatGoogleMapsAutocompleteModule,
    SharedModule
  ],
  declarations: [
    ConnectAccountSuccessComponent,
    loginNavbarComponent,
    PopupInfoComponent,
    SignUpInLoadingComponent,
    PlanPurchaseComponent,
    SignInComponent,
    ForgotPasswordComponent,
    FooterComponent,
    SignUpVerifyComponent
  ],

  entryComponents: [PopupInfoComponent],
  exports: [
    SignInComponent,
    ForgotPasswordComponent,
    FooterComponent,
    SignUpVerifyComponent
  ],
  providers: [DatePipe],
})
export class CoreModule { }
