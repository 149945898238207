import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'phone',
  pure: true,
})
export class PhonePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    let countryCodeMx=value?.slice(0,3)
    let countryCodeUSCA=value?.slice(0,2)
    if (value) {
      if(countryCodeMx =='+52' || countryCodeMx =='+91'){
      let firstNum = value?.slice(0, 4);
      let secondNum = value?.slice(4, 7);
      let middleNum = value?.slice(7, 10);
      let lastNum = value?.slice(10, 14);
     
      if(args)
      return args + ' '+firstNum + secondNum + '-' + middleNum + '-' + lastNum;
      else 
      {
        return  firstNum + secondNum + '-' + middleNum + '-' + lastNum;
      }
     
    }
    else  if(countryCodeUSCA =='+1'){
      let firstNum = value?.slice(0, 3);
      let secondNum = value?.slice(3, 6);
      let middleNum = value?.slice(6, 9);
      let lastNum = value?.slice(9, 13);
     
      if(args)
      return args + ' '+ firstNum + secondNum + '-' + middleNum + '-' + lastNum;
      else 
      {
        return  firstNum + secondNum + '-' + middleNum + '-' + lastNum;
      }
      // let firstNum = value.slice(3, 6);
      // let middleNum = value.slice(6, 9);
      // let lastNum = value.slice(9, 13);
      // if(args)
      // return args + ' '+firstNum + '-' + middleNum + '-' + lastNum;
      // else 
      // {
      //   return  firstNum + '-' + middleNum + '-' + lastNum;
      // }
    } else {
      return '-';
    }
  }
  }
}
