import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MaterialModule } from '../material.module';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgxLoadingModule } from 'ngx-loading';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { BannersComponent } from './banners/banners.component';
import { PopupComponent } from './popup/popup.component';
import { DocumentViewPopupComponent } from './document-view-popup/document-view-popup.component';
import { ViewNotificationComponent } from './view-notification/view-notification.component';
import { CarrierSidePopupComponent } from './carrier-side-popup/carrier-side-popup.component';
import { AssignDriverComponent } from './assign-driver/assign-driver.component';
import { OrderSidePanelComponent } from './order-side-panel/order-side-panel.component';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { ProgressComponent } from './progress/progress.component';
import { OrderProgressComponent } from './order-progress/order-progress.component';
import { AddressCardComponent } from './address-card/address-card.component';
import { PaymentCardComponent } from './payment-card/payment-card.component';
import { AchCardComponent } from './ach-card/ach-card.component';
import { PaymentUsdcCardComponent } from './payment-usdc-card/payment-usdc-card.component';
import { TwoDigitDecimalDirective } from './directive/twoDigitDecimal.directive';
import { PaymentPopupComponent } from './payment-popup/payment-popup.component';
import { UserOnboardingComponent } from './user-onboarding/user-onboarding.component';
import { BeforeLoginNavbarComponent } from './before-login-navbar/before-login-navbar.component';
import { TwoDigitDecimaNumberDirective } from './directive/appTwoDigitDecimaNumber.directive';
import { ZeroNotAllowFirstDirective } from './directive/zeroNotAllowFirst.directive';
import { RatePerMileComponent } from './rate-per-mile/rate-per-mile.component';
import { StripeAccountCreateService } from '../commons/service/stripeAccountCreate.service ';
import { ImageCropperModule } from 'ngx-image-cropper';
import { RatingComponent } from './rating/rating.component';
import { RatingViewComponent } from './rating-view/rating-view.component';
import { NotificationControlComponent } from './notification-control/notification-control/notification-control.component';
import { CarrierSidePanelComponent } from './carrier-side-panel/carrier-side-panel.component';
import { DriverSidePanelComponent} from './driver-side-panel/driver-side-panel.component';
import { ShipperSidePanelComponent } from './shipper-side-panel/shipper-side-panel.component';
import { TopBannerGuideComponent } from './top-banner-guide/top-banner-guide.component';
import { StorageProfileSidePanelComponent } from './storage-profile-side-panel/storage-profile-side-panel.component';
import { SubscriptionPopupComponent } from './subscription-popup/subscription-popup.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    MatInputModule,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule,
    NgxLoadingModule.forRoot({}),
    NgxSkeletonLoaderModule.forRoot({}),
    NgxMatSelectSearchModule,
    InfiniteScrollModule,
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyAMMBwZYg03hSwJEnODnIWf14YJKjWz_2A',
    //   libraries: ['places'],
    // }),
    MatGoogleMapsAutocompleteModule,
    ImageCropperModule,
  ],
  declarations: [
    BannersComponent,
    PopupComponent,
    DocumentViewPopupComponent,
    ViewNotificationComponent,
    AssignDriverComponent,
    CarrierSidePopupComponent,
    AddressCardComponent,
    PaymentCardComponent,
    TwoDigitDecimalDirective,
    PaymentUsdcCardComponent,
    PaymentPopupComponent,
    UserOnboardingComponent,
    TwoDigitDecimaNumberDirective,
    BeforeLoginNavbarComponent,
    ZeroNotAllowFirstDirective,
    RatePerMileComponent,
    AchCardComponent,
    OrderSidePanelComponent,
    OrderProgressComponent,
    RatingComponent,
    RatingViewComponent,
    ProgressComponent,
    NotificationControlComponent,
    DriverSidePanelComponent,
    CarrierSidePanelComponent,
    ShipperSidePanelComponent,
    StorageProfileSidePanelComponent,
    TopBannerGuideComponent,
    SubscriptionPopupComponent
  ],
  entryComponents: [
    BannersComponent,
    PopupComponent,
    DocumentViewPopupComponent,
    ViewNotificationComponent,
    AssignDriverComponent,
    CarrierSidePopupComponent,
    AddressCardComponent,
    PaymentUsdcCardComponent,
    PaymentPopupComponent,
    UserOnboardingComponent,
    OrderSidePanelComponent,
    NotificationControlComponent,
    TopBannerGuideComponent
  ],
  providers: [StripeAccountCreateService],
  exports: [
    BannersComponent,
    PopupComponent,
    DocumentViewPopupComponent,
    DragDropModule,
    ViewNotificationComponent,
    AssignDriverComponent,
    CarrierSidePopupComponent,
    AddressCardComponent,
    PaymentCardComponent,
    TwoDigitDecimalDirective,
    PaymentUsdcCardComponent,
    UserOnboardingComponent,
    TwoDigitDecimaNumberDirective,
    ZeroNotAllowFirstDirective,
    BeforeLoginNavbarComponent,
    RatePerMileComponent,
    AchCardComponent,
    OrderSidePanelComponent,
    OrderProgressComponent,
    RatingComponent,
    RatingViewComponent,
    ProgressComponent,
    NotificationControlComponent,
    DriverSidePanelComponent,
    CarrierSidePanelComponent,
    ShipperSidePanelComponent,
    StorageProfileSidePanelComponent,
    TopBannerGuideComponent

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule { }
