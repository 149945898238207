import { Component, Input, EventEmitter, Output } from '@angular/core';
import { MatDialog, } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder, } from '@angular/forms';
import { CommonService } from '../../commons/service/common.service';
import { AppSettings } from '../../commons/setting/app_setting';
import { AlertService } from '../../commons/service/alert.service';
import { PopupComponent } from '../../shared/popup/popup.component';
import { StatusSetting } from '../../commons/setting/status_setting';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'plan-purchase',
  templateUrl: './plan-purchase.component.html',
  styleUrls: ['./plan-purchase.component.css']
})
export class PlanPurchaseComponent {
  public stripe: any;
  @Input() planId: any;
  public afterLogin: any
  public firstIndex: boolean = false
  public dayPlanPrice: any;
  public monthPlanPrice: any;
  public yaerPlanPrice: any;
  public afterPercentAmount: any
  public trialDay = '0';
  public showActualPrice = true;
  public message: any
  public spinnerLoader = false
  public checkBox: any
  public FixedAmount: any
  public amountOff: any
  public percentOff: any;
  public promoCodeId: any
  public showCouponCode: any
  public centPrice: boolean = true
  public centDollar: boolean = false

  public isFreeTracking: any;
  public card: any;
  public saveCardButton = true;
  public showCouponCodeFeild: boolean = false;
  public showSuccessMessage: boolean = false;
  public addcouponCodeBtn: boolean = true
  public PlanPrice: any
  public payPlan: any;
  public currentPay = 'big_card';
  public selectPlanName: any;
  public selectAmount: any;
  public addInterTime: any;
  public selectPlanDate: any;
  public carrierDetails: any = {};
  public states: any;
  public loading = false;
  public cardListing = [];
  public currentCardActive: any;
  public paymentCardId: any;
  public index: any
  public paymentCard: FormGroup;
  public serverPathForUrl: any;
  public couponCodeForm: FormGroup;
 public planTrue:any
 public month:any;
 public day:any
 public year:any
  @Output() logoutUser = new EventEmitter();

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private alertService: AlertService,
  ) {
  }

  ngOnInit() {
  
    this.serverPathForUrl = environment.serverPathForUrl;
    this.promoCodeId = localStorage.getItem('promotion_code_id')
    this.showCouponCode = localStorage.getItem('couponCode')
    this.percentOff = localStorage.getItem('percentOff')
    this.amountOff = localStorage.getItem('amountOff')
    this.index = localStorage.getItem('index')
    this.afterLogin = localStorage.getItem('login')
    this.getPlanListing();
    this.isFreeTracking = localStorage.getItem("is_free_tracking");
    this.states = StatusSetting.state;
    this.paymentCard = this.formBuilder.group({
      cardName: ['', [Validators.required]],
    });
    this.cardList();
    this.couponCodeForm = this.formBuilder?.group({
      promoCode: [localStorage.getItem('couponCode') ? localStorage.getItem('couponCode') : '',
        [
          // Validators.minLength(2),
          Validators.maxLength(20),
          Validators.pattern(/^[a-z0-9]+([-\s]{1}[a-z0-9]+)*$/i)
        ],
      ],
    })
  }

  // Save and Exit during registration
  saveExist() {
    localStorage.removeItem('user_type');
    localStorage.removeItem('premium');
    localStorage.removeItem('tag');
    localStorage.removeItem('userProfileImage');
    localStorage.removeItem('access_token');
    localStorage.removeItem('userName');
    localStorage.removeItem('user_id');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('showExpiryPopup');
    localStorage.removeItem('email_verified');
    localStorage.removeItem('phone_verified');
    localStorage.removeItem('onboarding');
    localStorage.removeItem('promotion_code_id')
    localStorage.removeItem('couponCode')
    localStorage.removeItem('percentOff')
    localStorage.removeItem('amountOff')
    localStorage.removeItem('amountOff')
    localStorage.removeItem('index')
    localStorage.removeItem('month')
    localStorage.removeItem('day')
    localStorage.removeItem('year');
    localStorage.removeItem('monthPlan')
    localStorage.removeItem('dayPlan')
    localStorage.removeItem('yearPlan')
    sessionStorage.clear();
    this.router.navigate(['/']);
  }

  // Function for generate duplicate loaders 
  generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }

  // To calculate fixed price
  priceFixed(price: any) {
    const newPrice = (price / 100).toFixed(2);
    return newPrice;
  }

  // To calculate fixed price for month
  priceFixedMonth(price: any, duration: any) {
    const newPrice = (price / 100);
    const perMonth = (newPrice / duration);
    return perMonth;
  }

  // API call to get GTFO plan data
  getPlanListing(params = {}) {
    let uri = null;
    let paramsNew = {
    }
    if (paramsNew)
      uri = this.commonService.getAPIUriFromParams(paramsNew);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.NEWPAYMENT.GETPRICEGTFO,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      if (ServerRes.success === true) {
        this.payPlan = ServerRes.response.data;
        for (let v = 0; v < this.payPlan.length; v++) {
          if (localStorage.getItem('promotion_code_id') && localStorage.getItem('index') && (localStorage.getItem('percentOff') || localStorage.getItem('amountOff'))) {
          let index:any=localStorage.getItem('index')
            if(index==v){
              this.payPlan[v].indexType = true;
              this.payPlan[v].indexMessage = true;
              this.payPlan[v].indexFeild = false;
              // this.index=index
            }
            else if(index!=v){
              this.payPlan[v].indexType = true;
              this.payPlan[v].indexMessage = false;
              this.index=index

            }
          }
          else{
            this.payPlan[v].indexType = false;
            this.payPlan[v].indexMessage = false;
            this.addcouponCodeBtn = true
          }
          
        }
        localStorage.removeItem('monthPlan')
    localStorage.removeItem('dayPlan')
    localStorage.removeItem('yearPlan')
        localStorage.removeItem('day')
        localStorage.removeItem('month')
        localStorage.removeItem('year')
        this.showCouponCodeAmount(this.percentOff, this.amountOff, this.index)
        this.dayPlanPrice = this.payPlan[0]?.unit_amount;
        this.monthPlanPrice = this.payPlan[1]?.unit_amount;
        this.yaerPlanPrice = this.payPlan[2]?.unit_amount;
        this.day=localStorage.getItem('day')
        this.month=localStorage.getItem('month')
        this.year=localStorage.getItem('year')
      } else {
        this.payPlan = [];
      }
    },
      error => {
        this.payPlan = [];
      },
    );
  }

  // Not found in HTML/TS 
  subscriptionPlanDetail(planDetails: any) {
    const dialogRef = this.dialog.open(PopupComponent, {
      disableClose: true,
      data: { openPop: "showSubscriptionPlanDetail", plans: planDetails }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  // To select plan by mini_card
  miniSelectPlan(event: any, amount: any, timeInterval: any, interval: any,index:any) {
    this.selectAmount=index

    let newDate = new Date();
    let new_date: any;
    if (interval === 'day') {
      new_date = moment(newDate).add(timeInterval, 'd');
     if(localStorage.getItem('day') !=='true' || (localStorage.getItem('year') !=='true' && localStorage.getItem('month') !=='true')){
      this.planTrue=true

     }
    if(amount < 299){
      localStorage.setItem('dayPlan','true');
      }
      // else{
      //   localStorage.setItem('dayPlan','false');

      // }
      
    } else if (interval === 'month') {
      new_date = moment(newDate).add(timeInterval, 'M');
      if(localStorage.getItem('month') !=='true' || (localStorage.getItem('day') !=='true' && localStorage.getItem('year') !=='true')){
        this.planTrue=true;
       }
      if(amount < 999){
        localStorage.setItem('monthPlan','true');
        }
        // else{
        //   localStorage.setItem('monthPlan','true');
  
        // }
    } else {
      new_date = moment(newDate).add(timeInterval, 'y');
      if(localStorage.getItem('year') !=='true' || (localStorage.getItem('day') !=='true' && localStorage.getItem('month') !=='true')){
        this.planTrue=true

       }
      new_date = moment(newDate).add(timeInterval, 'y');
      if(amount < 9900){
        localStorage.setItem('yearPlan','true');

        }
        // else{
        //   localStorage.setItem('yearPlan','true');

        // }
    }
    this.selectPlanDate = new_date;
    this.selectPlanDate = new Date(this.selectPlanDate);
    // this.selectAmount = (amount / 100).toFixed(2);
    this.selectPlanName = event;
    this.addInterTime = timeInterval + " " + interval;
  }

  // To select plan by big_card
  selectPlan(event: any, amount: any, timeInterval: any, interval: any,index:any) {
    this.selectAmount=index;
    let newDate = new Date();
    let new_date: any;
    if (interval === 'day') {
      new_date = moment(newDate).add(timeInterval, 'd');
      if(amount < 299){
      localStorage.setItem('dayPlan','true');
      }
      else{
        localStorage.setItem('dayPlan','false');

      }
    } else if (interval === 'month') {
      new_date = moment(newDate).add(timeInterval, 'M');
      if(amount < 999){
        localStorage.setItem('monthPlan','true');
        }
        else{
          localStorage.setItem('monthPlan','false');
  
        }
    } else {
      new_date = moment(newDate).add(timeInterval, 'y');
      if(amount < 9900){
        localStorage.setItem('yearPlan','true');
        }
        else{
          localStorage.setItem('yearPlan','false');
  
        }
    }
    this.selectPlanDate = new_date;
    // this.selectAmount = (amount / 100).toFixed(2);
    this.currentPay = 'mini_card';
    this.selectPlanName = event;
    this.addInterTime = timeInterval + " " + interval;
    if (this.cardListing.length == 0) {
      this.saveCardButton = true;
    }
  }

  // To submit paymentCard form
  async subscriptionPay({ value, valid }) {
    if (this.paymentCardId != null) {
      this.loading = true;
      this.saveCardButton = true;
      this.paymentIntentSub(this.paymentCardId);
    }
  }

  // API call for create subscription
  paymentIntentSub(paymentMethodId: any) {
    let postBody = null;
    if(localStorage.getItem('dayPlan')=='true' && this.planTrue && this.selectAmount=='0'){
      postBody = {
        'paymentMethodId': paymentMethodId,
        'customerId': this.carrierDetails.stripeCustomerId,
        'priceId': this.selectPlanName,
        'promotion_code_id': localStorage.getItem('promotion_code_id')
      }
    }
    else if(localStorage.getItem('monthPlan')=='true'  && this.planTrue && this.selectAmount=='1'){
      postBody = {
        'paymentMethodId': paymentMethodId,
        'customerId': this.carrierDetails.stripeCustomerId,
        'priceId': this.selectPlanName,
        'promotion_code_id': localStorage.getItem('promotion_code_id')
      }
    }
    else if(localStorage.getItem('yearPlan')=='true'  && this.planTrue && this.selectAmount=='2'){
      postBody = {
        'paymentMethodId': paymentMethodId,
        'customerId': this.carrierDetails.stripeCustomerId,
        'priceId': this.selectPlanName,
        'promotion_code_id': localStorage.getItem('promotion_code_id')
      }
    }
    else{
      postBody = {
        'paymentMethodId': paymentMethodId,
        'customerId': this.carrierDetails.stripeCustomerId,
        'priceId': this.selectPlanName,
      }
    }
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.NEWPAYMENT.CREATE,
      uri: '',
      postBody: postBody,
    };
    this.commonService.post(APIparams).subscribe(
      success => {
        if (success.success == true) {
          if (success.response.paymentIntent_status == 'requires_payment_method') {

            this.paymentStatus('fail_payment', 'Purchase Failed', 'It looks like there was a problem with your subscription purchase');

          } else if (success.response.paymentIntent_status == 'requires_action') {

            this.paymentConfirmation(success.response.paymentIntent_secret);
          }
          else if (success.response.subscription_status == "active" || success.response.subscription_status == "trialing") {
            this.paymentStatus('success_payment', 'Success', 'Subscription plan purchased successfully');
            localStorage.setItem("premium", '1');
            this.loading = false;
          }
        }
        else if (success.success == false) {
          this.saveCardButton = false;
          this.paymentStatus('fail_payment', 'Purchase Failed', 'It looks like there was a problem with your subscription purchase');
          this.loading = false;
        }
      },
      error => {
        this.paymentStatus('fail_payment', 'Purchase Failed', 'It looks like there was a problem with your subscription purchase');
        this.saveCardButton = false;
        this.loading = false;
      },
    );
  }

  // To show payment confirmation
  async paymentConfirmation(clientsecret: any) {
    if (clientsecret) {
      const { paymentIntent, error } = await this.stripe.confirmCardPayment(clientsecret,
        {
          payment_method: this.paymentCardId,
        },
      );
      if (error) {
        this.loading = false;
        this.paymentStatus('fail_payment', 'Purchase Failed', 'It looks like there was a problem with your subscription purchase');
      } else {
        if (paymentIntent) {
          if (paymentIntent.status == "succeeded") {
            this.paymentCardId = "";
            this.paymentStatus('success_payment', 'Success', 'Subsription plan purchased successfully')
            localStorage.setItem("premium", '1');
          }
        }
        this.loading = false;
      }
    }
  }

  // Popup to show payment status
  paymentStatus(type: any, title: any, contain: any): void {
    const dialogRef = this.dialog.open(PopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      // height:AppSettings.popHeight,
      data: { openPop: type, title: title, contain: contain }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.event == "Ok") {
      }
    });
  }

  // Function to select card for payment
  selectCard(event) {
    this.currentCardActive = event;
    this.paymentCardId = event;
    this.saveCardButton = false;
  }

  // Function to remove selected card 
  removeCard(id: any) {
    this.cardConfirmationCard(id);
  }

  // Popup for card confirmation removal
  cardConfirmationCard(id: any) {
    const dialogRef = this.dialog.open(PopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      // height:AppSettings.popHeight,
      data: { openPop: "cardConfirmation" }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.event == "Ok") {
        this.removePaymentMethod(id);
      }
    });
  }

  // API call for remove payment method
  removePaymentMethod(id: any) {
    this.loading = true;
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.LOADPAYMENT.REMOVEPAYMENT,
      uri: '',
      postBody: { 'type': 'card', 'cardId': id },
    };
    this.commonService.post(APIparams).subscribe(
      success => {
        if (success.success == true) {
          this.alertService.showNotificationMessage("success", "bottom", "right", 'txt_s', 'check_circle', 'Payment Method', "You have successfully removed the card details.");
          this.loading = false;
          this.cardList();
        } else if (success.success == false) {
          this.loading = false;
          this.alertService.showNotificationMessage("success", "bottom", "right", 'txt_d', 'check_circle', 'Payment Method', success.message);
        }
      },
      error => {
        this.alertService.showNotificationMessage("danger", "bottom", "right", 'txt_g', 'error', 'Unexpected Error', AppSettings.ERROR);
        this.loading = false;
      },
    );
  }

  // API call for card listing
  cardList(params = {}) {
    let uri = null;
    //get dynamic URI for APIs
    let paramsNew = {
      "type": "card",
    }
    if (paramsNew)
      uri = this.commonService.getAPIUriFromParams(paramsNew);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.LOADPAYMENT.CARDLIST,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      if (ServerRes.success === true) {
        this.cardListing = ServerRes.response.data;
      } else {
        this.cardListing = [];
      }
    },
      error => {
        this.cardListing = [];
      },
    );
  }

  // To add new card for payment
  addPaymentCard() {
    const dialogRef = this.dialog.open(PopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      // height:AppSettings.popHeight,
      data: { openPop: "addPaymentCardPopup" }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.event == "Success") {
        this.cardList();
      }
    });
  }
  showCouponCodeAmount(percentOff: any, amountOff: any, index) {
    if (percentOff != null) {
      let amount: any = (this.payPlan[index]?.unit_amount / 100)
      let removeDollar: any = amount;
      let percentageAmount: any = (removeDollar * percentOff) / 100;
      this.afterPercentAmount = (removeDollar - percentageAmount);
      // if(this.afterPercentAmount){
      if (this.afterPercentAmount?.toString()?.includes('.')) {
        let amountPer = this.afterPercentAmount?.toFixed(2);
        this.payPlan[index].unit_amount = (amountPer * 100)
        this.FixedAmount = this.payPlan[index]?.unit_amount;
        this.centPrice = true;
        this.planTrue=true

        localStorage.setItem('index', index)
        this.index=  localStorage.getItem('index')
        localStorage.setItem(this.payPlan[index]?.recurring.interval,'true')

      }
      else if (!this.afterPercentAmount?.toString()?.includes('.')) {
        this.payPlan[index].unit_amount = (this.afterPercentAmount * 100);
        this.FixedAmount = this.payPlan[index]?.unit_amount
        this.centPrice = true;
        this.planTrue=true
        localStorage.setItem('index', index)
        this.index=  localStorage.getItem('index')

        localStorage.setItem(this.payPlan[index]?.recurring.interval,'true')
      }
      // }      
    }
    if (amountOff != null) {
      let amount: any = (this.payPlan[index]?.unit_amount / 100)
      this.afterPercentAmount = (amount - amountOff);
      if (this.afterPercentAmount?.toString()?.includes('.') && !this.afterPercentAmount?.toString()?.includes('-')) {
        this.payPlan[index].unit_amount = (this.afterPercentAmount * 100)?.toFixed(2);
        this.FixedAmount = this.payPlan[index]?.unit_amount
        localStorage.setItem('index', index)
        this.centPrice = true;
        this.planTrue=true
        this.index=  localStorage.getItem('index')


        localStorage.setItem(this.payPlan[index]?.recurring.interval,'true')
      }
      else if (!this.afterPercentAmount?.toString()?.includes('.') && !this.afterPercentAmount?.toString()?.includes('-')) {
        this.payPlan[index].unit_amount = (this.afterPercentAmount * 100);
        this.FixedAmount = this.payPlan[index]?.unit_amount;
        localStorage.setItem('index', index)
        this.centPrice = true;
        this.planTrue=true
        this.index=  localStorage.getItem('index')


        localStorage.setItem(this.payPlan[index]?.recurring.interval,'true')
      }
      else if (this.afterPercentAmount?.toString()?.includes('-')) {
        this.payPlan[index].unit_amount = 0;
        this.FixedAmount = this.payPlan[index]?.unit_amount;
        localStorage.setItem('index', index)
        localStorage.setItem(this.payPlan[index]?.recurring.interval,'true')
        this.planTrue=true
        this.index=  localStorage.getItem('index')


        this.centPrice = true
      }
    }
  }

  // remove promocode

  removeCouponCode(index) {
    this.couponCodeForm.reset();
    localStorage.removeItem('promotion_code_id')
    localStorage.removeItem('couponCode')
    localStorage.removeItem('percentOff')
    localStorage.removeItem('amountOff')
    localStorage.removeItem('amountOff')
    localStorage.removeItem('month')
    localStorage.removeItem('day')
    localStorage.removeItem('year')
    localStorage.removeItem('index');
    localStorage.removeItem('monthPlan')
    localStorage.removeItem('dayPlan')
    localStorage.removeItem('yearPlan')
    for (let v = 0; v < this.payPlan?.length; v++) {
      if (v == index){
        this.payPlan[v].indexMessage = false;
        this.payPlan[v].indexType = false;
        this.payPlan[v].indexFeild = false;
        this.couponCodeForm.reset();
        this.message = null;
        this.spinnerLoader = false
      }
      else if(v != index){
        this.payPlan[v].indexType = false;
      }
    }
    this.showActualPrice = false
    this.message = null;
    this.spinnerLoader = false
    if (index == '0') {
      this.payPlan[index].unit_amount = 299;
      this.centDollar = false;
      this.centPrice = true;

    }
    else if (index == '1') {
      this.payPlan[index].unit_amount = 999;
      this.centDollar = false;
      this.centPrice = true;
    }
    else if (index == '2') {
      this.payPlan[index].unit_amount = 9900
      this.centDollar = false;
      this.centPrice = true;
    }
  }

  addAnotherPromoCode(index) {
    localStorage.removeItem('promotion_code_id')
    localStorage.removeItem('couponCode')
    localStorage.removeItem('percentOff')
    localStorage.removeItem('amountOff')
    localStorage.removeItem('amountOff')
    localStorage.removeItem('month')
    localStorage.removeItem('day')
    localStorage.removeItem('year')
    localStorage.removeItem('monthPlan')
    localStorage.removeItem('dayPlan')
    localStorage.removeItem('yearPlan')
    localStorage.removeItem('index')
    for (let v = 0; v < this.payPlan?.length; v++) {
      if (index ==v ){
        this.payPlan[v].indexType = true;
        this.payPlan[v].indexMessage = false;
        this.payPlan[v].indexFeild = true;

        this.message = null;
        this.spinnerLoader = false
      }
      else if(index !=v){
        this.payPlan[v].indexType = false;

      }
    }
    this.showActualPrice = false
    
    this.message = null;
    this.spinnerLoader = false
    if (index == '0' && this.dayPlanPrice) {
      this.payPlan[index].unit_amount = 299;
      this.centDollar = false;
      this.centPrice = true;
    }
    else if (index == '1' && this.dayPlanPrice) {
      this.payPlan[index].unit_amount = 999;
      this.centDollar = false;
      this.centPrice = true;
    }
    else if (index == '2' && this.yaerPlanPrice) {
      this.payPlan[index].unit_amount = 9990
      this.centDollar = false;
      this.centPrice = true;
    }
  }


  //  add a coupon code
  addCouponCode(index) {
    for (let v = 0; v < this.payPlan?.length; v++) {
      if (v == index){
        this.payPlan[v].indexType = true;
        this.payPlan[v].indexFeild = true;
        this.payPlan[v].indexMessage = false;

      this.message = false;
      this.spinnerLoader = false
      this.couponCodeForm.reset()
      }
      else if(v != index){
        this.payPlan[v].indexType = false;
        this.payPlan[v].indexMessage = false;
        this.payPlan[v].indexFeild = false;
      }
    }
  }
  // close coupon code feild
  closeCouponCodeFeild(index) {
    for (let v = 0; v < this.payPlan?.length; v++) {
      if (v == index){
        this.payPlan[v].indexType = false;
        this.payPlan[v].indexFeild = false;
        this.couponCodeForm.reset();
        this.message = null;
        this.spinnerLoader = false
      }
      else if (v != index){
        this.payPlan[v].indexType = false;
      }
    }
  }

  onSubmitSouponCode({ value, valid }, i) {
    this.index = i
    this.spinnerLoader = true;
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.SUBSCRIPTION.ADDCOUPONCODE,
      uri: '',
      postBody:{'promoCode': value?.promoCode?.trim()} ,
      
    };
    this.commonService.post(APIparams).subscribe(
      (success) => {
        if (success.success === true) {
          localStorage.setItem('index',i)
          for (let v = 0; v < this.payPlan?.length; v++) {
            if (i==v){
              this.payPlan[v].indexMessage = true;
              this.payPlan[v].indexType = true;
             this.payPlan[v].indexFeild = false;
            }
            else if(i !=v){
              this.payPlan[v].indexType = true;
              this.payPlan[v].indexMessage = false;
            }
          }
          this.addcouponCodeBtn = false
          this.showCouponCodeFeild = false;
          this.amountOff = success?.response?.amountOff;
          this.percentOff = success?.response?.percentOff;
          this.promoCodeId = success?.response?.promoCodeId;
          this.showActualPrice = true
          this.index = i
          localStorage.setItem('promotion_code_id', this.promoCodeId)
          localStorage.setItem('couponCode', value?.promoCode)
          if (success?.response?.amountOff) {
            localStorage.setItem('amountOff', success?.response?.amountOff)
          }
          if (success?.response?.percentOff) {
            localStorage.setItem('percentOff', success?.response?.percentOff)
          }
          if (success?.response?.percentOff) {
            this.showCouponCodeAmount(this.percentOff, null, this.index)
          }
          if (success?.response?.amountOff) {
            this.showCouponCodeAmount(null, this.amountOff, this.index)
          }
          this.spinnerLoader = false;
        } else if (success.success === false) {
          this.message = success.message
          this.showSuccessMessage = false;
          this.showCouponCodeFeild = true;
          this.spinnerLoader = false;
        }
      },
      (error) => {
        this.message = AppSettings.ERROR;
        this.spinnerLoader = false;
      }
    );
    // }
  }

}
