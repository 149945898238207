<div *ngIf="bannerHomeSub === 'homebanner' && showBanner" class="container home_banner">
	<div class="banner_caed border_lp">
		<div class="row">
			<div class="col-md-8">
				<p class="line_beforeQW fs_20 fsb_6 txt_b mb-3 f_clash"> Welcome to the LaneAxis Network Portal.</p>
				<span class="fs_16 fsn_4 txt_lb">Click the "Steps to Get Started" button on the right for tips to get you up and running quickly.</span>
			</div>
			<div class="col-md-4">
				<div class="banner_action">
					<button class="btn custum_button button_primary" (click)="startTapTop()">Steps to Get Started</button>
					<button class="btn custum_button button_primary skip_btn" (click)="dismissBanner()" style="cursor: pointer;">Skip</button>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- Home Banner -->
<div *ngIf="false && subcriptionType ==='showConnectBanner'" class="container home_banner">
	<div class="banner_caed border_lp">
		<div class="row">
			<div class="col-md-8">
				<p class="line_beforeQW fs_20 fsb_6 txt_b mb-3 f_clash">Stripe Connect Account</p>
				<span class="fs_16 fsn_4 txt_lb"><span class="fsm_5">Alert:</span> To receive payments on your shipment,
					you are required to create a Stripe connect account.</span>
			</div>
			<div class="col-md-4">
				<div class="banner_action">
					<button class="btn custum_button button_primary" (click)="connectAccount()">Create Connect
						Account</button>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="container home_banner" *ngIf="false && subcriptionType ==='showConnectBanner'">
	<div class="banner_caed border_lp">
		<div class="row">
			<div class="col-md-8">
				<p class="line_beforeQW fs_20 fsb_6 txt_b mb-3 f_clash" *ngIf="userType != 'STORAGE'">Start Accepting
					Shipper Direct Loads:</p>
				<p class="line_beforeQW fs_20 fsb_6 txt_b mb-3 f_clash" *ngIf="userType == 'STORAGE'">Start allowing
					Shippers to Book Storage/Warehouse Capacity</p>
				<span class="fs_16 fsn_4 txt_lb" *ngIf="userType != 'STORAGE'"><span class="fsm_5">To Begin:</span>
					Click the button to the right and
					complete the two required steps.</span>
				<span class="fs_16 fsn_4 txt_lb" *ngIf="userType == 'STORAGE'"><span class="fsm_5">To Begin:</span>
					Click the button to the right and complete your Stripe registration.</span>
			</div>
			<div class="col-md-4">
				<div class="banner_action">
					<!-- Start Steps -->
					<button class="btn custum_button button_primary" (click)="COIStripeSteps()"
						*ngIf="userType != 'STORAGE'"> Steps to Get Started 
					</button>
					<button class="btn custum_button button_primary" (click)="COIStripeSteps()"
						*ngIf="userType == 'STORAGE'">Create Account
					</button>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="container" *ngIf="bannerType==='certificate' && checkValueCertificate === null">
	<div class="banner_caed border_lp">
		<p class="line_beforeQW fs_20 fsb_6 txt_b mb-3 f_clash">Certificate of Insurance</p>
		<span class="close_icon icon-ic-cross" (click)="setWithExpiry('certificate_insurance_expired')"></span>
		<div class="row">
			<div class="col-md-8">
				<span class="fs_16 fsn_4 txt_lb">Your Certificate of Insurance is expired on </span>
				<span class="fs_16 fsm_5 txt_b"> {{carrierInfo.insuranceCertificateExpireDate | date: 'MMM dd,
					yyyy'}}</span>
				<p class="mb-0 fs_16 fsn_4 txt_lb">Update COI and notify your network to accept the insurance document
				</p>
			</div>
			<div class="col-md-4 text-end">
				<button class="btn custum_button button_primary" (click)="openView()">Update and Notify</button>
			</div>
		</div>
	</div>
</div>

<!-- Banner with Action -->
<div class="container" *ngIf="bannerType==='credit' && checkValue === null">
	<div class="banner_caed border_lp">
		<p class="line_beforeQW fs_20 fsb_6 txt_b mb-3 f_clash">Purchase Loads</p>
		<span class="close_icon icon-ic-cross" (click)="setWithExpiry('load_expired')"></span>
		<div class="row">
			<div class="col-md-8">
				<p class="mb-0 fs_16 fsn_4 txt_lb">Laneaxis Network gives you the ability to send your shippers a
					real-time tracking link, letting customers know they can bypass brokers and go direct with YOU.</p>
			</div>
			<div class="col-md-4 text-end">
				<button class="btn custum_button button_primary" [routerLink]="['/carrier/subscription/payment']">Buy
					More Loads</button>
			</div>
		</div>
	</div>
</div>

<div class="container" *ngIf="bannerTypeSub==='subscription' && showSubscriptionBanner==='0'">
	<div class="banner_caed border_lp">
		<p class="line_beforeQW fs_20 fsb_6 txt_b mb-3 f_clash">Your subscription expires soon</p>
		<span class="close_icon icon-ic-cross" (click)="subBannerHide()"></span>
		<div class="row">
			<div class="col-md-8">
				<p class="mb-0 fs_16 fsn_4 txt_lb">
					<span>Your Auto-Renew is Off. <br> Your subscription will not automatically renew.</span>
				</p>
			</div>
			<div class="col-md-4 text-end">
				<button class="btn custum_button button_primary txt_p ms-4" style="background: #e3f1ff;"
					[routerLink]="['/payment/overview']">Turn Auto-Renew</button>
			</div>
		</div>
	</div>
</div>

<!-- Banner with Image -->
<div class="container" style="display: none;">
	<div class="banner_caed border_lp">
		<span class="close_icon icon-ic-cross"></span>
		<div class="row">
			<div class="col-md-8">
				<p class="fs_20 fsb_6 txt_b f_clash">Your Subscription plan will expire by Sep 21, 2020</p>
				<p class="mb-0 fs_16 fsn_4 txt_lb">Renew your plan before expiry to continue with the services of
					LaneAxis Mobile Application.</p>
			</div>
			<div class="col-md-4">
				<div class="plan_expire_img">
					
					<img src="https://staticprod.laneaxis.com/portal/images/after-login/Empty_State_icons/graphic_no-transactions.svg"
						class="img-fluid">
				</div>
			</div>
		</div>
	</div>
</div>