import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../environments/environment';
import {  Router } from '@angular/router';
import { CommonService } from '../../commons/service/common.service';
import { AppSettings } from '../../commons/setting/app_setting';
@Component({
  selector: 'app-before-login-navbar',
  templateUrl: './before-login-navbar.component.html',
  styleUrls: ['./before-login-navbar.component.css'],
})
export class BeforeLoginNavbarComponent implements OnInit {
  @Input() currentTab;
  public userLogin = false;
  public isLogin: any;
  public serverPathForUrl: any;
  public logo:any;
  constructor(
    private router: Router,
    private commonService: CommonService
  ) {}
  ngOnInit() {
    this.logo =AppSettings.logo;
    this.isLogin = localStorage.getItem('login');
    this.serverPathForUrl = environment.serverPathForUrl;
    if (localStorage.getItem('access_token') !== null) {
      this.userLogin = true;
    } else {
      this.userLogin = false;
    }
  }
  activeTab(value: any) {
    this.currentTab = value;
  }

  loginPage() {
    if (localStorage.getItem('access_token')) {
      this.commonService.sendTokanUpdate.next();
    }
    this.router.navigate(['/']);
  }
}
