import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
declare var $: any;
@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private http: HttpClient, private router: Router) {}
  showNotification(color, form, align, message) {
    const type = color;
    $.notify(
      {
        icon: 'notifications',
        message: message,
      },
      {
        allow_dismiss: true,
        //newest_on_left: true,
        placement: {
          from: form,
          align: align,
        },
        type: type,
        delay: 2000,
        timer: 2000,
        template:
          '<div data-notify="container" class="col-xl-3 col-lg-3 col-11 col-sm-3 col-md-3 success_alert alert alert-{0} alert-with-icon" role="alert" style="background-color: var(--clWhta); box-shadow: #091e424f 0px 0px 1px, #091e4240 0px 0px 12px -8px; color: #42526e; max-width: 400px; border-radius: 3px; padding-left: 56px;">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons txt_g" style="font-size: 16px; font-weight: bold; padding: 8px 0 0;">close</i></button>' +
          '<i class="material-icons txt_s" data-notify="icon" style="font-size: 24px; top: auto; margin-top: auto;">check_circle</i> <i class="material-icons txt_d" data-notify="icon" style="font-size: 24px; top: auto; margin-top: auto;">cancel</i> <i class="material-icons txt_g" data-notify="icon" style="font-size: 24px; top: auto; margin-top: auto;">error</i> ' +
          '<span data-notify="title" style="font-size:14px; font-weight: bold;" class="f_clash">{1}</span> ' +
          '<span data-notify="message" style="font-size:14px; font-weight: 400; max-height: 60px; overflow: hidden; padding: 5px 0 0;">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          '</div>' +
          '<a href="" target="" data-notify="url"></a>' +
          '</div>',
      }
    );
  }
  showNotificationMessage(
    color,
    form,
    align,
    className,
    typeIcon,
    title,
    message,
    messageTime = null
  ) {
    const typeIcon1 = typeIcon;
    const type = color;
    $.notify(
      {
        icon: 'notifications',
        title: title,
        message: message,
      },
      {
        allow_dismiss: true,

        placement: {
          from: form,
          align: align,
        },
        type: type,
        delay: messageTime === true ? 5000 : 2000,
        timer: messageTime === true ? 5000 : 2000,
        template:
          '<div data-notify="container" class="col-xl-3 col-lg-3 col-11 col-sm-3 col-md-3 success_alert alert alert-{0} alert-with-icon" role="alert" style="background-color: var(--clWhta); box-shadow: #091e424f 0px 0px 1px, #091e4240 0px 0px 12px -8px; color: #42526e; max-width: 400px; border-radius: 3px; padding-left: 56px;">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons txt_g" style="font-size: 16px; font-weight: bold; padding: 8px 0 0;">close</i></button>' +
          '<i class="material-icons ' +
          className +
          '" data-notify="icon" style="font-size: 24px; top: auto; margin-top: auto;">' +
          typeIcon +
          '</i>' +
          '<span data-notify="title" style="font-size:14px; font-weight: bold;" class="f_clash">{1}</span>' +
          '<span data-notify="message" style="font-size:14px; font-weight: 400; max-height: 90px; overflow: hidden; padding: 5px 0 0;">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          '</div>' +
          '<a href="" target="" data-notify="url"></a>' +
          '</div>',
      }
    );
  }
  showErrorNotification(color, form, align, message) {
    $.notifyClose();
    const type = color;
    $.notify(
      {
        icon: 'notifications',
        message: message,
      },
      {
        allow_dismiss: true,
        newest_on_top: true,
        type: type,
        delay: 2000,
        timer: 2000,
        placement: {
          from: form,
          align: align,
        },
        template:
          '<div data-notify="container" style="top:0px;" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title" class="f_clash">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          '</div>' +
          '<a href="" target="" data-notify="url"></a>' +
          '</div>',
      }
    );
  }

  showPushNotification(color, form, align, title, message, url) {
    const type = color;
    $.notify(
      {
        icon: 'notifications',
        title: title,
        message: message,
        url: url,
      },
      {
        allow_dismiss: true,
        newest_on_top: true,
        type: type,
        delay: 2000,
        timer: 2000,
        placement: {
          from: form,
          align: align,
        },
        template:
          '<div data-notify="container" style="bottom: 0px; border-radius: 4px; background: #e4e4e4; position: relative; max-width: 28rem;" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-6 alert alert-{0} alert-with-icon ps-0" role="alert">' +
          '<div data-notify="dismiss" style="position: absolute; right: 8px; top: 10px; cursor: pointer;">' +
          '<span class="icon-ic-cross fs_18 txt_lb"></span>' +
          '</div>' +
          '<a href="{3}" target="_self" data-notify="url" class="alert_action">' +
          '<div style=" display: flex; padding-right: 10px;">' +
          '<div style="padding-right: 20px;">' +
          '<img src="' +
          environment.serverPathForUrl +
          '/portal/images/after-login/LA-fav.png" class="img-fluid" style="max-width: 40px;">' +
          '</div>' +
          '<div style="">' +
          '<p class="mb-1 fs_16 fsm_5 txt_b f_clash" data-notify="title">{1}</p>' +
          '<p class="mb-0 fs_14 fsn_4 txt_b" data-notify="message" style="opacity: 0.6;">{2}</p>' +
          '</div>' +
          '</div>' +
          '</a>' +
          '</div>',
      }
    );
  }
}
