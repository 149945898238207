import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/commons/service/alert.service';
import { CommonService } from 'src/app/commons/service/common.service';
import { SharedService } from 'src/app/commons/service/shared.service';
import { AppSettings } from 'src/app/commons/setting/app_setting';
export interface DialogData {
  openPop: any;
  planDetails:any;
  fullName:any;
  laneName:any;
  companyName:any;
  email:any;
  phone:any;
  countryCode:any;
  ftlVolume:any
}
@Component({
  selector: 'app-enterprice-popup',
  templateUrl: './enterprice-popup.component.html',
  styleUrls: ['./enterprice-popup.component.css']
})


export class EnterpricePopupComponent implements OnInit {
  public userSubmitted:boolean=false;
  public defualtPickupCountryFlag: any ='https://s3.us-east-2.amazonaws.com/staticdev.laneaxis.com/portal/images/shipment-countries-flags/US.png';
  getRecord:any;
  public checkEmptyValue: any;
  public ftlVolumeData:any
  public equipmentLengthList: any;

  public equipmentListing:any;
  public countryList: any;
  public ftlVolumes:any;
  public loading=false;
  public enterPriceForm:FormGroup;
  public emailPattern = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$';  
  public numOnly = '[0-9]*';
  constructor(
    private formBuilder: FormBuilder,
    public sharedService: SharedService,
    private commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    public dialogRef: MatDialogRef<EnterpricePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    let configValue = this.sharedService.getConfig();

    if (configValue !== undefined) {
      this.getConfigSet(configValue);
    }
    this.commonService.configData.subscribe((userData) => {
      this.getConfigSet(userData);
    });
    this.selectPickCountryCode(this.data?.countryCode ? this.data?.countryCode : 'US') 

    this.enterPriceForm = this.formBuilder.group({
      // dotNumber: ['', [Validators.pattern(this.numOnly),Validators.maxLength(10),]],
      companyName: [
        this.data?.companyName ? this.data?.companyName :'',
        [
          Validators.required,
          Validators.maxLength(128),
          Validators.pattern(/^([a-zA-Z0-9&-@]+\s?)*$/),
        ],
      ],
      name: [
        this.data?.fullName ? this.data?.fullName :'',
        [
          Validators.required,
          Validators.maxLength(64),
          Validators.pattern(/^([a-zA-Z0-9]+\s?)*$/),
        ],
      ],
      email: [
        this.data?.email ? this.data?.email :'',
        [
          Validators.required,
          Validators.email,
          Validators.maxLength(128),
          Validators.pattern(this.emailPattern),
        ],
      ],
      equipmentType:['',[
        Validators.required,

      ]  ],

      phone: [
        this.data?.phone ? this.data?.phone :'',
        [
          Validators.required,
          Validators.pattern(this.numOnly),
          Validators.minLength(10),
          Validators.maxLength(10),
        ],
      ],
      countryCode: [this.data.countryCode ? this.data?.countryCode :'US', [
        Validators.required,
      ],
      ],
      ftlVolume: ['',[
        Validators.required,
      ]],
    });
    
  }

  submitInterPricePlan({ value, valid }){
      this.userSubmitted = true;
      if (valid) {
        this.loading = true;
        let userType = null;
        if (localStorage.getItem('user_type') === 'SHIPPER') {
          userType = AppSettings.APIsNameArray.SUBSCRIPTION.ENTERPRICE;
        } else {
          userType = AppSettings.APIsNameArray.EXTERNAL.INVITE;
        }
        let APIparams = {
          apiKey: userType,
          uri: '',
          postBody: value,
        };
        this.commonService.post(APIparams).subscribe(
          (success) => {
            if (success.success === true) {
             
              this.alertService.showNotificationMessage(
                'success',
                'bottom',
                'right',
                'txt_s',
                'check_circle',
                'Request Submitted Successfully',
                success.message
              );
              this.loading = false;
              this.dialogRef.close({ event: 'Ok' });
            } else if (success.success === false) {
              this.loading = false;
              this.alertService.showNotificationMessage(
                'danger',
                'bottom',
                'right',
                'txt_d',
                'check_circle',
                'Request Submitted Successfully',
                success.message
              );
            }
          },
          (error) => {
            this.alertService.showNotificationMessage(
              'danger',
              'bottom',
              'right',
              'txt_g',
              'error',
              'Unexpected Error',
              AppSettings.ERROR
            );
            this.loading = false;
          }
        );
      }
    }

    getConfigSet(configValue: any) {
      this.equipmentListing = configValue.equipmentTypes;
      this.countryList = configValue.countries;
      this.ftlVolumes=configValue.ftlVolume
    }

    onNoConfirmClick() {
      this.dialogRef.close({ event: 'fail' });
    }

    selectPickCountryCode(event: any) {
      this.getRecord = this.countryList.filter((item) => item.countryAbbr == event);
      this.defualtPickupCountryFlag = this.getRecord[0]?.flag;
    }

    keyPress(event: any) {
      const pattern = /^[0-9]*$/;
      let inputChar = String.fromCharCode(event.charCode);
      if (event.keyCode !== 8 && !pattern.test(inputChar)) {
        event.preventDefault();
      }
    }
  
    keyPasteShipper(event: any) {
      const pattern = /^[0-9]*$/;
      if (!pattern.test(event.target.value)) {
        return this.enterPriceForm.get('phone').setValue('');
      }
    }
  
    keyPasteCarrier(event: any) {
      const pattern = /^[0-9]*$/;
      if (!pattern.test(event.target.value)) {
        return this.enterPriceForm.get('phone').setValue('');
      }
    }
    selectEquipmentLength(event) {
      const equipmentLength = this.equipmentListing.filter(
        (item) => item.id === event.value
      );
      if (event.value === 9) {
        this.checkEmptyValue = 'NoLength';
        this.enterPriceForm
          .get('equipmentTypeLength')
          .setValue(this.checkEmptyValue);
          this.equipmentLengthList = [];
      } else {
        this.checkEmptyValue = '';
        this.enterPriceForm
          .get('equipmentTypeLength')
          .setValue(this.checkEmptyValue);
        if (equipmentLength) {
          const mapped = Object.keys(equipmentLength[0].length).map((key) => ({
            lengthKey: key,
            lengthValue: equipmentLength[0].length[key],
          }));
          this.equipmentLengthList = mapped;
        }
      }
    }

    selectFtlVolume(event){
      const ftlRecord = this.ftlVolumes.filter((item) => item.id == event);
      this.ftlVolumeData = ftlRecord[0]?.name
     
    }
}
