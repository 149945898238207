import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RefreshPopupComponent } from '../../commons/refresh-popup/refresh-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { SharedService } from '../../commons/service/shared.service';
@Injectable({
  providedIn: 'root',
})
export class TokenInterceptorService implements HttpInterceptor {
  constructor(
    public dialog: MatDialog,
    public sharedService: SharedService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    localStorage.setItem('showExpiryPopup', '0');
    let authToken = localStorage.getItem('access_token');

    //bypass header for heremap8 because its not require any header to run
    if (req.headers.has('hereMap8')) {
      const clonedRequest = req.clone({
        headers: req.headers.delete('hereMap8')
      });
      return next.handle(clonedRequest);
    }
    //end code for heremap8


     if (authToken) {
      req = req.clone({
        setHeaders: {
          Authorization: 'Bearer ' + authToken,
        },
      });
    }
    /* return next.handle(req); */
    return next.handle(req).pipe(
      tap(
        () => {},
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (
              err.status === 401 &&
              localStorage.getItem('access_token') &&
              localStorage.getItem('login') === 'after_login'
            ) {
              if (localStorage.getItem('showExpiryPopup') === '0') {
                const dialogRef = this.dialog.open(RefreshPopupComponent, {
                  disableClose: true,
                 
                  panelClass: 'upload_certificate_pop',
                });
                dialogRef.afterClosed().subscribe((result) => {});
                this.sharedService.closePopupMultipleTime.next(null);
                localStorage.setItem('showExpiryPopup', '1');
              }
            }
          }
        }
      )
    );
  }
}
