<div class="text-center py-3" *ngIf="showCurrentSection==='connect_account'">
	<p class="fs_18 fsm_5 txt_b mb-4">Your payment account successfully Created, Please close this popup if It's not automatically closed</p>
	<p class="mb-0">
		<span class="fs_14 fsm_5 txt_p">Please close this window</span>
	</p>
</div>

<div class="text-center py-3" *ngIf="showCurrentSection==='ach_account'">
	<p class="fs_18 fsm_5 txt_b mb-4">Your ACH account added successfully, Please close this popup if It's not automatically closed.

	</p>
	<p class="mb-0">
		<span class="fs_14 fsm_5 txt_p">Please close this window</span>
	</p>
</div>


<div class="text-center py-3" *ngIf="showCurrentSection==='configure-ach-account'">
	<p class="fs_18 fsm_5 txt_b mb-4">There's been an unexpected error</p>
	<p class="mb-0">
		<span class="fs_14 fsm_5 txt_p">Please close the browser popup window and try again.
		</span>
	</p>
</div>




