<div class="sign_in_header">
  <app-login-navbar></app-login-navbar>
</div>
<div class="sign_in_body">
  <div class="sign_in_box">
    <div class="signin_inner">
      <div class="sign_in_con" *ngIf="currentTab==='forgot-password'">
        <form [formGroup]="forGotForm" (ngSubmit)="forGotPass(forGotForm)" class="custum_input_css" autocomplete="off">
          <!-- forgot password -->
          <div class="fp_box">
            <p class="sf_form_tlt">Forgot Your Password?</p>
            <p class="fs_14 fsn_4 txt_b" style="margin: 0 0 1.5rem;">Enter the email you use for LaneAxis, and we'll help you create a new password</p>
            <div class="input_field_box">
              <p class="fs_14 fsn_4 txt_lb mb-1">Email Address <span class="txt_d">*</span></p>
              <mat-form-field appearance="none">
                <input type="email" matInput formControlName="username" class="form-control custom_input" maxlength="129" placeholder="Enter registered email address" />
                <mat-error *ngIf="forpasswordsumbit && forGotForm.get('username').errors?.['required']" class="error_mess">Email is required.</mat-error>
                <mat-error *ngIf="forGotForm.get('username').errors?.['pattern']" class="error_mess">Please enter valid email address.</mat-error>
                <mat-error *ngIf="(forGotForm.get('username').errors?.['maxlength']) && !(forGotForm.get('username').errors?.['pattern'])" class="error_mess">Email must not exceed 128 characters.</mat-error>
              </mat-form-field>
              <small class="remember_password error_mess" *ngIf="oopsError">{{oopsError}}</small>
            </div>
            <div class="form_btm_actn">
              <button class="btn custum_button button_primary" type="submit">Get verification code</button>
              <p class="forgot_password"><u class="pointer f_clash" (click)="goToLogin()">Back to Sign in</u></p>
            </div>
          </div>
        </form>
      </div>
      <div class="sign_in_con" *ngIf="currentTab==='email-verify-forgot-password'">
        <form [formGroup]="optVerifictionForm" (ngSubmit)="optVerifyForPassword(optVerifictionForm)" class="custum_input_css" autocomplete="off">
          <!-- Email OTP for Password reset -->
          <div class="email_otp_box">
            <p class="sf_form_tlt">Check your email</p>
            <p class="fs_14 fsn_4 txt_lb" style="margin: 0 0 1rem;">We've sent a 6-digit verification code to <span class="fsm_5 txt_b">{{setEmail}}</span>. If you are registered in our system please check your inbox (or possible spam folder) and enter the code you received below to confirm your email address.</p>
            <div class="input_field_box">
              <p class="fs_14 fsn_4 txt_lb mb-1">Verification code <span class="txt_d">*</span></p>
              <mat-form-field appearance="none">
                <input type="text" matInput formControlName="email_otp" class="form-control custom_input" placeholder="Enter verification code" minlength="6" maxlength="6" (input)="keyPasteEmail($event)" (keypress)="keyPressEamil($event)"/>
                <mat-error *ngIf="optVerifictionSumit && optVerifictionForm.get('email_otp').errors?.['required']" class="error_mess">Verification code is required.</mat-error>
                <mat-error *ngIf="optVerifictionForm.get('email_otp').errors?.['maxlength']" class="error_mess">Verification code should have maximum 6 digits.</mat-error>
                <mat-error *ngIf="optVerifictionForm.get('email_otp').errors?.['minlength']" class="error_mess">Verification code should have minimum 6 digits.</mat-error>
              </mat-form-field>
              <p class="resend_otp_box fs_14 fsm_5">
                <i class="txt_p pointer" (click)=resendOTP()>Resend email verification code.</i>
                <span *ngIf="spinnerLoading" class="spinner-border spinner_border text-primary" role="status">
                  <span class="sr-only">
                    <!-- Loading... -->
                  </span>
                </span>
                <small class="fs_14 fsn_4 txt_s" *ngIf="showMessage">{{showMessage}}</small>
                <small class="error_mess" *ngIf="oopsError">{{oopsError}}</small>
              </p>
            </div>
            <div class="form_btm_actn">
              <button class="btn custum_button button_primary" type="submit">Verify</button>
              <p class="forgot_password" (click)="backVerifyForgot('forgot-password')"><u class="pointer f_clash">Back</u></p>
            </div>
          </div>
        </form>
      </div>
      <div class="sign_in_con" *ngIf="currentTab==='change-password'">
        <form [formGroup]="createNewPasswordForm" (ngSubmit)="createPasswordSumbit(createNewPasswordForm)" class="custum_input_css" autocomplete="off">
          <!-- Email OTP for Password reset -->
          <div class="email_otp_box">
            <p class="sf_form_tlt">Reset your password</p>
            <div class="input_field_box">
              <p class="fs_14 fsn_4 txt_lb mb-1">New Password <span class="txt_d">*</span></p>
              <mat-form-field appearance="none" class="ic_password">
                <input type="text" matInput type="pasword" placeholder="Enter new password" formControlName="newPassword" name="newPassword" [type]="hide1 ? 'password' : 'text'" minlength="6" maxlength="17" class="form-control custom_input">
                <button type="button" mat-icon-button matSuffix (click)="hide1 = !hide1" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide1">
                  <mat-icon class="ff_im fs_18 txt_lb {{hide1 ? 'icon-ic-hide' : 'icon-ic-show'}}"></mat-icon>
                </button>
                <mat-error *ngIf="createNewPasswordForm.get('newPassword').errors?.['required']" class="error_mess">Password is required.</mat-error>
                <mat-error *ngIf="createNewPasswordForm.get('newPassword').errors?.['minlength']" class="error_mess">Password min lengh must be 6 character.</mat-error>
                <mat-error *ngIf="createNewPasswordForm.get('newPassword').errors?.['maxlength']" class="error_mess">Only 16 characters are allowed.</mat-error>
              </mat-form-field>
            </div>
            <div class="input_field_box">
              <p class="fs_14 fsn_4 txt_lb mb-1">Re-enter your Password</p>
              <mat-form-field appearance="none" class="ic_password">
                <input type="text" matInput type="pasword" placeholder="Re-enter your password" formControlName="confirmPassword" name="confirmPassword" [type]="hide2 ? 'password' : 'text'" class="form-control custom_input" maxlength="17">
                <button type="button" mat-icon-button matSuffix (click)="hide2 = !hide2" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide2">
                  <mat-icon class="ff_im fs_18 txt_lb {{hide2 ? 'icon-ic-hide' : 'icon-ic-show'}}"></mat-icon>
                </button>
                <mat-error *ngIf="createNewPasswordForm.get('confirmPassword').errors?.['required']" class="error_mess">Confirm password is required.</mat-error>
                <mat-error *ngIf="createNewPasswordForm.get('confirmPassword').errors?.['mustMatch']" class="error_mess">Password do not match.</mat-error>
              </mat-form-field>
              <p class="resend_otp_box fs_14 fsm_5">
                <small class="fs_14 fsn_4 txt_s" *ngIf="showMessage">{{showMessage}}</small>
                <small class="error_mess" *ngIf="oopsError">{{oopsError}}</small>
              </p>
            </div>
            <div class="form_btm_actn">
              <button class="btn custum_button button_primary" type="submit">Reset</button>
              <p class="forgot_password"><u class="pointer f_clash" (click)="backVerifyForgot('email-verify-forgot-password')"> Back </u></p>
            </div>
          </div>
        </form>
      </div>
      <div class="sign_in_con" *ngIf="currentTab==='success-password'">
        <div class="txt_b fs_18 my-4 text-center">
          <span class="material-icons" style="margin: 0 0 1.5rem; font-size: 48px; color: var(--clGrna);">check_circle</span>
          <p class="fs_20 fsm_5 txt_b" style="margin: 0 0 2rem;">Thank You!</p>
          <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">You have successfully reset your password.</p>
          <div class="col-md-12 px-0">
            <button class="btn custum_button button_primary w-100" (click)="goToLogin()">Back to Sign in</button>
          </div>
        </div>
      </div>
      <p class="sign_up_txt">
        <span>Don't have an account?</span> 
        <u (click)="singUp()">Sign up</u>
      </p>
    </div>
  </div>
  <app-footer></app-footer>
</div>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px',fullScreenBackdrop:true }"></ngx-loading>