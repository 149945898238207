import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { CommonService } from '../../commons/service/common.service';
import { AlertService } from '../../commons/service/alert.service';
import { AppSettings } from '../../commons/setting/app_setting';
import { SharedService } from '../../commons/service/shared.service';

export interface DialogData {
  openPop: any;
  shipmentRecord: any;
  shipmentUniqueId: any;
  shipmentDetail:any;
  driverName: any;
  shipmentId: any;
  typeSection: any;
  occurence: any;
}

@Component({
  selector: 'app-carrier-side-popup',
  templateUrl: './carrier-side-popup.component.html',
  styleUrls: ['./carrier-side-popup.component.css'],
})
export class CarrierSidePopupComponent implements OnInit {
  public loaderSpinner = false;
  public showConfirm: any;
  public showSuccess: any;
  public driverListPoup: any;
  public driverInfo: any;
  public addshipment: any;

  constructor(
    private commonService: CommonService,
    private sharedService: SharedService,
    public alertService: AlertService,
    public dialogRef: MatDialogRef<CarrierSidePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    if(this.data.openPop === 'crtAssignDriver') {
       this.driverListPoup = 'show';
    }
  }

  close(event) {
    this.dialogRef.close({ event: 'close' });
  }

  successShipmentCreate() {
    this.dialogRef.close({ event: 'success' });
  }

  successShipment() {
    let shipmentId;
    if (this.data.shipmentDetail.id) {
      shipmentId = this.data.shipmentDetail.id;
    } else {
      shipmentId = this.data.shipmentId;
    }
    this.dialogRef.close({ event: 'success', id: shipmentId });
  }

  backToDriverList() {
    this.driverListPoup = 'show';
    this.showConfirm = '';
  }

  confirmToAssign(event) {
    this.driverInfo = event;
    this.driverListPoup = 'hide';
    if (this.data.typeSection === 'assign') {
      this.showConfirm = 'shipmentConfirm';
    } else if (this.data.typeSection === 'createShipment') {
      this.showConfirm = 'assignShipmentConfirm';
    } else if (this.data.typeSection === 'changeDriver') {
      this.showConfirm = 'shipmentConfirm';
    } else {
      this.showConfirm = 'assignShipmentConfirm';
    }
  }

  driverShipmentAssign() {
    if (this.data.shipmentDetail && this.data.shipmentDetail.id) {
      this.assignDriverShipment();
      this.loaderSpinner = true;
    } else {
      let driverName;
      if (this.driverInfo.legalName) {
        driverName = this.driverInfo.legalName;
      } else {
        driverName = this.driverInfo.name;
      }
      this.dialogRef.close({
        event: 'OK',
        driverId: this.driverInfo.userId,
        driverName: driverName,
      });
    }
  }

  assignDriverShipment() {
    let postBody = {
      shipmentId: parseInt(this.data.shipmentDetail.id),
      driverId: parseInt(this.driverInfo.userId),
    };
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.SHIPMENT.ASSIGN,
      uri: '',
      postBody: postBody,
    };
    this.commonService.put(APIparams).subscribe(
      (success) => {
        if (success.success === true) {
          let driverName;
          if (this.driverInfo.legalName) {
            driverName = this.driverInfo.legalName;
          } else {
            driverName = this.driverInfo.name;
          }
          var dimensions = null;
          if (driverName) {
            dimensions = 'driverName=' + driverName;
          }
          let eventName;
          if (this.driverInfo.legalName) {
            this.driverInfo.legalName = eventName;
            eventName = 'assign_self';
          } else {
            eventName = 'assign_driver';
          }
          var obj = {
            eventName: eventName,
            event_category: 'Shipment',
            Occurence: this.data.occurence,
            userType: localStorage.getItem('user_type'),
            EmailID: localStorage.getItem('email'),
            Dimensions: dimensions ? dimensions : '-',
            ShipmentID: this.data.shipmentDetail.id
          };
          this.sharedService.track(obj);
          this.loaderSpinner = false;
          this.driverListPoup = 'hide';
          this.showConfirm = '';
          if (this.data.typeSection === 'assign') {
            this.showSuccess = 'changeDriver';
          } else if (this.data.typeSection === 'changeDriver') {
            this.showSuccess = 'changeDriver';
          } else {
            this.showSuccess = 'shipmentSuccess';
          }
        } else if (success.success === false) {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_d',
            'cancel',
            'Query drivers',
            success.message
          );
          this.loaderSpinner = false;
        }
      },
      (error) => {
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_g',
          'error',
          'Unexpected Error',
          AppSettings.ERROR
        );
        this.loaderSpinner = false;
      }
    );
  }
}
