import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-rate-per-mile',
  templateUrl: './rate-per-mile.component.html',
  styleUrls: ['./rate-per-mile.component.scss'],
})
export class RatePerMileComponent implements OnInit {
  @Input() amount: number;
  @Input() miles: number;
  ratePerMile: any;
  constructor() {}

  ngOnInit(): void {
    this.ratePerMile = this.amount / this.miles;
    if (this.miles === 0 || this.miles === null) {
      this.ratePerMile = '0.00';
    }
  }
}
