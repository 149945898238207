<div class="side_contaier">
	<div class="side_contaiert">
		<div class="header_bar" >
			<div class="side_headerl">
				<a *ngIf="userType==='CARRIER'" [routerLink]="['/shipper/profile/' + profileSidePanelData?.id]"
					target="_blank"><u class="fs_14 fsm_5 txt_p f_clash">Open in New tab</u></a>
				<a *ngIf="userType==='SHIPPER'" [routerLink]="['/carrier/profile/' + profileSidePanelData?.id]"
					target="_blank"><u class="fs_14 fsm_5 txt_p f_clash">Open in New tab</u></a>
					
			</div>
			<div class="side_headerr"  (click)="closePanel(profileSidePanelData,'close')">
				<span class="icon-ic-cross fs_24 txt_lb pointer"></span>
			</div>
		</div>

		<div class="map_card_back">
			<span class="material-icons back_icon txt_w"
				(click)="closePanel(profileSidePanelData,'changeView')">arrow_back</span>
		</div>
		<div class="map_card_back" >
			<span class="material-icons back_icon txt_w" (click)="closePanel(profileSidePanelData,'close')">arrow_back</span>
		</div>

		<div class="side_content v_scrollBar">
			<div class="header_con">
				<div class="header_img">
					<img *ngIf="profileSidePanelData?.coverImage" [src]="profileSidePanelData?.coverImage" class="img-fluid">
				</div>
				<div class="header_info">
					<div class="head_img">
						<div class="img_Con miW_84">
							<span *ngIf="profileSidePanelData?.profileImage" class="user_image">
								<img [src]="profileSidePanelData?.profileImage" class="img-fluid">
							</span>
							<span *ngIf="profileSidePanelData?.companyName && !profileSidePanelData?.profileImage"
								class="missed_img f_clash">{{profileSidePanelData?.companyName | acronymOfName}}</span>
							<span *ngIf="profileSidePanelData?.legalName && !profileSidePanelData?.profileImage"
								class="missed_img f_clash">{{profileSidePanelData?.legalName | acronymOfName}}</span>
						</div>
						<p class="head_name f_clash card_cName" *ngIf="profileSidePanelData?.legalName">
							<span class="text_truncate f_clash">{{profileSidePanelData?.legalName}}</span>
								<span *ngIf="userType=='SHIPPER' && profileSidePanelData?.subscription!==null">
									<img *ngIf="profileSidePanelData?.subscription"
									src="https://staticprod.laneaxis.com/portal/images/after-login/premium_plan_icon.png"
									class="img-fluid verified_icon">
								</span>
						</p>

						<p class="head_name f_clash card_cName" *ngIf="profileSidePanelData?.companyName">
							<span class="text_truncate f_clash">{{profileSidePanelData?.companyName}}</span>
						</p>
					</div>
					<div class="side_panel_rating">
						<app-rating-view [rate]="profileSidePanelData?.ratingReview?.rate"
							[ratingReview]="profileSidePanelData?.ratingReview" [ratingOwnerDetail]="ratingOwnerDetail"
							[shipmentDetail]="profileSidePanelData"
							*ngIf="profileSidePanelData?.ratingReview?.rate"></app-rating-view>
					</div>
					<div class="revanu_loca">
						<p class="location">
							<span class="loca_lable">Location: </span>
							<span class="loca_value" *ngIf="!profileSidePanelData?.phyState && !profileSidePanelData?.phyCity"> -
							</span>
							<span class="loca_value" *ngIf="profileSidePanelData?.phyState || profileSidePanelData?.phyCity"> {{
								profileSidePanelData?.phyCity }}<span
									*ngIf="profileSidePanelData?.phyState && profileSidePanelData?.phyCity">,</span> {{
								profileSidePanelData?.phyState}}</span>

						</p>
						<p class="revanu" *ngIf="userType==='CARRIER'|| findUserType==='SHIPPER'">
							<span class="" style="width: 24px;">
								
								<img src="https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-revenue-greenc.svg"
									class="img-fluid">
							</span>
							<span class="revanu_value">{{profileSidePanelData?.revenueUsd ? convertedRevenue :'-'
								}}</span>
						</p>
						<p class="revanu" *ngIf="userType==='SHIPPER'|| findUserType==='CARRIER'">
							<span style="width: 20px; display: inline-block;">
								<img src="https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-driver-tc.svg"
									class="img-fluid">
							</span>
							<span class="revanu_value txt_p">{{profileSidePanelData?.driverTotal ? profileSidePanelData?.driverTotal
								:'-' }}</span>
						</p>
					</div>

					<button [ngClass]="userType === 'STORAGE' ? 'hide' : 'show'" class="sidebar_btn action_connect"
						*ngIf="!profileSidePanelData?.networkLabel || profileSidePanelData?.networkLabel==='CONNECT'"
						(click)="closePanel(profileSidePanelData,'connect')">
						<span class="icon-ic-plus me-1"></span>
						<span class="f_clash">Connect</span>
					</button>

					<span class="sidebar_btn action_pending" *ngIf="profileSidePanelData?.networkLabel==='PENDING'">
						<span class="icon-ic-pending txt_w me-1"></span>
						<span class="f_clash">Pending</span>
					</span>

					<!-- login user-->
					<span class="sidebar_btn action_accept pointer" *ngIf="profileSidePanelData?.networkLabel==='Accept'"
						(click)="userLoginPopup(findUserType)">
						<span class="icon-ic-tick fs_16 me-1"></span>
						<span class="f_clash">Accept</span>
					</span>

					<!-- login user-->
					<span class="sidebar_btn action_accept" *ngIf="profileSidePanelData?.networkLabel==='INVITATION'">
						<span class="icon-ic-envelope fs_16 me-1"></span>
						<span class="f_clash">New Invitation</span>
					</span>

					<span class="sidebar_btn bg_s txt_w" *ngIf="profileSidePanelData?.networkLabel==='CONNECTED'">
						<span class="f_clash">Connected</span>
					</span>
					<p class="accept_mess" *ngIf="profileSidePanelData?.note">
						<span class="fsm_5 me-1" *ngIf="profileSidePanelData.legalName">{{profileSidePanelData.legalName}}</span>
						<span class="fsm_5 me-1" *ngIf="profileSidePanelData.companyName">{{profileSidePanelData.companyName}}</span>
						<i class="fsn_4">{{profileSidePanelData?.note}}</i>
					</p>
				</div>
			</div>

			<div class="contact_info_container">
				<div class="info_conT">
					<p class="mb-0 fs_20 fsb_6 txt_b line_before f_clash">Contact Info</p>
				</div>
				<div class="info_conB">
					<div class="info_conBrow">
						<span class="">
							<img src="https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-user-tc.svg"
								class="img-fluid">
						</span>
						<span class="ms-2 fs_14 fsn_4"
							*ngIf="profileSidePanelData?.firstName || profileSidePanelData?.lastName">{{profileSidePanelData?.firstName}}
							{{profileSidePanelData?.lastName}}</span>
						<span class="ms-2 fs_14 fsn_4"
							*ngIf="!profileSidePanelData?.firstName && !profileSidePanelData?.lastName">-</span>
					</div>
					<div class="info_conBrow">
					</div>
					<div class="info_conBrow" *ngIf="profileSidePanelData?.phyState && profileSidePanelData?.phyCity">
						<span class="">
							<img src="https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-address-tc.svg"
								class="img-fluid">
						</span>
						<span class="ms-2 fs_14 fsm_5">{{profileSidePanelData?.phyAddressLine1 ?
							profileSidePanelData?.phyAddressLine1+"," :""}} {{profileSidePanelData?.phyAddressLine2 ?
							profileSidePanelData?.phyAddressLine2+"," :""}} {{profileSidePanelData?.phyCity ?
							profileSidePanelData?.phyCity+",": ""}} {{profileSidePanelData?.phyState ? profileSidePanelData?.phyState+",":
							""}} {{profileSidePanelData?.phyZip ? profileSidePanelData?.phyZip+"," :""}}
							{{profileSidePanelData?.phyCountry}}</span>
					</div>
					<div class="info_conBrow" *ngIf="profileSidePanelData?.mailingState && profileSidePanelData?.mailingCity">
						<span class="">
							<img src="https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-address-tc.svg"
								class="img-fluid">
						</span>
						<span class="ms-2 fs_14 fsn_4">
							<span>{{profileSidePanelData?.mailingAddressLine1 ? profileSidePanelData?.mailingAddressLine1+"," :""}}
								{{profileSidePanelData?.mailingAddressLine2 ? profileSidePanelData?.mailingAddressLine2+"," :""}}
								{{profileSidePanelData?.mailingCity ? profileSidePanelData?.mailingCity+",": ""}}
								{{profileSidePanelData?.mailingState ? profileSidePanelData?.mailingState+",": ""}}
								{{profileSidePanelData?.mailingZip ? profileSidePanelData?.mailingZip+",": ""}}
								{{profileSidePanelData?.phyCountry}}</span>
							<span class="mail_address">Mailing Address</span>
						</span>
					</div>
					<div class="info_conBrow" *ngIf="userType==='SHIPPER' || findUserType=== 'SHIPPER'">
						<span class="" *ngIf="profileSidePanelData?.fax">
							<img src="https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-fax-tc.svg"
								class="img-fluid">
						</span>
						<span class="ms-2 fs_14 fsm_5" *ngIf="profileSidePanelData?.fax">{{profileSidePanelData?.fax }}
						</span>
					</div>

					<div class="info_conBrow">
						<span class="">
							<img src="https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-job-title-tc.svg"
								class="img-fluid">
						</span>
						<span class="ms-2 fs_14 fsn_4">Manager</span>
					</div>
					<div class="info_conBrowIn">
						<p class="fs_16 fsm_5 txt_b mb-0 f_clash">LinkedIn Profile</p>

						<span class="info_social" *ngIf="profileSidePanelData?.linkedin">
							<a href="{{profileSidePanelData?.linkedin}}" target="_blank"><img class="img-fluid"
									src="https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-linkedin.svg"></a>
						</span>

						<span class="info_social unactive_icon" *ngIf="!profileSidePanelData?.linkedin">
							<img class="img-fluid"
								src="https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-linkedin.svg">
						</span>

					</div>
				</div>
			</div>

			<div class="contact_info_container py-0">
				<div class="info_conT">
					<p class="mb-0 fs_20 fsb_6 txt_b line_before">
						<img src="https://staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-business-service.svg"
							class="img-fluid" style="width: 22px;">
						<span style="margin: 0 0 0 10px;" class="f_clash">Business Services </span>
					</p>
				</div>
				<div class="buss_infoSide">
					<div class="row">
						<div class="col-md-5">
							<p class="buss_lable">Truckload Type </p>
						</div>
						<div class="col-md-7">
							<p class="buss_value">{{profileSidePanelData?.truckLoadType?.label ? profileSidePanelData?.truckLoadType?.label : "-"}}</p>
						</div>
						<div class="col-md-5">
							<p class="buss_lable">Equipment Type</p>
						</div>
						<div class="col-md-7">
							<p class="buss_value"
								*ngIf="profileSidePanelData?.equipmentType && profileSidePanelData?.equipmentType.length > 0"
								matTooltip="{{eqipmentSpace(profileSidePanelData?.equipmentType)}}">
								<span *ngFor="let equipment of profileSidePanelData.equipmentType; let i=index">
									{{ equipment}}<span *ngIf="i < profileSidePanelData.equipmentType.length-1">, </span>
								</span>
							</p>
							<p class="buss_value" *ngIf="!profileSidePanelData?.equipmentType">-</p>
						</div>

						<div class="col-md-5">
							<p class="buss_lable">Preferred Regions</p>
						</div>
						<div class="col-md-7">
							<p class="buss_value" *ngIf="profileSidePanelData?.preferredRegions?.length=== 0">I’m open for all
								Regions</p>

							<span class="buss_value" *ngIf="profileSidePanelData?.preferredRegions?.length > 0">
								<div class="mb-2" *ngFor="let regionSelect of regionSelectedData; let i = index;">
									<p class="fs_14 fsn_4 txt_lb mb-0">{{regionSelect.region}} : </p>
									<span *ngFor="let selectState of regionSelect.stateNameArray; let y = index;">
										{{selectState}}<span *ngIf="y < regionSelectedData[i].stateNameArray.length-1">,
										</span> </span>
								</div>
							</span>
						</div>

						<div class="col-md-5">
							<p class="buss_lable">Industries</p>
						</div>
						<div class="col-md-7">
							<p class="buss_value">{{profileSidePanelData?.usSic1987Description ?
								profileSidePanelData?.usSic1987Description : "-"}}</p>
						</div>
						
						<div class="col-md-5" *ngIf="userType=='CARRIER'">
							<p class="buss_lable">Industry</p>
						</div>
						<div class="col-md-7" *ngIf="userType=='CARRIER'">
							<p class="buss_value">{{profileSidePanelData?.industry ?
								profileSidePanelData?.industry : "-"}}</p>
						</div>

					
						<div class="col-md-5" *ngIf="userType=='SHIPPER'">
							<p class="buss_lable">About Me</p>
						</div>
						<div class="col-md-7" *ngIf="userType=='SHIPPER'">
							<p class="buss_value">{{profileSidePanelData?.aboutMe ? profileSidePanelData?.aboutMe : "-"}}</p>
						</div>

					</div>
				</div>
			</div>
		</div>




		
	</div>
	<div class="side_contaierb">
		<a *ngIf="!getUseProfileNewTab && findUserType==='CARRIER'" [routerLink]="['/network/find-a-carrier']"
			[queryParams]="{id: profileSidePanelData.id}" target="_blank" class="fs_14 fsn_4 txt_p pro_view_btn d-block">View
			Full Profile in new tab</a>
		<a *ngIf="!getUseProfileNewTab && findUserType==='SHIPPER'" [routerLink]="['/network/find-a-shipper']"
			[queryParams]="{id: profileSidePanelData.id}" target="_blank" class="fs_14 fsn_4 txt_p pro_view_btn d-block">View
			Full Profile in new tab</a>
			
			
		<button *ngIf="userType==='CARRIER'" class="pro_view_btn btn custum_button button_primary w-100"
			[routerLink]="['/shipper/profile/' + profileSidePanelData?.id]">View Full Profile</button>
		<button *ngIf="userType==='SHIPPER'" class="pro_view_btn btn custum_button button_primary w-100"
			[routerLink]="['/carrier/profile/' + profileSidePanelData?.id]">View Full Profile</button>

	</div>
</div>