import { Component, OnInit,  Inject, } from '@angular/core';
import {MatDialog,MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router } from '@angular/router';
declare var require: any;

export interface DialogData {
  popup: string;
  videoUrl:any;
  openPop:any;
}

@Component({
  selector: 'app-user-guide-popup',
  templateUrl: './user-guide-popup.component.html',
  styleUrls: ['./user-guide-popup.component.css'],
})
export class UserGuidePopupComponent implements OnInit {

  constructor(
    private router: Router,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<UserGuidePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {

  }

  closePoup(){
    this.dialogRef.close({ event: 'Ok' });
  }

  successClose(){
    this.dialogRef.close({ event: 'dissMiss' });
  }

}
