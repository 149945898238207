import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../commons/service/shared.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../../commons/service/common.service';
import { AlertService } from '../../commons/service/alert.service';
import { AppSettings } from '../../commons/setting/app_setting';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessagingService } from '../../commons/service/messaging.service';
declare let require: any;

@Component({
  selector: 'app-sign-up-in-loading',
  templateUrl: './sign-up-in-loading.component.html',
  styleUrls: ['./sign-up-in-loading.component.css'],
})
export class SignUpInLoadingComponent implements OnInit {
  public loading = false;
  public params: any;
  
  constructor(
    private sharedService: SharedService,
    private commonService: CommonService,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    private deviceService: DeviceDetectorService,
    private messagingService: MessagingService
  ) { }

  ngOnInit() {
    this.params = {};
    this.route.queryParams.subscribe((params) => {
      if (Object.keys(params).length > 0) {
        this.checkLogin(params['temp_token']);
      }
    });
  }

  // API call to check login-with-one-time-token
  checkLogin(tempToken: any) {
    let data = require('../../../../package.json');
    const getInfo = this.deviceService.getDeviceInfo();
    let postBody = null;
    postBody = {
      tempToken: tempToken,
      appVersion: data.version,
      osVersion: getInfo.os_version,
    };
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.EXTERNAL.LOGINTOKEN,
      uri: '',
      postBody: postBody,
    };
    this.commonService.post(APIparams).subscribe(
      (Serves) => {
        if (Serves.success === true) {
      
          this.commonService.callToLogout.next();
          if (!Serves.response.emailVerified && !Serves.response.phoneVerified) {
            localStorage.setItem('user_type', Serves.response.userType);
            localStorage.setItem('user_id', Serves.response.userId);
            localStorage.setItem('access_token', Serves.response.verifyOtpToken);
            localStorage.setItem('email_verified', Serves.response.emailVerified);
            localStorage.setItem('phone_verified', Serves.response.phoneVerified);
            localStorage.setItem('refresh_token', Serves.response.refresh);
            localStorage.setItem('onboarding', 'signUp');
            this.sharedService.setPlatformType('Website');
            this.commonService.sendTokanUpdate.next();
            this.getFCMToken();
            if (localStorage.getItem('user_type') === 'CARRIER') {
              this.router.navigate(['/sign-up-verify-email']);
            } else if (localStorage.getItem('user_type') === 'SHIPPER') {
              localStorage.setItem('premium', Serves.response.premium);
              if (Serves.response.tag === 'GTFO') {
                localStorage.setItem('tag', Serves.response.tag);
                localStorage.setItem('premium', Serves.response.premium);
                if (Serves.response.premium == '0') {
                  this.router.navigate(['/subscription-plan']);
                } else {
                  this.router.navigate(['/sign-up-verify-email']);
                }
              } else {
                this.router.navigate(['/sign-up-verify-email']);
              }
            } else if (localStorage.getItem('user_type') === 'STORAGE') {
              this.router.navigate(['/sign-up-verify-email']);
            }
          } else {
            localStorage.setItem('user_type', Serves.response.userType);
            localStorage.setItem('access_token', Serves.response.access);
            localStorage.setItem('email_verified', Serves.response.emailVerified);
            localStorage.setItem('user_id', Serves.response.userId);
            localStorage.setItem('refresh_token', Serves.response.refresh);

            if ((Serves.response.tag === 'GTFO') && (Serves.response.premium == '0' || Serves.response.premium == '1')) {
              localStorage.setItem('tag', Serves.response.tag);
              localStorage.setItem('premium', Serves.response.premium);
              if (Serves.response.premium == '0') {
                this.router.navigateByUrl('/subscription-plan');
                localStorage.setItem('login', 'before_login');
                localStorage.setItem('onboarding', 'signUp');
              } else {
                this.commonService.sendTokanUpdate.next();
                this.router.navigateByUrl('/network/my-network/connected');
                localStorage.setItem('login', 'after_login');
              }
            } else {
              localStorage.setItem('login', 'after_login');
            }
            if (Serves.response.userType === 'CARRIER') {
              localStorage.setItem('premium', Serves.response.premium);
              localStorage.setItem('is_trial', Serves.response.isTrial);
              localStorage.setItem('is_free_tracking',
                Serves.response.isFreeTracking
              );
              localStorage.setItem('is_dba', Serves.response.isDba);
            }
            if (Serves.response.userType === 'SHIPPER') {
              localStorage.setItem('is_contract', Serves.response.isContract);
              localStorage.setItem('premium', Serves.response.premium);
            }
            this.commonService.sendTokanUpdate.next();
            if (localStorage.getItem('user_type') === 'CARRIER') {
              this.router.navigateByUrl('/network/my-network/connected');
            } else if (localStorage.getItem('user_type') === 'SHIPPER') {
              this.router.navigateByUrl('/network/my-network/connected');
            } else if (localStorage.getItem('user_type') === 'STORAGE') {
              this.router.navigateByUrl('/storage/location/list');
            }
          }
        } else if (Serves.success === false) {
          this.router.navigate(['/']);
        }
      },
      (error) => {
        this.router.navigate(['/']);
      }
    );
  }

  // To get FCMToken
  getFCMToken() {
    if (localStorage.getItem('fcmToken') !== null) {
    } else {
      this.messagingService.requestPermission();
    }
  }

}
